const getPaymentDue = (paymentTerm) => {
  let netDays

  if (paymentTerm && paymentTerm.startsWith('NET')) {
    netDays = paymentTerm.split(' ')[1]
  } else if (paymentTerm === 'PAYMENT IN ADVANCE') {
    netDays = 1
  }

  const paymentDue = new Date(
    new Date().getTime() + netDays * 24 * 60 * 60 * 1000,
  )
    .toISOString()
    .substring(0, 10)

  return paymentDue
}

export default getPaymentDue
