import React from 'react'
import { Form } from 'formik'
import GoodsPayment from './subItemComponents/GoodsPayment'
import ForwarderPayment from './subItemComponents/ForwarderPayment'

const ItemComponent = (props) => {
  const {
    values,
    handleBlur,
    index,
    handleChange,
    setFieldValue,
    formStyle,
    item,
    userGroups: groups,
  } = props

  let SubComponent = () => <p>sub item comp</p>

  switch (values.paymentType) {
    case 'goodsPayment':
      SubComponent = GoodsPayment
      break
    case 'forwarderPayment':
      SubComponent = ForwarderPayment
      break
    default:
      break
  }

  return (
    <Form style={formStyle} autoComplete='off'>
      {values.items && <SubComponent {...props} />}
    </Form>
  )
}

export default ItemComponent
