import {
  vendor,
  qty,
  openQty,
  cpn,
  mpn,
  reqSched,
  cost,
  poNumber,
  costCurrency,
  date,
} from '../../functions/getValidations'

const formValidationSchema = {
  poNumber,
  qty,
  date,
  openQty,
  mpn,
  cpn,
  reqSched,
  cost,
  costCurrency,
}

export default formValidationSchema
