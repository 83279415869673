const getInitialValue = (rowData) => ({
  id: rowData.id,
  type: rowData.type,
  customer: rowData.customer,
  email: rowData.email,
  name:rowData.name,
  dept: rowData.dept,
  date: rowData.date,
  unit: rowData.unit,
  number: rowData.number,
  mfr: rowData.mfr,
  mpn: rowData.mpn,
  cpn: rowData.cpn,
  price: rowData.price,
  priceCurrency: rowData.priceCurrency,
  customerMOQ: rowData.customerMOQ,
  customerMPQ: rowData.customerMPQ,
  customerLeadtime: rowData.customerLeadtime,
  memo: rowData.memo,
  paymentTerm: rowData.paymentTerm,
  deliveryTerm: rowData.deliveryTerm,
  hankenoEntity: rowData.hankenoEntity,
})

export default getInitialValue
