import dsCreateOutgoingInvoice from '../../functions/APIOperations/dsCreateOutgoingInvoice'
import dsUpdateInventory from '../../functions/APIOperations/dsUpdateInventory'
import dsCreateShipments from '../../functions/APIOperations/dsCreateShipments'
import dsUpdateCPOQtyAfterCreateShipments from '../../functions/APIOperations/dsUpdateCPOQtyAfterCreateShipments'

const handleSubmit = async (values) => {
  let items = values.items.map((x) => ({
    type: 'shipping',
    date: values.date,
    invoiceType: x.priceCurrency === 'CNY' ? 'VAT' : 'PI',
    customer: values.customer,
    customerPaymentDue: values.customerPaymentDue,
    fulfillmentNumber: values.fulfillmentNumber,
    deliveryTerm: values.deliveryTerm,
    invoiceNumber: values.invoiceNumber,
    cartonNumber: x.cartonNumber,
    cartonNw: x.nw || (x.gw && x.gw * 0.92),
    cartonGw: x.gw,
    cartonDimensions:
      values.cartons[x.cartonNumber] &&
      values.cartons[x.cartonNumber].dimensions,
    cpn: x.cpn,
    hpn: x.hpn,
    mpn: x.mpn,
    poNumber: x.poNumber && x.poNumber.trim(),
    qty: x.qty,
    unit: x.unit,
    price: x.price,
    priceCurrency: x.priceCurrency,
    coo: x.coo && x.coo.split(','),
    billingStatus: x.priceCurrency === 'CNY' ? 'unbilled' : 'billed',
    receivingCode: x.receivingCode || '',
  }))

  await dsCreateShipments(items)

  await dsUpdateCPOQtyAfterCreateShipments(items)

  await dsUpdateInventory(items, 'minus')

  if (values.items[0].priceCurrency !== 'CNY') {
    await dsCreateOutgoingInvoice(items)
  }

  // await dsUpdateShipmentBillingStatusAfterCreateOrDeleteShipments(items)
}

export default handleSubmit
