const getAddedItems = (
  item,
  existingItems,
  qtyAttr,
  openQtyAttr,
  isSchedule,
) => {
  const updatedItems = []

  let qty = item[qtyAttr]

  for (let x of existingItems) {
    if (qty >= 0) {
      const fulfilledQty = Math.min(qty, x[qtyAttr] - x[openQtyAttr])
      const newObj = {
        changedQty: fulfilledQty,

        date: item.date,
        fulfillmentNumber: item.fulfillmentNumber || '',
        cartonNumber: item.cartonNumber && item.cartonNumber * 1,
        cartonDimensions: item.cartonDimensions,
        cartonNw: item.cartonNw && item.cartonNw * 1,
        cartonGw: item.cartonGw && item.cartonGw * 1,
        coo: item.coo,
        deliveryTerm: item.deliveryTerm,

        id: x.id,
        type: x.type,
        poNumber: x.poNumber,
        receivingCode: x.receivingCode || '',
        invoiceNumber: x.invoiceNumber,

        hpn: x.hpn,
        cpn: x.cpn,
        mfr: x.mfr,
        mpn: x.mpn,
        qty: x.qty,
        unit: x.unit,
        openQty: x.openQty,
        vendor: x.vendor,
        customer: x.customer,
        reqSched: x.reqSched,
        estSched: x.estSched,
      }

      updatedItems.push(newObj)
      qty = qty - fulfilledQty

      // console.log('isSchedule', isSchedule)
      if (!isSchedule) {
        // console.log('888')
        if (qty <= 0) break
      }
    }
  }
  // console.log('updatedItems', updatedItems)
  return updatedItems
}

export default getAddedItems

// let totalFulfillmentQtyForSchedOperation = totalFulfillmentQty
// for (let po of sameHpnInPos) {
//   if (totalFulfillmentQtyForSchedOperation > 0) {
//     const itemFulfillmentQty = Math.min(
//       totalFulfillmentQtyForSchedOperation,
//       po.qty - po.openQty,
//     )

//     await handleDSOperation(null, 'update', {
//       id: po.id,
//       openQty: po.openQty + itemFulfillmentQty,
//     })

//     totalFulfillmentQtyForSchedOperation =
//       totalFulfillmentQtyForSchedOperation - itemFulfillmentQty

//     if (totalFulfillmentQtyForSchedOperation <= 0) break
//   }
// }
