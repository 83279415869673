import React from 'react'

import { FormField } from '../../components/Form/FormField'

import { Form } from 'formik'

const createItemComponent = ({
  values,
  handleBlur,
  handleChange,
  index,
  formStyle,
  item: currentItem,
}) => {
  // const currentItem = item

  return (
    <Form style={formStyle} autoComplete='off'>
      <FormField disabled fieldName='poNumber' value={currentItem.poNumber} />

      <FormField disabled fieldName='mpn' value={currentItem.mpn} />

      <FormField disabled fieldName='cost' value={currentItem.cost} />

      <FormField
        disabled
        fieldName='costCurrency'
        value={currentItem.costCurrency}
      />

      <FormField disabled fieldName='qty' value={currentItem.qty} />

      <FormField
        disabled
        fieldName='amountExclVat'
        value={
          (currentItem.amountExclVat && currentItem.amountExclVat.toFixed(2)) ||
          ''
        }
      />

      <FormField
        disabled
        fieldName='cnyValue'
        value={(currentItem.cnyValue && currentItem.cnyValue.toFixed(2)) || ''}
      />

      <FormField
        disabled
        fieldName='importDuty'
        value={currentItem.importDuty || 0}
      />

      <FormField
        disabled
        fieldName='duty'
        value={(currentItem.duty && currentItem.duty.toFixed(2)) || 0}
      />

      <FormField
        disabled
        fieldName='contractAmount'
        value={
          (currentItem.contractAmount &&
            currentItem.contractAmount.toFixed(2)) ||
          ''
        }
      />
      <FormField
        disabled
        fieldName='vat'
        value={(currentItem.vat && currentItem.vat.toFixed(2)) || ''}
      />

      <FormField
        disabled
        fieldName='handlingFee'
        value={
          (currentItem.handlingFee && currentItem.handlingFee.toFixed(2)) || ''
        }
      />
    </Form>
  )
}

export default createItemComponent
