import { IconButton, Typography } from '@mui/material'
import { AddOutlined } from '@mui/icons-material'

export default function FormikDuplicateBtnIcon({ isDisabled, onClick }) {
  return (
    <IconButton disabled={isDisabled} aria-label='add' onClick={onClick}>
      <AddOutlined
        color={isDisabled ? 'disabled' : 'secondary'}
        fontSize='large'
      />
      <Typography color={isDisabled ? 'textSecondary' : 'secondary'}>
        复制最后一行
      </Typography>
    </IconButton>
  )
}
