const isItemExistinPO = (row, items) => {
  // console.log('row', row)
  // console.log('items', items)

  // debugger

  const firstRecordFound = items.find(
    (x) =>
      (x.cpn && x.cpn === row.cpn) ||
      (x.mpn && row.mpn && x.mpn.toLowerCase().includes(row.mpn.toLowerCase())),
  )

  if (items.length === 0) {
    row.enteredPrice = null
    row.alertMessage &&
      row.alertMessage.push({
        message: `can not find items in po ${row.poNumber}`,
        index: 'pid',
        style: 'alertLevel5',
        level: 5,
      })
    return false
  } else if (!firstRecordFound) {
    const attr = row.cpn || row.mpn
    let index = row.cpn ? 'cpn' : 'mpn'

    row.enteredPrice = null
    row.alertMessage &&
      row.alertMessage.push({
        message: `can not find ${attr} in po ${row.poNumber}`,
        index,
        style: 'alertLevel5',
        level: 5,
      })
    return false
  }

  return firstRecordFound
}

export default isItemExistinPO
