import React, { useEffect } from 'react'
import { FormField } from '../../components/Form/FormField'
import { Form } from 'formik'
import config from '../../parameters'

const ItemComponent = ({
  values,
  handleBlur,
  index,
  handleChange,
  setFieldValue,
  formStyle,
  item: currentItem,
  userGroups: groups,
}) => {
  useEffect(() => {
    if (
      (values.paymentType === 'goodsPayment' ||
        values.paymentType === 'goodsPrePayment') &&
      !currentItem.amountReceived &&
      currentItem.amountExclVat
    ) {
      setFieldValue(`items[${index}]`, {
        ...currentItem,
        amountReceived:
          currentItem.invoiceType === 'VAT'
            ? (currentItem.amountExclVat * (1 + config.vatRate)).toFixed(2) * 1
            : currentItem.amountExclVat,
      })
    }
  }, [currentItem, setFieldValue, index, values])

  return (
    <>
      {(values.paymentType === 'goodsPayment' ||
        values.paymentType === 'goodsPrePayment') && (
        <Form style={formStyle} autoComplete='off'>
          <FormField
            disabled
            fieldName='invoiceDate'
            value={currentItem.invoiceDate}
          />
          <FormField
            disabled
            fieldName='invoiceNumber'
            value={currentItem.invoiceNumber}
          />
          <FormField
            disabled
            fieldName='invoiceType'
            value={currentItem.invoiceType}
          />
          <FormField disabled fieldName='qty' value={currentItem.qty} />

          <FormField
            disabled
            fieldName='priceCurrency'
            value={currentItem.priceCurrency}
          />
          <FormField
            disabled
            fieldName='amountExclVat'
            value={currentItem.amountExclVat}
          />
          {currentItem.invoiceType === 'VAT' && (
            <FormField
              disabled
              fieldName='amountInclVat'
              value={
                (currentItem.amountExclVat * (1 + config.vatRate)).toFixed(2) *
                  1 || ''
              }
            />
          )}

          <FormField
            fieldName='amountReceived'
            index={index}
            value={currentItem.amountReceived || ''}
            handleBlur={handleBlur}
            handleChange={handleChange}
            validate={(value) => {
              const receviedAmountExclVat =
                currentItem.invoiceType === 'VAT'
                  ? value / (1 + config.vatRate)
                  : value

              let msg

              if (!value) {
                msg = '请输入收款金额'
              } else if (
                receviedAmountExclVat - currentItem.amountExclVat >
                1
              ) {
                msg = '收款金额大于应收金额'
              }
              return msg
            }}
          />
        </Form>
      )}
    </>
  )
}

export default ItemComponent
