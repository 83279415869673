import dsUpdateInventory from '../../functions/APIOperations/dsUpdateInventory'
import dsCreateReceivings from '../../functions/APIOperations/dsCreateReceivings'
import dsUpdateSPOQtyAndSPOSchedAfterCreateReceivings from '../../functions/APIOperations/dsUpdateSPOQtyAndSPOSchedAfterCreateReceivings'
import dsUpdateForwarderPOQtyAfterCreateReceivings from '../../functions/APIOperations/dsUpdateForwarderPOQtyAfterCreateReceivings'
import dsUpdateForwarderPOsDeliveryStatusWithReceivingCodeAndHpn from '../../functions/APIOperations/dsUpdateForwarderPOsDeliveryStatusWithReceivingCodeAndHpn'

const handleSubmit = async (values) => {
  // console.log('values', values)
  // debugger
  const type = 'receiving'
  const isForwarderPO = values.items[0].receivingCode ? true : false

  let items = values.items.map((x) => ({
    date: values.date,
    vendor: values.vendor,
    type,
    cpn: x.cpn,
    hpn: x.hpn,
    mpn: x.mpn,
    poNumber: x.poNumber || '',
    invoiceNumber: x.invoiceNumber || '',
    receivingCode: x.receivingCode || '',
    cost: x.cost,
    costCurrency: x.costCurrency,
    qty: x.qty,
    unit: x.unit,
  }))

  // SPO NUMBER AND VENDOR ARE RETURN BELOW
  if (isForwarderPO) {
    items = await dsUpdateForwarderPOQtyAfterCreateReceivings(items)
  }

  await dsCreateReceivings(items)

  await dsUpdateInventory(items, 'add')

  await dsUpdateSPOQtyAndSPOSchedAfterCreateReceivings(items)

  // const itemsWithReceivingCode = values.items.filter((x) => x.receivingCode)

  // await dsUpdateForwarderPOsDeliveryStatusWithReceivingCodeAndHpn(
  //   itemsWithReceivingCode,
  // )
}

export default handleSubmit
