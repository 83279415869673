import React from 'react'
import { Button, Stack } from '@mui/material'
import { Hub } from 'aws-amplify'
import MTSumField from '../../components/Table/toolbar/MTSumField'

const Toolbar = ({ selectedRows, dataType }) => {
  return selectedRows && selectedRows.length > 0 ? (
    <Stack direction='row' spacing={3}>
      <Button
        variant='contained'
        color='primary'
        size='small'
        onClick={async () => {}}>
        button
      </Button>
      <MTSumField qtyAttr='amountExclVat' selectedRows={selectedRows} />
    </Stack>
  ) : (
    <Stack direction='row' spacing={3}>
      <Button
        variant='contained'
        color='secondary'
        size='small'
        onClick={() =>
          Hub.dispatch('FormMonitor', {
            event: 'formEdit',
            data: {
              dataType,
              formType: 'create',
              allowAddItem: false,
              showDeleteItem: false,
            },
            message: '',
          })
        }>
        新建付款记录
      </Button>
    </Stack>
  )
}

export default Toolbar
