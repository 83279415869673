import * as Yup from 'yup'

const itemValidationShape = {
  price: Yup.string().required('please enter price'),
  mpn: Yup.string().required('please enter MPN'),
  customerLeadtime: Yup.string().required('please enter leadtime'),
  customerMOQ: Yup.string().required('please enter MOQ'),
  customerMPQ: Yup.string().required('please enter MPQ'),
}

const formValidationSchema = {
  customer: Yup.string().required('请选择客户'),
  number: Yup.string().required('invalid number'),
  items: Yup.array().of(Yup.object(itemValidationShape)),
}

export default formValidationSchema
