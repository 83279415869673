const initialValue = {
  product: {
    price: '',
    mpn: '',
    customerLeadtime: '',
    customerMOQ: '',
    customerMPQ: '',
  },
  // price: '',
  // mpn: '',
  // customerLeadtime: '',
}

export default initialValue
