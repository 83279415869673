import React, { useEffect, useMemo } from 'react'

import { FormField, ProductAutoComplete } from '../../components/Form/FormField'
import usePrevious from '../../hooks/usePrevious'
import { Form } from 'formik'
import config from '../../parameters'

const ItemComponent = ({
  values,
  handleBlur,
  index,
  handleChange,
  setFieldValue,
  formStyle,
  item: currentItem,
}) => {
  const isForwarderPo = values.vendor === config.forwarder.name

  const attr = isForwarderPo ? 'receivingCode' : 'poNumber'
  const preAttrValue = usePrevious(currentItem[attr])
  const prevHpn = usePrevious(currentItem.hpn)

  const sessionQty = values.items.reduce(
    (a, v) =>
      a +
      (v[attr] === currentItem[attr] && v.hpn === currentItem.hpn
        ? v.qty * 1 || 0
        : 0),
    0,
  )

  useEffect(() => {
    const func = async () => {
      if (currentItem.hpn !== prevHpn) {
        const itemSelectablePos = values.poOptions.filter(
          (x) => x.hpn === currentItem.hpn,
        )
        let attrValue
        let receivingCode
        console.log('itemSelectablePos', itemSelectablePos)
        if ([...new Set(itemSelectablePos.map((x) => x[attr]))].length === 1) {
          attrValue = itemSelectablePos[0][attr]
        }

        receivingCode =
          itemSelectablePos[0] && itemSelectablePos[0].receivingCode

        setFieldValue(`items[${index}]`, {
          itemSelectablePos,
          hpn: currentItem.hpn,
          mpn: currentItem.mpn,
          mfr: currentItem.mfr,
          cpn: currentItem.cpn,
          [attr]: attrValue,
          receivingCode,
          qty: '',
          openQty: '',
        })
      }
      if (currentItem[attr] && currentItem[attr] !== preAttrValue) {
        const getItems = values.poOptions.filter(
          (x) =>
            currentItem[attr] &&
            x[attr] === currentItem[attr] &&
            x.hpn === currentItem.hpn,
        )

        const openQty =
          getItems.reduce((a, v) => a + v.openQty, 0).toFixed(2) * 1

        // console.log('getItems', getItems)
        setFieldValue(`items[${index}]`, {
          ...currentItem,
          cpn: getItems[0].cpn,
          hpn: getItems[0].hpn,
          mpn: getItems[0].mpn,
          mfr: getItems[0].mfr,
          openQty,
          qty: openQty - sessionQty,
          unit: getItems[0].unit,
          [attr]: currentItem[attr],
          cost: getItems[0].cost,
          costCurrency: getItems[0].costCurrency,
          amountExclVat: getItems[0].amountExclVat,
        })
      }
    }

    func()
  }, [
    index,
    setFieldValue,
    prevHpn,
    values,
    currentItem,
    attr,
    preAttrValue,
    sessionQty,
  ])
  console.log('receiving values', values)

  return (
    <Form style={formStyle} autoComplete='off'>
      <ProductAutoComplete
        id={`${values.vendor}${values.items.length}`}
        options={values.selection}
        value={
          currentItem.hpn
            ? {
                mpn: currentItem.mpn,
                cpn: currentItem.cpn,
                hpn: currentItem.hpn,
                mfr: currentItem.mfr,
              }
            : null
        }
        currentItem={currentItem}
        index={index}
        setFieldValue={setFieldValue}
      />

      <FormField
        fieldName={attr}
        select
        index={index}
        value={
          (isForwarderPo ? currentItem.receivingCode : currentItem.poNumber) ||
          ''
        }
        options={[
          ...new Set(currentItem.itemSelectablePos.map((x) => x[attr] || '')),
        ]}
        handleChange={(e) => {
          setFieldValue(`items[${index}]`, {
            ...currentItem,
            [attr]: e.target.value,
            qty: 0,
          })
        }}
        handleBlur={handleBlur}
      />

      <FormField
        disabled
        fieldName='cpn'
        index={index}
        value={currentItem.cpn}
      />

      <FormField
        disabled
        fieldName='openQty'
        index={index}
        value={currentItem.openQty}
      />
      <FormField disabled fieldName='sessionQty' value={sessionQty || ''} />

      <FormField
        fieldName='qty'
        index={index}
        value={currentItem.qty}
        handleBlur={handleBlur}
        handleChange={handleChange}
        validate={(value) => {
          // console.log('value8888', value)
          return sessionQty > currentItem.openQty
            ? `本订单收货数量${sessionQty}大于未交数量${currentItem.openQty}`
            : null
        }}
      />
    </Form>
  )
}

export default ItemComponent
