const mapping = {
  enhanced: [
    'actions',
    'memo',
    'customer',
    'date',
    'number',
    'cpn',
    'mfr',
    'mpn',
    'price',
    'priceCurrency',
  ],
  simple: [
    'date',
    'invoiceNumber',
    'poNumber',
    'cpn',
    'qty',
    'price',
    'amountExclVat',
    'customerPaymentDue',
  ],
  collasped: [
    'date',
    'invoiceNumber',
    'poNumber',
    'cpn',
    'price',
    'amountExclVat',

    'vendor',
    'qty',
    'mpn',
  ],
}

export default mapping
