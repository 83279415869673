import * as Yup from 'yup'
import {
  vendor,
  qty,
  cpn,
  mpn,
  reqSched,
  price,
  poNumber,
  receivingCode,
  exchangeRate,
  invoiceNumber,
  date,
} from '../../functions/getValidations'

const itemValidationShape = {
  poNumber,
  mpn,
  qty,
}

const formValidationSchema = {
  receivingCode,
  exchangeRate,
  vendor,
  invoiceNumber,
  items: Yup.array().of(Yup.object(itemValidationShape)),
}

export default formValidationSchema
