import handleDSOperation from '../handleDSOperation'
import groupArrayofObjectsByKey from '../helpFunc/groupArrayofObjectsByKey'
import dsCreatePoItem from './dsCreatePoItem'

const dsCreateSpoScheds = async (items) => {
  // console.log('items', items)

  // debugger

  const groupedPOByNumber = Object.entries(
    groupArrayofObjectsByKey(items, 'poNumber'),
  )
  await Promise.all(
    groupedPOByNumber.map(async ([poNumber, arrOfItemsByPO]) => {
      const latestScheds = await handleDSOperation('spoSched', 'query', {
        poNumber: { predicate: 'eq', value: poNumber },
        version: { predicate: 'eq', value: 0 },
      })

      // console.log('latestScheds', latestScheds)

      // debugger

      const latestVersion =
        (latestScheds[0] && latestScheds[0].latestVersion) || 0

      await Promise.all(
        latestScheds.map(
          async (x) => await handleDSOperation(null, 'delete', x.id),
        ),
      )

      await Promise.all(
        arrOfItemsByPO.map(async (item) => {
          const itemInput = {
            date: item.date,
            type: 'spoSched',
            poNumber: item.poNumber,
            cpn: item.cpn,
            hpn: item.hpn,
            mpn: item.mpn,
            mfr: item.mfr,
            unit: item.unit,
            qty: item.qty * 1,
            openQty: item.openQty * 1,
            estSched: item.estSched,
            vendor: item.vendor,
            memo: item.memo,
          }

          // console.log('itemInput', itemInput)
          // debugger
          await dsCreatePoItem(itemInput, latestVersion)
        }),
      )
    }),
  )
}

export default dsCreateSpoScheds
