import * as Yup from 'yup'

export const qty = Yup.number()
  .required('Qty is required')
  // .positive('Qty entry should be greater than 0')
  .max(10000000, 'Maximum 10M')

export const openQty = Yup.number()
  .required('please enter open qty')
  .min(0, 'Open qty can not be less than 0')

export const price = Yup.number()
  .required('please enter price')
  .min(0, 'Price can not be less than 0')

export const cost = Yup.number()
  .required('please enter cost')
  .min(0, 'Cost can not be less than 0')

export const poNumber = Yup.string().test(
  'len',
  'po number must have at least three characters',
  (val) => {
    if (val) {
      return val.length > 2
    }
  },
)

export const fulfillmentNumber = Yup.string().test(
  'len',
  'fulfillment number must have at least three characters',
  (val) => {
    if (val) {
      return val.length > 3
    }
  },
)

export const customer = Yup.string().required('Customer is required')

export const deductStatus = Yup.string().required('请输入发票抵扣月份')


export const entity = Yup.string().required('Entity name is required')

export const hankenoEntity = Yup.string().required(
  'Please enter Hankeno entity',
)

export const paymentTerm = Yup.string().required('Please select payment term')

// export const deliveryTerms = Yup.array()
//   .of(Yup.string().required('Please select delivery term'))
//   .required('Please select delivery terms')
export const deliveryTerms = Yup.array().min(1, 'Please select delivery terms')

export const number = Yup.string().required('Please enter entity number')

export const vendor = Yup.string().required('Vendor is required')

export const date = Yup.string().required('date is required')

export const cpn = Yup.string().required('CPN is required')

export const mpn = Yup.string().required('MPN is required')

export const costCurrency = Yup.string().required('cost currency is required')

export const reqSched = Yup.string().required('please enter requested schedule')

export const estSched = Yup.string().required('Please enter schedule')

export const exchangeRate = Yup.string().required('请输入汇率')

export const receivingCode = Yup.string().test(
  'len',
  'receiving code must have at least three characters',
  (val) => {
    if (val) {
      return val.length > 2
    }
  },
)

export const invoiceNumber = Yup.string().test(
  'len',
  'invoice number must have at least three characters',
  (val) => {
    if (val) {
      return val.length > 2
    }
  },
)
