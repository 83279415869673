import React  from 'react'
import { Paper } from '@mui/material'
import Draggable from 'react-draggable'

export default function DraggablePaperComp(props) {
  return (
    <Draggable
      handle='#draggable-dialog-title'
      cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} style={{ minHeight: 300 }} />
    </Draggable>
  )
}
