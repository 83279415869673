import React, { useEffect } from 'react'
import { FormField, AutoCompleteField } from '../../components/Form/FormField'
import usePrevious from '../../hooks/usePrevious'
import { Form } from 'formik'

import handleDSOperation from '../../functions/handleDSOperation'

const CreateMainComponent = (props) => {
  const {
    values,
    submitForm,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    initialValues,
    setFieldValue,
    setValues,
    isSubmitting,
    formStyle,
    userGroups: groups,
  } = props

  const prevCustomer = usePrevious(values.customer)

  useEffect(() => {
    const func = async () => {
      if (!values.shippings) {
        const getShippings = await handleDSOperation('shipping', 'query', {
          // billingStatus: { predicate: 'ne', value: 'billed' },
        })
        setFieldValue('shippings', getShippings)
      }

      if (values.customer && values.customer !== prevCustomer) {
        const getCustomer = await handleDSOperation('customer', 'query', {
          entity: { predicate: 'eq', value: values.customer },
        })

        if (getCustomer.length === 0) return

        const hankenoEntity = getCustomer[0].hankenoEntity
        const paymentTerm = getCustomer[0].paymentTerm

        let netDays

        if (paymentTerm && paymentTerm.startsWith('NET')) {
          netDays = paymentTerm.split(' ')[1]
        } else if (paymentTerm === 'PAYMENT IN ADVANCE') {
          netDays = 1
        }

        const customerPaymentDue = new Date(
          new Date().getTime() + netDays * 24 * 60 * 60 * 1000,
        )
          .toISOString()
          .substring(0, 10)

        let invoiceNumber

        if (hankenoEntity === 'HKNUSA') {
          const getInvoices = await handleDSOperation(
            'outgoingInvoice',
            'query',
            {
              invoiceType: { predicate: 'eq', value: 'PI' },
            },
          )

          if (getInvoices.length > 0) {
            getInvoices.sort((a, b) =>
              a.invoiceNumber > b.invoiceNumber ? -1 : 1,
            )
            const lastNumber = getInvoices[0].invoiceNumber.slice(2, 8)
            const lastDate = getInvoices[0].date
            const dateDiff =
              (new Date().getTime() - new Date(lastDate).getTime()) /
              (24 * 60 * 60 * 1000)
            const dailyDiff = Math.random() * 100 + 200
            const invoiceDiff = Math.floor(dateDiff * dailyDiff)
            invoiceNumber = `EN${lastNumber * 1 + invoiceDiff * 1}`
          } else {
            invoiceNumber = 'Temp123'
          }
        }

        setValues({
          ...values,
          selectableShippings: values.shippings.filter(
            (x) => x.customer === values.customer,
          ),
          items: initialValues.items,
          invoiceNumber,
          customerPaymentDue,
        })
      }
    }
    func()
  }, [prevCustomer, setFieldValue, values, initialValues, setValues])

  return (
    <>
      <Form style={formStyle} autoComplete='off'>
        <AutoCompleteField
          // id={values.invoiceType}
          fieldName='customer'
          options={[
            ...new Set(
              values.shippings && values.shippings.map((x) => x.customer),
            ),
          ].map((x) => ({ customer: x }))}
          attr='customer'
          setFieldValue={setFieldValue}
        />
        <FormField
          fieldName='date'
          value={values.date}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          disabled={!values.customer}
          fieldName='invoiceNumber'
          value={values.invoiceNumber}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
      </Form>
    </>
  )
}

export default CreateMainComponent
