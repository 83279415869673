import React from 'react'
import { Button } from '@mui/material'
import { Hub } from 'aws-amplify'
import MTSumField from '../../components/Table/toolbar/MTSumField'

const Toolbar = ({ selectedRows, dataType }) => {
  return selectedRows && selectedRows.length > 0 ? (
    <>
      <Button
        variant='contained'
        color='primary'
        size='small'
        onClick={async () => {}}>
        button
      </Button>
      <MTSumField qtyAttr='amountExclVat' selectedRows={selectedRows} />
      {selectedRows[0] && selectedRows[0].invoiceType === 'VAT' && (
        <MTSumField qtyAttr='amountInclVat' selectedRows={selectedRows} />
      )}
    </>
  ) : (
    <>
      <Button
        variant='contained'
        color='secondary'
        size='small'
        onClick={() =>
          Hub.dispatch('FormMonitor', {
            event: 'formEdit',
            data: {
              dataType,
              formType: 'create',
              allowAddItem: false,
              showDeleteItem: false,
            },
            message: '',
          })
        }>
        新建收款记录
      </Button>
    </>
  )
}

export default Toolbar
