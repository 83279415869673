const mapping = {
  enhanced: [
    'actions',
    'memo',
    'billingStatus',
    // 'deliveryStatus',
    'paymentStatus',
    'date',
    'receivingCode',
    'vendor',
    'costCurrency',
    // 'exchangeRate',
    'poNumber',
    'cpn',
    'mpn',
    'cost',
    'qty',
    'openQty',
    'piAmount',

    'duty',
    'handlingFee',
    'vat',
    'extraCharge',
    'contractAmount',
    'invoiceNumber',
    // 'vendorPaymentDue',
    // 'vendorPaymentPaid',
    // 'forwarderTaxAndHandlingDue',
    // 'forwarderTaxAndHandlingPaid',
    // 'forwarderPaymentDue',
    // 'forwarderPaymentPaid',
    // 'billedAmount',
    // 'billedDate',
    // 'goodsReceivedDate',
  ],
  simple: ['date', 'poNumber', 'cpn', 'qty', 'mpn', 'vendor'],
  collasped: [
    'date',
    'poNumber',
    'cpn',
    'mpn',
    'vendor',
    'invoiceNumber',
    'number',
    'qty',
    'mpn',
    'paymentDue',
    'paymentMade',
  ],
}

export default mapping
