import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import SimpleTable from '../SimpleTable'
import MTSumField from '../toolbar/MTSumField'
import handleDSOperation from '../../../functions/handleDSOperation'

const FulfillmentRecord = ({
  fulfillmentType,
  receivingCode,
  poNumber,
  fulfillmentNumber,
  hpn,
  cpn,
}) => {
  const [rows, setRows] = useState([])

  useEffect(() => {
    let receivingCodeOrPoNumberQueryObj = {}
    if (receivingCode) {
      receivingCodeOrPoNumberQueryObj = {
        receivingCode: { predicate: 'eq', value: receivingCode },
      }
    } else if (poNumber) {
      receivingCodeOrPoNumberQueryObj = {
        poNumber: { predicate: 'eq', value: poNumber },
      }
    }

    let hpnOrCpnQueryObj = {}
    if (hpn) {
      hpnOrCpnQueryObj = { hpn: { predicate: 'eq', value: hpn } }
    } else if (cpn) {
      hpnOrCpnQueryObj = { cpn: { predicate: 'eq', value: cpn } }
    }

    let fulfillmentNumberObj = {}
    if (fulfillmentNumber) {
      fulfillmentNumberObj = {
        fulfillmentNumber: { predicate: 'eq', value: fulfillmentNumber },
      }
    }

    // console.log('queryObj', {
    //   ...receivingCodeOrPoNumberQueryObj,
    //   ...hpnOrCpnQueryObj,
    //   ...fulfillmentNumberObj,
    // })

    const func = async () => {
      let items = await handleDSOperation(fulfillmentType, 'query', {
        ...receivingCodeOrPoNumberQueryObj,
        ...hpnOrCpnQueryObj,
        ...fulfillmentNumberObj,
      })
      // console.log('items: ', items)
      setRows(items)
    }
    func()
  }, [fulfillmentType, poNumber, receivingCode, hpn, cpn, fulfillmentNumber])

  return (
    <Box m={3}>
      <MTSumField qtyAttr='qty'  selectedRows={rows} />
      <SimpleTable
        dataType={fulfillmentType}
        tbType='collaspedFulfillment'
        rows={rows}
      />
    </Box>
  )
}

export default FulfillmentRecord
