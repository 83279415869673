import restAPICalls from '../../restAPICalls'
import mergeArrayAndCombineQty from '../../helpFunc/mergeArrayAndCombineQty'
import handleDSOperation from '../../handleDSOperation'

const handleToolbarSendCOA = async (itemsSelected, isPreview) => {
  const date = itemsSelected[0].date
  itemsSelected = itemsSelected.map((x) => ({
    cpn: x.cpn,
    qty: x.qty,
  }))
  const mergedItems = mergeArrayAndCombineQty(itemsSelected, ['cpn'], ['qty'])

  await Promise.all(
    mergedItems.map(async (x) => {
      const getProd = await handleDSOperation('product', 'query', {
        cpn: { predicate: 'eq', value: x.cpn },
        version: { predicate: 'eq', value: 0 },
      })

      if (getProd.length === 0) return alert(`${x.cpn} is not found`)
      if (!getProd[0].spec) return alert(`${x.cpn}'s spec is not found`)

      x.spec = getProd[0].spec
      x.mfr = getProd[0].mfr
      x.mpn = getProd[0].mpn
    }),
  )

  // console.log('isPreview', isPreview)
  // console.log('coa items', itemsSelected)
  // console.log('mergedItems', mergedItems)
  // console.log('date', date)

  const items = {
    date,
    hankenoEntityName: 'HANKENO TECHNOLOGY LIMITED',
    hankenoEntityPhoneNumber: '+86-21-5059 5180',
    hankenoEntityAdd:
      '1503, Tomson Commercial Building, No.710,Dongfang Rd, Shanghai',
    inspectedBy: 'TONY LIU',
    verifiedBy: 'JAMES LEUNG',
    coaItems: mergedItems,
  }

  // console.log('array of items', items)
  // debugger

  await restAPICalls('post', null, {
    type: 'coa',
    items,
    isPreview,
  })
}

export default handleToolbarSendCOA
