const getInitialValue = (data) => ({
  id: data.id,
  type: data.type,
  date: data.date,
  amountExclVat: data.amountExclVat,
  costCurrency: data.costCurrency,
  cpn: data.cpn,
  hpn: data.hpn,
  mpn: data.mpn,
  vat: data.vat,
  duty: data.duty,
  exchangeRate: data.exchangeRate,
  extraCharge: data.extraCharge,
  handlingFee: data.handlingFee,
  qty: data.qty,
  openQty: data.openQty,
  poNumber: data.poNumber,
  vendor: data.vendor,
  unit: data.unit,
  receivingCode: data.receivingCode,
  vendorPaymentDue: data.vendorPaymentDue,
  vendorPaymentPaid: data.vendorPaymentPaid,
  forwarderPaymentDue: data.forwarderPaymentDue,
  forwarderPaymentPaid: data.forwarderPaymentPaid,
  forwarderTaxAndHandlingDue: data.forwarderTaxAndHandlingDue,
  forwarderTaxAndHandlingPaid: data.forwarderTaxAndHandlingPaid,
  paymentStatus: data.paymentStatus,
  deliveryStatus: data.deliveryStatus,
  billingStatus: data.billingStatus,
  memo: data.memo,
})

export default getInitialValue
