import { firstBy } from 'thenby'
import dsCreatePoItem from '../../functions/APIOperations/dsCreatePoItem'

const handleSubmit = async (values) => {
  const poNumber = values.poNumber && values.poNumber.trim()
  const customer = values.customer

  values.items.sort(firstBy('cpn').thenBy('reqSched'))

  const latestVersion = 0
  return await Promise.all(
    values.items.map(async (x, i) => {
      await dsCreatePoItem(
        {
          ...x,
          date: values.date,
          type: 'cpo',
          customer,
          poNumber,
          openQty: x.qty,
          deliveryTerm: values.deliveryTerm,
        },
        latestVersion,
      )
    }),
  )
}

export default handleSubmit
