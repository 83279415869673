const mapping = {
  enhanced: [
    'actions',
    'memo',
    'paymentStatus',
    'invoiceType',
    // 'fulfillmentNumber',
    'customer',
    'date',
    'invoiceNumber',
    'poNumber',
    'cpn',
    'qty',
    'price',
    'priceCurrency',
    'amountExclVat',
    'amountInclVat',
    'customerPaymentDue',
  ],
  simple: [
    'date',
    'invoiceNumber',
    'poNumber',
    'cpn',
    'qty',
    'price',
    'amountExclVat',
    'customerPaymentDue',
  ],
  collasped: [
    'date',
    'invoiceNumber',
    'poNumber',
    'cpn',
    'price',
    'amountExclVat',

    'customer',
    'qty',
    'mpn',
  ],
}

export default mapping
