import handleDSOperation from '../handleDSOperation'

const dsCreateProduct = async ({ id, ...item }, latestVersion) => {
  const inputForProductCreation = {
    type: 'product',
    productType: item.productType && item.productType.trim().toUpperCase(),
    hpn: item.hpn && item.hpn.trim().toUpperCase(),
    cpn: item.cpn && item.cpn.trim().toUpperCase(),
    mpn: item.mpn && item.mpn.trim().toUpperCase(),
    coo: item.coo || [],
    unit: item.unit,
    mfr: item.mfr && item.mfr.trim().toUpperCase(),
    vendors: item.vendors || [],
    supplierMOQ: item.supplierMOQ * 1,
    supplierMPQ: item.supplierMPQ * 1,
    leadtime: item.leadtime * 1,
    transitDays: item.transitDays * 1,
    cost: item.cost * 1,
    costCurrency: item.costCurrency,
    price: item.price * 1,
    priceCurrency: item.priceCurrency,
    customerMOQ: item.customerMOQ * 1,
    customerMPQ: item.customerMPQ * 1,
    customerLeadtime: item.customerLeadtime * 1,
    initialInventory: item.initialInventory * 1 || 0,
    safetyInventory: item.safetyInventory * 1 || 0,
    currentInventory: item.currentInventory * 1 || 0,
    spec: item.spec,
    isActive: item.isActive,
    memo: item.memo && item.memo.trim(),
  }

  const createLatestVersionObj = {
    ...inputForProductCreation,
    version: latestVersion + 1,
  }

  await handleDSOperation('product', 'create', createLatestVersionObj)

  const updateOrCreateVersionZeroObj = {
    ...inputForProductCreation,
    version: 0,
    latestVersion: latestVersion + 1,
  }

  if (id) {
    await handleDSOperation(null, 'update', {
      id,
      ...updateOrCreateVersionZeroObj,
    })
  } else {
    await handleDSOperation(item.type, 'create', updateOrCreateVersionZeroObj)
  }
}

export default dsCreateProduct
