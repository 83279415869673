import React from 'react'
// import { FormField } from '../../components/Form/FormField'

// import { Form } from 'formik'

const EditComponent = (props) => {
  const {
    values,
    submitForm,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    setFieldValue,
    setValues,
    isSubmitting,
    classes,
  } = props

  return <p>edit component</p>
}

export default EditComponent
