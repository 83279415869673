import React, { useState, useEffect, useContext, useMemo } from 'react'
import { Auth } from 'aws-amplify'
import {
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  Button,
  Typography,
  Box,
  Menu,
  MenuItem,
  Popover,
} from '@mui/material'
import { BrowserRouter as Route, useHistory } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import AuthContext from '../context/AuthContext'
import routeMapping from '../routeMapping'

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: 'white',
  },
})

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: '#fff',
    },
    // '&.Mui-focusVisible': {
    //   backgroundColor: 'rgba(100, 95, 228, 0.32)',
    // },
  }),
)

export default function NavTab() {
  const { groups, email } = useContext(AuthContext)

  const routes = routeMapping.filter(
    (x) =>
      x.firstLevel === true &&
      x.restriction.some((r) =>
        groups.map((z) => z.toLowerCase()).includes(r.toLowerCase()),
      ),
  )

  const history = useHistory()
  const [value, setValue] = useState(0)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      setValueFunc(location.pathname)
    })

    const setValueFunc = (path) => {
      const result = routes.find(
        (x) => x.path.split('/')[1] === path.split('/')[1],
      )

      if (result) {
        setValue(result.value)
      } else {
        const type = path.split('/')[1]
        switch (type) {
          case 'vendor':
          case 'customer':
          case 'product':
          case 'contact':
          case 'outgoingQuote':
            setValue(6)
            break
          case 'incomingInvoice':
          case 'outgoingInvoice':
            setValue(7)
            break
          default:
            setValue(0)
        }
      }
    }

    setValueFunc(window.location.pathname)

    return () => {
      unlisten()
    }
  }, [history, routes])

  return (
    <AppBar position='static'>
      <Box sx={{ bgcolor: '#2e1534' }}>
        <Toolbar>
          <StyledTabs
            value={value}
            onChange={(e, newValue) => {
              setValue(newValue)
            }}
            aria-label='styled tabs example'>
            {routes.map((x) => {
              // console.log('pathsss', x.path)
              return (
                <StyledTab
                  key={x.label}
                  label={
                    <>
                      <p
                        onMouseEnter={(e) => {
                          // setAnchorEl(e.currentTarget)
                        }}>
                        {x.label}
                      </p>
                      <Popover
                        sx={{
                          pointerEvents: 'none',
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        // onClose={() => setAnchorEl(null)}
                        disableRestoreFocus>
                        <div
                          onMouseLeave={() => {
                            console.log('leave div')
                            setAnchorEl(null)
                          }}>
                          <Menu
                            id='basic-menu'
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}>
                            <MenuItem onClick={handleClose}>Profile</MenuItem>
                            <MenuItem onClick={handleClose}>
                              My account
                            </MenuItem>
                            <MenuItem onClick={handleClose}>Logout</MenuItem>
                          </Menu>
                        </div>
                      </Popover>
                    </>
                  }
                  menu={x.menu}
                  onClick={(e) => {
                    e.preventDefault()
                    history.push(x.path)
                  }}
                />
              )
            })}
          </StyledTabs>

          <div style={{ marginLeft: 'auto' }}>
            {email && <Typography variant='body1'>{email}</Typography>}

            <Button
              color='inherit'
              onClick={async () => {
                try {
                  // console.log('sign out')
                  await Auth.signOut()
                } catch (error) {
                  console.log('error signing out: ', error)
                }
              }}>
              Log out
            </Button>
          </div>
        </Toolbar>
      </Box>
    </AppBar>
  )
}
