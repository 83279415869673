import React, { useState } from 'react'
import { Hub } from 'aws-amplify'
import { Button, Stack } from '@mui/material'
import MTSumField from '../../components/Table/toolbar/MTSumField'
import handleToolbarResendPO from '../../functions/tableFunctions/toolbar/handleToolbarResendPO'

const Toolbar = ({ selectedRows, currentUser }) => {
  const [sendPOPreviewloading, setSendPOPreviewLoading] = useState(false)
  const [sendPOloading, setSendPOLoading] = useState(false)

  const { groups } = currentUser

  return selectedRows && selectedRows.length > 0 ? (
    <Stack direction='row' spacing={3}>
      <Button
        disabled={sendPOPreviewloading}
        variant='contained'
        color='primary'
        size='small'
        onClick={async () => {
          setSendPOPreviewLoading(true)
          const preview = true
          await handleToolbarResendPO(selectedRows, preview)
        }}>
        resend PO preview
      </Button>
      <Button
        disabled={sendPOloading || !groups.includes('admin')}
        variant='contained'
        color='primary'
        size='small'
        onClick={async () => {
          setSendPOLoading(true)
          const preview = false
          await handleToolbarResendPO(selectedRows, preview)
        }}>
        resend PO
      </Button>
      <MTSumField qtyAttr='qty' selectedRows={selectedRows} />
      <MTSumField qtyAttr='openQty' selectedRows={selectedRows} />
      <MTSumField qtyAttr='amount' selectedRows={selectedRows} />
    </Stack>
  ) : (
    <Stack direction='row' spacing={3}>
      <Button
        variant='contained'
        color='secondary'
        size='small'
        onClick={() => {
          Hub.dispatch('FormMonitor', {
            event: 'formEdit',
            data: {
              dataType: 'spo',
              formType: 'create',
              allowAddItem: true,
              showDeleteItem: true,
            },
            message: '',
          })
        }}>
        新建订单
      </Button>

      <Button
        variant='contained'
        color='secondary'
        size='small'
        onClick={() => {
          Hub.dispatch('FormMonitor', {
            event: 'formEdit',
            data: {
              dataType: 'spoSched',
              formType: 'create',
              allowAddItem: true,
              showDeleteItem: true,
            },
            message: '',
          })
        }}>
        更新交期
      </Button>
    </Stack>
  )
}

export default Toolbar
