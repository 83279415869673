import handleDSOperation from '../../functions/handleDSOperation'

const onRowUpdate = async (newData) => {
  const input = {
    id: newData.id,
    entity: newData.entity && newData.entity.trim().toUpperCase(),
    number: newData.number && newData.number.trim().toUpperCase(),
    entityType: newData.entityType,
    paymentTerm: newData.paymentTerm,
    deliveryTerms: newData.deliveryTerms,
    memo: newData.memo,
    hankenoEntity: newData.hankenoEntity,
    billingInfo: newData.billingInfo,
    vendorOrCtmCode: newData.vendorOrCtmCode,
    usePaymentAgent: newData.usePaymentAgent,
    isActive: newData.isActive || false,
  }
  await handleDSOperation(null, 'update', input)
}

export default onRowUpdate
