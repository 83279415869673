import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import SimpleTable from '../SimpleTable'
import MTSumField from '../toolbar/MTSumField'
import handleDSOperation from '../../../functions/handleDSOperation'

const Record = ({
  invoiceType,
  invoiceNumber,
  cpn,
  poNumber,
  customer,
}) => {
  const [rows, setRows] = useState([])
  useEffect(() => {
    ;(async () => {
      let cpnQuery = {}
      if (cpn) {
        cpnQuery = { cpn: { predicate: 'eq', value: cpn } }
      }
      let poNumberQuery = {}
      if (poNumber) {
        poNumberQuery = { poNumber: { predicate: 'eq', value: poNumber } }
      }

      let customerQuery = {}
      if (customer) {
        customerQuery = { customer: { predicate: 'eq', value: customer } }
      }

      let invoiceNumberQuery = {}
      if (invoiceNumber) {
        invoiceNumberQuery = {
          invoiceNumber: { predicate: 'eq', value: invoiceNumber },
        }
      }
      let items = await handleDSOperation(invoiceType, 'query', {
        ...invoiceNumberQuery,
        ...cpnQuery,
        ...customerQuery,
        ...poNumberQuery,
      })
      setRows(items)
    })()
  }, [invoiceType, invoiceNumber, customer, cpn, poNumber])

  return (
    <Box m={3}>
      <MTSumField qtyAttr='qty'selectedRows={rows} />
      <SimpleTable
        dataType={invoiceType}
        tbType='collasped'
        rows={rows}
      />
    </Box>
  )
}

export default Record
