import React, { useEffect } from 'react'
import {
  FormField,
  AutoCompleteField,
  SwitchField,
  SelectField,
} from '../../components/Form/FormField'

import { Form } from 'formik'
import handleDSOperation from '../../functions/handleDSOperation'
import config from '../../parameters'
import usePrevious from '../../hooks/usePrevious'

const SharedComponent = (props) => {
  const {
    values,
    submitForm,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    setFieldValue,
    setValues,
    isSubmitting,
    formType,
    formStyle,
    userGroups: groups,
  } = props

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  }

  const prevEntity = usePrevious(values.entity)

  // console.log('values', values)

  useEffect(() => {
    const func = async () => {
      if (values.entityOptions.length === 0 && formType === 'create') {
        const getCustomers = await handleDSOperation('customer', 'query')
        const getVendors = await handleDSOperation('vendor', 'query')

        const customers = getCustomers.map((x) => x.entity)
        const vendors = getVendors.map((x) => x.entity)

        setFieldValue('entityOptions', [...customers, ...vendors])
      }

      if (
        (values.entity && values.entity !== prevEntity) ||
        (values.deliveryTerms.length === 0 && values.role.includes('receiving'))
      ) {
        const getCustomer = await handleDSOperation('customer', 'query', {
          entity: { predicate: 'eq', value: values.entity },
        })

        if (getCustomer.length > 0) {
          const deliveryTerms = getCustomer[0].deliveryTerms
          if (deliveryTerms) {
            setFieldValue('deliveryTerms', deliveryTerms)
          }
        }
      }
    }
    func()
  }, [values, prevEntity, setFieldValue, formType])

  // console.log('values', values)

  return (
    <>
      <Form style={formStyle} autoComplete='off'>
        <div>
          <SwitchField
            checked={values.isMainContact}
            fieldName='isMainContact'
            handleChange={handleChange}
            labelPlacement='start'
          />
        </div>
        <div>
          <AutoCompleteField
            value={values.entity ? { entity: values.entity } : null}
            attr='entity'
            fieldName='entity'
            options={values.entityOptions.map((x) => ({ entity: x }))}
            setFieldValue={setFieldValue}
          />
          <FormField
            fieldName='name'
            value={values.name}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <FormField
            fieldName='title'
            value={values.title}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />

          <SelectField
            fieldName='role'
            value={values.role}
            options={config.role}
            handleChange={handleChange}
          />
        </div>
        <div>
          <FormField
            fieldName='dept'
            value={values.dept}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
        </div>
        <div>
          <FormField
            fieldName='email'
            value={values.email}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <FormField
            fieldName='phoneNumber'
            value={values.phoneNumber}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
        </div>
        <div>
          <FormField
            fieldName='address'
            value={values.address}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />

          {values.role.includes('receiving') &&
            values.deliveryTerms &&
            values.deliveryTerms.length > 0 && (
              <FormField
                select
                fieldName='deliveryTerm'
                value={values.deliveryTerm}
                handleBlur={handleBlur}
                handleChange={handleChange}
                options={values.deliveryTerms}
              />
            )}
        </div>
        <div>
          <FormField
            multiline
            fieldName='memo'
            value={values.memo}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
          />
        </div>
      </Form>
    </>
  )
}

export default SharedComponent
