import handleDSOperation from '../handleDSOperation'
import mergeArrayAndCombineQty from '../helpFunc/mergeArrayAndCombineQty'

const dsCreateReceivings = async (items) => {
  const mergedArr = mergeArrayAndCombineQty(items, ['hpn', 'poNumber'], ['qty'])
  // console.log('mergedArr', mergedArr)
  await Promise.all(
    mergedArr.map(async (x) => {
      // console.log('xxxxx', x)
      // debugger
      await handleDSOperation('receiving', 'create', x)
    }),
  )
}

export default dsCreateReceivings
