const getInitialValue = (data) => ({
  id: data.id,
  customer: data.customer,
  type: data.type,
  date: data.date,
  fulfillmentNumber: data.fulfillmentNumber,
  invoiceNumber: data.invoiceNumber,
  poNumber: data.poNumber,
  deliveryTerm: data.deliveryTerm,
  cpn: data.cpn,
  hpn: data.hpn,
  mpn: data.mpn,
  qty: data.qty * 1,
  billingStatus: data.billingStatus,
  paymentStatus: data.paymentStatus,
  memo: data.memo,
  coo: data.coo,
  coos: (data.coo && [...data.coo]) || [],
  trackingNumber: data.trackingNumber,
  carrier: data.carrier,
  cartonNumber: data.cartonNumber,
})

export default getInitialValue
