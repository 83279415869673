const failedRowForRender = (row) => {
  switch (row.type) {
    case 'cpo':
      return {
        isNew: row.isNew,
        poNumber: row.poNumber,
        cpn: row.cpn,
        qty: row.qty,
        openQty: row.openQty,
        alertMessage: row.alertMessage,
      }

    case 'spo':
      return {
        isNew: row.isNew,
        poNumber: row.poNumber,
        pid: row.pid,
        mpn: row.mpn,
        cpn: row.cpn,
        qty: row.qty,
        openQty: row.openQty,
        alertMessage: row.alertMessage,
      }

    case 'receiving':
      return {
        date: row.date,
        poNumber: row.poNumber,
        pid: row.pid,
        cpn: row.cpn,
        qty: row.qty,
        alertMessage: row.alertMessage,
      }
    case 'shipping':
      return {
        fulfillmentNumber: row.fulfillmentNumber,
        customer: row.customer,
        date: row.date,
        poNumber: row.poNumber,
        cpn: row.cpn,
        qty: row.qty,
        alertMessage: row.alertMessage,
      }
    default:
      return row
  }
}

const filterOutPropertyForInvalidRows = (rows) =>
  rows.map((row) => {
    return row.alertMessage && row.alertMessage.map((x) => x.level).includes(5)
      ? failedRowForRender(row)
      : row
  })

export default filterOutPropertyForInvalidRows
