import groupArrayofObjectsByKey from '../helpFunc/groupArrayofObjectsByKey'

const isPoFieldNull = (originalRows) => {
  if (!originalRows || originalRows.length === 0) return

  const rows = JSON.parse(JSON.stringify(originalRows))

  const type = rows[0].type

  const groupedPoItemsByNumber = Object.entries(
    groupArrayofObjectsByKey(rows, 'poNumber'),
  )
  groupedPoItemsByNumber.forEach(([poNumber, itemsGroupedByPO]) => {
    if (poNumber === 'undefined') {
      if (type === 'spo') {
        itemsGroupedByPO.forEach((x) => {
          x.isNew = 'new'
        })
      } else if (type === 'cpo') {
        itemsGroupedByPO.forEach((x) => {
          x.alertMessage &&
            x.alertMessage.push({
              message: `PO Number can't be null`,
              index: 'poNumber',
              style: 'alertLevel5',
              level: 5,
            })
        })
      }
    }
  })
  return rows
  // return [].concat.apply([], arrOfarr)
}
export default isPoFieldNull
