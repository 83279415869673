import React from 'react'
import { Hub } from 'aws-amplify'
import { Button, Typography, Stack } from '@mui/material'

const Toolbar = ({ classes, selectedRows, dataType }) => {
  let qtyTotal, exchangeRate, costTotal, forwarderChargeTotal, cnyValue
  
  if (selectedRows) {
    qtyTotal = selectedRows.reduce((a, v) => v.qty * 1 + a, 0)
    exchangeRate = selectedRows[0] && selectedRows[0].exchangeRate
    costTotal = selectedRows.reduce(
      (a, v) => (v.amountExclVat || v.cost * v.qty) + a,
      0,
    )
    forwarderChargeTotal = selectedRows.reduce(
      (a, v) => v.duty + v.handlingFee + v.vat + v.extraCharge + a,
      0,
    )
    cnyValue = costTotal && costTotal * exchangeRate
  }

  return selectedRows && selectedRows.length > 0 ? (
    <Stack direction='row' spacing={3}>
      <Typography color='inherit' variant='subtitle1'>
        数量合计: {qtyTotal}
      </Typography>
      <Typography color='inherit' variant='subtitle1'>
        货款合计: {costTotal.toFixed(2)}
      </Typography>
      <Typography color='inherit' variant='subtitle1'>
        税代合计: {forwarderChargeTotal.toFixed(2)}
      </Typography>
      <Typography color='inherit' variant='subtitle1'>
        货值: {cnyValue.toFixed(2)}
      </Typography>
      <Typography color='inherit' variant='subtitle1'>
        合同金额: {(cnyValue + forwarderChargeTotal).toFixed(2)}
      </Typography>
    </Stack>
  ) : (
    <Stack direction='row' spacing={3}>
      <Button
        variant='contained'
        color='secondary'
        size='small'
        onClick={() => {
          Hub.dispatch('FormMonitor', {
            event: 'formEdit',
            data: {
              dataType,
              formType: 'create',
              allowAddItem: false,
              showDeleteItem: false,
            },
            message: '',
          })
        }}>
        新建委托单
      </Button>
    </Stack>
  )
}

export default Toolbar
