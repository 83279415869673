const mapping = {
  enhanced: [
    'actions',
    'memo',
    'date',
    'customer',
    'invoiceNumber',
    // 'paymentType',
    'invoiceType',
    'priceCurrency',
    'amountExclVat',
    'amountInclVat',
  ],
  simple: [
    'date',
    'invoiceNumber',
    'poNumber',
    'cpn',
    'qty',
    'price',
    'amountExclVat',
    'customerPaymentDue',
  ],
  collasped: [
    'date',
    'customer',
    'invoiceNumber',
    'amountExclVat',
    'amountInclVat',
  ],
}

export default mapping
