import React from 'react'

import { Form } from 'formik'
import config from '../../parameters'
import { FormField } from '../../components/Form/FormField'

const EditComponent = (props) => {
  const {
    values,
    submitForm,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    setFieldValue,
    setValues,
    isSubmitting,
    formStyle,
    userGroups: groups,
  } = props

  const isAdmin = groups.map((x) => x.toLowerCase()).includes('admin')

  return (
    <Form style={formStyle} autoComplete='off'>
      <div>
        <FormField disabled fieldName='customer' value={values.customer} />
        <FormField
          fieldName='date'
          value={values.date}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          fieldName='invoiceNumber'
          value={values.invoiceNumber}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          select
          fieldName='invoiceType'
          value={values.invoiceType}
          handleBlur={handleBlur}
          handleChange={handleChange}
          options={config.invoiceTypes}
        />
      </div>
      <div>
        <FormField
          fieldName='poNumber'
          value={values.poNumber}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          disabled
          fieldName='cpn'
          value={values.cpn}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          disabled={!groups.includes('admin')}
          fieldName='qty'
          value={values.qty}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />

        <FormField
          select
          disabled={!groups.includes('admin')}
          fieldName='unit'
          value={values.unit}
          handleBlur={handleBlur}
          handleChange={handleChange}
          options={config.units}
        />
        <FormField
          select
          disabled={!groups.includes('admin')}
          fieldName='priceCurrency'
          value={values.priceCurrency}
          handleBlur={handleBlur}
          handleChange={handleChange}
          options={config.currencyOptions}
        />
        <FormField
          disabled={!groups.includes('admin')}
          fieldName='price'
          value={values.price}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
      </div>
      <div>
        <FormField
          disabled={!groups.includes('admin')}
          fieldName='customerPaymentDue'
          value={values.customerPaymentDue}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />

        <FormField
          disabled={!groups.includes('admin')}
          fieldName='customerPaymentPaid'
          value={values.customerPaymentPaid}
          handleBlur={handleBlur}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
        />
        <FormField
          select
          disabled={!groups.includes('admin')}
          fieldName='paymentStatus'
          value={values.paymentStatus}
          handleBlur={handleBlur}
          handleChange={handleChange}
          options={config.paymentStatus}
        />
      </div>
      <div>
        <FormField
          multiline
          fieldName='memo'
          value={values.memo}
          handleBlur={handleBlur}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
        />
      </div>
    </Form>
  )
}

export default EditComponent
