import papaparse from 'papaparse'
import arrToObjForCustomerInput from './arrToObjForCustomerInput'
import arrToObjForVendorInput from './arrToObjForVendorInput'
import arrToObjForContactInput from './arrToObjForContactInput'
import arrToObjForProductInput from './arrToObjForProductInput'
import arrToObjForBSLCpoInput from './arrToObjForBSLCpoInput'
import arrToObjForOralCpoInput from './arrToObjForOralCpoInput'
import arrToObjForGeneralCpoInput from './arrToObjForGeneralCpoInput'
import arrToObjForShippingInput from './arrToObjForShippingInput'
import arrToObjForReceivingInput from './arrToObjForReceivingInput'
import arrToObjForExistingSpoInput from './arrToObjForExistingSpoInput'
import arrToObjForNewSpoInput from './arrToObjForNewSpoInput'
import arrToObjForIncomingInvoiceInput from './arrToObjForIncomingInvoiceInput'
import arrToObjForOutgoingVatInvoiceInput from './arrToObjForOutgoingVatInvoiceInput'

import filterOutEmptyRows from '../rowValidator/filterOutEmptyRows'

const getTypeAndTableData = async (textArea) => {
  let dataType, operationType, rows

  let { data, errors } = papaparse.parse(textArea, {
    skipEmptyLines: true,
  })
  if (errors.length > 0) console.log('papaparse error', errors)

  // data = JSON.parse(JSON.stringify(data))
  data = filterOutEmptyRows(data)

  // console.log('data', data)
  // debugger

  if (data.length > 0) {
    if (
      data[0][0] &&
      data[0][1] &&
      data[0][1].startsWith('HT') &&
      data[0][8] === 'vendor'
    ) {
      rows = arrToObjForVendorInput(data)
      dataType = 'vendor'
    } else if (
      data[0][0] &&
      data[0][1] &&
      data[0][1].startsWith('EA') &&
      data[0][8] === 'customer'
    ) {
      rows = arrToObjForCustomerInput(data)
      dataType = 'customer'
      // } else if (data[0][0] && data[0][0].startsWith('SA')) {
    } else if (data[0][0] === 'contact') {
      rows = arrToObjForContactInput(data)
      dataType = 'contact'
      // } else if (data[0][0] && data[0][0].startsWith('SA')) {
    } else if (data[0][0] === 'product') {
      rows = arrToObjForProductInput(data)
      dataType = 'product'
    } else if (data[0][2] && data[0][2] === '8493' && data[0][3] === '') {
      rows = await arrToObjForBSLCpoInput(data)
      dataType = 'cpo'
    } else if (data[0][2] && data[0][3] === '8493') {
      rows = await arrToObjForOralCpoInput(data)
      dataType = 'cpo'
    } else if (data[0][0] === '8493') {
      rows = await arrToObjForGeneralCpoInput(data)
      dataType = 'cpo'
    } else if (data[0].length === 6) {
      rows = await arrToObjForExistingSpoInput(data)
      dataType = 'spo'
      operationType = 'updateSched'
    } else if (data[0].length === 7) {
      rows = await arrToObjForNewSpoInput(data)
      dataType = 'spo'
      operationType = 'createdNewSpo'
    } else if (data[0].length === 11) {
      rows = await arrToObjForShippingInput(data)
      dataType = 'shipping'
    } else if (data[0].length === 5) {
      rows = await arrToObjForReceivingInput(data)
      dataType = 'receiving'
    } else if (data[0].length === 8) {
      rows = await arrToObjForIncomingInvoiceInput(data)
      dataType = 'incomingInvoice'
    } else if (data[0].length === 10) {
      rows = await arrToObjForOutgoingVatInvoiceInput(data)
      dataType = 'outgoingInvoice'
    }
    // else if (data[0][0] === 'vat') {
    //   rows = await arrToObjForOutgoingVatInvoiceInput(data)
    //   dataType = 'outgoingInvoice'
    // }
  }
  // console.log('row333333', rows)

  // console.log('row info', rows)
  // debugger

  return [dataType, operationType, rows]
}

export default getTypeAndTableData
