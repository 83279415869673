import React, { useEffect } from 'react'

import { FormField } from '../../components/Form/FormField'

import { Form } from 'formik'

const EditComponent = (props) => {
  const {
    values,
    initialValues,
    submitForm,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    setFieldValue,
    setValues,
    isSubmitting,
    formStyle,
    userGroups: groups,
  } = props

  console.log('edit cpo values', values)

  return (
    <>
      <Form style={formStyle} autoComplete='off'>
        <div>
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='customer'
            value={values.customer}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />

          <FormField
            disabled={!groups.includes('admin')}
            fieldName='poNumber'
            value={values.poNumber}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />

          <FormField
            disabled={!groups.includes('admin')}
            fieldName='date'
            value={values.date}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
        </div>
        <div>
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='cpn'
            value={values.cpn}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='mfr'
            value={values.mfr}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='mpn'
            value={values.mpn}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='qty'
            value={values.qty}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='openQty'
            value={values.openQty}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='price'
            value={values.price}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <FormField
            disabled={!groups.includes('admin')}
            select
            fieldName='priceCurrency'
            value={values.priceCurrency}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
        </div>
        <div>
          <FormField
            fieldName='reqSched'
            value={values.reqSched}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
        </div>
        <div>
          <FormField
            fieldName='memo'
            multiline
            value={values.memo}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
          />
        </div>
      </Form>
    </>
  )
}

export default EditComponent
