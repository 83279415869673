import React, { useEffect } from 'react'
import { Checkbox, ListItemText, Typography, Stack } from '@mui/material'
import { Form } from 'formik'
import { DatePickerField } from '../../components/Form/FormField'
import handleDSOperation from '../../functions/handleDSOperation'
import config from '../../parameters'

const MainComponent = (props) => {
  const {
    values,
    submitForm,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    setFieldValue,
    initialValues,
    setValues,
    isSubmitting,
    formStyle,
  } = props

  useEffect(() => {
    const func = async () => {
      if (!values.allInvoices) {
        const getVATInvoices = await handleDSOperation(
          'incomingInvoice',
          'query',
          {
            invoiceType: { predicate: 'eq', value: 'VAT' },
            deductStatus: { predicate: 'eq', value: null },
          },
        )
        setFieldValue(
          'allInvoices',
          getVATInvoices.map((x) => ({
            invoiceNumber: x.invoiceNumber,
            vendor: x.vendor,
            date: x.date,
            costCurrency: x.costCurrency,
            amountExclVat: x.amountExclVat,
          })),
        )
      }

      // const currentPoNumber = values.poNumber
    }
    func()
  }, [setFieldValue, setValues, initialValues, values])

  const totalExclVat =
    values.items
      .reduce(
        (a, v) =>
          a +
          v.selectedInvoices.reduce(
            (subA, subV) => subA + subV.amountExclVat,
            0,
          ),
        0,
      )
      .toFixed(2) * 1

  return (
    <>
      <Form style={formStyle} autoComplete='off'>
        <DatePickerField
          required
          fieldName='deductStatus'
          minDate={new Date(new Date().getTime() - 24 * 60 * 60 * 1000 * 30)
            .toISOString()
            .substring(0, 10)}
          maxDate={new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 60)
            .toISOString()
            .substring(0, 10)}
          value={values.deductStatus}
          setFieldValue={setFieldValue}
        />

        {values.items && totalExclVat !== 0 && (
          // <Stack component='span' direction='row' spacing={3}>
          <>
            <Typography component='span' variant='h6' color='secondary'>
              未税金额合计: {totalExclVat}元
            </Typography>{' '}
            <Typography component='span' variant='h6' color='secondary'>
              含税金额合计:{' '}
              {(totalExclVat * (1 + config.vatRate)).toFixed(2) * 1}元
            </Typography>{' '}
            {/* <Typography component='span' variant='h6' color='secondary'>
              含税金额合计:{' '}
              {(
                currentItem.selectedInvoices.reduce(
                  (a, v) => a + v.amountExclVat,
                  0,
                ) *
                (1 + config.vatRate)
              ).toFixed(2) * 1}
              元
            </Typography> */}
          </>
        )}
      </Form>

    </>
  )
}

export default MainComponent
