import isPoExistingAndGetLatestVersionAndRecord from '../rowValidator/isPoExistingAndGetLatestVersionAndRecord'
import getProductInfoForNewRowItems from '../APIOperations/getProductInfoForNewRowItems'
import getRowValue from './getRowValue'
import filterOutPropertyForInvalidRows from '../rowValidator/filterOutPropertyForInvalidRows'
import isPoFieldNull from '../rowValidator/isPoFieldNull'

const arrToObjForExistingSpoInput = async (rows) => {
  const dataType = 'spo'
  const inputType = 'existingSpo'

  rows = getRowValue(dataType, inputType, rows)

  rows.forEach((row) => {
    row.isNew = 'old'
    row.qty = row.openQty
    row.date = row.date || new Date().toISOString().substring(0, 10)
  })

  rows = isPoFieldNull(rows)

  rows = await isPoExistingAndGetLatestVersionAndRecord(rows)

  return filterOutPropertyForInvalidRows(rows)
}

export default arrToObjForExistingSpoInput
