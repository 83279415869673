import { firstBy } from 'thenby'
import groupArrayofObjectsByKey from '../helpFunc/groupArrayofObjectsByKey'
import getValidRows from '../rowValidator/getValidRows'

const generateSPONumberAndPIDForRowItems = (originalRows) => {
  // formState.items.sort(firstBy('cpn').thenBy('reqSched'))

  // ATTENTION OLD IMPLEMENTATION
  if (!originalRows || originalRows.length === 0) return

  let rows = JSON.parse(JSON.stringify(originalRows))

  const validRows = getValidRows(rows)

  const newPoItems = validRows.filter((x) => x.isNew === 'new')

  // const groupedPOByVendor = Object.entries(
  //   groupArrayofObjectsByKey(newPoItems, 'vendor'),
  // )
  // let generatedPO

  // groupedPOByVendor.forEach(([vendor, itemsGroupedByVendor]) => {
  //   itemsGroupedByVendor.sort(firstBy('cpn').thenBy('reqSched'))

  const poNumber = Date.now().toString().slice(1, 9)
  newPoItems.sort(firstBy('cpn').thenBy('reqSched'))
  newPoItems.forEach((x, i) => {
    x.poNumber = x.poNumber || (poNumber * 1 + i).toString()
  })

  // let poNumber
  // if (!generatedPO) {
  //   poNumber = `F${Date.now().toString().slice(2, 9)}`
  //   generatedPO = poNumber
  // } else {
  //   poNumber = `${generatedPO.slice(0, 1)}${
  //     generatedPO.slice(generatedPO.length - 7) * 1 + 1
  //   }`
  //   generatedPO = poNumber
  // }

  // itemsGroupedByVendor.forEach((x, i) => {
  //   x.poNumber = poNumber * 1 + i
  // x.poNumber = x.poNumber || poNumber
  // x.pid = (x.pid || i + 1).toString()
  // })
  // })
  return rows
}
export default generateSPONumberAndPIDForRowItems
