import React, { useState, useEffect } from 'react'
import { Dialog } from '@mui/material'
import _ from 'lodash'
import { Hub } from 'aws-amplify'
import DraggablePaperComp from '../DraggablePaperComp'

export default function DetailPanelDialog() {
  const [data, setData] = useState({})

  useEffect(() => {
    let ignore = false
    Hub.listen('FormMonitor', ({ payload }) => {
      const { data, event, message } = payload
      if (!ignore && event === 'showDetailPanel') {
        setData({ dataType: data.dataType, row: data.row })
      }
    })

    return () => {
      ignore = true
      Hub.remove('FormMonitor')
    }
  }, [])

  const DetailPanel =
    data.dataType && require(`resources/${data.dataType}/DetailPanel`).default

  return data.dataType ? (
    <Dialog
      fullWidth
      maxWidth='lg'
      PaperComponent={DraggablePaperComp}
      open={!_.isEmpty(data)}
      onClose={() => setData({})}
      aria-labelledby='draggable-dialog-title'>
      <>
        <div
          style={{ cursor: 'move', backgroundColor: 'pink', height: 30 }}
          id='draggable-dialog-title'
        />
        <DetailPanel rowData={data.row || {}} />
      </>
    </Dialog>
  ) : null
}
