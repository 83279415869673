import restAPICalls from '../../restAPICalls'
import handleDSOperation from '../../handleDSOperation'
import groupArrayofObjectsByKey from '../../helpFunc/groupArrayofObjectsByKey'
import config from '../../../parameters'

const handleToolbarSendShippingList = async (itemsSelected, isPreview) => {
  let type
  let presetDeliveryTerm

  const customer = itemsSelected[0].customer

  switch (customer) {
    case '宝洁':
      type = 'bslShipping'
      presetDeliveryTerm = 'DAP SHANGHAI'
      break
    case 'PROCTER & GAMBLE':
      type = 'pgShipping'
      break
    default:
      type = 'bslShipping'
  }

  const fulfillmentNumbers = [
    ...new Set(itemsSelected.map((x) => x.fulfillmentNumber)),
  ]

  const arrOfPoObj = await Promise.all(
    fulfillmentNumbers.map(async (x) => {
      const fulfillmentItems = await handleDSOperation('shipping', 'query', {
        fulfillmentNumber: { predicate: 'eq', value: x },
      })

      const customer = fulfillmentItems[0].customer
      const deliveryTerm =
        fulfillmentItems[0].deliveryTerm || presetDeliveryTerm
      const invoiceNumber = fulfillmentItems[0].invoiceNumber

      const getCustomers = await handleDSOperation('customer', 'query', {
        entity: { predicate: 'eq', value: customer },
      })

      // const getCustomerShippingContact = await handleDSOperation('contact', 'query', {
      //   entity: { predicate: 'eq', value: customer },
      //   deliveryTerm: { predicate: 'eq', value: deliveryTerm },
      // })

      const getCustomerContact = await handleDSOperation('contact', 'query', {
        entity: { predicate: 'eq', value: customer },
      })
      // console.log('deliveryTerm', deliveryTerm)

      // console.log('getCustomerContact', getCustomerContact)
      // debugger

      const getCustomerShippingContact = getCustomerContact.find(
        (x) => x.deliveryTerm === deliveryTerm,
      )

      // console.log('deliveryTerm', deliveryTerm)
      // console.log('getCustomerContact', getCustomerContact)

      // console.log('getCustomerShippingContact', getCustomerShippingContact)
      // debugger

      const getCustomerBillingContact = getCustomerContact.find((x) =>
        x.role.includes('billing'),
      )

      // console.log('getCustomerBillingContact', getCustomerBillingContact)
      // // console.log('isPreview', isPreview)
      // console.log('getCustomers', getCustomers[0])

      // console.log('getShippingContact', getCustomerContact)

      if (!getCustomerShippingContact)
        return alert("please set 'receiving role' in contact")

      // debugger

      const fulfillmentNumber = x
      const date = fulfillmentItems[0].date
      const customerNumber = getCustomers[0].number
      const hankenoEntity = getCustomers[0].hankenoEntity
      const vendorCode = getCustomers[0].vendorOrCtmCode
      const paymentTerm = getCustomers[0].paymentTerm
      const shippingAddress = getCustomerShippingContact.address
      const shippingDept = getCustomerShippingContact.dept
      const shippingName = getCustomerShippingContact.name
      const shippingPhoneNumber = getCustomerShippingContact.phoneNumber
      const billingAddress =
        getCustomerBillingContact && getCustomerBillingContact.address
      const billingDept =
        getCustomerBillingContact && getCustomerBillingContact.dept
      const billingName =
        getCustomerBillingContact && getCustomerBillingContact.name
      const billingPhoneNumber =
        getCustomerBillingContact && getCustomerBillingContact.phoneNumber
      const trackingNumber = fulfillmentItems[0].trackingNumber
      const carrier = fulfillmentItems[0].carrier
      const hankenoEntityDetail = config.hankenoEntities.find(
        (x) => x.tag === hankenoEntity,
      )
      const hankenoEntityName = hankenoEntityDetail.name
      const hankenoEntityAdd = hankenoEntityDetail.add
      const hankenoEntityPhoneNumber = hankenoEntityDetail.phoneNumber

      const arrOfItemsByhpn = Object.entries(
        groupArrayofObjectsByKey(fulfillmentItems, 'hpn'),
      )
      await Promise.all(
        arrOfItemsByhpn.map(async ([hpn, items]) => {
          const prod = await handleDSOperation('product', 'query', {
            hpn: { predicate: 'eq', value: hpn },
            version: { predicate: 'eq', value: 0 },
          })
          items.forEach((y) => {
            y.mfr = prod[0].mfr
            y.unit = prod[0].unit
          })
        }),
      )

      return {
        date,
        fulfillmentNumber,
        invoiceNumber,
        hankenoEntityName,
        hankenoEntityAdd,
        hankenoEntityPhoneNumber,
        vendorCode,
        customer,
        customerNumber,
        hankenoEntity,
        paymentTerm,
        deliveryTerm,
        shippingAddress,
        shippingDept,
        shippingPhoneNumber,
        shippingName,
        billingAddress,
        billingDept,
        billingPhoneNumber,
        billingName,
        currency: fulfillmentItems[0].priceCurrency,
        trackingNumber,
        carrier,
        fulfillmentItems: fulfillmentItems.map((x) => ({
          cartonNw: x.cartonNw,
          cartonGw: x.cartonGw,
          cartonDimensions: x.cartonDimensions,
          cartonNumber: x.cartonNumber,
          poNumber: x.poNumber,
          qty: x.qty,
          coo: x.coo && x.coo.length > 0 && x.coo[0],
          mfr: x.mfr,
          cpn: x.cpn,
          hpn: x.hpn,
          desc: x.mpn,
          unit: x.unit,
          price: x.price,
        })),
      }
    }),
  )

  // console.log('arrOfPoObj6666666', arrOfPoObj)

  // debugger

  await restAPICalls('post', null, {
    type,
    items: arrOfPoObj,
    isPreview,
  })
}

export default handleToolbarSendShippingList
