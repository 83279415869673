import React from 'react'
import { Hub } from 'aws-amplify'
import { Button } from '@mui/material'

const Toolbar = ({ selectedRows, dataType }) => {
  return selectedRows && selectedRows.length > 0 ? (
    <>
      <Button
        variant='contained'
        color='primary'
        size='small'
        onClick={async () => {}}>
        button
      </Button>
    </>
  ) : (
    <>
      <Button
        variant='contained'
        color='secondary'
        size='small'
        onClick={() =>
          Hub.dispatch('FormMonitor', {
            event: 'formEdit',
            data: {
              dataType,
              formType: 'create',
              allowAddItem: false,
              showDeleteItem: false,
            },
            message: '',
          })
        }>
        新建联系人
      </Button>
    </>
  )
}

export default Toolbar
