const getInitialValue = (data) => ({
  id: data.id,
  type: data.type,
  vendor: data.vendor,
  receivingCode: data.receivingCode,
  invoiceNumber: data.invoiceNumber,
  vendorPaymentDue: data.vendorPaymentDue,
  vendorPaymentPaid: data.vendorPaymentPaid,
  poNumber: data.poNumber,
  cpn: data.cpn,
  hpn: data.hpn,
  qty: data.qty * 1,
  date: data.date,
  memo: data.memo,
  billingStatus: data.billingStatus,
})

export default getInitialValue
