/* eslint-disable eqeqeq */
import {
  TextField,
  MenuItem,
  FormControl,
  Checkbox,
  ListItemText,
  FormControlLabel,
  FormLabel,
  Switch,
  Radio,
  RadioGroup,
  IconButton,
  Input,
  Chip,
  Select,
  InputLabel,
  Autocomplete,
} from '@mui/material'
import { Clear } from '@mui/icons-material'
import { DatePicker } from '@mui/lab'

import { Field } from 'formik'
import config from '../../parameters'
import _ from 'lodash'

const trimedField = [
  'poNumber',
  'cpn',
  'receivingCode',
  'invoiceNumber',
  'fulfillmentNumber',
]
const notUppercaseField = [
  'vendor',
  'customer',
  'memo',
  'paymentStatus',
  'billingStatus',
  'deliveryStatus',
  'deductStatus',
  'deliveryTerm',
  'deliveryTerms',
  'email',
  'price',
  'cost',
  'unit',
  'desc',
]

const disabledNumberTypeFieldWhenSelect = ['price', 'cost']

const getValue = ({ fieldName, value }) => {
  let newValue = value
  if (trimedField.includes(fieldName)) newValue = newValue.trim()
  if (!notUppercaseField.includes(fieldName)) newValue = newValue.toUpperCase()
  // console.log('newValue', newValue)
  return newValue
}

const fieldProperty = {
  customer: { width: 300, label: '客户' },
  date: { width: 160, type: 'date', label: '日期' },
  invoiceDate: { width: 160, type: 'date', label: '开票日期' },

  vendorPaymentDue: { width: 160, type: 'date', label: '货款到期' },
  vendorPaymentPaid: { width: 200, type: 'date', label: '付款日期' },
  customerPaymentDue: { width: 160, type: 'date', label: '货款到期' },
  customerPaymentPaid: { width: 200, type: 'date', label: '付款日期' },
  orderDate: { width: 160, type: 'date', label: '下单日期' },
  poNumber: { width: 250, label: '订单号' },
  receivingCode: { width: 250, label: '入仓号' },
  invoiceNumber: { width: 150, label: '发票号' },
  fulfillmentNumber: { width: 150, label: '送货单号' },
  cpn: { width: 120, label: '客户型号' },
  customerNumber: { width: 120, label: '客户编号' },
  paymentTerm: { width: 260, label: '账期' },
  deliveryTerm: { width: 300, label: 'Delivery Term' },
  line: { width: 40, label: 'Line' },
  mfr: { width: 120, label: '原厂' },
  mpn: { width: 240, label: '原厂型号' },
  hpn: { width: 120, label: 'Hankeno型号' },
  productType: { width: 160, label: '产品类型' },
  unit: { width: 80, label: '单位' },
  coo: { multipleWidth: 800, width: 180, label: '原产国' },
  supplierMOQ: { width: 80, label: 'supplierMOQ', type: 'number' },
  supplierMPQ: { width: 80, label: 'supplierMPQ', type: 'number' },
  customerMOQ: { width: 80, label: 'customerMOQ', type: 'number' },
  customerMPQ: { width: 80, label: 'customerMPQ', type: 'number' },
  leadtime: { width: 80, label: '交期(周)', type: 'number' },
  customerLeadtime: { width: 80, label: 'PDT(天)', type: 'number' },
  transitDays: { width: 80, label: '物流(天)', type: 'number' },
  initialInventory: { width: 80, label: '初始库存', type: 'number' },
  safetyInventory: { width: 80, label: '安全库存', type: 'number' },
  currentInventory: { width: 80, label: '当前在库', type: 'number' },
  cartonNumber: { width: 50, label: '箱号', type: 'number' },
  gw: { width: 60, label: '毛重', type: 'number' },
  nw: { width: 60, label: '净重', type: 'number' },
  dimensions: { width: 120, label: '尺寸' },

  qty: { width: 100, label: '数量', type: 'number' },
  openQty: { width: 100, label: '未交订单数量', type: 'number' },
  reqSched: { width: 150, label: '需求交期', type: 'date' },
  estSched: { width: 150, label: '预计交期', type: 'date' },
  salesAmount: { width: 100, label: '金额', type: 'number' },
  exchangeRate: { width: 80, label: '汇率', type: 'number' },
  amount: { width: 100, label: '金额', type: 'number' },
  amountExclVat: { width: 100, label: '未税金额', type: 'number' },
  amountInclVat: { width: 100, label: '含税金额', type: 'number' },
  priceExclVat: { width: 100, label: '未税单价', type: 'number' },
  paymentAgentAmountExclVat: {
    width: 120,
    label: 'EZPAY未税金额',
    type: 'number',
  },
  paymentAgentPriceExclVat: {
    width: 120,
    label: 'EZPAY未税单价',
    type: 'number',
  },
  cnyValue: { width: 100, label: '货款', type: 'number' },
  importDuty: { width: 80, label: '关税率', type: 'number' },
  duty: { width: 80, label: '关税', type: 'number' },
  contractAmount: { width: 100, label: '合同金额', type: 'number' },
  vat: { width: 80, label: '增值税', type: 'number' },
  handlingFee: { width: 80, label: '代理费', type: 'number' },
  extraCharge: { width: 80, label: '附加', type: 'number' },
  paymentStatus: { width: 120, label: '付款状态' },
  deliveryStatus: { width: 120, label: '送货状态' },
  billingStatus: { width: 120, label: '发票状态' },
  deductStatus: { width: 120, label: '抵扣月份' },
  billingInfo: { width: 800, label: 'Billing Info' },
  // price: { width: 80,  label: '未税售价' },
  price: { width: 80, type: 'number', label: '未税售价' },
  cost: { width: 80, type: 'number', label: '未税进价' },
  priceCurrency: {
    width: 80,
    label: '币种',
  },
  costCurrency: {
    width: 80,
    label: '币种',
  },
  entity: { width: 460, label: '公司名称' },
  vendor: { multipleWidth: 500, width: 360, label: '供应商' },
  itemsByVendor: { width: 360, label: '供应商' },

  vendors: { width: 800, label: '供应商' },

  entityType: { width: 200, label: '公司类型' },
  number: { width: 100, label: '编号' },
  vendorOrCtmCode: { width: 200, label: 'vendor/customer code' },

  hankenoEntity: { width: 200, label: 'Hankeno Entity' },

  name: { width: 200, label: '姓名' },
  role: { width: 380, label: 'Role' },
  title: { width: 200, label: 'Title' },
  isMainContact: { width: 200, label: 'Is Main Contact?' },
  dept: { width: 700, label: 'Dept/Branch' },
  phoneNumber: { width: 180, label: 'Phone Number' },
  address: { width: 700, label: 'Address' },

  email: { width: 200, label: 'Email' },
  trackingNumber: { width: 200, label: 'Tracking Number' },
  carrier: { width: 100, label: 'Carrier' },
  isActive: { width: 100, label: 'Active' },
  usePaymentAgent: { width: 100, label: '是否使用EZPAY' },
  deliveryTerms: { width: 380, label: 'Delivery Terms' },
  invoiceType: { width: 180, label: '发票类型' },
  paymentType: { width: 180, label: 'Payment Type' },
  selectedInvoices: {
    width: 800,
    multipleWidth: 1000,
    label: '请输入发票的任意字段',
  },
  forwarderPOsbyReceivingCode: { width: 1000, label: '入仓号' },
  memo: { label: '备注', variant: 'outlined' },
  amountReceived: { label: '收款金额', width: 120, type: 'number' },
  amountPaid: { label: '付款金额', width: 120, type: 'number' },
  month: { label: '月份', width: 80 },
  handlingAndTaxChecked: { label: '', width: 80 },
  handlingAndTax: { label: '税代', width: 100 },
  cnyValueChecked: { label: '', width: 80 },

  poQty: { width: 100, label: '累计订单数量', type: 'number' },
  totalReceivings: { width: 100, label: '累计收货数量', type: 'number' },
  totalBilled: { width: 100, label: '累计收票数量', type: 'number' },
  billable: { width: 100, label: '可收票数量', type: 'number' },
  sessionQty: { width: 100, label: '本次累计数量', type: 'number' },
}

export const FormField = (props) => {
  const {
    fieldName,
    select,
    currentItem,
    value,
    index,
    subIndex,
    disabled,
    handleBlur,
    handleChange,
    validate,
    inputRef,
    setFieldValue,
    CustomMenuItem,
    menuItemDisable,
    multiline,
    options,
    multiple,
  } = props

  const subItemName =
    subIndex !== undefined
      ? `items[${index}][${subIndex}][${fieldName}]`
      : `items[${index}][${fieldName}]`

  const name = index !== undefined ? subItemName : fieldName
  let type =
    (fieldProperty[fieldName] && fieldProperty[fieldName].type) || undefined

  if ((fieldName === 'price' || fieldName === 'cost') && select) {
    type = undefined
  }

  let width =
    (fieldProperty[fieldName] &&
      fieldProperty[fieldName][multiple ? 'multipleWidth' : 'width']) ||
    200

  let InputLabelProps =
    fieldProperty[fieldName] && fieldProperty[fieldName].type === 'date'
      ? { shrink: true }
      : undefined

  let selectChild = null

  let variant =
    (fieldProperty[fieldName] && fieldProperty[fieldName].variant) || 'standard'

  let selectOptions = options

  switch (fieldName) {
    case 'priceCurrency':
    case 'costCurrency':
      selectOptions = config.currencyOptions
      break
    case 'paymentStatus':
      selectOptions = config.paymentStatus
      break
    case 'deliveryStatus':
      selectOptions = config.deliveryStatus
      break
    case 'billingStatus':
      selectOptions = config.billingStatus
      break

    default:
      selectOptions = options || []
  }

  // const DefaultMenuItem = ({ item }) => (
  //   <MenuItem key={item} value={item}>
  //     {item}
  //   </MenuItem>
  // )

  if (select) {
    selectChild = selectOptions.map(
      (x) => (
        <MenuItem
          // key={x}
          // value={x}
          key={x.value || x}
          value={x.value || x}
          disabled={menuItemDisable ? menuItemDisable(x) : false}>
          {x.label || x}
        </MenuItem>
      ),
      // CustomMenuItem ? (
      //   <CustomMenuItem item={x} />
      // ) : (
      //   <DefaultMenuItem key={x.value || x} item={x} />
      // ),
    )
  }

  let InputProps
  let fieldValue = value

  if (fieldName === 'line' && index !== undefined) {
    fieldValue = index + 1
  }

  if (type === 'number') {
    InputProps = {
      inputProps: {
        min: 1,
      },
    }
  } else if (select) {
    InputProps = {
      name,
      id: name,
    }
  } else if (fieldName === 'reqSched') {
    InputProps = {
      inputProps: {
        min: new Date().toISOString().substring(0, 10),
      },
    }
  } else if (fieldName === 'memo') {
    InputProps = {
      // width: 800,
      style: {
        width: 600,
        minHeight: 100,
        maxHeight: 200,
        resize: 'both',
      },

      endAdornment: (
        <IconButton
          onClick={() => {
            if (index !== undefined) {
              if (!currentItem) return alert('currentItem not defined')
              // console.log('currentItem', currentItem)
              // console.log('newvalue', newValue)
              // debugger
              setFieldValue(`items[${index}]`, {
                ...currentItem,
                memo: '',
              })
            } else {
              setFieldValue('memo', '')
            }
          }}>
          <Clear />
        </IconButton>
      ),
    }
  } else if (fieldName === 'vendorPaymentPaid') {
    InputProps = {
      endAdornment: (
        <IconButton
          onClick={() => {
            setFieldValue('vendorPaymentPaid', '')
          }}>
          <Clear />
        </IconButton>
      ),
    }
  } else if (fieldName === 'customerPaymentPaid') {
    InputProps = {
      endAdornment: (
        <IconButton
          onClick={() => {
            setFieldValue('customerPaymentPaid', '')
          }}>
          <Clear />
        </IconButton>
      ),
    }
  }
  // if (fieldName === 'price') {
  //   console.log('select', select)
  //   console.log('options', options)
  //   console.log('selectOptions', selectOptions)
  //   console.log('index', index)
  //   console.log('name', name)
  // }

  // ATTENTION InputProps OR inputProps?????
  // console.log('fieldValue', fieldValue)
  return (
    <Field
      select={select}
      component={TextField}
      disabled={disabled}
      inputRef={inputRef}
      multiline={multiline}
      variant={variant}
      // autoFocus={true}
      InputLabelProps={InputLabelProps}
      id={name}
      name={name}
      style={{
        width,
        margin: 10,
      }}
      type={type}
      InputProps={InputProps}
      // inputProps={InputProps}
      validate={validate}
      value={fieldValue !== undefined && fieldValue !== null ? fieldValue : ''}
      label={
        (fieldProperty[fieldName] && fieldProperty[fieldName].label) ||
        fieldName
      }
      onBlur={handleBlur}
      // onChange={handleChange}
      onChange={(e, value) => {
        // console.log('eeeeeeee', e)
        // console.log('value', value)

        e.target.value = getValue({ fieldName, value: e.target.value })
        // console.log('e.target.value', e.target.value)
        handleChange(e)
      }}>
      {selectChild}
    </Field>
  )
}

export const DatePickerField = ({
  required,
  fieldName,
  value,
  minDate,
  maxDate,
  setFieldValue,
}) => {
  // const currentYear = new Date(value).getFullYear()
  // const maxYear = new Date(maxDate).getFullYear()
  let views = ['year', 'month', 'day']
  // if (maxYear - currentYear > 0) {
  //   views = ['year', 'month']
  // }
  return (
    <DatePicker
      views={views}
      name={fieldName}
      id={fieldName}
      label={
        (fieldProperty[fieldName] && fieldProperty[fieldName].label) ||
        fieldName
      }
      date={(value && new Date(value)) || null}
      value={(value && new Date(value).toISOString()) || null}
      minDate={new Date(minDate)}
      maxDate={new Date(maxDate)}
      renderInput={(params) => {
        // console.log('params', params)
        return (
          <TextField
            {...params}
            style={{ margin: 10 }}
            InputProps={{
              endAdornment: (
                <>
                  {!required && (
                    <IconButton
                      onClick={() => {
                        setFieldValue(fieldName, null)
                      }}>
                      <Clear />
                    </IconButton>
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )
      }}
      onChange={(newValue) => {
        console.log('newvalue00000', newValue)
        if (!newValue || newValue == 'Invalid Date') {
          console.log(333333333)
          return setFieldValue(fieldName, null)
        }

        console.log('new value', newValue)
        console.log('new value', newValue === 'Invalid Date')
        setFieldValue(
          fieldName,
          new Date(newValue).toISOString().substring(0, 10),
        )
      }}
    />
  )
}

export const SwitchField = ({
  fieldName,
  index,
  subIndex,
  disabled,
  handleBlur,
  handleChange,
  checked,
  labelPlacement,
}) => {
  const subItemName =
    subIndex !== undefined
      ? `items[${index}][${subIndex}][${fieldName}]`
      : `items[${index}][${fieldName}]`

  const name = index !== undefined ? subItemName : fieldName

  return (
    <FormControlLabel
      control={
        <>
          <Checkbox
            name={name}
            disabled={disabled}
            checked={checked}
            onChange={handleChange}
          />
          {/* <TextField
            disabled={!x.cnyValueChecked}
            name={`items[${index}].[${i}].cnyValue`}
            style={{ width: 80 }}
            value={x.cnyValue || ''}
            label='货款'
            onBlur={handleBlur}
            onChange={handleChange}
          /> */}
        </>
      }
      label={
        fieldProperty[fieldName] && fieldProperty[fieldName].label !== undefined
          ? fieldProperty[fieldName].label
          : fieldName
      }
      labelPlacement={labelPlacement}
    />
  )
}

export const SelectField = ({
  fieldName,
  index,
  value,
  handleChange,
  options,
}) => {
  const labelId =
    index !== undefined
      ? `items[${index}][${fieldName}]label`
      : `${fieldName}label`
  return (
    <FormControl>
      <InputLabel id={labelId}>
        {(fieldProperty[fieldName] && fieldProperty[fieldName].label) ||
          fieldName}
      </InputLabel>
      <Select
        labelId={labelId}
        style={{
          width:
            (fieldProperty[fieldName] && fieldProperty[fieldName].width) || 200,
        }}
        name={index !== undefined ? `items[${index}][${fieldName}]` : fieldName}
        multiple
        value={value || []}
        onChange={handleChange}
        input={<Input />}
        renderValue={(selected) => (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
            }}>
            {selected.map((value) => (
              <Chip
                key={value}
                label={value}
                style={{
                  margin: 2,
                }}
              />
            ))}
          </div>
        )}
        // MenuProps={MenuProps}
      >
        {options.map((x) => (
          <MenuItem key={x} value={x}>
            <Checkbox checked={value.indexOf(x) > -1} />
            {/* <ListItemText primary={x} secondary={x} /> */}
            <ListItemText primary={x} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export const AutoCompleteField = ({
  id,
  attr,
  value,
  multiple,
  disableCloseOnSelect,
  options,
  index,
  setFieldValue,
  fieldName,
  currentItem,
  getOptionLabel,
  renderOption,
  handleChange,
}) => {
  if (!setFieldValue)
    return alert('function(setFieldValue) is not defined in AutoComplete')

  // console.log('options', options)

  const defaultRenderOption = (props, option, { selected }) => {
    // console.log('option', option)
    // console.log('props', props)
    // console.log('selected', selected)

    // debugger
    return (
      <li {...props}>
        <Checkbox style={{ marginRight: 8 }} checked={selected} />
        <ListItemText
          primary={option[attr]}
          // secondary={`${option.mfr}:${option.mpn}`}
        />
      </li>
    )
  }

  const defaultOptionLabel = (x) => x && x[attr]

  const defaultHandleChange = async (event, newValue) => {
    // console.log('newValue', newValue)
    let value

    if (!multiple) {
      value = (newValue && (attr ? newValue[attr] : newValue)) || null
    } else {
      value = attr ? newValue.map((x) => x[attr]) : newValue
    }

    if (index !== undefined) {
      if (!currentItem) return alert('currentItem not defined')
      // console.log('currentItem', currentItem)
      // console.log('newvalue', newValue)
      // debugger
      setFieldValue(`items[${index}]`, {
        ...currentItem,
        [fieldName]: value,
      })
    } else {
      setFieldValue(fieldName, value)
    }
  }

  return (
    <FormControl>
      <Autocomplete
        key={id}
        value={value}
        // style={{ margin: 10 }}
        multiple={multiple}
        disableCloseOnSelect={disableCloseOnSelect}
        disabled={!options || options.length === 0}
        name={index !== undefined ? `items[${index}][${fieldName}]` : fieldName}
        options={options || []}
        getOptionLabel={getOptionLabel || defaultOptionLabel}
        isOptionEqualToValue={
          (option, value) => {
            // console.log('option', option)
            // console.log('value', value)
            // console.log(_.isEqual(option, value))

            return attr
              ? option && value && option[attr] === value[attr]
              : _.isEqual(option, value)
          }
          //
        }
        renderOption={renderOption || defaultRenderOption}
        onChange={handleChange || defaultHandleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            style={{
              margin: 10,
              width:
                (fieldProperty[fieldName] &&
                  fieldProperty[fieldName][
                    multiple ? 'multipleWidth' : 'width'
                  ]) ||
                500,
            }}
            label={
              (fieldProperty[fieldName] && fieldProperty[fieldName].label) ||
              fieldName
            }
            variant='outlined'
          />
        )}
      />
    </FormControl>
  )
}

export const ProductAutoComplete = ({
  id,
  options,
  index,
  value,
  setFieldValue,
  currentItem,
  handleChange,
}) => {
  const defaultHandleChange = (event, newValue) => {
    // console.log('newvalueddd', newValue)
    // console.log('currentItemddd', currentItem)

    if (index !== undefined) {
      if (!currentItem) return alert('currentitem not defined')

      setFieldValue(`items[${index}]`, {
        ...currentItem,
        // ...newValue,
        hpn: newValue && newValue.hpn,
        cpn: newValue && newValue.cpn,
        mpn: newValue && newValue.mpn,
        mfr: newValue && newValue.mfr,
        productType: newValue && newValue.productType,
        unit: newValue && newValue.unit,
        price: newValue && newValue.price,
        priceCurrency: newValue && newValue.priceCurrency,
        cost: newValue && newValue.cost,
        costCurrency: newValue && newValue.costCurrency,
        supplierMOQ: newValue && newValue.supplierMOQ,
        supplierMPQ: newValue && newValue.supplierMPQ,
        coo: newValue && newValue.coo,
        vendors: newValue && newValue.vendors,
        vendor: newValue && newValue.vendor,
        leadtime: newValue && newValue.leadtime,
        customerMPQ: newValue && newValue.customerMPQ,
        customerMOQ: newValue && newValue.customerMOQ,
        customerLeadtime: newValue && newValue.customerLeadtime,
        qty: newValue && newValue.supplierMOQ,
      })
    } else {
      setFieldValue('product', newValue)
    }
  }

  return (
    <FormControl>
      <Autocomplete
        // style={{ width: 1500, height: 150 }}
        disabled={!options || options.length === 0}
        value={value}
        key={id}
        name={index !== undefined ? `items[${index}].product` : 'product'}
        options={options || []}
        getOptionLabel={(x) =>
          x.cpn !== 'ALL' ? `${x.cpn} -  ${x.mfr}:${x.mpn}` : x.cpn
        }
        isOptionEqualToValue={(option, value) => {
          if (option && value && option.cpn === value.cpn) {
            return true
          }
        }}
        //   option && value && option.name === value.name
        // }
        // disableCloseOnSelect
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox style={{ marginRight: 8 }} checked={selected} />
            <ListItemText
              primary={option.cpn}
              secondary={
                option.cpn !== 'ALL' ? `${option.mfr}:${option.mpn}` : null
              }
            />
          </li>
        )}
        onChange={handleChange || defaultHandleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            style={{
              width: 400,
              margin: 10,
            }}
            label='请输入品牌，客户型号或原厂型号的任意字段'
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        )}
      />
    </FormControl>
  )
}

export const DimensionAutoComplete = ({
  options,
  values,
  currentItem,
  value,
  index,
  setFieldValue,
  disabled,
}) => {
  // console.log('key00000000000', keyIndex)
  return (
    <FormControl>
      <Autocomplete
        freeSolo
        disabled={disabled}
        value={value}
        // style={{ width: 1500, height: 150 }}
        name={`items[${index}].dimensions`}
        options={options}
        // getOptionLabel={(x) => `${x.cpn} -  ${x.mfr}:${x.mpn}`}

        //   option && value && option.name === value.name
        // }
        // disableCloseOnSelect
        // renderOption={(option, { selected }) => (
        //   <>
        //     <Checkbox style={{ marginRight: 8 }} checked={selected} />
        //     <ListItemText
        //       primary={option.cpn}
        //       secondary={`${option.mfr}:${option.mpn}`}
        //     />
        //   </>
        // )}
        onChange={(event, newValue) => {
          setFieldValue('cartons', {
            ...values.cartons,
            [currentItem.cartonNumber]: {
              dimensions: newValue,
            },
          })
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            style={{ width: 140 }}
            label='请选择或输入尺寸'
            onChange={(e) => {
              setFieldValue('cartons', {
                ...values.cartons,
                [currentItem.cartonNumber]: {
                  dimensions: getValue({
                    fieldName: 'dimensions',
                    value: e.target.value,
                  }),
                },
              })
            }}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        )}
      />
    </FormControl>
  )
}

export const RadioField = ({
  fieldName,
  value,
  index,
  handleChange,
  groups,
}) => (
  <FormControl component='fieldset'>
    <FormLabel component='legend'>
      {(fieldProperty[fieldName] && fieldProperty[fieldName].label) ||
        fieldName}
    </FormLabel>
    <div style={{ display: 'inline' }}>
      <RadioGroup
        style={{ display: 'inline-block' }}
        row
        aria-label={
          index !== undefined ? `items[${index}][${fieldName}]` : fieldName
        }
        name={index !== undefined ? `items[${index}][${fieldName}]` : fieldName}
        value={value}
        onChange={handleChange}>
        {groups.map((x) => (
          <FormControlLabel
            key={x.value}
            value={x.value}
            control={<Radio />}
            label={x.label}
          />
        ))}
      </RadioGroup>
    </div>
  </FormControl>
)
