import React, { useState } from 'react'
import SPOsPerVendor from './SPOsPerVendor'
import { Button, Paper, DialogActions } from '@mui/material'
import dsCreatePoItem from '../../../functions/APIOperations/dsCreatePoItem'
import restAPICalls from '../../../functions/restAPICalls'

const SPOsPreview = ({ spos, onClose, setSubmitting, resolve }) => {
  const [itemsByVendor, setItemsByVendor] = useState(spos)
  const [confirmLoading, setConfirmLoading] = useState(false)

  // console.log('itemsByVendor', itemsByVendor)

  if (itemsByVendor.length === 0) return null

  return (
    <Paper>
      {itemsByVendor.map((x, i) => (
        <SPOsPerVendor
          key={i}
          index={i}
          obj={x}
          itemsByVendor={itemsByVendor}
          setItemsByVendor={setItemsByVendor}
        />
      ))}

      <DialogActions>
        <Button
          variant='contained'
          color='primary'
          size='small'
          onClick={() => {
            setSubmitting(false)
            onClose()
          }}>
          取消
        </Button>
        <Button
          variant='contained'
          color='secondary'
          disabled={confirmLoading}
          size='small'
          onClick={async () => {
            setConfirmLoading(true)
            const latestVersion = 0
            await Promise.all(
              itemsByVendor.map(async (x, i) => {
                // console.log('xxxxxxxxxx', x)
                await Promise.all(
                  x.poItems.map(async (y) => {
                    if (y.isNew) {
                      // console.log('new Items', y)
                      // // debugger
                      await dsCreatePoItem(
                        {
                          ...y,
                          date: x.orderDate,
                          vendor: x.entity,
                          type: 'spo',
                        },
                        latestVersion,
                      )
                    }
                  }),
                )
              }),
            )
            const itemsToSend = itemsByVendor.filter((x) => x.sendEmail)
            if (itemsToSend.length > 0) {
              await restAPICalls('post', null, {
                type: 'spo',
                items: itemsToSend,
                isPreview: false,
              })
            }
            // console.log('itemsToSend', itemsToSend)

            setConfirmLoading(false)
            // setLoading(false)
            onClose()
            resolve('success')
          }}>
          确认
        </Button>
      </DialogActions>
    </Paper>
  )
}

export default SPOsPreview
