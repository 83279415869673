const groupArrayofObjectsByKey = (arr, property) => {
  return arr.reduce((acc, obj) => {
    const key = obj[property]
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(obj)
    return acc
  }, {})
}

export default groupArrayofObjectsByKey
