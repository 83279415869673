import React, { useEffect } from 'react'
import { Typography } from '@mui/material'
import { Form } from 'formik'
import { FormField, AutoCompleteField } from '../../components/Form/FormField'

import usePrevious from '../../hooks/usePrevious'
import handleDSOperation from '../../functions/handleDSOperation'

const CreateMainComponent = (props) => {
  const {
    values,
    submitForm,
    errors,
    touched,
    handleChange,
    initialValues,
    handleSubmit,
    handleBlur,
    resetForm,
    setFieldValue,
    setValues,
    isSubmitting,
    formStyle,
  } = props

  const prevCustomer = usePrevious(values.customer)

  const totalQty = values.items.reduce((a, v) => (v.qty ? a + v.qty : a), 0)

  // console.log('totalQty', totalQty)

  useEffect(() => {
    const func = async () => {
      if (!values.customerOptions) {
        const getCpos = await handleDSOperation('cpo', 'query', {
          version: { predicate: 'eq', value: 0 },
          openQty: { predicate: 'gt', value: 0 },
        })

        setFieldValue(
          'customerOptions',
          [...new Set(getCpos.map((x) => x.customer))].map((y) => ({
            customer: y,
          })),
        )
      }
      // console.log('prevCustomer', prevCustomer)
      // console.log('values.customer', values.customer)
      if (values.customer && values.customer !== prevCustomer) {
        const fulfillmentNumber =
          values.customer === '宝洁' || values.customer === '博朗(上海)有限公司'
            ? `SHJH${values.date.substr(0, 4)}${values.date.substr(
                5,
                2,
              )}${values.date.substr(8, 2)}01`
            : Date.now().toString().slice(1, 9)

        const getCpos = await handleDSOperation('cpo', 'query', {
          version: { predicate: 'eq', value: 0 },
          openQty: { predicate: 'gt', value: 0 },
          customer: { predicate: 'eq', value: values.customer },
        })

        const getCustomer = await handleDSOperation('customer', 'query', {
          entity: { predicate: 'eq', value: values.customer },
        })

        if (getCustomer.length === 0 || !getCustomer[0].deliveryTerms) {
          return alert('shipping term not defined')
        }

        const deliveryTerms = getCustomer[0].deliveryTerms
        const hankenoEntity = getCustomer[0].hankenoEntity
        const paymentTerm = getCustomer[0].paymentTerm

        let netDays

        if (paymentTerm && paymentTerm.startsWith('NET')) {
          netDays = paymentTerm.split(' ')[1]
        } else if (paymentTerm === 'PAYMENT IN ADVANCE') {
          netDays = 1
        }

        const customerPaymentDue = new Date(
          new Date().getTime() + netDays * 24 * 60 * 60 * 1000,
        )
          .toISOString()
          .substring(0, 10)

        let invoiceNumber

        if (hankenoEntity === 'HKNUSA') {
          const getInvoices = await handleDSOperation(
            'outgoingInvoice',
            'query',
            {
              invoiceType: { predicate: 'eq', value: 'PI' },
            },
          )

          if (getInvoices.length > 0) {
            getInvoices.sort((a, b) =>
              a.invoiceNumber > b.invoiceNumber ? -1 : 1,
            )
            const lastNumber = getInvoices[0].invoiceNumber.slice(2, 8)
            const lastDate = getInvoices[0].date
            const dateDiff =
              (new Date().getTime() - new Date(lastDate).getTime()) /
              (24 * 60 * 60 * 1000)
            const dailyDiff = Math.random() * 100 + 200
            const invoiceDiff = Math.floor(dateDiff * dailyDiff)
            invoiceNumber = `EN${lastNumber * 1 + invoiceDiff * 1}`
          } else {
            invoiceNumber = 'Temp123'
          }
        }

        setValues({
          ...initialValues,
          customer: values.customer,
          fulfillmentNumber,
          invoiceNumber,
          deliveryTerms,
          deliveryTerm: deliveryTerms[0],
          customerPaymentDue,
          poOptions: getCpos.map((x) => ({
            poNumber: x.poNumber,
            hpn: x.hpn,
            cpn: x.cpn,
            mfr: x.mfr,
            mpn: x.mpn,
            qty: x.qty,
            openQty: x.openQty,
            price: x.price,
            priceCurrency: x.priceCurrency,
          })),
        })
      }
    }
    func()
  }, [setFieldValue, setValues, initialValues, values, prevCustomer])

  return (
    <>
      <Form style={formStyle} autoComplete='off'>
        <div>
          <AutoCompleteField
            fieldName='customer'
            attr='customer'
            options={values.customerOptions}
            setFieldValue={setFieldValue}
          />
          <FormField
            fieldName='date'
            value={values.date}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />

          <FormField
            disabled={!values.customer}
            fieldName='fulfillmentNumber'
            value={values.fulfillmentNumber}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <FormField
            disabled={!values.customer}
            fieldName='invoiceNumber'
            value={values.invoiceNumber}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <FormField
            select
            disabled={!values.customer}
            fieldName='deliveryTerm'
            options={values.deliveryTerms}
            value={values.deliveryTerm}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          {totalQty > 0 && (
            <span style={{ marginLeft: 50 }}>
              <Typography
                color='secondary'
                component='span'
                variant='h6'
                align='center'>
                数量合计: {totalQty.toFixed(2)}
              </Typography>
            </span>
          )}
        </div>
      </Form>

    </>
  )
}

export default CreateMainComponent
