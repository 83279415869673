import handleDSOperation from '../../functions/handleDSOperation'

const receivingOnRowUpdate = async (newData) => {
  const input = {
    id: newData.id,
    receivingCode: newData.receivingCode,
    invoiceNumber: newData.invoiceNumber,
    vendor: newData.vendor,
    vendorPaymentDue: newData.vendorPaymentDue,
    vendorPaymentPaid: newData.vendorPaymentPaid,
    poNumber: newData.poNumber,
    cpn: newData.cpn,
    qty: newData.qty * 1,
    date: newData.date,
    memo: newData.memo,
    billingStatus: newData.billingStatus,
  }
  await handleDSOperation(null, 'update', input)
}

export default receivingOnRowUpdate
