import * as Yup from 'yup'

const formValidationSchema = {
  customer: Yup.string().required('Customer is required'),
  number: Yup.string().test(
    'len',
    'Invoice number must have at least three characters',
    (val) => {
      if (val) {
        return val.length > 2
      }
    },
  ),
}

export default formValidationSchema
