import groupArrayofObjectsByKey from '../helpFunc/groupArrayofObjectsByKey'
import getValidRows from './getValidRows'
import getInvalidRows from './getInvalidRows'
import isValidRow from './isValidRow'

import isItemExistinPO from './isItemExistinPO'
import poItemGetValue from './poItemGetValue'
import getItemsFromPONumber from '../APIOperations/getItemsFromPONumber'
import compareOpenQtyofArrays from './compareOpenQtyofArrays'
import compareOrderQtyofArrays from './compareOrderQtyofArrays'
import fulfillmentQtyValidator from './fulfillmentQtyValidator'
import cpoInputPriceValidator from '../rowValidator/cpoInputPriceValidator'
import handleDSOperation from '../handleDSOperation'

const isPoExistingAndGetLatestVersionAndRecord = async (originalRows) => {
  const type = originalRows[0].type
  if (!originalRows || originalRows.length === 0) return

  let poType

  switch (type) {
    case 'cpo':
    case 'shipping':
      poType = 'cpo'
      break
    case 'spo':
    case 'receiving':
      poType = 'spo'
      break
    default:
      break
  }

  // SKIP WHEN THE INPUT IS NEW SPOS
  if (type === 'spo' && originalRows[0].isNew === 'new') return originalRows

  const rows = JSON.parse(JSON.stringify(originalRows))

  const validRows = getValidRows(rows)

  const groupedPoItemsByNumber = Object.entries(
    groupArrayofObjectsByKey(validRows, 'poNumber'),
  )
  await Promise.all(
    groupedPoItemsByNumber.map(async ([poNumber, itemsGroupedByPO]) => {
      const latestRecord = await handleDSOperation(poType, 'query', {
        poNumber: { predicate: 'eq', value: poNumber },
        version: { predicate: 'eq', value: 0 },
        // openQty: { predicate: 'gt', value: 0 },
      })
      // console.log(`poType, "${poType}"`)
      // console.log(`poNumber, "${poNumber}"`)

      // console.log('latestRecord6666', latestRecord)
      // debugger

      itemsGroupedByPO.forEach((x) => {
        // if (!isValidRow(x)) return

        if (latestRecord.length === 0) {
          if (type === 'cpo') {
            x.isNew = 'new'
          } else if (
            type === 'spo' ||
            type === 'shipping' ||
            type === 'receiving'
          ) {
            x.alertMessage &&
              x.alertMessage.push({
                message: `can not find po ${x.poNumber} in system`,
                index: 'poNumber',
                style: 'alertLevel5',
                level: 5,
              })
          }
          return
        }

        // console.log('wewe', x)

        const firstRecordFound = isItemExistinPO(x, latestRecord)

        // debugger

        if (firstRecordFound) {
          poItemGetValue(x, firstRecordFound)
        }

        if (type === 'cpo' || type === 'spo') {
          x.isNew = 'old'

          x.latestRecord = latestRecord

          cpoInputPriceValidator(x)
          // console.log('3333333333', x)
          // debugger

          // x.totalopenQty = latestRecord.reduce(
          //   (a, v) => (v.cpn === x.cpn ? a + v.openQty : a),
          //   0,
          // )

          // console.log('rows', rows)
          // debugger

          compareOpenQtyofArrays(x, rows, latestRecord)

          return
        }

        if (type === 'cpo') {
          return compareOrderQtyofArrays(x, rows, latestRecord)
        }

        if (type === 'shipping' || type === 'receiving') {
          fulfillmentQtyValidator(x, rows, latestRecord)
          // x.mpn = firstSameItemInPoItemsFound.mpn
          // return
        }
      })
    }),
  )
  return rows
}
export default isPoExistingAndGetLatestVersionAndRecord
