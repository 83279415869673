import getRowValue from './getRowValue'

const arrToObjForContactInput = (rows) => {
  const dataType = 'contact'
  const inputType = 'newContact'

  rows = getRowValue(dataType, inputType, rows)

  rows.forEach((x) => {
    x.entity = x.entity.toUpperCase()
    x.isMainContact = x.isMainContact.toLowerCase() === 'true' ? true : false
    x.role = x.role.split(',')
  })
  return rows
}

export default arrToObjForContactInput
