import React, { useState, useEffect } from 'react'
import {
  Paper,
  Container,
  Card,
  CardActionArea,
  InputLabel,
  Select,
  Box,
  makeStyles,
  FormControl,
  MenuItem,
  TextField,
  Checkbox,
  ListItemText,
  Autocomplete,
} from '@mui/material'
import { ProductAutoComplete } from '../components/Form/FormField'

import { BrowserRouter as Route, useHistory } from 'react-router-dom'
import handleDSOperation from '../functions/handleDSOperation'
import Charts from '../components/Charts'
import config from '../parameters'

// const useStyles = makeStyles((theme) => ({
//   card: {
//     width: 400,
//     height: 240,
//     display: 'inline-block',
//     margin: 10,
//   },
//   bullet: {
//     display: 'inline-block',
//     margin: '0 2px',
//     transform: 'scale(0.8)',
//   },
//   title: {
//     fontSize: 14,
//   },
//   pos: {
//     marginBottom: 12,
//   },
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
// }))

const Statistic = ({ classes }) => {
  const history = useHistory()
  const range = config.defaultScheduleRange

  const [products, setProducts] = useState([])
  const [selectedProduct, setSelectedProduct] = useState()

  useEffect(() => {
    const func = async () => {
      const getProducts = await handleDSOperation('product', 'query', {
        isActive: { predicate: 'eq', value: true },
        version: { predicate: 'eq', value: 0 },
      })

      setProducts(
        getProducts
          .map((x) => ({ mpn: x.mpn, cpn: x.cpn, mfr: x.mfr }))
          .sort((a, b) => (a.cpn > b.cpn ? 1 : -1)),
      )
    }

    func()
  }, [])

  console.log('selectedProduct', selectedProduct)

  return (
    <>
      <ProductAutoComplete
        value={selectedProduct ? selectedProduct : null}
        options={[{ cpn: 'ALL', mpn: '', mfr: '' }, ...products]}
        getOptionLabel={(x) =>
          x.cpn !== 'ALL' ? `${x.cpn} -  ${x.mfr}:${x.mpn}` : x.cpn
        }
        handleChange={(event, newValue) => {
          setSelectedProduct(newValue)
        }}
      />

      {selectedProduct && selectedProduct.cpn !== 'ALL' && (
        <div style={{ marginTop: 20 }}>
          <Box
            display='flex'
            justifyContent='center'
            // alignItems='center'
            minHeight='100vh'>
            <Card>
              <Charts
                cpn={selectedProduct.cpn}
                defaultRange={180}
                preview={false}
              />
            </Card>
          </Box>
        </div>
      )}
      {selectedProduct && selectedProduct.cpn === 'ALL' && (
        <div style={{ marginTop: 20 }}>
          {products.map((x, i) => (
            <Card
              key={i}
              style={{
                width: 400,
                height: 240,
                display: 'inline-block',
                margin: 10,
              }}
              variant='outlined'>
              <CardActionArea
                onClick={(e) => {
                  e.preventDefault()
                  // history.push(`./sched/${x.cpn}`)
                  setSelectedProduct(x)
                  // history.push({
                  //   pathname: `./sched/${x}`,
                  //   search: '?query=abc',
                  //   state: { cpn: x },
                  // })
                }}>
                <Charts cpn={x.cpn} defaultRange={range} preview={true} />
              </CardActionArea>
            </Card>
          ))}
        </div>
      )}
    </>
  )
}

export default Statistic
