import React, { useState, useEffect, createContext, Fragment } from 'react'
import { Amplify, Auth, DataStore, Hub, Logger } from 'aws-amplify'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AuthContext from './context/AuthContext'

import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import { Box } from '@mui/material'
import NavTab from './components/NavTab'
import AuthUI from './components/AuthUI'
import routeMapping from './routeMapping'
import './App.css'
import './css/react-confirm-alert.css'

import awsExports from './aws-exports'
Amplify.configure({
  ...awsExports,
  DataStore: {
    fullSyncInterval: 60 * 24 * 3, // 15 days
    syncPageSize: 1000, // "limit" sent to graphql
    maxRecordsToSync: 2000000,
  },
})

DataStore.configure({
  fullSyncInterval: 60 * 24 * 3, // 15 days
  syncPageSize: 1000, // "limit" sent to graphql
  maxRecordsToSync: 2000000,
})

const theme = createTheme()

function App() {
  const [authState, setAuthState] = useState('initializing')
  const [user, setUser] = useState()

  useEffect(() => {
    const func = async () => {
      try {
        if (!user) {
          const { attributes, signInUserSession } =
            await Auth.currentAuthenticatedUser()
          if (attributes) {
            const { email, sub } = attributes
            const groups =
              signInUserSession.accessToken.payload['cognito:groups'] &&
              signInUserSession.accessToken.payload['cognito:groups'].map((x) =>
                x.toLowerCase(),
              )
            const userObj = { email, sub, groups }
            setUser(userObj)
            setAuthState('signedin')
          }
        }
      } catch (e) {
        setAuthState('failed')
        console.log('auth err', e)
      }
    }

    func()
  }, [user])

  useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) => {
      // console.log('authData changed', authData)
      if (!authData || !authData.attributes) return setUser()
      const { email, sub } = authData.attributes
      const groups =
        authData.signInUserSession.accessToken.payload['cognito:groups'] &&
        authData.signInUserSession.accessToken.payload['cognito:groups'].map(
          (x) => x.toLowerCase(),
        )

      const userObj = { email, sub, groups }
      setUser(userObj)
      setAuthState(nextAuthState)
    })
  }, [])

  useEffect(() => {
    const listener = (data) => {
      switch (data.payload.event) {
        case 'signIn':
          // logger.info('user signed in')
          break
        case 'signUp':
          // logger.info('user signed up')
          break
        case 'signOut':
          console.log('signout')
          setUser()
          // logger.info('user signed out')
          break
        case 'signIn_failure':
          // logger.error('user sign in failed')
          break
        case 'tokenRefresh':
          // logger.info('token refresh succeeded')
          break
        case 'tokenRefresh_failure':
          // logger.error('token refresh failed')
          break
        case 'configured':
          // logger.info('the Auth module is configured')
          break
        default:
          break
      }
    }
    Hub.listen('auth', listener)

    return () => {
      Hub.remove('auth', listener)
    }
  }, [])

  if (authState === 'initializing') {
    return <p>authenticating</p>
  }

  if (authState === AuthState.SignedIn && user) {
    return (
      <AuthContext.Provider value={user}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={theme}>
            <Router>
              <Fragment>
                <NavTab />
                <Box p={3}>
                  <Switch>
                    {routeMapping.map((y, i) => {
                      const Comp = y.Component
                      return (
                        <Route key={i} path={y.path}>
                          <Comp
                            dataType={y.dataType}
                            userGroups={user.groups}
                          />
                        </Route>
                      )
                    })}
                  </Switch>
                </Box>
              </Fragment>
            </Router>
          </ThemeProvider>
        </LocalizationProvider>
      </AuthContext.Provider>
    )
  }
  return <AuthUI />
}

export default App
