import React from 'react'
// import usePrevious from '../../hooks/usePrevious'
import { FormField, ProductAutoComplete } from '../../components/Form/FormField'
import { Form } from 'formik'

const CreateItemComponent = ({
  values,
  handleBlur,
  index,
  handleChange,
  setFieldValue,
  formStyle,
  item: currentItem,
}) => {
  console.log('cpo values', values)
  return (
    <Form style={formStyle} autoComplete='off'>
      <FormField disabled fieldName='line' index={index} value={values.line} />

      <ProductAutoComplete
        id={values.items.length}
        options={values.productOptions}
        index={index}
        setFieldValue={setFieldValue}
        currentItem={currentItem}
        value={
          currentItem.hpn
            ? {
                // coo: currentItem.coo,
                // cost: currentItem.cost,
                // costCurrency: currentItem.costCurrency,
                cpn: currentItem.cpn,
                mfr: currentItem.mfr,
                mpn: currentItem.mpn,
                // hpn: currentItem.hpn,
                // unit: currentItem.unit,
                // productType: currentItem.productType,
                // vendors: currentItem.vendors,
                // supplierMOQ: currentItem.supplierMOQ,
                // supplierMPQ: currentItem.supplierMPQ,
                // leadtime: currentItem.leadtime,
                // vendor: currentItem.vendor,
              }
            : null
        }
      />
      <FormField
        disabled={!currentItem.hpn}
        fieldName='mfr'
        value={currentItem.mfr}
        index={index}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />

      <FormField
        disabled={!currentItem.hpn}
        fieldName='mpn'
        value={currentItem.mpn}
        index={index}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />
      <FormField
        disabled={!currentItem.hpn}
        fieldName='price'
        index={index}
        value={currentItem.price}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />
      <FormField
        disabled={!currentItem.hpn}
        select
        fieldName='priceCurrency'
        index={index}
        value={currentItem.priceCurrency}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />

      <FormField
        disabled
        fieldName='supplierMOQ'
        index={index}
        value={currentItem.supplierMOQ}
      />

      <FormField
        disabled
        fieldName='supplierMPQ'
        index={index}
        value={currentItem.supplierMPQ}
      />
      <FormField
        disabled={!currentItem.hpn}
        fieldName='qty'
        index={index}
        value={currentItem.qty}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />
      <FormField
        disabled={!currentItem.hpn}
        fieldName='reqSched'
        index={index}
        value={currentItem.reqSched}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />
      <FormField
        disabled={true}
        fieldName='salesAmount'
        index={index}
        value={
          currentItem.qty &&
          currentItem.price &&
          (currentItem.qty * currentItem.price).toFixed(2)
        }
        handleBlur={handleBlur}
        handleChange={handleChange}
      />
    </Form>
  )
}

export default CreateItemComponent
