const mapping = {
  enhanced: [
    'actions',
    'memo',
    'paymentStatus',
    'invoiceType',
    'date',
    'invoiceNumber',
    'vendor',
    'poNumber',
    // 'line',
    'cpn',
    'mpn',
    'qty',
    'cost',
    'costCurrency',
    'amountExclVat',
    'amountInclVat',
    'vendorPaymentDue',
    // 'vendorPaymentPaid',
    'receivingCode',
    'deductStatus',

    // 'fulfillmentNumber',
  ],
  simple: [
    'date',
    'vendor',
    'invoiceNumber',
    'invoiceType',
    'poNumber',
    'receivingCode',
    'line',
    'cpn',
    'mpn',
    'invoiceCostExclVat',
    'cost',
    'amountExclVat',
    'amountInclVat',
    'vendorPaymentDue',
    'qty',
    'unit',
  ],
  collasped: [
    'date',
    'invoiceNumber',
    'cpn',
    'mpn',
    'qty',
    'amountExclVat',
    'amountInclVat',
    'vendor',
  ],
}

export default mapping
