import { DataStore } from 'aws-amplify'
import { HDC } from '../models/index'

const handleDSOperation = async (type, operationType, arg, andOr) => {
  try {
    // console.log('dataType in DSOperation1111111', type)
    // console.log('operationType1111111111', operationType)
    // console.log('obj111111111111', arg)
    // debugger
    let res

    switch (operationType) {
      case 'create':
        // console.log('input000000000000000000', {
        //   type,
        //   ...arg,
        // })

        // debugger
        res = await DataStore.save(
          new HDC({
            type,
            ...arg,
          }),
        )
        break
      // return res
      case 'queryById':
        res = await DataStore.query(HDC, arg)
        break

      case 'query':
        // console.log(123)

        // console.log('test', test)

        // debugger

        let copyOfArg = { type: { predicate: 'eq', value: type }, ...arg }

        // if (arg && arg.vendor)

        if (arg && arg.range) {
          const date =
            arg.range !== 'all'
              ? {
                  predicate: 'gt',
                  value: new Date(
                    new Date().getTime() - arg.range * 30 * 1000 * 60 * 60 * 24,
                  )
                    .toISOString()
                    .substring(0, 10),
                }
              : { predicate: 'contains', value: '' }

          delete copyOfArg.range
          // copyOfArg = { ...copyOfArg }
          copyOfArg = { ...copyOfArg, date }

          console.log('query', copyOfArg)
          // debugger
        }

        if (arg && arg.status) {
          let openQty

          switch (arg.status) {
            case 'all':
              openQty = {
                predicate: 'ge',
                value: 0,
              }
              break
            case 'completed':
              openQty = {
                predicate: 'eq',
                value: 0,
              }
              break
            case 'open':
              openQty = {
                predicate: 'gt',
                value: 0,
              }
              break
            default:
              break
          }

          delete copyOfArg.status
          copyOfArg = { ...copyOfArg, openQty }
        }

        const attrToUppercase = [
          'vendor',
          'customer',
          'entity',
          'name',
          'mpn',
          'poNumber',
          'invoiceNumber',
        ]

        const arr = Object.entries(copyOfArg)

        res = await DataStore.query(HDC, (c) => {
          // console.log('ccccc', c)
          let res
          arr.forEach(([key, expression], i) => {
            if (attrToUppercase.includes(key)) {
              res = c[key](
                expression.predicate,
                expression.value && expression.value.toUpperCase(),
              )
            } else {
              res = c[key](expression.predicate, expression.value)
            }
            // console.log(`res${i}`, res)
            // c[key](expression.predicate, expression.value)
          })
          return res
        })
        //  res = await DataStore.query(HDC, (c) => {
        //    arr.forEach(([key, expression]) => {
        //      if (attrToUppercase.includes(key)) {
        //        c[key](
        //          expression.predicate,
        //          expression.value && expression.value.toUpperCase(),
        //        )
        //      } else c[key](expression.predicate, expression.value)
        //      // c[key](expression.predicate, expression.value)

        //      // return c
        //    })
        //    console.log('ccc', c)
        //    return c
        //  })

        // ATTENTION FETCH VENDOR CONTACT

        // if (res && (type === 'vendor' || type === 'customer')) {
        //   // console.log(777777777777)
        //   return await Promise.all(
        //     res.map(async (x) => {
        //       const contact = await DataStore.query(HDC, (c) =>
        //         c
        //           .type('eq', 'contact')
        //           .entity('eq', x.entity)
        //           .isMainContact('eq', true),
        //       )
        //       // console.log('contact', contact)
        //       return { ...x, contactPerson: contact[0] }
        //     }),
        //   )
        // }

        break
      // return res

      case 'update':
        const original = await DataStore.query(HDC, arg.id)
        // console.log('original', original)
        // debugger

        //   // delete arg.id
        //   // console.log('id to be updated', arg.id)

        //   // console.log('arg', arg)

        res = await DataStore.save(
          HDC.copyOf(original, (updated) => {
            const arr = Object.entries(arg)
            arr.forEach(([key, value]) => {
              switch (key) {
                case 'price':
                case 'cost':
                case 'initialInventory':
                case 'safetyInventory':
                case 'currentInventory':
                  updated[key] = value * 1
                  break
                default:
                  updated[key] = value
              }
            })
          }),
        )

        // console.log('update res', res)
        break
      // return res && JSON.parse(JSON.stringify(res))

      case 'delete':
        //   // debugger
        const toDelete = await DataStore.query(HDC, arg)
        await DataStore.delete(toDelete)
        // console.log('toDelete', toDelete)
        // }
        break
      default:
        return
    }

    return res && JSON.parse(JSON.stringify(res))

    // } else if (operationType === 'get') {
    //   res = await DataStore.query(HDC, arg)

    //   if (
    //     (dataType === 'shipping' || dataType === 'receiving') &&
    //     copyOfArg.range &&
    //     copyOfArg.range !== 'all'
    //   ) {
    //     copyOfArg = {
    //       ...copyOfArg,
    //       date: {
    //         predicate: 'gt',
    //         value: new Date(
    //           new Date().getTime() - copyOfArg.range * 30 * 1000 * 60 * 60 * 24,
    //         )
    //           .toISOString()
    //           .substring(0, 10),
    //       },
    //     }
    //   }

    //   // }
    //   // if (res && componentMapping[dataType].modelName === 'PoItem') {
    //     // return await Promise.all(
    //     //   res.map(async (x) => {
    //     //     const product = await DataStore.query(Product, (c) =>
    //     //       c.cpn('eq', x.cpn).version('eq', 'v0'),
    //     //     )
    //     //     return { ...x, product: product[0] }
    //     //   }),
    //     // )
    //   // }

    //   return res && JSON.parse(JSON.stringify(res))

    //   // debugger
    // } else if (operationType === 'update') {
    //   // console.log('dataType 999999999', dataType)
    //   // console.log('arg', arg)

    //   const original = await DataStore.query(
    //     componentMapping[dataType].model,
    //     arg.id,
    //   )
    //   // console.log('original', original)

    //   // debugger

    //   // delete arg.id
    //   // console.log('id to be updated', arg.id)

    //   // console.log('arg', arg)

    //   const res = await DataStore.save(
    //     componentMapping[dataType].model.copyOf(original, (updated) => {
    //       const arr = Object.entries(arg)
    //       arr.forEach(([key, value]) => {
    //         // switch (key) {
    //         //   case 'price':
    //         //   case 'cost':
    //         //     updated[key] = value * 1
    //         //     break
    //         //   default:
    //         updated[key] = value
    //         // }
    //       })
    //     }),
    //   )

    //   // console.log('update res', res)

    //   return res
    // } else if (operationType === 'delete') {
    //   // console.log('dataType in delete', dataType)
    //   // console.log('model', componentMapping[dataType].model)
    //   // console.log('arg in delete', arg)
    //   // debugger
    //   const toDelete = await DataStore.query(
    //     componentMapping[dataType].model,
    //     arg,
    //   )
    //   DataStore.delete(toDelete)
    // }
  } catch (err) {
    console.log('error', err)
  }
}

export default handleDSOperation
