import {
  vendor,
  qty,
  cpn,
  mpn,
  reqSched,
  price,
  poNumber,
  receivingCode,
  exchangeRate,
  invoiceNumber,
  date,
} from '../../functions/getValidations'

const formValidationSchema = {
  date,
  receivingCode,
  poNumber,
}

export default formValidationSchema
