import dsCreatePoItem from '../APIOperations/dsCreatePoItem'
import handleDSOperation from '../handleDSOperation'

const dsUpdatePoItem = async (poItem) => {
  const latestRecord = await handleDSOperation(poItem.type, 'query', {
    poNumber: { predicate: 'eq', value: poItem.poNumber },
    version: { predicate: 'eq', value: 0 },
    openQty: { predicate: 'gt', value: 0 },
  })

  const unchangedItems = latestRecord.filter((x) => x.id !== poItem.id)

  const newArr = [poItem, ...unchangedItems]

  // console.log('newArr', newArr)

  // debugger

  await Promise.all(
    newArr.map(async (x) => {
      await dsCreatePoItem(x, poItem.latestVersion)
      // await handleDSOperation(null, 'delete', x.id)
    }),
  )
}

export default dsUpdatePoItem

