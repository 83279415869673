const getValidRows = (rows) =>
  Array.isArray(rows)
    ? rows.filter(
        (x) =>
          !x.alertMessage ||
          (x.alertMessage && !x.alertMessage.map((x) => x.level).includes(5)),
      )
    : []

export default getValidRows
