import spoOnRowDelete from './onRowDelete'

const onSelectedDelete = async (selected) => {
  await Promise.all(
    selected.map(async (x) => {
      await spoOnRowDelete(x)
    }),
  )
}

export default onSelectedDelete
