import groupArrayofObjectsByKey from '../../helpFunc/groupArrayofObjectsByKey'
import restAPICalls from '../../restAPICalls'
import dsGenerateSPOItemsPerVendor from '../../APIOperations/dsGenerateSPOItemsPerVendor'
import handleDSOperation from '../../handleDSOperation'

const handleToolbarResendPO = async (itemsSelected, isPreview) => {
  const poItemsByVendor = groupArrayofObjectsByKey(itemsSelected, 'vendor')

  const arrOfVendor = Object.keys(poItemsByVendor)

  const arrOfPoObj = await Promise.all(
    arrOfVendor.map(async (vendor) => {
      const existingOrders = await handleDSOperation('spo', 'query', {
        vendor: { predicate: 'eq', value: vendor },
        version: { predicate: 'eq', value: 0 },
        openQty: { predicate: 'gt', value: 0 },
      })

      const currentDate = new Date().toISOString().substring(0, 10)

      const poObj = await dsGenerateSPOItemsPerVendor(
        vendor,
        currentDate,
        [],
        existingOrders,
      )

      return poObj
    }),
  )

  await restAPICalls('post', null, {
    type: 'spo',
    items: arrOfPoObj,
    isPreview,
  })
}

export default handleToolbarResendPO
