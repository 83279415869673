import groupArrayofObjectsByKey from '../helpFunc/groupArrayofObjectsByKey'
import dsUpdateForwarderPOBillingStatusWithReceivingCode from './dsUpdateForwarderPOBillingStatusWithReceivingCode'

const dsUpdateForwarderPOsBillingStatusWithReceivingCode = async (
  items,
  addOrMinus,
) => {
  // console.log('items', items)
  const arrByReceivingCode = Object.entries(
    groupArrayofObjectsByKey(items, 'receivingCode'),
  )
  // console.log('arrByReceivingCode', arrByReceivingCode)

  await Promise.all(
    arrByReceivingCode.map(async ([receivingCode, itemsByReceivingCode]) => {
      // console.log(1)
      // console.log('receivingCode', receivingCode)

      // console.log(3)
      await dsUpdateForwarderPOBillingStatusWithReceivingCode({
        receivingCode,
        itemsByReceivingCode,
        addOrMinus,
      })
    }),
  )
}

export default dsUpdateForwarderPOsBillingStatusWithReceivingCode
