const mergeArrayAndCombineQty = (items, reduceBy, qtyAttr) => {
  // console.log('items', items)
  // console.log('reduceBy', reduceBy)
  // console.log('qtyAttr', qtyAttr)

  // debugger
  const copyedArr = JSON.parse(JSON.stringify(items))

  const mergedArr = copyedArr.reduce((a, v) => {
    const sameItem = a.find((x) => {
      for (let attr of reduceBy) {
        if (x[attr] !== v[attr]) return false
      }

      return true
    })

    if (sameItem) {
      qtyAttr.forEach((x) => {
        sameItem[x] = sameItem[x] * 1 + v[x] * 1
      })
      return a
    } else {
      return a.concat(v)
    }
  }, [])

  // const initialValue = {}
  // return array.reduce((obj, item) => {
  //   return {
  //     ...obj,
  //     [item[key]]: item,
  //   }
  // }, initialValue)

  // console.log('mergedArr', mergedArr)

  // debugger

  return mergedArr
}

export default mergeArrayAndCombineQty
