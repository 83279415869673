import { firstBy } from 'thenby'
import handleDSOperation from '../handleDSOperation'
import mergeArrayAndCombineQty from '../helpFunc/mergeArrayAndCombineQty'
import getDeductedItems from '../helpFunc/getDeductedItems'

const dsUpdateForwarderPOQtyAfterReceivings = async (items) => {
  const mergedArr = mergeArrayAndCombineQty(
    items,
    ['hpn', 'receivingCode'],
    ['qty'],
  )
  // console.log('mergedArr', mergedArr)

  const arrOfarrUpdatedPo = await Promise.all(
    mergedArr.map(async (receivingItem) => {
      const poItems = await handleDSOperation('forwarderPo', 'query', {
        hpn: { predicate: 'eq', value: receivingItem.hpn },
        receivingCode: { predicate: 'eq', value: receivingItem.receivingCode },
        openQty: { predicate: 'gt', value: 0 },
      })

      poItems.sort(firstBy('poNumber').thenBy('openQty'))

      return getDeductedItems(receivingItem, poItems, 'qty', 'openQty')
    }),
  )
  const arrOfUpdatedPo = [].concat.apply([], arrOfarrUpdatedPo)

  await Promise.all(
    arrOfUpdatedPo.map(async (x) => {
      await handleDSOperation(null, 'update', {
        id: x.id,
        openQty: x.openQty - x.changedQty,
      })
    }),
  )
  const obj = arrOfUpdatedPo.map((x) => ({
    ...x,
    id: undefined,
    qty: x.changedQty,
  }))
  return obj
}

export default dsUpdateForwarderPOQtyAfterReceivings
