import React, { useState, useEffect } from 'react'
import { IconButton, Typography, Box, CircularProgress } from '@mui/material'
import config from '../../parameters'
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material'
import FulfillmentRecord from '../../components/Table/collapse/FulfillmentRecord'
import VATInvoiceRecord from '../../components/Table/collapse/VATInvoiceRecord'
import PaymentRecord from '../../components/Table/collapse/PaymentRecord'

const DetailPanel = ({ rowData }) => {
  const [openInvoice, setOpenInvoice] = useState(false)
  const [openReceiving, setOpenReceiving] = useState(false)
  const [openPayment, setOpenPayment] = useState(false)

  const SubItemMenu = () => (
    <div>
      <Typography variant='h6' gutterBottom component='span'>
        <IconButton
          aria-label='expand row'
          size='small'
          onClick={() => {
            setOpenReceiving(false)
            setOpenPayment(false)
            setOpenInvoice(!openInvoice)
          }}>
          {openInvoice ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
        发票记录
      </Typography>

      <Typography variant='h6' gutterBottom component='span'>
        <IconButton
          aria-label='expand row'
          size='small'
          onClick={() => {
            setOpenInvoice(false)
            setOpenPayment(false)
            setOpenReceiving(!openReceiving)
          }}>
          {openReceiving ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
        收货记录
      </Typography>
      <Typography variant='h6' gutterBottom component='span'>
        <IconButton
          aria-label='expand row'
          size='small'
          onClick={() => {
            setOpenInvoice(false)
            setOpenPayment(!openPayment)
            setOpenReceiving(false)
          }}>
          {openPayment ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
        付款记录
      </Typography>
    </div>
  )

  return (
    <Box m={3}>
      <SubItemMenu />
      {openInvoice && (
        <VATInvoiceRecord
          receivingCode={rowData.receivingCode}
          hpn={rowData.hpn}
        />
      )}
      {openReceiving && (
        <FulfillmentRecord
          fulfillmentType='receiving'
          receivingCode={rowData.receivingCode}
          hpn={rowData.hpn}
        />
      )}
      {openPayment && (
        <PaymentRecord
          paymentType='outgoingPayment'
          receivingCode={rowData.receivingCode}
          isForwarderPO={true}
          vendor={config.forwarder.name}
        />
      )}
    </Box>
  )
}

export default DetailPanel
