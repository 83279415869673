import { firstBy } from 'thenby'
import handleDSOperation from '../handleDSOperation'
import mergeArrayAndCombineQty from '../helpFunc/mergeArrayAndCombineQty'
import getAddedItems from '../helpFunc/getAddedItems'

const dsUpdateForwarderPOQtyAfterDeleteReceivings = async (items) => {
  let mergedArr = items.filter(
    (x) => x.receivingCode && x.receivingCode.length > 0,
  )

  mergedArr = mergeArrayAndCombineQty(
    items,
    ['hpn', 'receivingCode', 'poNumber'],
    ['qty'],
  )
  // console.log('mergedArr: ', mergedArr)

  const arrOfarrUpdatedPo = await Promise.all(
    mergedArr.map(async (receivingItem) => {
      let poItems = await handleDSOperation('forwarderPo', 'query', {
        hpn: { predicate: 'eq', value: receivingItem.hpn },
        receivingCode: { predicate: 'eq', value: receivingItem.receivingCode },
        poNumber: { predicate: 'eq', value: receivingItem.poNumber },
      })

      poItems = poItems.filter((x) => x.qty !== x.openQty)

      // poItems.sort(firstBy('poNumber').thenBy('openQty'))

      // console.log('receivingItem: ', receivingItem)
      // console.log('poItems: ', poItems)

      return getAddedItems(receivingItem, poItems, 'qty', 'openQty')
    }),
  )
  const arrOfUpdatedPo = [].concat.apply([], arrOfarrUpdatedPo)

  // console.log('arrOfUpdatedPo: ', arrOfUpdatedPo)

  // debugger

  await Promise.all(
    arrOfUpdatedPo.map(async (x) => {
      await handleDSOperation(null, 'update', {
        id: x.id,
        openQty: x.openQty + x.changedQty,
      })
    }),
  )
}

export default dsUpdateForwarderPOQtyAfterDeleteReceivings
