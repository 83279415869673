import React, { useState, useEffect } from 'react'
import { FormField } from '../../components/Form/FormField'
import { Button } from '@mui/material'
import { Form } from 'formik'
import handleDSOperation from '../../functions/handleDSOperation'
import config from '../../parameters'

const EditComponent = (props) => {
  const {
    values,
    submitForm,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    setFieldValue,
    setValues,
    isSubmitting,
    formStyle,
    userGroups: groups,
    onCloseForm,
  } = props

  const [trackingLoading, setTrackingLoading] = useState(false)

  useEffect(() => {
    const func = async () => {
      const getProd = await handleDSOperation('product', 'query', {
        version: { predicate: 'eq', value: 0 },
        hpn: { predicate: 'eq', value: values.hpn },
      })
      console.log('getProd', getProd[0])
      setFieldValue('coos', getProd[0].coo || [])
    }

    func()
  }, [values.hpn, setFieldValue])

  return (
    <>
      <Form style={formStyle} autoComplete='off'>
        <div>
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='customer'
            value={values.customer}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='fulfillmentNumber'
            value={values.fulfillmentNumber}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />

          <FormField
            disabled={!groups.includes('admin')}
            fieldName='invoiceNumber'
            value={values.invoiceNumber}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='date'
            value={values.date}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='poNumber'
            value={values.poNumber}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />

          <FormField
            disabled={!groups.includes('admin')}
            fieldName='deliveryTerm'
            value={values.deliveryTerm}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </div>
        <div>
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='cpn'
            value={values.cpn}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='mpn'
            value={values.mpn}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='qty'
            value={values.qty}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />

          <FormField
            disabled={!groups.includes('admin')}
            fieldName='cartonNumber'
            value={values.cartonNumber}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <FormField
            disabled={!groups.includes('admin')}
            select
            fieldName='coo'
            value={values.coo}
            handleChange={handleChange}
            handleBlur={handleBlur}
            options={values.coos}
          />
        </div>
        <div>
          {/* <TextField
            disabled={!groups.includes('admin')}
            select
            name='paymentStatus'
            value={values.paymentStatus || ''}
            style={{ width: 100 }}
            label='付款状态'
            onBlur={handleBlur}
            onChange={handleChange}>
            {config.paymentStatus.map((x, i) => (
              <MenuItem key={i} value={x}>
                {x}
              </MenuItem>
            ))}
          </TextField> */}
          <FormField
            disabled={!groups.includes('admin')}
            select
            fieldName='billingStatus'
            value={values.billingStatus}
            handleChange={handleChange}
            handleBlur={handleBlur}
            options={config.billingStatus}
          />
        </div>
        <div>
          <FormField
            fieldName='memo'
            multiline
            value={values.memo}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
          />
        </div>
        <div>
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='trackingNumber'
            value={values.trackingNumber}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='carrier'
            select
            value={values.carrier}
            handleChange={handleChange}
            handleBlur={handleBlur}
            options={config.carrier}
          />
        </div>
        <Button
          disabled={trackingLoading}
          onClick={async () => {
            if (!values.trackingNumber || !values.carrier) {
              alert('Tracking Info 不全')
            }
            // // console.log(trackingInfo)
            setTrackingLoading(true)
            const getFulfillments = await handleDSOperation(
              'shipping',
              'query',
              {
                fulfillmentNumber: {
                  predicate: 'eq',
                  value: values.fulfillmentNumber,
                },
              },
            )

            console.log('getFulfillments',getFulfillments)

            await Promise.all(
              getFulfillments.map(async (x) => {
                const updateObj = {
                  id: x.id,
                  trackingNumber: values.trackingNumber,
                  carrier: values.carrier,
                }
                await handleDSOperation(null, 'update', updateObj)
              }),
            )
            setTrackingLoading(false)
            onCloseForm()
          }}
          variant='contained'
          color='primary'>
          确认Tracking Info
        </Button>
      </Form>
    </>
  )
}

export default EditComponent
