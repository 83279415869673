import React, { useState, useEffect, useRef, Fragment } from 'react'
import { IconButton, Typography, Box, CircularProgress } from '@mui/material'
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material'
import PoRecord from '../../components/Table/collapse/PoRecord'
import PaymentRecord from '../../components/Table/collapse/PaymentRecord'
import FulfillmentRecord from '../../components/Table/collapse/FulfillmentRecord'

const DetailPanel = ({ rowData }) => {
  const [openReceiving, setOpenReceiving] = useState(false)
  const [openPaymentRecord, setOpenPaymentRecord] = useState(false)

  const SubItemMenu = () => (
    <div>
      <Typography
        variant='h6'
        gutterBottom
        component='span'>
        <IconButton
          aria-label='expand row'
          size='small'
          onClick={async () => {
            setOpenPaymentRecord(false)
            setOpenReceiving(!openReceiving)
          }}>
          {openReceiving ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
        收货记录
      </Typography>
      <Typography
        variant='h6'
        gutterBottom
        component='span'>
        <IconButton
          aria-label='expand row'
          size='small'
          onClick={async () => {
            setOpenReceiving(false)
            setOpenPaymentRecord(!openPaymentRecord)
          }}>
          {openPaymentRecord ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
        付款记录
      </Typography>
    </div>
  )

  return (
    <Box m={3}>
      <SubItemMenu />
      {openReceiving && (
        <FulfillmentRecord
          fulfillmentType='receiving'
          receivingCode={rowData.receivingCode}
          poNumber={rowData.poNumber}
          hpn={rowData.hpn}
        />
      )}
      {openPaymentRecord && (
        <PaymentRecord
          paymentType='outgoingPayment'
          invoiceNumber={rowData.invoiceNumber}
          receivingCode={rowData.receivingCode}
          isForwarderPO={false}
          vendor={rowData.vendor}
        />
      )}
    </Box>
  )
}

export default DetailPanel
