import {
  entity,
  hankenoEntity,
  number,
  paymentTerm,
  deliveryTerms,
} from '../../functions/getValidations'

const formValidationSchema = {
  entity,
  hankenoEntity,
  number,
  paymentTerm,
  deliveryTerms,
}

export default formValidationSchema
