import { Stack, Button } from '@mui/material'
import { useHistory, Link } from 'react-router-dom'
import Statistic from './pages/Statistic'
import Batch from './pages/Batch'
import MaterialTableLoader from './components/Table/MaterialTableLoader'

const Comp = ({ dataType }) => (
  <div
    style={{
      height: '88vh',
      width: '100%',
      display: 'flex',
    }}>
    <MaterialTableLoader dataType={dataType} tbType='enhanced' />
  </div>
)

const MenuComponent = ({ menus, userGroups }) => {
  const history = useHistory()
  const allowedMenus = menus.filter((x) =>
    x.restriction.some((y) =>
      userGroups.map((z) => z.toLowerCase()).includes(y.toLowerCase()),
    ),
  )
  return (
    <Stack spacing={2} direction='row'>
      {allowedMenus.map((x, i) => (
        <Button
          key={i}
          color='secondary'
          variant='outlined'
          onClick={() => {
            history.push(x.type)
          }}>
          {x.label}
        </Button>
      ))}
    </Stack>
  )
}

const routeMapping = [
  {
    label: '客户订单',
    value: 0,
    path: '/cpo',
    Component: Comp,
    dataType: 'cpo',
    firstLevel: true,
    restriction: ['Admin', 'Sales', 'Accounting'],
  },
  {
    label: '供应商订单',
    value: 1,
    path: '/spo',
    Component: Comp,
    dataType: 'spo',
    firstLevel: true,
    restriction: ['Admin', 'Sales', 'Accounting'],
  },
  {
    label: '委托单',
    value: 2,
    path: '/forwarderPo',
    Component: Comp,
    dataType: 'forwarderPo',
    firstLevel: true,
    restriction: ['Admin', 'Sales', 'Accounting'],
  },
  {
    label: '出货',
    value: 3,
    path: '/shipping',
    Component: Comp,
    dataType: 'shipping',
    firstLevel: true,
    restriction: ['Admin', 'Sales', 'Accounting'],
  },
  {
    label: '收货',
    value: 4,
    path: '/receiving',
    Component: Comp,
    dataType: 'receiving',
    firstLevel: true,
    restriction: ['Admin', 'Sales', 'Accounting'],
  },
  {
    label: '交期',
    value: 5,
    path: '/statistic',
    Component: Statistic,
    firstLevel: true,
    restriction: ['Admin', 'Sales', 'Accounting'],
  },
  {
    label: '数据维护',
    value: 6,
    path: '/maintenance',
    menu: [
      { label: 'vendor', type: 'vendor' },
      { label: 'customer', type: 'customer' },
      { label: 'product', type: 'product' },
      { label: 'contact', type: 'contact' },
      { label: 'outgoingQuote', type: 'outgoingQuote' },
    ],
    Component: ({ userGroups }) => {
      const menus = [
        {
          label: 'vendor',
          type: 'vendor',
          restriction: ['Admin', 'Sales', 'Accounting'],
        },
        {
          label: 'customer',
          type: 'customer',
          restriction: ['Admin', 'Sales', 'Accounting'],
        },
        {
          label: 'product',
          type: 'product',
          restriction: ['Admin', 'Sales', 'Accounting'],
        },
        {
          label: 'contact',
          type: 'contact',
          restriction: ['Admin', 'Sales', 'Accounting'],
        },
        {
          label: 'outgoingQuote',
          type: 'outgoingQuote',
          restriction: ['Admin'],
        },
      ]
      return <MenuComponent menus={menus} userGroups={userGroups} />
    },
    firstLevel: true,
    restriction: ['Admin', 'Sales', 'Accounting'],
  },
  {
    label: '发票',
    value: 7,
    path: '/billing',
    Component: ({ userGroups }) => {
      const menus = [
        {
          label: '进项发票',
          type: 'incomingInvoice',
          restriction: ['Admin', 'Sales', 'Accounting'],
        },
        {
          label: '销项发票',
          type: 'outgoingInvoice',
          restriction: ['Admin', 'Accounting'],
        },
      ]
      return <MenuComponent menus={menus} userGroups={userGroups} />
    },
    firstLevel: true,
    restriction: ['Admin', 'Sales', 'Accounting'],
  },
  {
    label: '付款',
    value: 8,
    path: '/outgoingPayment',
    Component: Comp,
    firstLevel: true,
    dataType: 'outgoingPayment',
    restriction: ['Admin', 'Sales', 'Accounting'],
  },
  {
    label: '收款',
    value: 9,
    path: '/incomingPayment',
    Component: Comp,
    firstLevel: true,
    dataType: 'incomingPayment',
    restriction: ['Admin'],
  },
  {
    label: '批量',
    value: 10,
    href: '/batch',
    path: '/batch',
    Component: Batch,
    firstLevel: true,
    restriction: ['Admin', 'Sales', 'Accounting'],
  },

  {
    label: 'vendor',
    value: 11,
    path: '/vendor',
    dataType: 'vendor',
    Component: Comp,
    firstLevel: false,
    restriction: ['Admin', 'Sales', 'Accounting'],
  },
  {
    label: 'customer',
    value: 12,
    path: '/customer',
    dataType: 'customer',
    Component: Comp,
    firstLevel: false,
    restriction: ['Admin', 'Sales', 'Accounting'],
  },
  {
    label: 'contact',
    value: 13,
    path: '/contact',
    dataType: 'contact',
    Component: Comp,
    firstLevel: false,
    restriction: ['Admin', 'Sales', 'Accounting'],
  },
  {
    label: 'product',
    value: 14,
    path: '/product',
    dataType: 'product',
    Component: Comp,
    firstLevel: false,
    restriction: ['Admin', 'Sales', 'Accounting'],
  },
  {
    label: 'incomingInvoice',
    value: 15,
    path: '/incomingInvoice',
    dataType: 'incomingInvoice',
    Component: Comp,
    firstLevel: false,
    restriction: ['Admin', 'Sales', 'Accounting'],
  },
  {
    label: 'outgoingInvoice',
    value: 16,
    path: '/outgoingInvoice',
    Component: Comp,
    firstLevel: false,
    dataType: 'outgoingInvoice',
    restriction: ['Admin', 'Sales', 'Accounting'],
  },
  {
    label: 'outgoingQuote',
    value: 17,
    path: '/outgoingQuote',
    dataType: 'outgoingQuote',
    Component: Comp,
    firstLevel: false,
    restriction: ['Admin'],
  },
]
export default routeMapping
