const config = {
  color: {
    alert3: '#ffa726',
    alert4: '#ef5350',
    alert5: '#b71c1c',
    purple: '#e1bee7',
    lightPurple: '#f3e5f5',
    grey: '#eeeeee',
    lightGrey: '#fafafa',
    darkGrey: '#bdbdbd',
    lightBlue: '#90caf9',
  },
  currencyOptions: ['USD', 'EUR', 'CNY'],
  currencies: [
    {
      value: 'USD',
      label: '$',
    },
    {
      value: 'EUR',
      label: '€',
    },
    {
      value: 'CNY',
      label: '¥',
    },
  ],
  packageDimensions: [
    '30X22X22',
    '37X37X38.5',
    '38X38X20',
    '39X39X40',
    '41X41X41',
    '42X42X24',
    '42X42X31',
    '44X41X22',
    '45X47X29',
    '50X32X27',
    '60X39X39',
  ],
  units: ['PCS', 'Kg', 'SET'],
  defaultScheduleRange: 180,
  hankenoEntities: [
    {
      tag: 'HKNCN',
      name: 'SHANGHAI HANKENO ELECTRONICS TECHNOLOGY CO LTD',
      add: '3/F, Bldg 2, Changzheng Industrial Park, Alley 1225, Tongpu Rd, Putuo District, Shanghai',
      phoneNumber: '+86-21-50595180',
    },
    {
      tag: 'HKNUSA',
      name: 'Hankeno LLC',
      add: '600 W Broadway, #175, San Diego, CA 92101',
      phoneNumber: '+1-951-297-7788',
    },
    {
      tag: 'EZPAY',
      name: 'EZPAY LLC',
      add: '3680 Wilshire Blvd Ste P04, Los Angeles, CA 90010',
      phoneNumber: '+1-951-297-7788',
    },
    {
      tag: 'BOOMPAI',
      name: 'SHANGHAI BOOMPAI ELECTRONICS TECHNOLOGY CO LTD',
      add: 'No.25, HangMing Rd, Pudong, Shanghai, China',
      phoneNumber: '+86-21-52708001',
    },
  ],
  paymentTerms: [
    'PAYMENT IN ADVANCE',
    'NET 30 DAYS',
    'NET 60 DAYS',
    'NET 90 DAYS',
    'NET 120 DAYS',
  ],
  shippingTerms: [
    'DAP SHANGHAI',
    'DAP BEIJING',
    'DAP SHENZHEN',
    'DAP XIAMEN',
    'DAP HONGKONG',
    'DAP P&G Marktheidenfeld Plant, DE',
    'DAP P&G Plant Wallduern, DE',
    'DAP P&G Kronberg, DE',
    'DAP GERMANY',
  ],

  coo: [
    'CHINA',
    'MALAYSIA',
    'TAIWAN',
    'THAILAND',
    'JAPAN',
    'PHILIPPINES',
    'GERMANY',
    'FRANCE',
    'KOREA',
    'INDONESIA',
  ],

  entityType: ['MFR', 'DIST', 'BROKER', 'FORWARDER', 'PAYMENT AGENT'],

  role: [
    'purchasing',
    'planning',
    'receiving',
    'accounting',
    'billing',
    'R&D',
    'sales',
    'cs',
    'QA',
  ],

  vatRate: 0.13,
  forwarder: {
    name: '上海富申供应链管理有限公司',
    handlingFeeRate: 0.0063,
    mininumServiceFee: 350,
    deliveryTerm: 'DAP HONGKONG',
  },
  productTypeAndImportDuty: [
    { type: 'MOSFET', importDuty: 0 },
    { type: 'IC', importDuty: 0 },
    { type: 'DISCRETE', importDuty: 0 },
    { type: 'LED', importDuty: 0 },
    { type: 'SWITCH', importDuty: 0 },
    { type: 'CONNECTOR', importDuty: 0 },
    { type: 'CAPACITOR', importDuty: 0 },
    { type: 'RESISTOR', importDuty: 0 },
    { type: 'INDUCTOR', importDuty: 0 },
    { type: 'FUSE', importDuty: 0.1 },
    { type: 'TOOL', importDuty: 0 },
    { type: 'HARDWARE', importDuty: 0 },
  ],
  pgEntities: [
    {
      poPrefix: 'A6P',
      name: '宝洁',
    },
    {
      poPrefix: 'G4P',
      name: '宝洁',
    },
    {
      poPrefix: 'F6P',
      name: 'PROCTER & GAMBLE',
    },
  ],
  shippingAddresses: {
    Hongkong: {
      name: 'Jennex Tech',
      add: 'Rm902, Grandtech Center, 8 On Ping St, Shatin N.T, Hongkong',
      contact: 'Mr. Ken',
      phone: '+852-29440800',
    },
    Shanghai: {
      name: '物料仓库 - 临2',
      add: '201111 上海市闵行区富岩路359弄2号',
      contact: '吴先生',
      phone: '13023297633',
    },
    Germany: {
      name: '',
      add: 'Moorkoppel 11 24113 Molfsee, Germany',
      contact: 'Ms. Scheuss',
      phone: '+49-015227178635',
    },
  },
  vendorRolesToAcceptPO: ['sales', 'cs'],
  defaultEmail: 'centralprocessing@hankeno.com',
  orderConfirmationUrl: 'https://www.hankeno.com/confirmation',
  carrier: ['DHL', 'FEDEX', 'UPS', 'SF', 'TNT', 'OTHERS'],
  billingStatus: ['unbilled', 'billed', 'partial'],
  paymentStatus: ['unpaid', 'paid', 'partial'],
  deliveryStatus: ['unDelivered', 'delivered', 'partial'],
  deductStatus: ['deducted', 'unDeducted', 'N/A'],
  invoiceTypes: ['VAT', 'PI', 'RECEIPT'],
}

export default config
