import * as Yup from 'yup'
import {
  customer,
  qty,
  cpn,
  mpn,
  reqSched,
  price,
  poNumber,
  fulfillmentNumber,
  date,
} from '../../functions/getValidations'

const formValidationSchema = {
  date,
  customer,
  fulfillmentNumber,
  items: Yup.array().of(
    Yup.object({
      poNumber,
      qty,
    }),
  ),
}

export default formValidationSchema
