import handleDSOperation from '../../functions/handleDSOperation'
import dsCreateProduct from '../../functions/APIOperations/dsCreateProduct'

import _ from 'lodash'

const onRowUpdate = async (newData) => {
  try {
    const { vendorOptions, initialValues, ...newDataObj } = newData

    const propertiesToTriggerVersionUpdate = [
      // 'vendors',
      'price',
      'cost',
      'customerMOQ',
      'customerMPQ',
      'supplierMOQ',
      'supplierMPQ',
      'mfr',
    ]
    const oldObj = propertiesToTriggerVersionUpdate.reduce((a, v) => {
      if (isNaN(initialValues[v])) {
        a[v] = initialValues[v]
      } else {
        a[v] = initialValues[v] * 1
      }
      return a
    }, {})

    const newObj = propertiesToTriggerVersionUpdate.reduce((a, v) => {
      if (isNaN(newDataObj[v])) {
        a[v] = newDataObj[v]
      } else {
        a[v] = newDataObj[v] * 1
      }

      return a
    }, {})

    if (_.isEqual(oldObj, newObj)) {
      await handleDSOperation(null, 'update', newDataObj)
    } else {
      await dsCreateProduct(newDataObj, newData.latestVersion)
    }
  } catch (e) {
    console.log('error8822', e)
  }
}

export default onRowUpdate
