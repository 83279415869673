import spoCreateItemInitialValue from './createItemInitialValue'

const initialValue = () => ({
  date: new Date().toISOString().substring(0, 10),
  poNumber: Date.now().toString().slice(1, 9),
  vendor: '',
  sendEmail: true,
  vendorOptions: [],
  deliveryTerms: [],
  productOptions: [],

  items: [spoCreateItemInitialValue],
})

export default initialValue
