const fulfillmentQtyValidator = (row, inputedArray, fetchedPoItems) => {
  // console.log('row2222', row)
  // if (row.alertMessage.map((x) => x.level).includes(5)) return

  const fulfillmentQty = inputedArray.reduce(
    (a, v) =>
      v.poNumber === row.poNumber &&
      ((v.cpn && v.cpn === row.cpn) || (v.mpn && v.mpn === row.mpn))
        ? a + v.qty
        : a,
    0,
  )
  // console.log('fulfillmentQty', fulfillmentQty)
  // debugger
  const totalOpenQty = fetchedPoItems.reduce(
    (a, v) => (v.cpn === row.cpn || v.mpn === row.mpn ? a + v.openQty : a),
    0,
  )

  // console.log('fulfillmentQty', fulfillmentQty)
  // console.log('totalopenQty', totalopenQty)

  if (fulfillmentQty > totalOpenQty) {
    row.alertMessage &&
      row.alertMessage.push({
        message: `Shipping quantity ${fulfillmentQty} is great than open quanity ${totalOpenQty}`,
        index: 'qty',
        style: 'alertLevel5',
        level: 5,
      })
  }
  // return row
}

export default fulfillmentQtyValidator
