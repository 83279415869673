const getInitialValue = (rowData) => ({
  id: rowData.id,
  type: rowData.type,
  amountExclVat: rowData.amountExclVat,
  cost: rowData.cost,
  costCurrency: rowData.costCurrency,
  cpn: rowData.cpn,
  hpn: rowData.hpn,
  invoiceNumber: rowData.invoiceNumber,
  invoiceType: rowData.invoiceType,
  paymentStatus: rowData.paymentStatus,
  date: rowData.date,
  mpn: rowData.mpn,
  poNumber: rowData.poNumber,
  deductStatus: rowData.deductStatus,
  qty: rowData.qty,
  receivingCode: rowData.receivingCode,
  unit: rowData.unit,
  usePaymentAgent: rowData.usePaymentAgent,
  vendor: rowData.vendor,
  vendorPaymentDue: rowData.vendorPaymentDue,
  vendorPaymentPaid: rowData.vendorPaymentPaid,
  memo: rowData.memo,
})

export default getInitialValue
