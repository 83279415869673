const getInitialValue = (rowData) => {
  const initialValues = {
    id: rowData.id,
    type: rowData.type,
    customer: rowData.customer,
    poNumber: rowData.poNumber,
    date: rowData.date,
    qty: rowData.qty,
    openQty: rowData.openQty,
    mfr: rowData.mfr,
    unit: rowData.unit,
    mpn: rowData.mpn,
    hpn: rowData.hpn,
    cpn: rowData.cpn,
    price: rowData.price,
    priceCurrency: rowData.priceCurrency,
    reqSched: rowData.reqSched,
    memo: rowData.memo,
    latestVersion: rowData.latestVersion,
  }

  return {
    ...initialValues,
    initialValues,
  }
}
export default getInitialValue
