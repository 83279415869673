import React, { useEffect } from 'react'

import { FormField, ProductAutoComplete } from '../../components/Form/FormField'

import usePrevious from '../../hooks/usePrevious'
import { Form } from 'formik'
import config from '../../parameters'

const ItemComponent = ({
  values,
  handleBlur,
  index,
  handleChange,
  setFieldValue,
  formStyle,
  item: currentItem,
  userGroups: groups,
}) => {
  const prevPoNumber = usePrevious(currentItem.poNumber)

  const prevHpn = usePrevious(currentItem.hpn)

  const prevPrice = usePrevious(currentItem.price)

  useEffect(() => {
    const func = async () => {
      if (currentItem.hpn && currentItem.hpn !== prevHpn) {
        const itemSelectablePos = [
          ...new Set(
            values.selectableShippings
              .filter((x) => x.hpn === currentItem.hpn)
              .map((y) => y.poNumber),
          ),
        ]

        setFieldValue(`items[${index}]`, {
          ...currentItem,
          itemSelectablePos,
          poNumber: itemSelectablePos.length === 1 && itemSelectablePos[0],
        })
      }
      if (!currentItem.hpn && currentItem.hpn !== prevHpn) {
        setFieldValue(`items[${index}]`, {
          itemSelectablePos: [],
          itemSelectablePrices: [],
        })
      }

      if (currentItem.poNumber && currentItem.poNumber !== prevPoNumber) {
        const itemSelectablePrices = [
          ...new Set(
            values.selectableShippings
              .filter(
                (x) =>
                  x.poNumber === currentItem.poNumber &&
                  x.hpn === currentItem.hpn,
              )
              .map((y) => y.price),
          ),
        ]

        // console.log('items', itemSelectablePrices)
        setFieldValue(`items[${index}]`, {
          ...currentItem,
          itemSelectablePrices,
          price: itemSelectablePrices.length === 1 && itemSelectablePrices[0],
        })
      }
      if (currentItem.price && currentItem.price !== prevPrice) {
        const itemSelectedShippingItem = values.selectableShippings.filter(
          (x) =>
            x.poNumber === currentItem.poNumber &&
            x.hpn === currentItem.hpn &&
            x.price === currentItem.price,
        )

        setFieldValue(`items[${index}]`, {
          ...currentItem,
          poNumber:
            itemSelectedShippingItem && itemSelectedShippingItem[0].poNumber,
          unit: itemSelectedShippingItem && itemSelectedShippingItem[0].unit,
          qty: itemSelectedShippingItem && itemSelectedShippingItem[0].qty,
          mpn: itemSelectedShippingItem && itemSelectedShippingItem[0].mpn,
          cpn: itemSelectedShippingItem && itemSelectedShippingItem[0].cpn,
          mfr: itemSelectedShippingItem && itemSelectedShippingItem[0].mfr,
          priceCurrency:
            itemSelectedShippingItem &&
            itemSelectedShippingItem[0].priceCurrency,
        })
      }
    }

    func()
  }, [
    prevPoNumber,
    index,
    setFieldValue,
    prevHpn,
    prevPrice,
    currentItem,
    values,
  ])

  return (
    <Form style={formStyle} autoComplete='off'>
      <ProductAutoComplete
        id={`${values.customer}${values.items.length}`}
        index={index}
        currentItem={currentItem}
        setFieldValue={setFieldValue}
        value={
          currentItem.hpn
            ? {
                hpn: currentItem.hpn,
                cpn: currentItem.cpn,
                mfr: currentItem.mfr,
                mpn: currentItem.mpn,
              }
            : undefined
        }
        options={[...new Set(values.selectableShippings.map((x) => x.cpn))]
          .sort()
          .map((y) => {
            const prod = values.selectableShippings.find((z) => z.cpn === y)
            return {
              cpn: prod.cpn,
              mpn: prod.mpn,
              mfr: prod.mfr,
              hpn: prod.hpn,
            }
          })}
      />
      <FormField
        select
        disabled={currentItem.itemSelectablePos.length === 0}
        fieldName='poNumber'
        index={index}
        value={currentItem.poNumber || ''}
        handleBlur={handleBlur}
        handleChange={handleChange}
        options={currentItem.itemSelectablePos}
      />

      <FormField
        select
        disabled={currentItem.itemSelectablePrices.length === 0}
        fieldName='price'
        index={index}
        value={currentItem.price || ''}
        handleBlur={handleBlur}
        handleChange={handleChange}
        options={currentItem.itemSelectablePrices}
      />

      <FormField
        disabled={!currentItem.price}
        fieldName='qty'
        index={index}
        value={currentItem.qty}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />

      <FormField
        select
        fieldName='unit'
        index={index}
        value={currentItem.unit}
        handleBlur={handleBlur}
        handleChange={handleChange}
        options={config.units}
      />

      <FormField
        fieldName='amountExclVat'
        value={
          currentItem.price &&
          currentItem.qty &&
          currentItem.price * currentItem.qty
        }
      />
    </Form>
  )
}

export default ItemComponent
