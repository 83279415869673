import React, { useState } from 'react'
import { IconButton, Typography, Box } from '@mui/material'
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material'
import PaymentRecord from '../../components/Table/collapse/PaymentRecord'
import InvoiceRecord from '../../components/Table/collapse/InvoiceRecord'
import FulfillmentRecord from '../../components/Table/collapse/FulfillmentRecord'

const DetailPanel = ({ rowData }) => {
  const [openPaymentRecord, setOpenPaymentRecord] = useState(false)
  const [openInvoiceRecord, setOpenInvoiceRecord] = useState(false)

  const SubItemMenu = () => (
    <div>
      <Typography
        variant='h6'
        gutterBottom
        component='span'>
        <IconButton
          aria-label='expand row'
          size='small'
          onClick={async () => {
            setOpenInvoiceRecord(false)
            setOpenPaymentRecord(!openPaymentRecord)
          }}>
          {openPaymentRecord ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
        付款记录
      </Typography>
      <Typography
        variant='h6'
        gutterBottom
        component='span'>
        <IconButton
          aria-label='expand row'
          size='small'
          onClick={async () => {
            setOpenPaymentRecord(false)
            setOpenInvoiceRecord(!openInvoiceRecord)
          }}>
          {openInvoiceRecord ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
        发票记录
      </Typography>
    </div>
  )

  return (
    <Box m={3}>
      <SubItemMenu />
      {openPaymentRecord && (
        <PaymentRecord
          paymentType='outgoingPayment'
          invoiceNumber={rowData.invoiceNumber}
          isForwarderPO={false}
          vendor={rowData.vendor}
        />
      )}
      {openInvoiceRecord && (
        <InvoiceRecord
          invoiceType='incomingInvoice'
          invoiceNumber={rowData.invoiceNumber}
        />
      )}
    </Box>
  )
}

export default DetailPanel
