import React, { useEffect } from 'react'
import usePrevious from '../../hooks/usePrevious'
import { Form } from 'formik'
import { FormField, ProductAutoComplete } from '../../components/Form/FormField'
import handleDSOperation from '../../functions/handleDSOperation'

const SpoEditMainComponent = (props) => {
  const {
    values,
    submitForm,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    setFieldValue,
    initialValues,
    setValues,
    isSubmitting,
    formStyle,
  } = props

  const prevPoNumber = usePrevious(values.poNumber)
  const prevProduct = usePrevious(values.product)

  useEffect(() => {
    const func = async () => {
      // const currentPoNumber = values.poNumber

      if (!values.allSPOs) {
        const getSpos = await handleDSOperation('spo', 'query', {
          version: { predicate: 'eq', value: 0 },
          openQty: { predicate: 'gt', value: 0 },
        })

        setValues({
          ...initialValues,
          allSPOs: getSpos.map((x) => ({
            poNumber: x.poNumber,
            vendor: x.vendor,
            mpn: x.mpn,
            hpn: x.hpn,
            cpn: x.cpn,
            openQty: x.openQty,
            orderDate: x.date,
            reqSched: x.reqSched,
          })),
          selection: [...new Set(getSpos.map((x) => x.hpn))].map((y) => {
            const prod = getSpos.find((z) => z.hpn === y)
            return {
              mpn: prod.mpn,
              cpn: prod.cpn,
              mfr: prod.mfr,
              hpn: y,
            }
          }),
        })
      }

      if (values.product !== prevProduct && values.allSPOs) {
        const pos = values.allSPOs.filter((x) => x.hpn === values.product.hpn)
        let poNumber
        if (pos.length === 1) {
          poNumber = pos[0].poNumber
        }
        setValues({
          ...values,
          product: values.product,
          poOptions: pos,
          poNumber,
        })
      }

      if (
        values.poNumber &&
        values.poNumber !== prevPoNumber &&
        values.poOptions
      ) {
        const getPoItems = values.poOptions.filter(
          (x) => x.poNumber === values.poNumber,
        )
        let getScheds = await handleDSOperation('spoSched', 'query', {
          version: { predicate: 'eq', value: 0 },
          poNumber: { predicate: 'eq', value: values.poNumber },
          openQty: { predicate: 'gt', value: 0 },
        })

        // console.log('getScheds', getScheds)
        // debugger

        let scheds
        if (getScheds.length === 0) {
          scheds = getPoItems.map((x) => ({
            cpn: x.cpn,
            mpn: x.mpn,
            qty: x.openQty,
            // openQty: x.openQty,
            estSched: '',
          }))
        } else {
          scheds = getScheds.map((x) => ({
            cpn: x.cpn,
            mpn: x.mpn,
            qty: x.openQty,
            // openQty: x.openQty,
            estSched: x.estSched,
          }))
        }

        setValues({
          ...values,
          date: getPoItems[0].date,
          cpn: getPoItems[0].cpn,
          mpn: getPoItems[0].mpn,
          mfr: getPoItems[0].mfr,
          unit: getPoItems[0].unit,
          vendor: getPoItems[0].vendor,
          orderDate: getPoItems[0].orderDate,
          orderOpenQty: getPoItems.reduce((a, v) => a + v.openQty, 0),
          items: scheds,
        })
      }
    }
    func()
  }, [
    setFieldValue,
    setValues,
    initialValues,
    values,
    prevPoNumber,
    prevProduct,
  ])

  return (
    <>
      <Form style={formStyle} autoComplete='off'>
        <ProductAutoComplete
          options={values.selection}
          setFieldValue={setFieldValue}

        />

        <FormField
          disabled={!values.product}
          fieldName='poNumber'
          value={values.poNumber}
          select
          options={[...new Set(values.poOptions.map((x) => x.poNumber))]}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />

        <FormField disabled fieldName='vendor' value={values.vendor} />
        <FormField disabled fieldName='mpn' value={values.mpn} />
        <FormField disabled fieldName='cpn' value={values.cpn} />
        <FormField disabled fieldName='openQty' value={values.orderOpenQty} />
        <FormField disabled fieldName='orderDate' value={values.orderDate} />
      </Form>

    </>
  )
}

export default SpoEditMainComponent
