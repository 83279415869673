import handleDSOperation from '../handleDSOperation'
import config from '../../parameters'

const dsUpdateForwarderPOBillingStatusWithReceivingCode = async ({
  receivingCode,
  itemsByReceivingCode,
  addOrMinus,
}) => {
  console.log('receivingCode', receivingCode)
  console.log('itemsByReceivingCode', itemsByReceivingCode)
  console.log('addOrMinus', addOrMinus)

  if (!receivingCode || !itemsByReceivingCode || !addOrMinus) {
    alert('9039, argument not defined')
  }
  console.log('receiving code', receivingCode)

  const getVatInvoices = await handleDSOperation('incomingInvoice', 'query', {
    receivingCode: {
      predicate: 'eq',
      value: receivingCode,
    },
    invoiceType: { predicate: 'eq', value: 'VAT' },
  })

  const getForwarderPo = await handleDSOperation('forwarderPo', 'query', {
    receivingCode: {
      predicate: 'eq',
      value: receivingCode,
    },
  })

  const forwarderPOsAmountWithVatByItems = getForwarderPo.map(
    (x) =>
      (x.amountExclVat || x.cost * x.qty) * x.exchangeRate +
      x.duty +
      x.vat +
      x.handlingFee +
      x.extraCharge,
  )

  const invoicesAmountWithVatByItems = getVatInvoices.map(
    (x) => x.amountExclVat * (1 + config.vatRate),
  )

  const forwarderPoAmount = forwarderPOsAmountWithVatByItems.reduce(
    (a, v) => a + v * 1,
    0,
  )

  const billedAmount = invoicesAmountWithVatByItems.reduce(
    (a, v) => a + v * 1,
    0,
  )

  let sessionAmountWithVat = itemsByReceivingCode.reduce(
    (a, v) => a + v.amountExclVat * (1 + config.vatRate),
    0,
  )

  console.log(
    'forwarderPOsAmountWithVatByItems',
    forwarderPOsAmountWithVatByItems,
  )
  console.log('invoicesAmountWithVatByItems', invoicesAmountWithVatByItems)

  console.log('getVatInvoices', getVatInvoices)
  console.log('getForwarderPo', getForwarderPo)
  console.log(
    'forwarderPOsAmountWithVatByItems',
    forwarderPOsAmountWithVatByItems,
  )

  console.log('billedAmount', billedAmount)
  console.log('forwarderPoAmount', forwarderPoAmount)
  console.log('sessionAmountWithVat', sessionAmountWithVat)

  sessionAmountWithVat =
    addOrMinus === 'add' ? sessionAmountWithVat : 0 - sessionAmountWithVat

  debugger
  let billingStatus
  const invoiceValueDiff = Math.abs(
    billedAmount + sessionAmountWithVat - forwarderPoAmount,
  )

  // console.log('invoiceValueDiff', invoiceValueDiff)

  if (billedAmount + sessionAmountWithVat === 0) {
    billingStatus = 'unbilled'
  } else if (invoiceValueDiff < 2) {
    billingStatus = 'billed'
  } else if (billedAmount + sessionAmountWithVat < forwarderPoAmount) {
    billingStatus = 'partial'
  } else {
    return alert(
      `${receivingCode} billing amount is greater than forwarder po amount`,
    )
  }

  // console.log('billingStatus', billingStatus)
  await Promise.all(
    getForwarderPo.map(async (x) => {
      await handleDSOperation(null, 'update', {
        id: x.id,
        billingStatus,
      })
    }),
  )
}

export default dsUpdateForwarderPOBillingStatusWithReceivingCode
