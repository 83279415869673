const initialValue = () => ({
  isMainContact: false,
  entityOptions: [],
  deliveryTerms: [],
  productOptions: [],
  role: [],
  name: '',
  entity: '',
})

export default initialValue
