const initialValue = {
  vendors: [],
  poNumber: '',
  cpn: '',
  mpn: '',
  cost: '',
  costCurrency: '',
  supplierMOQ: '',
  supplierMPQ: '',
  reqSched: '',
}

export default initialValue
