import getValidRows from '../rowValidator/getValidRows'
import createPoItemAndGetId from '../APIOperations/createPoItemAndGetId'
import poObjGetValue from '../rowValidator/poObjGetValue'
import groupArrayofObjectsByKey from '../helpFunc/groupArrayofObjectsByKey'
import createNewVersionsOfPO from '../APIOperations/createNewVersionsOfPO'
import isPoItemsofSpecificCPNChanged from '../rowValidator/isPoItemsofSpecificCPNChanged'
import flexUpdatePoItemsAndCreatePO from '../APIOperations/flexUpdatePoItemsAndCreatePO'
import updateAllCPOSchedOnCPN from '../APIOperations/updateAllCPOSchedOnCPN'
import restAPICalls from '../restAPICalls'
import generateSPONumberAndPIDForRowItems from '../APIOperations/generateSPONumberAndPIDForRowItems'

import getProductInfoForNewRowItems from '../APIOperations/getProductInfoForNewRowItems'
import isPoFieldNull from '../rowValidator/isPoFieldNull'
import isPoExistingAndGetLatestVersionAndRecord from '../rowValidator/isPoExistingAndGetLatestVersionAndRecord'
import dsCreatePoItem from '../APIOperations/dsCreatePoItem'
import handleDSOperation from '../handleDSOperation'

const handlePO = async (rows) => {
  const groupedPOByNumber = Object.entries(
    groupArrayofObjectsByKey(rows, 'poNumber'),
  )
  await Promise.all(
    groupedPOByNumber.map(async ([poNumber, arrOfItemsByPO]) => {
      const type = arrOfItemsByPO[0].type
      const isNew = arrOfItemsByPO[0].isNew

      if (isNew === 'new') {
        const latestVersion = 0
        await Promise.all(
          arrOfItemsByPO.map(async (x) => {
            await dsCreatePoItem(x, latestVersion)
          }),
        )
      } else {
        const latestRecord = arrOfItemsByPO[0].latestRecord

        const isEqual = isPoItemsofSpecificCPNChanged(
          type,
          arrOfItemsByPO,
          latestRecord,
        )

        if (!isEqual) {
          const latestVersion = latestRecord[0].latestVersion

          await Promise.all(
            arrOfItemsByPO.map(async (x) => {
              await dsCreatePoItem(x, latestVersion)
            }),
          )

          await Promise.all(
            latestRecord.map(
              async (x) => await handleDSOperation(x.type, 'delete', x.id),
            ),
          )
        }
      }
    }),
  )
}

export default handlePO
