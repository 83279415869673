import React from 'react'

import { Form } from 'formik'
import { FormField } from '../../components/Form/FormField'
import config from '../../parameters'

const EditComponent = (props) => {
  const {
    values,
    submitForm,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    setFieldValue,
    setValues,
    isSubmitting,
    formStyle,
    userGroups: groups,
  } = props

  const isAdmin = groups.map((x) => x.toLowerCase()).includes('admin')

  return (
    <Form style={formStyle} autoComplete='off'>
      <div>
        <FormField
          fieldName='customer'
          value={values.customer}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          fieldName='name'
          value={values.name}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />

        <FormField
          fieldName='email'
          value={values.email}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          fieldName='date'
          value={values.date}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          fieldName='number'
          value={values.number}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
      </div>
      <div>
        <FormField
          fieldName='cpn'
          value={values.cpn}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          fieldName='mfr'
          value={values.mfr}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          fieldName='mpn'
          value={values.mpn}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          fieldName='priceCurrency'
          select
          value={values.priceCurrency}
          handleBlur={handleBlur}
          handleChange={handleChange}
          options={config.currencies.map((x) => x.value)}
        />

        <FormField
          fieldName='price'
          value={values.price}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
      </div>

      <div>
        <FormField
          fieldName='customerMPQ'
          value={values.customerMPQ}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          fieldName='customerMOQ'
          value={values.customerMOQ}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          fieldName='customerLeadtime'
          value={values.customerLeadtime}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
      </div>
      <div>
        <FormField
          fieldName='paymentTerm'
          select
          value={values.paymentTerm}
          handleBlur={handleBlur}
          handleChange={handleChange}
          options={config.paymentTerms}
        />
        <FormField
          select
          fieldName='deliveryTerm'
          value={values.deliveryTerm}
          handleBlur={handleBlur}
          handleChange={handleChange}
          options={config.shippingTerms}
        />
        <FormField
          select
          fieldName='hankenoEntity'
          value={values.hankenoEntity}
          handleBlur={handleBlur}
          handleChange={handleChange}
          options={config.hankenoEntities.map((x) => x.tag)}
        />
      </div>

      <div>
        <FormField
          multiline
          fieldName='memo'
          value={values.memo}
          handleBlur={handleBlur}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
        />
      </div>
    </Form>
  )
}

export default EditComponent
