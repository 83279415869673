import outgoingPaymentCreateItemInitialValue from './createItemInitialValue'

const initialValue = () => ({
  date: new Date().toISOString().substring(0, 10),
  customerOptions: [],
  invoices: [],
  paymentType: 'goodsPayment',
  // items: [outgoingPaymentCreateItemInitialValue],
})

export default initialValue
