import handleDSOperation from '../handleDSOperation'

const dsUpdateForwarderPOPaymentStatusWithIDAndAmount = async ({
  forwarderPOID,
}) => {
  const getForwarderPO = await handleDSOperation(
    null,
    'queryById',
    forwarderPOID,
  )

  const getPayments = await handleDSOperation('outgoingPayment', 'query', {
    forwarderPOID: { predicate: 'eq', value: forwarderPOID },
  })

  const amountPaid = getPayments.reduce((a, v) => a + v.amountExclVat * 1, 0)

  // console.log('getPayments', getPayments)
  // console.log('amountPaid', amountPaid)

  // debugger

  if (!getForwarderPO) return

  const {
    amountExclVat,
    duty,
    cost,
    qty,
    extraCharge,
    exchangeRate,
    vat,
    handlingFee,
  } = getForwarderPO

  // console.log('getForwarderPO', getForwarderPO)
  // console.log(
  //   amountExclVat,
  //   duty,
  //   cost,
  //   qty,
  //   extraCharge,
  //   exchangeRate,
  //   vat,
  //   handlingFee,
  // )

  const payable =
    (amountExclVat * 1 || cost * qty) * exchangeRate +
    duty * 1 +
    extraCharge * 1 +
    vat * 1 +
    handlingFee * 1

  // const amountPaid =
  //   (cnyValueChecked && cnyValue * 1) +
  //   (handlingAndTaxChecked && handlingAndTax * 1)

  // console.log('yyyyyy', y)
  // console.log('payable', payable)
  // console.log('amountPaid', amountPaid)

  let paymentStatus = 'unpaid'

  console.log('8888', Math.abs(amountPaid - payable) < 1)

  if (Math.abs(amountPaid - payable) < 1) {
    paymentStatus = 'paid'
  } else if (amountPaid === 0) {
    paymentStatus = 'unpaid'
  } else if (amountPaid < payable) {
    paymentStatus = 'partial'
  } else {
    alert('付款金额大于应付金额')
  }

  await handleDSOperation(null, 'update', {
    id: forwarderPOID,
    paymentStatus,
  })
}

export default dsUpdateForwarderPOPaymentStatusWithIDAndAmount
