import forwarderPoCreateItemInitialValue from './createItemInitialValue'

const initialValue = () => {
  const leadtime = 24
  const transitDays = 7
  const leadtimeBufferWeeks = 8
  const customerLeadtime = (leadtime + leadtimeBufferWeeks) * 7 + transitDays

  return {
    cost: '',
    costCurrency: '',
    price: '',
    priceCurrency: '',
    productType: '',
    cpn: '',
    hpn: '',
    mfr: '',
    mpn: '',
    initialInventory: '',
    currentInventory: '',
    safetyInventory: '',
    coo: [],
    leadtime,
    customerLeadtime,
    customerMPQ: '',
    customerMOQ: '',
    supplierMPQ: '',
    supplierMOQ: '',
    transitDays,
    unit: 'PCS',
    vendors: [],
    memo: '',
    spec: [],
    vendorOptions: [],
    isActive: true,
  }
}

export default initialValue
