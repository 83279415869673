const mapping = {
  enhanced: [
    'actions',
    'memo',
    'isActive',
    'number',
    'entity',
    'vendorOrCtmCode',
    'hankenoEntity',
    'paymentTerm',
    'deliveryTerms',
    // 'mainContactName',
    // 'mainContactRole',
    // 'mainContactPhoneNumber',
    // 'mainContactEmail',
    'billingInfo',
  ],
  simple: [
    'number',
    'entity',
    'paymentTerm',
    'deliveryTerms',
    'mainContactName',
    'mainContactRole',
    'mainContactPhoneNumber',
    'mainContactEmail',
    'hankenoEntity',
    'billingInfo',
  ],
  collasped: [
    'number',
    'entity',
    'hankenoEntity',
    'paymentTerm',
    'deliveryTerm',
  ],
}

export default mapping
