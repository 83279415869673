import * as Yup from 'yup'
import {
  vendor,
  qty,
  cpn,
  mpn,
  reqSched,
  cost,
  poNumber,
  date,
} from '../../functions/getValidations'

export const itemValidationShape = {
  vendor,
  cpn,
  cost,
  reqSched,
  mpn,
  qty,
}

const formValidationSchema = {
  items: Yup.array().of(Yup.object(itemValidationShape)),
}

export default formValidationSchema
