import dsDeleteFulfillments from '../../functions/APIOperations/dsDeleteFulfillments'
import dsUpdateInventory from '../../functions/APIOperations/dsUpdateInventory'
import dsUpdateCPOQtyAfterDeleteShipments from '../../functions/APIOperations/dsUpdateCPOQtyAfterDeleteShipments'

const onRowDelete = async (oldData) => {
  await dsDeleteFulfillments([oldData])

  await dsUpdateCPOQtyAfterDeleteShipments([oldData])

  await dsUpdateInventory([oldData], 'add')
}

export default onRowDelete
