import handleDSOperation from '../../functions/handleDSOperation'

const onRowDelete = async (oldData) => {


  
  const spos = await handleDSOperation('spo', 'query', {
    poNumber: { predicate: 'eq', value: oldData.poNumber },
  })
  const scheds = await handleDSOperation('spoSched', 'query', {
    poNumber: { predicate: 'eq', value: oldData.poNumber },
  })

  await Promise.all(
    spos.map(async (x) => {
      await handleDSOperation(null, 'delete', x.id)
    }),
  )

  await Promise.all(
    scheds.map(async (x) => {
      await handleDSOperation(null, 'delete', x.id)
    }),
  )
}

export default onRowDelete
