import handleDSOperation from '../handleDSOperation'

const dsUpdatePaymentStatusOnInvoices = async ({
  invoiceNumber,
  customer,
  amountReceived = 0,
}) => {
  // if (!invoiceNumber || !customer || !amountReceived) {
  //   alert('9032, argument not defined')
  // }
  const getInvoices = await handleDSOperation('outgoingInvoice', 'query', {
    invoiceNumber: {
      predicate: 'eq',
      value: invoiceNumber,
    },
    customer: { predicate: 'eq', value: customer },
  })
  const totalBilled = getInvoices.reduce((a, v) => a + v.price * v.qty, 0)

  const getPayments = await handleDSOperation('incomingPayment', 'query', {
    invoiceNumber: { predicate: 'eq', value: invoiceNumber },
    customer: { predicate: 'eq', value: customer },
  })
  const totalPaid = getPayments.reduce((a, v) => a + v.amountExclVat * 1, 0)

  // console.log(
  //   'invoiceNumber,customer,amountReceived',
  //   invoiceNumber,
  //   customer,
  //   amountReceived,
  // )
  // console.log('getInvoices', getInvoices)
  // console.log('getPayments', getPayments)

  // console.log('totalBilled', totalBilled)
  // console.log('totalPaid', totalPaid)
  // console.log('amountReceived', amountReceived)

  // debugger

  let paymentStatus = 'paid'

  if (totalPaid === 0) {
    paymentStatus = 'unpaid'
  } else if (totalPaid + amountReceived < totalBilled) {
    paymentStatus = 'partial'
  } else if (totalPaid + amountReceived > totalBilled + 0.01) {
    alert(`${invoiceNumber} 总付款金额大于发票金额`)
  }

  await Promise.all(
    getInvoices.map(async (x) => {
      await handleDSOperation(null, 'update', {
        id: x.id,
        paymentStatus,
      })
    }),
  )
}

export default dsUpdatePaymentStatusOnInvoices
