import React from 'react'
import {
  Box,
  Typography,
  Toolbar,
  FormControlLabel,
  Switch,
  Stack,
} from '@mui/material'
import SimpleTable from '../SimpleTable'

const SPOsPerVendor = ({ obj, index, itemsByVendor, setItemsByVendor }) => {
  const { entity: vendor, deliveryTerm, orderBy, poItems } = obj

  return (
    <Box m={5}>
      <Toolbar>

        <Stack direction='row' spacing={3}>
          <Typography component='span' variant='h6'>
            Vendor: {vendor}
          </Typography>
          <Typography component='span' variant='h6'>
            Hankeno Entity: {orderBy.tag}
          </Typography>
          <Typography component='span' variant='h6'>
            DeliveryTerm: {deliveryTerm}
          </Typography>
        </Stack>

        <div style={{ marginLeft: 'auto' }}>
          <FormControlLabel
            control={
              <Switch
                checked={obj.sendEmail}
                onChange={() => {
                  const newArr = JSON.parse(JSON.stringify(itemsByVendor))
                  newArr[index] = {
                    ...newArr[index],
                    sendEmail: !newArr[index].sendEmail,
                  }
                  setItemsByVendor(newArr)
                }}
                name='sendEmail'
              />
            }
            label='是否自动发送邮件?'
          />
        </div>
      </Toolbar>

      <SimpleTable dataType='spo' rows={poItems} tbType='preview' />
    </Box>
  )
}
export default SPOsPerVendor
