import _ from 'lodash'
import { firstBy } from 'thenby'

const isPoItemsofSpecificCPNChanged = (type, currentPoItems, latestPoItems) => {
  // const oldItems = latestPoItems.filter((x) => x.openQty > 0)
  // console.log('type', type)
  // // console.log('pid', pid)
  // // console.log('cpn', cpn)
  const poNumber = currentPoItems[0].poNumber
  let attr
  if (type === 'cpo') {
    attr = 'reqSched'
  } else if (type === 'spo') {
    attr = 'estSched'
  }

  const copyOfCurrentPoItems = currentPoItems.map((x) => ({
    openQty: x.openQty * 1,
    qty: x.qty,
    [attr]: x[attr],
  }))

  const copyOfLastestPoItems = latestPoItems
    .filter((x) => x[attr])
    .map((x) => ({
      openQty: x.openQty * 1,
      qty: x.qty,
      [attr]: x[attr],
    }))

  copyOfCurrentPoItems.sort(firstBy(attr).thenBy('openQty'))
  copyOfLastestPoItems.sort(firstBy(attr).thenBy('openQty'))

  console.log('copyOfCurrentPoItems', copyOfCurrentPoItems)
  console.log('copyOfLastestPoItems', copyOfLastestPoItems)

  const isEqual = _.isEqual(copyOfCurrentPoItems, copyOfLastestPoItems)

  if (isEqual) {
    console.log(`There are no changes on PO ${poNumber}`)
  } else {
    console.log(`There are changes on PO ${poNumber}!`)
  }
  return isEqual
}

export default isPoItemsofSpecificCPNChanged
