import handleDSOperation from '../handleDSOperation'

const dsCreateContact = async (rows) => {
  await Promise.all(
    rows.map(async (x) => {
      const inputForContactCreation = {
        entity: x.entity && x.entity.toUpperCase(),
        name: x.name,
        isMainContact: x.isMainContact,
        dept: x.dept,
        role: x.role,
        title: x.title,
        email: x.email,
        address: x.address,
        phoneNumber: x.phoneNumber,
      }

      await handleDSOperation('contact', 'create', inputForContactCreation)
    }),
  )
}

export default dsCreateContact
