import handleDSOperation from '../../functions/handleDSOperation'
import dsUpdateForwarderPOBillingStatusWithReceivingCode from '../../functions/APIOperations/dsUpdateForwarderPOBillingStatusWithReceivingCode'
import dsUpdateSPOBillingStatus from '../../functions/APIOperations/dsUpdateSPOBillingStatus'
const onRowDelete = async (oldData) => {
  if (oldData.receivingCode && oldData.invoiceType === 'VAT') {
    await dsUpdateForwarderPOBillingStatusWithReceivingCode({
      receivingCode: oldData.receivingCode,
      itemsByReceivingCode: [oldData],
      addOrMinus: 'minus',
    })
  } else {
    await dsUpdateSPOBillingStatus([oldData], 'minus')
  }
  await handleDSOperation(null, 'delete', oldData.id)
}

export default onRowDelete
