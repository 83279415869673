import React from 'react'
import {
  FormField,
  SwitchField,
  SelectField,
} from '../../components/Form/FormField'

import { Form } from 'formik'
import config from '../../parameters'

const EditComponent = (props) => {
  const {
    values,
    submitForm,
    errors,
    touched,
    handleChange,
    setFieldValue,
    handleSubmit,
    handleBlur,
    formStyle,
    userGroups: groups,
  } = props

  return (
    <>
      <Form style={formStyle} autoComplete='off'>
        <div>
          <SwitchField
            fieldName='isActive'
            handleBlur={handleBlur}
            handleChange={handleChange}
            checked={values.isActive}
          />
        </div>
        <div>
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='entity'
            value={values.entity}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <FormField
            disabled={!groups.includes('admin')}
            select
            fieldName='entityType'
            value={values.entityType}
            handleChange={handleChange}
            handleBlur={handleBlur}
            options={config.entityType}
          />
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='number'
            value={values.number}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />

          <FormField
            select
            disabled={!groups.includes('admin')}
            fieldName='hankenoEntity'
            value={values.hankenoEntity}
            handleChange={handleChange}
            handleBlur={handleBlur}
            options={config.hankenoEntities.map((x) => x.tag)}
          />
        </div>
        <div>
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='vendorOrCtmCode'
            value={values.vendorOrCtmCode}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />

          <FormField
            select
            disabled={!groups.includes('admin')}
            fieldName='paymentTerm'
            value={values.paymentTerm}
            handleChange={handleChange}
            handleBlur={handleBlur}
            options={config.paymentTerms}
          />

          <SelectField
            fieldName='deliveryTerms'

            value={values.deliveryTerms}
            handleChange={handleChange}
            options={config.shippingTerms}
          />
        </div>
        <div>
          <FormField
            fieldName='memo'
            multiline
            value={values.memo}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
          />
        </div>
        <div>
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='billingInfo'
            value={values.billingInfo}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </div>
      </Form>
    </>
  )
}

export default EditComponent
