import React from 'react'

const CreateMainComponent = (props) => {
  const {
    values,
    submitForm,
    errors,
    touched,
    initialValues,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    setFieldValue,
    setValues,
    isSubmitting,
    formStyle,
  } = props
  return <p>main component</p>
  // const prevInvoiceType = usePrevious(values.invoiceType)
  // const prevVendor = usePrevious(values.vendor)

  // useEffect(() => {
  //   const func = async () => {
  //     if (values.invoiceType && values.invoiceType !== prevInvoiceType) {
  //       // console.log('fetch vendors')
  //       let vendors
  //       switch (values.invoiceType) {
  //         case 'VAT':
  //         case 'RECEIPT':
  //           const getVendors = await handleDSOperation('vendor', 'query', {
  //             hankenoEntity: { predicate: 'eq', value: 'HKNCN' },
  //           })
  //           vendors = getVendors.map((x) => x.entity)
  //           break
  //         case 'PI':
  //           const getSpos = await handleDSOperation('spo', 'query', {
  //             version: { predicate: 'eq', value: 0 },
  //             costCurrency: { predicate: 'ne', value: 'CNY' },
  //             openQty: { predicate: 'gt', value: 0 },
  //           })
  //           vendors = getSpos.map((x) => x.vendor)
  //           break
  //         default:
  //           break
  //       }
  //       setFieldValue('vendorOptions', [...new Set(vendors)])
  //     }

  //     if (values.vendor && values.vendor !== prevVendor) {
  //       let isForwarder
  //       let retrivedPos
  //       let netDays
  //       let vendorPaymentDue
  //       let usePaymentAgent

  //       const getVendor = await handleDSOperation('vendor', 'query', {
  //         entity: { predicate: 'eq', value: values.vendor },
  //       })

  //       if (getVendor.length > 0) {
  //         if (getVendor[0].entityType === 'FORWARDER') {
  //           isForwarder = true
  //         }

  //         usePaymentAgent = getVendor[0].usePaymentAgent || false
  //         const paymentTerm = getVendor[0].paymentTerm

  //         if (paymentTerm.startsWith('NET')) {
  //           netDays = paymentTerm.split(' ')[1]
  //         } else if (paymentTerm === 'PAYMENT IN ADVANCE') {
  //           netDays = 1
  //         }

  //         vendorPaymentDue = new Date(
  //           new Date().getTime() + netDays * 24 * 60 * 60 * 1000,
  //         )
  //           .toISOString()
  //           .substring(0, 10)
  //       }

  //       if (values.invoiceType !== 'PI') {
  //         const query = isForwarder
  //           ? { receivingCode: { predicate: 'ne', value: '' } }
  //           : { vendor: { predicate: 'eq', value: values.vendor } }

  //         retrivedPos = await handleDSOperation('receiving', 'query', query)
  //       } else {
  //         retrivedPos = await handleDSOperation('spo', 'query', {
  //           vendor: { predicate: 'eq', value: values.vendor },
  //           version: { predicate: 'eq', value: 0 },
  //           openQty: { predicate: 'gt', value: 0 },
  //         })
  //       }

  //       setValues({
  //         ...initialValues,
  //         vendorOptions: values.vendorOptions,
  //         usePaymentAgent,
  //         vendorPaymentDue: vendorPaymentDue,
  //         invoiceType: values.invoiceType,
  //         vendor: values.vendor,
  //         poOptions: retrivedPos.map((x) => ({
  //           id: x.id,
  //           hpn: x.hpn,
  //           cpn: x.cpn,
  //           mpn: x.mpn,
  //           mfr: x.mfr,
  //           poNumber: x.poNumber,
  //           fulfillmentNumber: x.fulfillmentNumber,
  //           qty: x.qty,
  //           openQty: x.openQty,
  //           receivingCode: x.receivingCode,
  //           vendor: x.vendor,
  //           unit: x.unit,
  //           cost: x.cost,
  //           costCurrency: x.costCurrency,
  //           amountExclVat: x.amountExclVat,
  //         })),
  //       })
  //     }
  //   }
  //   func()
  // }, [
  //   values,
  //   prevVendor,
  //   prevInvoiceType,
  //   setValues,
  //   initialValues,
  //   setFieldValue,
  // ])

  // const totalQty = values.items.reduce((a, v) => (v.qty ? a + v.qty : a), 0)
  // const totalAmountExclVat = values.items.reduce(
  //   (a, v) => (v.amountExclVat ? a + v.amountExclVat * 1 : a),
  //   0,
  // )

  // let totalAmountInclVat

  // if (values.invoiceType !== 'PI') {
  //   totalAmountInclVat =
  //     totalAmountExclVat && totalAmountExclVat * (1 + config.vatRate)
  // }

  // console.log('totalAmountExclVat', totalAmountExclVat)

  // return (
  //   <>
  //     <Form className={classes.formRoot}>
  //       <div>
  //         <FormControl component='fieldset'>
  //           <FormLabel component='legend'>发票类型</FormLabel>
  //           <div style={{ display: 'inline' }}>
  //             <RadioGroup
  //               style={{ display: 'inline-block', width: 400 }}
  //               row
  //               aria-label='invoiceType'
  //               name='invoiceType'
  //               value={values.invoiceType}
  //               onChange={(e) => {
  //                 resetForm()
  //                 handleChange(e)
  //               }}>
  //               <FormControlLabel
  //                 value='VAT'
  //                 control={<Radio />}
  //                 label='增值税发票'
  //               />
  //               <FormControlLabel
  //                 value='RECEIPT'
  //                 control={<Radio />}
  //                 label='收据'
  //               />
  //               <FormControlLabel
  //                 value='PI'
  //                 control={<Radio />}
  //                 label='形式发票'
  //               />
  //             </RadioGroup>

  //             {values.invoiceType === 'PI' && (
  //               <FormControlLabel
  //                 control={
  //                   <Switch
  //                     name='usePaymentAgent'
  //                     // disabled={isDisabled}
  //                     checked={values.usePaymentAgent}
  //                     onChange={handleChange}
  //                     // onChange={(e) => {
  //                     //   setUsePaymentAgent(true)
  //                     //   handleChange(e)
  //                     // }}
  //                   />
  //                 }
  //                 label='是否使用EZPAY'
  //               />
  //             )}
  //           </div>
  //         </FormControl>
  //       </div>
  //       <div>
  //         <TextField
  //           select
  //           id='vendor'
  //           name='vendor'
  //           value={values.vendor}
  //           style={{ width: 300 }}
  //           label='供应商'
  //           onChange={handleChange}>
  //           {values.vendorOptions.map((x, i) => (
  //             <MenuItem key={i} value={x}>
  //               {x}
  //             </MenuItem>
  //           ))}
  //         </TextField>

  //         <TextField
  //           id='date'
  //           InputLabelProps={{ shrink: true }}
  //           style={{ width: 140 }}
  //           type='date'
  //           value={values.date}
  //           label='发票日期'
  //           onChange={handleChange}
  //         />

  //         <TextField
  //           // id='invoiceNumber'
  //           name='invoiceNumber'
  //           style={{ width: 120 }}
  //           value={values.invoiceNumber}
  //           label='发票号'
  //           onChange={handleChange}
  //           onBlur={handleBlur}
  //         />
  //         {totalQty > 0 && (
  //           <span style={{ marginLeft: 50 }}>
  //             <Typography
  //               color='secondary'
  //               component='span'
  //               variant='h6'
  //               align='center'>
  //               数量: {totalQty.toFixed(2)}
  //             </Typography>
  //           </span>
  //         )}
  //         {totalAmountExclVat > 0 && (
  //           <span style={{ marginLeft: 50 }}>
  //             <Typography
  //               color='secondary'
  //               component='span'
  //               variant='h6'
  //               align='center'>
  //               未税金额: {totalAmountExclVat.toFixed(2)}
  //             </Typography>
  //           </span>
  //         )}
  //         {totalAmountInclVat > 0 && (
  //           <span style={{ marginLeft: 50 }}>
  //             <Typography
  //               color='secondary'
  //               component='span'
  //               variant='h6'
  //               align='center'>
  //               含税金额: {totalAmountInclVat.toFixed(2)}
  //             </Typography>
  //           </span>
  //         )}
  //       </div>
  //     </Form>
  //   </>
  // )
}

export default CreateMainComponent
