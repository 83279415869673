import {
  date,
  cpn,
  mpn,
  customer,
  qty,
  openQty,
  price,
  poNumber,
  fulfillmentNumber,
} from '../../functions/getValidations'

const formValidationSchema = {
  date,
  customer,
  fulfillmentNumber,
  cpn,
  mpn,
  qty,
  // openQty,
  // price,
  poNumber,
}

export default formValidationSchema
