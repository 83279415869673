import handleDSOperation from '../../../functions/handleDSOperation'
import config from '../../../parameters'

const handleMiscFee = async ({ date, month, amount, paymentType }) => {
  if (!amount || !month) {
    return alert('9800, incomplete parameters')
  }
  const obj = {
    date,
    paymentType,
    invoiceType: 'forwarderPo',
    amountExclVat: amount * 1,
    vendor: config.forwarder.name,
    costCurrency: 'CNY',
    desc: `2021${month}`,
  }

  await handleDSOperation('outgoingPayment', 'create', obj)
}

export default handleMiscFee
