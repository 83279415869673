import dsUpdatePoItem from '../../functions/APIOperations/dsUpdatePoItem'
import handleDSOperation from '../../functions/handleDSOperation'
import _ from 'lodash'

export default async function onRowUpdate(newData) {
  try {
    const { initialValues, ...newDataObj } = newData
    const propertiesToTriggerVersionUpdate = ['cost', 'qty', 'reqSched']

    const oldObj = propertiesToTriggerVersionUpdate.reduce((a, v) => {
      a[v] = isNaN(initialValues[v])
        ? initialValues[v]
        : (a[v] = initialValues[v] * 1)
      return a
    }, {})
    const newObj = propertiesToTriggerVersionUpdate.reduce((a, v) => {
      a[v] = isNaN(newDataObj[v]) ? newDataObj[v] : (a[v] = newDataObj[v] * 1)
      return a
    }, {})

    if (_.isEqual(oldObj, newObj)) {
      await handleDSOperation(null, 'update', newDataObj)
    } else {
      await dsUpdatePoItem(newDataObj)
    }
  } catch (err) {
    console.log('err65544', err)
  }
}
