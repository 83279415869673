const getDeductedItems = (
  fulfillmentItem,
  poItems,
  qtyAttr,
  openQtyAttr,
  isSchedule,
) => {
  const updatedItems = []

  let qty = fulfillmentItem[qtyAttr]

  for (let poItem of poItems) {
    // console.log('fulfillmentItem', fulfillmentItem)

    // console.log('poItem', poItem)
    // debugger

    if (qty >= 0) {
      const fulfilledQty = Math.min(qty, poItem[openQtyAttr])
      const newObj = {
        ...fulfillmentItem,
        changedQty: fulfilledQty,
        id: poItem.id,
        qty: poItem.qty,
        openQty: poItem.openQty,
        poNumber: fulfillmentItem.poNumber || poItem.poNumber,
        vendor: poItem.vendor || fulfillmentItem.vendor,
        reqSched: poItem.reqSched,
        estSched: poItem.estSched,
      }
      updatedItems.push(newObj)
      qty = qty - fulfilledQty

      if (!isSchedule) {
        if (qty <= 0) break
      }
    }
  }
  return updatedItems
}

export default getDeductedItems
