import handleDSOperation from '../../functions/handleDSOperation'

const onRowUpdate = async (newData) => {
  const input = {
    id: newData.id,
    entity: newData.entity && newData.entity.toUpperCase(),
    number: newData.number,
    entityType: newData.entityType,
    paymentTerm: newData.paymentTerm,
    deliveryTerms: newData.deliveryTerms,
    memo: newData.memo,
    hankenoEntity: newData.hankenoEntity,
    billingInfo: newData.billingInfo,
    vendorOrCtmCode: newData.vendorOrCtmCode,
    isActive: newData.isActive || false,
  }

  console.log('input', input)
  await handleDSOperation(null, 'update', input)
}

export default onRowUpdate
