import handleGoodsPayment from './handleSubmit/handleGoodsPayment'
import handleForwarderPayment from './handleSubmit/handleForwarderPayment'
import handleMiscFee from './handleSubmit/handleMiscFee'
import handleShippingFee from './handleSubmit/handleShippingFee'

const handleSubmit = async (values) => {
  try {
    let onSubmit = () => console.log('onSumbit')

    switch (values.paymentType) {
      case 'goodsPayment':
        onSubmit = handleGoodsPayment
        break
      case 'forwarderPayment':
        onSubmit = handleForwarderPayment
        break
      case 'shippingFee':
        onSubmit = handleShippingFee
        break
      case 'miscFee':
        onSubmit = handleMiscFee
        break
      default:
        break
    }

    onSubmit(values)
  } catch (err) {
    console.log('err 8893', err)
  }
}

export default handleSubmit
