import createItemInitialValue from './createItemInitialValue'

const initialValue = () => ({
  date: new Date().toISOString().substring(0, 10),
  invoiceNumber: '',
  customer: '',
  selectableShippings: [],
  items: [createItemInitialValue],
})

export default initialValue
