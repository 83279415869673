const getLineForRow = (items, filterBy) => {
  items = items.filter((x) => {
    if (x.alertMessage && x.alertMessage.map((x) => x.level).includes(5))
      return false
    return true
  })

  let list = {}
  items.forEach((x) => {
    // if (x.alertMessage.map((y) => y.level).includes(5)) return
    let sequence = 1
    const occurrence = items.reduce((a, v) => {
      return v[filterBy] === x[filterBy]
        ? // &&
          // !v.alertMessage.map((y) => y.level).includes(5)
          a + 1
        : a
    }, 0)

    if (occurrence > 1) {
      if (!list[x[filterBy]]) {
        list[x[filterBy]] = 1
      } else {
        list[x[filterBy]] = list[x[filterBy]] + 1
        sequence = list[x[filterBy]]
      }
    }
    x.line = `${sequence}/${occurrence}`
  })
}

export default getLineForRow
