import React, { useState, useEffect } from 'react'
import {
  Stack as TypographyStack,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Backdrop,
  FormGroup,
  Card,
  CircularProgress,
  FormControlLabel,
  Switch,
  Typography,
} from '@mui/material'

import { BrowserRouter as Route, useHistory, Link } from 'react-router-dom'

import { Plugin } from '@devexpress/dx-react-core'

import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  Title,
  Tooltip,
  ZoomAndPan,
  Legend,
} from '@devexpress/dx-react-chart-material-ui'

import { scaleBand } from '@devexpress/dx-chart-core'
import {
  ArgumentScale,
  Stack,
  EventTracker,
  HoverState,
  ValueScale,
  Animation,
} from '@devexpress/dx-react-chart'
// import { easeBounceOut } from 'd3-ease'
// import { scaleLinear } from 'd3-scale'
import handleDSOperation from '../functions/handleDSOperation'
import moment from 'moment'
import _ from 'lodash'
import mergeArrayAndCombineQty from '../functions/helpFunc/mergeArrayAndCombineQty'
import groupArrayofObjectsByKey from '../functions/helpFunc/groupArrayofObjectsByKey'

const Charts = ({ cpn, defaultRange, preview }) => {
  const history = useHistory()
  // const classes = useStyles()
  const [range, setRange] = useState(defaultRange)
  const [isPreview, setIsPreview] = useState(preview)
  const [schedsByWeek, setSchedsByWeek] = useState([])
  const [spoTotal, setSpoTotal] = useState(0)
  const [cpoTotal, setCpoTotal] = useState(0)
  const [currentProd, setCurrentProd] = useState({ stock: 0, mpn: '', mfr: '' })

  const [confirmedSpoTotal, setConfirmedSpoTotal] = useState(0)

  const [cpns, setCpns] = useState([])
  const [showSpos, setShowSpos] = useState(true)
  const [selected, setSelected] = useState()

  const reqSeries = (
    <BarSeries
      key='req'
      valueField='req'
      argumentField='date'
      name='req'
      color='#bcaaa4'
    />
  )

  const estSeries = (
    <BarSeries
      key='est'
      valueField='est'
      argumentField='date'
      name='est'
      color='#4dabf5'
    />
  )

  const unConfirmedSeries = (
    <BarSeries
      key='unConfirmed'
      valueField='unConfirmed'
      argumentField='date'
      name='unConfirmed'
      color='#bdbdbd'
    />
  )
  const groups = ['One', 'Two', 'Three', 'Four', 'Five']
  const qtySeries = groups.map((x, i) => {
    let color

    switch (x) {
      case 'One':
        color = '#8bc34a'
        break
      case 'Two':
        color = '#ffeb3b'
        break
      case 'Three':
        color = '#9575cd'
        break
      case 'Four':
        color = '#f57c00'
        break
      case 'Five':
        color = '#e53935'
        break
      default:
        color = '#e6ee9c'
    }

    return (
      <BarSeries
        key={i}
        valueField={`fulfillmentGroup${x}Qty`}
        // scaleName='valueScale'
        argumentField='date'
        name={`fulfillmentGroup${x}Qty`}
        color={color}
        // pointComponent={(...props) => {
        //   console.log(111111111)
        //   return (
        //     <BarSeries.Point
        //       // {...props}
        //       //
        //       index={props.index}
        //       // seriesIndex={props.seriesIndex}
        //       startVal={props.startVal}
        //       val={props.val}
        //       // readyToRenderSeries={props.readyToRenderSeries}
        //       value={props.value}
        //       // value0={props.value0}
        //       // rotated={props.rotated}
        //       // scales={props.scales}
        //       // pane={props.pane}
        //       // animation={props.animation}
        //       arg={props.arg}
        //       // argument={props.argument}
        //       maxBarWidth={props.maxBarWidth}
        //       barWidth={props.barWidth}
        //       color='pink'
        //     />
        //   )
        // }}
      />
    )
  })

  useEffect(() => {
    if (!cpn || cpn.length === 0) return

    const currentDate = new Date().toISOString().substring(0, 10)
    const weeks = []

    for (let i = 0; i < range; i = i + 7) {
      const date = new Date(new Date().getTime() + i * 24 * 60 * 60 * 1000)
      const firstDayOfTheWeek = moment(date)
        .startOf('week')
        .toDate()
        .toISOString()
        .substring(0, 10)
      weeks.push({ date: firstDayOfTheWeek })
    }

    const getSched = async () => {
      const getProd = await handleDSOperation('product', 'query', {
        cpn: { predicate: 'eq', value: cpn },
        version: { predicate: 'eq', value: 0 },
      })

      const currentInventory =
        (getProd && getProd[0] && getProd[0].currentInventory) || 0

      if (getProd && getProd.length && getProd.length > 0) {
        setCurrentProd({
          stock: currentInventory,
          mpn: getProd[0].mpn,
          mfr: getProd[0].mfr,
        })
      }

      // console.log('currentInventory', currentInventory)

      const transitDays = getProd[0].transitDays || 21

      const getCpos = await handleDSOperation('cpo', 'query', {
        version: { predicate: 'eq', value: 0 },
        openQty: { predicate: 'gt', value: 0 },
      })

      const arrByCpn = Object.entries(groupArrayofObjectsByKey(getCpos, 'cpn'))
      const fetchCpns = arrByCpn.map((x) => ({ cpn: x[0], mpn: x[1][0].mpn }))
      setCpns(fetchCpns.sort())
      // setCpns([...new Set(getCpos.map((x) => x.cpn))].sort())

      const cposOfCurrentCpn = getCpos.filter((x) => x.cpn === cpn)

      setCpoTotal(cposOfCurrentCpn.reduce((a, v) => a + v.openQty, 0))

      let cpos = cposOfCurrentCpn.map((x) => ({
        cpoNumber: x.poNumber,
        req: x.openQty,
        date: x.reqSched > currentDate ? x.reqSched : currentDate,
      }))

      const getSpos = await handleDSOperation('spo', 'query', {
        cpn: { predicate: 'eq', value: cpn },
        version: { predicate: 'eq', value: 0 },
        openQty: { predicate: 'gt', value: 0 },
      })

      setSpoTotal(getSpos.reduce((a, v) => a + v.openQty, 0))

      const getSpoScheds = await handleDSOperation('spoSched', 'query', {
        cpn: { predicate: 'eq', value: cpn },
        version: { predicate: 'eq', value: 0 },
        openQty: { predicate: 'gt', value: 0 },
      })

      setConfirmedSpoTotal(getSpoScheds.reduce((a, v) => a + v.openQty, 0))

      let spoScheds = getSpoScheds.map((x) => {
        const arrival = new Date(
          new Date(x.estSched).getTime() + transitDays * 24 * 60 * 60 * 1000,
        )
          .toISOString()
          .substring(0, 10)

        // console.log('arrival', arrival)

        return {
          spoNumber: x.poNumber,
          est: x.openQty,
          shipping: x.estSched,
          date: currentDate > arrival ? currentDate : arrival,
        }
      })

      // console.log('spoSched', spoSched)

      if (currentInventory > 0) {
        spoScheds.unshift({
          inStock: true,
          est: currentInventory,
          date: currentDate,
        })
      }

      cpos.sort((a, b) => (a.date > b.date ? 1 : -1))
      spoScheds.sort((a, b) => (a.date > b.date ? 1 : -1))

      cpos.forEach((x) => {
        x.date = moment(x.date)
          .startOf('week')
          .toDate()
          .toISOString()
          .substring(0, 10)
      })

      //  console.log('cpos888888888888', JSON.parse(JSON.stringify(cpos)))

      spoScheds.forEach((x) => {
        x.date = moment(x.date)
          .startOf('week')
          .toDate()
          .toISOString()
          .substring(0, 10)
      })
      const mergedCpos = mergeArrayAndCombineQty(cpos, ['date'], ['req'])
      const mergedSpos = mergeArrayAndCombineQty(spoScheds, ['date'], ['est'])

      // console.log('mergedSpos', JSON.parse(JSON.stringify(mergedSpos)))

      let accumulatedCpoOpenQty = 0

      // cpos.forEach(async (x, i) => {

      for (let cpo of mergedCpos) {
        let cpoQty = cpo.req

        let accumulatedSpoOpenQty = 0
        cpo.fulfillments = []
        cpo.fulfillmentGroupOne = []
        cpo.fulfillmentGroupTwo = []
        cpo.fulfillmentGroupThree = []
        cpo.fulfillmentGroupFour = []
        cpo.fulfillmentGroupFive = []

        for (let el of mergedSpos) {
          accumulatedSpoOpenQty = accumulatedSpoOpenQty + el.est
          // debugger

          if (accumulatedSpoOpenQty > accumulatedCpoOpenQty) {
            const remainingBalance =
              accumulatedSpoOpenQty - accumulatedCpoOpenQty

            const fulfillmentQty = Math.min(remainingBalance, cpoQty)

            const diff =
              (new Date(el.date).getTime() - new Date(cpo.date).getTime()) /
              (24 * 60 * 60 * 1000)
            // console.log('diff', diff)
            // if (diff > 100) {
            //   console.log('date', x.date)
            //   debugger
            // }

            cpo.fulfillments.push({
              cpoNumber: cpo.cpoNumber,
              qty: fulfillmentQty,
              date: el.date,
              spoNumber: el.spoNumber,
            })

            // console.log('diff', diff)
            switch (true) {
              case diff < 3:
                cpo.fulfillmentGroupOne.push({
                  poNumber: cpo.cpoNumber,
                  qty: fulfillmentQty,
                  date: el.date,
                })
                break
              case diff < 15:
                // console.log('diff1', diff)
                // debugger
                cpo.fulfillmentGroupTwo.push({
                  poNumber: cpo.cpoNumber,
                  qty: fulfillmentQty,
                  date: el.date,
                })
                break
              case diff < 30:
                cpo.fulfillmentGroupThree.push({
                  poNumber: cpo.cpoNumber,
                  qty: fulfillmentQty,
                  date: el.date,
                })
                break
              case diff < 60:
                cpo.fulfillmentGroupFour.push({
                  poNumber: cpo.cpoNumber,
                  qty: fulfillmentQty,
                  date: el.date,
                })
                break
              case diff > 60:
                // console.log('9999999999999', cpo.date)
                // debugger
                cpo.fulfillmentGroupFive.push({
                  poNumber: cpo.cpoNumber,
                  qty: fulfillmentQty,
                  date: el.date,
                })
                break
              default:
                return
            }

            cpoQty = cpoQty - fulfillmentQty
            accumulatedCpoOpenQty = accumulatedCpoOpenQty + fulfillmentQty

            if (cpoQty <= 0) break
          }
        }
      }

      const reducedByWeek = [...mergedCpos, ...mergedSpos].reduce((a, v) => {
        let index = a.findIndex((x) => x.date === v.date)
        if (index !== -1) {
          const obj = a[index]
          const newObj = { ...obj, ...v }
          a.splice(index, 1, newObj)
          return a
        } else {
          return a.concat(v)
        }
      }, [])

      // console.log('reducedByWeek', JSON.parse(JSON.stringify(reducedByWeek)))

      let finalMerged = weeks.map((firstDayOfTheWeek) => {
        const availableItem = reducedByWeek.find(
          (x) => x.date === firstDayOfTheWeek.date,
        )

        if (availableItem) {
          return availableItem
        }
        return firstDayOfTheWeek
      })

      // console.log('finalMerged', JSON.parse(JSON.stringify(finalMerged)))

      finalMerged = finalMerged.map((x, i) => {
        x.req = x.req || 0
        x.est = x.est || 0
        x.date = x.date.substring(5, 10)
        x.fulfillmentTotal =
          (x.fulfillments &&
            x.fulfillments.length > 0 &&
            x.fulfillments.reduce((a, v) => a + v.qty, 0)) ||
          0
        x.fulfillmentGroupOneQty =
          (x.fulfillmentGroupOne &&
            x.fulfillmentGroupOne.length > 0 &&
            x.fulfillmentGroupOne.reduce((a, v) => a + v.qty, 0)) ||
          0
        x.fulfillmentGroupTwoQty =
          (x.fulfillmentGroupTwo &&
            x.fulfillmentGroupTwo.length > 0 &&
            x.fulfillmentGroupTwo.reduce((a, v) => a + v.qty, 0)) ||
          0
        x.fulfillmentGroupThreeQty =
          (x.fulfillmentGroupThree &&
            x.fulfillmentGroupThree.length > 0 &&
            x.fulfillmentGroupThree.reduce((a, v) => a + v.qty, 0)) ||
          0
        x.fulfillmentGroupFourQty =
          (x.fulfillmentGroupFour &&
            x.fulfillmentGroupFour.length > 0 &&
            x.fulfillmentGroupFour.reduce((a, v) => a + v.qty, 0)) ||
          0
        x.fulfillmentGroupFiveQty =
          (x.fulfillmentGroupFive &&
            x.fulfillmentGroupFive.length > 0 &&
            x.fulfillmentGroupFive.reduce((a, v) => a + v.qty, 0)) ||
          0

        x.unConfirmed = x.req && x.req - x.fulfillmentTotal

        return x
      })

      setSchedsByWeek(finalMerged)
    }

    getSched()
  }, [cpn, range])

  const totalFulfillment = schedsByWeek
    .filter((x) => x.fulfillmentTotal)
    .reduce((a, v) => a + v.fulfillmentTotal, 0)

  const diff = cpoTotal - currentProd.stock - spoTotal

  let totalUnconfirmedSpo

  if (spoTotal > 0) totalUnconfirmedSpo = spoTotal - confirmedSpoTotal

  const allSeries = [...qtySeries, unConfirmedSeries]
  if (showSpos) {
    allSeries.push(estSeries)
  }

  const stacks = [
    // { series: ['req'] },
    {
      series: [...groups.map((x) => `fulfillmentGroup${x}Qty`), 'unConfirmed'],
    },
  ]
  if (showSpos) {
    stacks.push({ series: ['est'] })
  }

  return (
    <Paper
      style={{
        width: '100%',
        marginBottom: 10,
      }}>
      {!isPreview && (
        <>
          <TypographyStack spacing={2} direction='row' color={'gray'}>
            <Typography variant='h5' component='span' color='primary'>
              {`CPN: ${cpn}`}
            </Typography>
            <Typography
              variant='h6'
              component='span'
              // color='primary'
            >
              {`MPN: ${currentProd.mpn}`} {`MFR: ${currentProd.mfr}`}
            </Typography>
          </TypographyStack>
          <FormGroup row>
            {/* <InputLabel id='rangeLabel'>区间</InputLabel> */}
            <Select
              style={{ margin: 20 }}
              labelId='rangeLabel'
              id='range-select'
              value={range}
              onChange={(e) => setRange(e.target.value)}>
              <MenuItem value={90}>90天</MenuItem>
              <MenuItem value={180}>180天</MenuItem>
              <MenuItem value={360}>360天</MenuItem>
              <MenuItem value={720}>720天</MenuItem>
            </Select>

            <FormControlLabel
              control={
                <Switch
                  checked={showSpos}
                  onChange={(e) => {
                    setShowSpos(e.target.checked)
                  }}
                  name='showSpos'
                  color='primary'
                />
              }
              label='显示供应商交期'
            />
          </FormGroup>
        </>
      )}

      {!isPreview && (
        <TypographyStack spacing={2} direction='row' color={'gray'}>
          <Typography component='span' variant='h6'>
            Stock:{currentProd.stock}
          </Typography>
          <Typography component='span' variant='h6'>
            CPO open:{cpoTotal}
          </Typography>
          <Typography component='span' variant='h6'>
            SPO open:{spoTotal}
          </Typography>
          {diff > 0 && (
            <Typography
              component='span'
              variant='h6'
              color={diff > 0 ? 'secondary' : 'primary'}>
              diff:{diff}
            </Typography>
          )}
          <Typography component='span' variant='h6'>
            Scheduled CPO:{totalFulfillment}
          </Typography>
          <Typography component='span' variant='h6'>
            Scheduled SPO: {confirmedSpoTotal}
          </Typography>

          {totalUnconfirmedSpo > 0 && (
            <Typography component='span' color='secondary' variant='h6'>
              unConfirmed SPO: {totalUnconfirmedSpo}
            </Typography>
          )}
          <Typography component='span' variant='h6' color='primary'>
            Extra: {spoTotal + currentProd.stock - cpoTotal}
          </Typography>
        </TypographyStack>
      )}

      <Chart
        data={schedsByWeek}
        width={isPreview ? 380 : 2000}
        height={isPreview ? 220 : 800}>
        <ArgumentScale factory={scaleBand} />
        <ValueScale />
        {!isPreview && <ArgumentAxis />}
        {!isPreview && <ValueAxis />}

        <Plugin name='ser'>
          {/* {[reqSeries, ...qtySeries, unConfirmedSeries, estSeries]} */}

          {allSeries}
        </Plugin>

        {isPreview && <Title text={cpn} />}

        <Stack stacks={stacks} />

        {!isPreview && <EventTracker />}
        {!isPreview && <HoverState />}
        {!isPreview && (
          <Plugin name='toolTip'>
            <Tooltip
              contentComponent={({ text, targetItem }) => {
                const item = schedsByWeek[targetItem.point]
                // console.log('item', item)
                const attr = targetItem.series
                // console.log('attr', attr)

                let info
                let dateLines = []

                if (attr.startsWith('fulfillment')) {
                  dateLines = item.fulfillments
                }

                // console.log('dateLines', dateLines)
                //   const num = attr.substr(-1)
                //   const dateAttr = `fulfillmentDate${num}`
                //   const date = item[dateAttr].substring(5, 10)

                //   if (date > item.date) {
                //     dateLine = `${dateAttr}: ${date}`
                //   }
                // }

                return (
                  <>
                    {item.cpoNumber && <p> poNumber: {item.cpoNumber}</p>}
                    {item.spoNumber && <p> spoNumber: {item.spoNumber}</p>}

                    {item.inStock && <p>stock: {item[attr]}</p>}

                    {!attr.startsWith('fulfillment') && !item.inStock && (
                      <p>
                        {attr}: {item[attr]}
                      </p>
                    )}

                    {attr === 'est' && !item.inStock && (
                      <p>
                        shipping date: {item.shipping.substring(5, 10)}
                        {''} arrival:{item.date}
                      </p>
                    )}

                    {dateLines.length > 0 &&
                      dateLines.map((x, i) => (
                        <p key={i}>
                          qty: {x.qty}
                          {''} date:{x.date}
                          {''} spoNumber:{x.spoNumber}
                        </p>
                      ))}
                  </>
                )
              }}
            />
          </Plugin>
        )}
        {/* {!isPreview && <ZoomAndPan />} */}
        {/* {!isPreview && <Animation duration={500} easing={easeBounceOut} />} */}
        {!isPreview && <Animation duration={300} />}
      </Chart>

      {schedsByWeek.length === 0 && isPreview ? (
        <div
          style={{
            // display: 'flex',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',

            // marginLeft: '50%',
            // alignItems: 'flex-start',
            // justifyContent: 'center',
            // minHeight: '100vh',

            // '& > * + *': {
            //   marginLeft: '50%',
            // },
          }}>
          <CircularProgress color='secondary' />
        </div>
      ) : (
        <Backdrop
          style={{
            // zIndex: theme.zIndex.drawer + 1,
            zIndex: 999,
            color: '#fff',
          }}
          open={schedsByWeek.length === 0}
          onClick={() => {}}>
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
    </Paper>
  )
}

export default Charts
