import handleDSOperation from '../handleDSOperation'

const dsCreateIncomingInvoices = async (rows) => {
  // console.log('rows', rows)
  await Promise.all(
    rows.map(async (x) => {
      const input = {
        paymentStatus: 'unpaid',
        date: x.date,
        invoiceNumber: x.invoiceNumber.toUpperCase(),
        invoiceType: x.invoiceType,
        vendor: x.vendor,
        vendorPaymentDue: x.vendorPaymentDue,
        poNumber: x.poNumber,
        receivingCode: x.receivingCode || '',
        qty: x.qty * 1,
        unit: x.unit,
        amountExclVat: x.amountExclVat * 1,
        costCurrency: x.costCurrency,
        cpn: x.cpn,
        hpn: x.hpn,
        mpn: x.mpn,
        mfr: x.mfr,
        usePaymentAgent: x.usePaymentAgent || false,
      }

      // console.log('input', input)

      // debugger

      await handleDSOperation('incomingInvoice', 'create', input)
    }),
  )
}

export default dsCreateIncomingInvoices
