import React, { useState } from 'react'
import { Hub } from 'aws-amplify'
import { Button, Stack } from '@mui/material'
import handleToolbarSendQuote from '../../functions/tableFunctions/toolbar/handleToolbarSendQuote'

const Toolbar = ({ selectedRows, dataType, currentUser }) => {
  const [loading, setLoading] = useState(false)
  const [previewLoading, setPreviewLoading] = useState(false)

  return selectedRows && selectedRows.length > 0 ? (
    <Stack direction='row' spacing={3}>
      <Button
        disabled={previewLoading}
        variant='contained'
        color='primary'
        size='small'
        onClick={async () => {
          try {
            setPreviewLoading(true)
            const preview = true
            await handleToolbarSendQuote(selectedRows, preview, currentUser)
            setPreviewLoading(false)
            alert('quote sent successfully')
          } catch (err) {
            console.log('error 778996', err)
            alert('sending quote failed')
          }
        }}>
        send Quote preview
      </Button>
      <Button
        disabled={loading}
        variant='contained'
        color='secondary'
        size='small'
        onClick={async () => {
          try {
            setLoading(true)
            const preview = false
            if (window.confirm('Send quote to customer?')) {
              await handleToolbarSendQuote(selectedRows, preview, currentUser)
            }
            setLoading(false)
            alert('quote sent successfully')
          } catch (err) {
            console.log('error 778996', err)
            alert('sending quote failed')
          }
        }}>
        send Quote
      </Button>
    </Stack>
  ) : (
    <Stack direction='row' spacing={3}>
      <Button
        variant='contained'
        color='secondary'
        size='small'
        onClick={() =>
          Hub.dispatch('FormMonitor', {
            event: 'formEdit',
            data: {
              dataType,
              formType: 'create',
              allowAddItem: true,
              showDeleteItem: true,
            },
            message: '',
          })
        }>
        新建报价单
      </Button>
    </Stack>
  )
}

export default Toolbar
