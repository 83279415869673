import restAPICalls from '../restAPICalls'
import handleDSOperation from '../handleDSOperation'
import dsCreateContact from './dsCreateContact'

const dsCreateBp = async (BPs) => {
  await Promise.all(
    BPs.map(async (x) => {
      // console.log(x)

      // debugger
      const inputForCustomerCreation = {
        number: x.number,
        entity: x.entity,
        paymentTerm: x.paymentTerm,
        // deliveryTerm: x.deliveryTerm,
        deliveryTerms: x.deliveryTerms,
        hankenoEntity: x.hankenoEntity,
        type: x.type,
        entityType: x.entityType,
        billingInfo: x.billingInfo,
        isActive: true,
      }

      await handleDSOperation(x.type, 'create', inputForCustomerCreation)

      // console.log('createdBp', createdBp)

      // debugger

      const getContact = await handleDSOperation('contact', 'query', {
        entity: { predicate: 'eq', value: x.entity },
        name: { predicate: 'eq', value: x.contactPerson.name },
      })

      // console.log('getContact', getContact)

      // debugger

      if (getContact.length > 0) return

      const inputForContactPersonCreation = {
        entity: x.entity,
        name: x.contactPerson.name.toUpperCase(),
        role: x.contactPerson.role,
        phoneNumber: x.contactPerson.phoneNumber,
        email: x.contactPerson.email,
        isMainContact: true,
      }

      await dsCreateContact([inputForContactPersonCreation])
      // console.log(
      //   'inputForContactPersonCreation',
      //   inputForContactPersonCreation,
      // )
      // await handleDSOperation(
      //   'contact',
      //   'create',
      //   inputForContactPersonCreation,
      // )
    }),
  )
}

export default dsCreateBp
