import React from 'react'

const ItemComponent = ({
  values,
  handleBlur,
  index,
  handleChange,
  setFieldValue,
  formStyle,
  item: currentItem,
}) => {
  return <p>item component</p>
}

export default ItemComponent
