import handleDSOperation from '../../functions/handleDSOperation'

const onSelectedDelete = async (selectedRows) => {
  await Promise.all(
    selectedRows.map(async (oldData) => {
      const hpn = oldData.hpn

      const getProducts = await handleDSOperation('product', 'query', {
        hpn: { predicate: 'eq', value: hpn },
      })

      await Promise.all(
        getProducts.map(async (product) => {
          await handleDSOperation(null, 'delete', product.id)
        }),
      )
    }),
  )
}

export default onSelectedDelete
