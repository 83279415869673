const mapping = {
  enhanced: [
    'actions',
    'memo',
    'billingStatus',
    'date',
    'customer',
    'fulfillmentNumber',
    'poNumber',
    'line',
    'cpn',
    'mpn',
    'qty',
    'cartonNumber',
    'invoiceNumber',
    'trackingNumber',
    'carrier',
    // 'billingStatus',
    // 'paymentStatus',
  ],
  simple: [
    'date',
    'customer',
    'poNumber',
    'line',
    'cpn',
    'mpn',
    'qty',
    'price',
    'cartonNumber',
    'cartonDimensions',
    'cartonGw',
    'deliveryTerm',
    'coo',
    'fulfillmentNumber',
  ],
  collasped: [
    'date',
    'customer',
    'fulfillmentNumber',
    'poNumber',
    'line',
    'cpn',
    'mpn',
    'qty',
  ],
  collaspedFulfillment: [
    'date',
    'fulfillmentNumber',
    'poNumber',
    'cpn',
    'mpn',
    'qty',
  ],
}

export default mapping
