const getInitialValue = (rowData) => ({
  id: rowData.id,
  type: rowData.type,
  cost: rowData.cost,
  costCurrency: rowData.costCurrency,
  price: rowData.price,
  priceCurrency: rowData.priceCurrency,
  productType: rowData.productType,
  cpn: rowData.cpn,
  hpn: rowData.hpn,
  mfr: rowData.mfr,
  mpn: rowData.mpn,
  initialInventory: rowData.initialInventory || 0,
  currentInventory: rowData.currentInventory || 0,
  safetyInventory: rowData.safetyInventory || 0,
  customerLeadtime: rowData.customerLeadtime,
  coo: rowData.coo || [],
  leadtime: rowData.leadtime,
  customerMPQ: rowData.customerMPQ,
  customerMOQ: rowData.customerMOQ,
  supplierMPQ: rowData.supplierMPQ,
  supplierMOQ: rowData.supplierMOQ,
  transitDays: rowData.transitDays,
  unit: rowData.unit,
  isActive: rowData.isActive,
  // vendor: rowData.vendor,
  vendors: rowData.vendors || [],
  memo: rowData.memo,
  spec: rowData.spec,
  version: rowData.version,
  latestVersion: rowData.latestVersion,
  vendorOptions: [],
})

export default getInitialValue
