const mapping = {
  enhanced: [
    'actions',
    'memo',
    'isActive',
    'cpn',
    'mfr',
    'mpn',
    'productType',
    // 'coo',
    'hpn',
    'vendors',
    'cost',
    'costCurrency',
    'price',
    // 'unit',
    // 'priceCurrency',
    // 'supplierMOQ',
    // 'supplierMPQ',
    'leadtime',
    // 'customerMOQ',
    // 'customerMPQ',
    'customerLeadtime',
    // 'transitDays',
    'safetyInventory',
    'currentInventory',
    // '_lastChangedAt',
  ],
  simple: [
    'vendor',
    'cpn',
    'mpn',
    'mfr',
    'hpn',
    'productType',
    'cost',
    'costCurrency',
    'price',
    'priceCurrency',
    'supplierMOQ',
    'supplierMPQ',
    'customerMOQ',
    'customerMPQ',
    'safetyInventory',
    'currentInventory',
    'leadtime',
  ],
  collasped: [
    'cpn',
    'mpn',
    'mfr',
    'vendors',
    'cost',
    'costCurrency',
    'price',
    'priceCurrency',
    'supplierMOQ',
    'supplierMPQ',
    'leadtime',
    'customerMOQ',
    'customerMPQ',
    'customerLeadtime',
    'version',
    '_lastChangedAt',
  ],
}

export default mapping
