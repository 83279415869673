import handleDSOperation from '../handleDSOperation'
import groupArrayofObjectsByKey from '../helpFunc/groupArrayofObjectsByKey'

const dsUpdateInventory = async (arr, addOrMinus) => {
  // console.log('arr', arr)
  // console.log('addOrMinus', addOrMinus)

  if (!addOrMinus) {
    return alert('8889 Update Inventory,invalid arguments')
  }
  const arrOfFulfillmentsByHpn = Object.entries(
    groupArrayofObjectsByKey(arr, 'hpn'),
  )
  await Promise.all(
    arrOfFulfillmentsByHpn.map(async ([hpn, fulfillmentsofHpn]) => {
      const getProducts = await handleDSOperation('product', 'query', {
        hpn: { predicate: 'eq', value: hpn },
        version: { predicate: 'eq', value: 0 },
      })

      if (getProducts.length === 0) {
        throw new Error(`HPN ${hpn} can be found in system`)
      }
      const currentInventory = getProducts[0].currentInventory
      const totalFulfillmentQty = fulfillmentsofHpn.reduce(
        (a, v) => a + v.qty,
        0,
      )

      // if (currentInventory < totalFulfillmentQty) {
      //   return alert(`${hpn}库存数量不足`)
      // }

      let updatedInventoryByCpn
      if (addOrMinus === 'add') {
        updatedInventoryByCpn = currentInventory + totalFulfillmentQty
      } else if (addOrMinus === 'minus') {
        updatedInventoryByCpn = currentInventory - totalFulfillmentQty
      }

      const inputForUpdate = {
        id: getProducts[0].id,
        currentInventory: updatedInventoryByCpn,
      }

    await handleDSOperation(null, 'update', inputForUpdate)

      // console.log('res', res)
    }),
  )
}

export default dsUpdateInventory
