import handleDSOperation from '../../functions/handleDSOperation'

const receivingOnRowUpdate = async (newData) => {
  const input = {
    id: newData.id,
    customer: newData.customer,
    date: newData.date,
    fulfillmentNumber: newData.fulfillmentNumber,
    invoiceNumber: newData.invoiceNumber,
    poNumber: newData.poNumber,
    deliveryTerm: newData.deliveryTerm,
    cpn: newData.cpn,
    mpn: newData.mpn,
    qty: newData.qty * 1,
    memo: newData.memo,
    coo: newData.coo,
    trackingNumber: newData.trackingNumber,
    carrier: newData.carrier,
    cartonNumber: newData.cartonNumber,
    billingStatus: newData.billingStatus,
    paymentStatus: newData.paymentStatus,
  }
  await handleDSOperation(null, 'update', input)
}

export default receivingOnRowUpdate
