import { API } from 'aws-amplify'

const restAPICalls = async (method, pathSuffix, data) => {
  // console.log('pathSuffix', pathSuffix)
  try {
    const apiName = 'restful'
    // const path = pathSuffix ? `/mail${pathSuffix}` : '/mail'
    const path = '/mail'

    let obj

    switch (method) {
      case 'get':
        obj = { queryStringParameters: data }
        break
      case 'post':
        obj = { body: data }
        break
      default:
        return
    }
    // await API.get('restful', '/mail', {
    //   queryStringParameters: {
    //     order: 'byPrice',
    //   },
    // })
    // console.log('path', path)
    await API[method](apiName, path, obj)
  } catch (err) {
    alert(`error2993 ${err}`)
    console.log('error2993', err)
  }
}

export default restAPICalls
