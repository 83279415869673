const mapping = {
  enhanced: [
    'actions',
    'memo',
    'date',
    'vendor',
    'poNumber',
    // 'line',
    'cpn',
    'mpn',
    'cost',
    'costCurrency',
    'qty',
    'openQty',
    'amount',
    'reqSched',
    'billingStatus'
  ],
  simple: [
    'isNew',
    // 'date',
    'vendor',
    'poNumber',
    'cpn',
    'mpn',
    'cost',
    'qty',
    'openQty',
    'reqSched',
    'estSched',
  ],
  preview: [
    'isNew',
    'poNumber',
    'cpn',
    'mpn',
    'cost',
    'qty',
    'openQty',
    'reqSched',
  ],
  // collasped: [
  //   'poNumber',
  //   'line',
  //   'cpn',
  //   'mpn',
  //   'qty',
  //   'openQty',
  //   // 'reqSched',
  //   'estSched',
  //   'leadtime',
  //   'scheduleBasedOnLeadtime',
  //   'leadtimeDifference',
  //   'version',
  //   '_lastChangedAt',
  //   // 'poVersion',
  // ],
  collasped: [
    'poNumber',
    'cpn',
    'mpn',
    'cost',
    'qty',
    'openQty',
    'reqSched',
    '_lastChangedAt',
    'version',
  ],
  collaspedSched: [
    'poNumber',
    'line',
    'cpn',
    'mpn',
    'qty',
    'openQty',
    'estSched',
    'leadtime',
    'scheduleBasedOnLeadtime',
    'leadtimeDifference',
    'version',
    '_lastChangedAt',
    // 'poVersion',
  ],
}

export default mapping
