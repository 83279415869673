import mapping from './mapping'

const getAttrValue = (inputType, attr, row) => {
  let value

  const validAttr = mapping(inputType).find((x) => x.attr === attr)
  if (validAttr) {
    switch (validAttr.attr) {
      case 'qty':
      case 'openQty':
      case 'price':
      case 'enteredPrice':
      case 'leadtime':
      case 'transitDays':
      case 'amountExclVat':
        value = row[validAttr.index].replace(/,/g, '') * 1
        break
      case 'reqSched':
        if (!isNaN(Date.parse(row[validAttr.index])))
          value = new Date(row[validAttr.index]).toISOString().substring(0, 10)
        break
      case 'estSched':
        if (!isNaN(Date.parse(row[validAttr.index])))
          value = new Date(row[validAttr.index]).toISOString().substring(0, 10)
        break
      case 'orderDate':
        if (!isNaN(Date.parse(row[validAttr.index])))
          value = new Date(row[validAttr.index]).toISOString().substring(0, 10)
        break
      case 'date':
        if (!isNaN(Date.parse(row[validAttr.index])))
          value = new Date(row[validAttr.index]).toISOString().substring(0, 10)
        break
      case 'mainContactRole':
        value = row[validAttr.index].split(',').map((x) => x.trim())
        break
      case 'spec':
        value = row[validAttr.index].split(';').map((x) => {
          const values = x.split(',')
          return {
            spec: values[0].toUpperCase(),
            range: values[1],
            unit: values[2],
            predicate: values[3].toLowerCase(),
            value: values[4].toUpperCase(),
            digits: values[5] && values[5] * 1,
          }
        })
        break

      default:
        value = row[validAttr.index]
    }
  }
  return value
}

export default getAttrValue
