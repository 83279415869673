import React from 'react'
import { FormField } from '../../components/Form/FormField'

import { Form } from 'formik'

const EditComponent = (props) => {
  const {
    values,
    submitForm,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    setFieldValue,
    setValues,
    isSubmitting,
    formStyle,
    userGroups: groups,
  } = props

  return (
    <>
      <Form style={formStyle} autoComplete='off'>
        <div>
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='date'
            value={values.date}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='poNumber'
            value={values.poNumber}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='cpn'
            value={values.cpn}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='qty'
            value={values.qty}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />

          <FormField
            disabled={!groups.includes('admin')}
            fieldName='receivingCode'
            value={values.receivingCode}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='invoiceNumber'
            value={values.invoiceNumber}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </div>
        <div>
          <FormField
            disabled={!groups.includes('admin')}
            select
            fieldName='billingStatus'
            value={values.billingStatus}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
        </div>

        <div>
          <FormField
            fieldName='memo'
            multiline
            value={values.memo}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
          />
        </div>
      </Form>
    </>
  )
}

export default EditComponent
