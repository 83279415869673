import React, { Fragment, useContext } from 'react'
import { Button, DialogActions, DialogContent } from '@mui/material'
import { Box } from '@mui/system'
import { Formik, FieldArray } from 'formik'
import * as Yup from 'yup'
import FormikErrorMessage from './FormikErrorMessage'
import FormikAddBtnIcon from './formComponent/FormikAddBtnIcon'
import FormikDuplicateBtnIcon from './formComponent/FormikDuplicateBtnIcon'
import FormikRemoveBtnIcon from './formComponent/FormikRemoveBtnIcon'
import AuthContext from '../../context/AuthContext'

const FormikForm = ({
  onCloseForm,
  setAllowAdd,
  setAllowDelete,
  allowAddItem,
  allowDuplicateItem,
  allowDuplicateItemFunc,
  showDeleteItem,
  formType,
  dataType,
  rowData,
}) => {
  let MainComponent,
    ItemComponent,
    handleDelete,
    handleSubmit,
    schema,
    getInitialValuesFunc,
    createItemInitialValue

  if (formType === 'edit') {
    MainComponent = require(`resources/${dataType}/EditComponent`).default
    ItemComponent = null
    handleDelete = require(`resources/${dataType}/onRowDelete`).default
    handleSubmit = require(`resources/${dataType}/onRowUpdate`).default
    schema = require(`resources/${dataType}/editInitialSchema`).default
    getInitialValuesFunc =
      require(`resources/${dataType}/editInitialValue`).default
  } else if (formType === 'create') {
    MainComponent = require(`resources/${dataType}/CreateMainComponent`).default
    ItemComponent = require(`resources/${dataType}/CreateItemComponent`).default
    handleDelete = () => {
      alert('handleDelete on create called')
    }
    handleSubmit = require(`resources/${dataType}/createHandleSubmit`).default
    schema = require(`resources/${dataType}/createInitialSchema`).default
    getInitialValuesFunc =
      require(`resources/${dataType}/createMainInitialValue`).default
    createItemInitialValue =
      require(`resources/${dataType}/createItemInitialValue`).default
  } else {
    alert('formType not recogized')
  }

  const initialValues = getInitialValuesFunc(rowData)

  schema = Yup.object().shape(schema)

  const { groups } = useContext(AuthContext)

  return (
    <DialogContent>
      <Formik
        initialValues={initialValues || {}}
        validationSchema={schema || {}}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={async (values, actions) => {
          const { setSubmitting } = actions
          await handleSubmit(values, setSubmitting)
          onCloseForm()
          // actions.resetForm()
        }}>
        {(props) => {
          return (
            <>
              <MainComponent
                {...props}
                formStyle={{
                  // '& .MuiTextField-root': {
                  //   margin: 10,
                  //   width: '25ch',
                  // },
                  backgroundColor: '#e0f7fa',
                  // margin: 10,
                  // padding: 10,
                  borderRadius: 5,
                  width: '100%',
                }}
                formType={formType}
                setAllowAdd={setAllowAdd}
                setAllowDelete={setAllowDelete}
                userGroups={groups}
                onCloseForm={onCloseForm}
              />
              <FormikErrorMessage
                errors={props.errors}
                touched={props.touched}
              />

              {ItemComponent &&
                // allowAddItem &&
                props.values.items &&
                props.values.items.length > 0 && (
                  <FieldArray name='items'>
                    {({ push, remove }) => (
                      <>
                        {props.values.items.map((item, i) => (
                          <Fragment key={i}>
                            <Box display='flex' flexWrap='nowrap' p={1} m={1}>
                              <ItemComponent
                                {...props}
                                index={i}
                                userGroups={groups}
                                item={item}
                                onCloseForm={onCloseForm}
                                formStyle={{
                                  // '& .MuiTextField-root': {
                                  //   margin: 10,
                                  //   width: '25ch',
                                  // },
                                  backgroundColor: '#e0f7fa',
                                  margin: 10,
                                  padding: 10,
                                  borderRadius: 5,
                                }}
                              />

                              {showDeleteItem && (
                                <FormikRemoveBtnIcon
                                  onDelete={() => remove(i)}
                                  items={props.values.items}
                                />
                              )}
                            </Box>

                            <FormikErrorMessage
                              errors={props.errors}
                              touched={props.touched}
                              index={i}
                            />
                          </Fragment>
                        ))}

                        {allowAddItem && (
                          <FormikAddBtnIcon
                            onClick={async () => {
                              const errors = await props.validateForm()
                              if (Object.keys(errors).length === 0) {
                                push({ ...createItemInitialValue })
                              } else {
                                props.setTouched({
                                  ...props.touched,
                                  ...errors,
                                })
                              }
                            }}
                            isDisabled={false}
                          />
                        )}

                        {allowDuplicateItem && (
                          <FormikDuplicateBtnIcon
                            onClick={async () => {
                              const errors = await props.validateForm()
                              if (Object.keys(errors).length === 0) {
                                if (!allowDuplicateItemFunc)
                                  return alert(
                                    'allowDuplicateItemFunc not defined',
                                  )
                                allowDuplicateItemFunc({
                                  values: props.values,
                                  push,
                                  setFieldValue: props.setFieldValue,
                                })
                              } else {
                                props.setTouched({
                                  ...props.touched,
                                  ...errors,
                                })
                              }
                            }}
                            isDisabled={false}
                          />
                        )}
                      </>
                    )}
                  </FieldArray>
                )}

              <DialogActions>
                <Button
                  disabled={props.isSubmitting}
                  onClick={() => {
                    props.submitForm()
                  }}
                  variant='contained'
                  color='primary'>
                  SUBMIT
                </Button>
                <Button
                  disabled={props.isSubmitting}
                  onClick={() => {
                    onCloseForm()
                  }}
                  variant='contained'
                  color='inherit'>
                  CANCEL
                </Button>

                {formType === 'edit' && (
                  <Button
                    disabled={props.isSubmitting}
                    variant='contained'
                    color='secondary'
                    onClick={async () => {
                      props.setSubmitting(true)

                      if (
                        window.confirm(
                          'Are you sure you want to delete this item?',
                        )
                      ) {
                        await handleDelete(props.values)
                        onCloseForm()
                      } else {
                        props.setSubmitting(false)
                      }
                    }}>
                    DELETE
                  </Button>
                )}
              </DialogActions>
            </>
          )
        }}
      </Formik>
    </DialogContent>
  )
}

export default FormikForm
