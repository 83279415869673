import React, { useState, useEffect, useContext, useMemo } from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Tooltip,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import AuthContext from '../../context/AuthContext'

import _ from 'lodash'

import getTableMapping from '../../functions/getTableMapping'
import sort from '../../functions/tableFunctions/sort'
import config from '../../parameters'

const SimpleTable = ({ dataType, rows, tbType }) => {
  const currentUserFromContext = useContext(AuthContext)

  const currentUser = useMemo(
    () => currentUserFromContext || { groups: ['admin'] },
    [currentUserFromContext],
  )

  const [tableMappings, setTableMapping] = useState()

  rows = sort(dataType, tbType, rows)

  useEffect(() => {
    const func = async () => {
      if (currentUser && !tableMappings) {
        // console.log(333333)
        const mapping = await getTableMapping(dataType, tbType, currentUser)
        setTableMapping(mapping)
      }
    }

    func()
  }, [dataType, tbType, currentUser, tableMappings])

  // console.log('tableMappings', tableMappings)

  const AlertTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      // fontSize: theme.typography.pxToRem(18),
      fontSize: 16,
      border: '1px solid #dadde9',
    },
  }))(Tooltip)

  return (
    <TableContainer component={Paper}>
      <Table
        aria-label='simple table'
        aria-labelledby='tableTitle'
        size='small'>
        <TableHead>
          <TableRow
            style={{
              backgroundColor: config.color.purple,
              fontWeight: 'bold',
            }}>
            {tableMappings &&
              tableMappings.map((x, i) => (
                <TableCell
                  key={i}
                  style={{
                    fontWeight: 'bold',
                  }}>
                  {x.head}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.length === 0 ? (
            <TableRow>
              <TableCell colSpan={5} style={{ maxWidth: 100 }}>
                no record found
              </TableCell>
            </TableRow>
          ) : (
            rows.map((row, i) => {
              return (
                <AlertTooltip
                  key={i}
                  placement='top'
                  title={
                    <>
                      {row.alertMessage &&
                        row.alertMessage.map((x, i) => (
                          <p key={i}>{x.message}</p>
                        ))}
                    </>
                  }>
                  <TableRow
                    onClick={() => {
                      console.log('simple row data', row)
                    }}
                    hover
                    style={{
                      backgroundColor:
                        row.alertMessage && row.alertMessage.length > 0
                          ? config.color.alert4
                          : tbType.startsWith('collasped') &&
                            (dataType === 'cpo' ||
                              dataType === 'spo' ||
                              dataType === 'spoSched') &&
                            // dataType !== 'shipping' &&
                            // dataType !== 'receiving' &&
                            // dataType !== 'product' &&
                            row.version % 2 === 0
                          ? config.color.grey
                          : null,
                    }}>
                    {tableMappings &&
                      tableMappings.map((x, i) => {
                        let value = row[x.index]
                        let cellClass
                        // console.log('x.index', x.index)
                        // console.log('value', value)

                        // debugger

                        switch (x.index) {
                          case '_lastChangedAt':
                            // console.log('x.index', x.index)
                            // debugger
                            if (value) {
                              value = new Date(value)
                                .toISOString()
                                .substring(0, 10)
                            }
                            break

                          case 'amountExclVat':
                            value =
                              (value && (value * 1).toFixed(2)) ||
                              (row.price * row.qty).toFixed(2) ||
                              (row.cost * row.qty).toFixed(2)
                            break
                          case 'isNew':
                            if (_.isBoolean(value)) {
                              value = value ? 'New' : 'Existing'
                            }

                            break
                          case 'amountInclVat':
                            const invoiceType = row.invoiceType
                            if (invoiceType && invoiceType === 'VAT') {
                              value = (
                                row.amountExclVat *
                                (1 + config.vatRate)
                              ).toFixed(2)
                            }
                            break

                          default:
                            break
                        }

                        if (row.alertMessage && row.alertMessage.length > 0) {
                          const findAlertItem = row.alertMessage.find(
                            (y) => y.index === x.index,
                          )
                          // if (findAlertItem) {
                          //   cellClass = classes[findAlertItem.style]
                          // }
                        }
                        return <TableCell key={i}>{value}</TableCell>
                      })}
                  </TableRow>
                </AlertTooltip>
              )
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SimpleTable
