import React, { useState, useEffect } from 'react'
import {
  FormField,
} from '../../../components/Form/FormField'
import handleDSOperation from '../../../functions/handleDSOperation'

const months = [
  { label: 'Jan', value: '01' },
  { label: 'Feb', value: '02' },
  { label: 'Mar', value: '03' },
  { label: 'Apr', value: '04' },
  { label: 'May', value: '05' },
  { label: 'Jun', value: '06' },
  { label: 'Jul', value: '07' },
  { label: 'Aug', value: '08' },
  { label: 'Sep', value: '09' },
  { label: 'Oct', value: '10' },
  { label: 'Nov', value: '11' },
  { label: 'Dec', value: '12' },
]

const MiscFee = ({ values, handleBlur, handleChange, setFieldValue }) => {
  const [miscFeePaidMonth, setMiscFeePaidMonth] = useState()
  useEffect(() => {
    const func = async () => {
      if (!miscFeePaidMonth) {
        const getMiscPayments = await handleDSOperation(
          'outgoingPayment',
          'query',
          {
            paymentType: { predicate: 'eq', value: 'miscFee' },
          },
        )
        const months = getMiscPayments.reduce(
          (a, v) => (v.desc ? a.concat(v.desc.slice(4, 6)) : a),
          [],
        )
        if (months.length > 0) {
          setMiscFeePaidMonth(months)
        }
      }
    }

    func()
  }, [setFieldValue, values, miscFeePaidMonth])


  return (
    <>
      <FormField
        select
        fieldName='month'
        value={values.month}
        options={months}
        handleBlur={handleBlur}
        handleChange={handleChange}
        menuItemDisable={(x) =>
          miscFeePaidMonth && miscFeePaidMonth.includes(x.value)
        }
      />

      <FormField
        fieldName='amount'
        value={values.amount}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />
    </>
  )
}

export default MiscFee
