import * as Yup from 'yup'
import {
  customer,
  qty,
  cpn,
  mpn,
  reqSched,
  price,
  poNumber,
  date,
} from '../../functions/getValidations'

const itemValidationShape = {
  cpn,
  price,
  reqSched,
  mpn,
  qty,
}

const formValidationSchema = {
  customer,
  poNumber,
  date,
  items: Yup.array().of(Yup.object(itemValidationShape)),
}

export default formValidationSchema
