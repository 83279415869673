import { firstBy } from 'thenby'
import handleDSOperation from '../handleDSOperation'
import mergeArrayAndCombineQty from '../helpFunc/mergeArrayAndCombineQty'
import getAddedItems from '../helpFunc/getAddedItems'

import dsCreateSpoScheds from './dsCreateSpoScheds'

const dsUpdateSPOQtyAndSPOSchedAfterDeleteReceivings = async (items) => {
  const mergedArr = mergeArrayAndCombineQty(items, ['hpn', 'poNumber'], ['qty'])

  const arrOfarrUpdatedPo = await Promise.all(
    mergedArr.map(async (receivingItem) => {
      let poItems = await handleDSOperation('spo', 'query', {
        version: { predicate: 'eq', value: 0 },
        hpn: { predicate: 'eq', value: receivingItem.hpn },
        poNumber: { predicate: 'eq', value: receivingItem.poNumber },
      })

      poItems = poItems.filter((x) => x.qty !== x.openQty)

      poItems.sort(firstBy('reqSched').thenBy('openQty'))

      return getAddedItems(receivingItem, poItems, 'qty', 'openQty')
    }),
  )
  const arrOfUpdatedPo = [].concat.apply([], arrOfarrUpdatedPo)

  // console.log('arrOfUpdatedPo', arrOfUpdatedPo)

  await Promise.all(
    arrOfUpdatedPo.map(async (x) => {
      await handleDSOperation(null, 'update', {
        id: x.id,
        openQty: x.openQty + x.changedQty,
      })
    }),
  )

  // ATTENTION UPDATE SPO SCHEDULE

  const arrOfarrUpdatedSpoSched = await Promise.all(
    arrOfUpdatedPo
      // .map((y) => ({ ...y, qty: y.changedQty }))
      .map(async (x) => {
        const getSchedItems = await handleDSOperation('spoSched', 'query', {
          version: { predicate: 'eq', value: 0 },
          hpn: { predicate: 'eq', value: x.hpn },
          poNumber: { predicate: 'eq', value: x.poNumber },
        })
        // console.log('getSchedItems', getSchedItems)
        getSchedItems.sort(firstBy('estSched').thenBy('openQty'))

        const isSchedule = true

        const items = getAddedItems(
          x,
          getSchedItems,
          'qty',
          'openQty',
          isSchedule,
        )

        // console.log('spoSched items', items)

        // debugger

        return items.map((z) => ({ ...z, openQty: z.openQty + z.changedQty }))
      }),
  )
  const arrOfUpdatedSpoSched = [].concat.apply([], arrOfarrUpdatedSpoSched)
  await dsCreateSpoScheds(arrOfUpdatedSpoSched)
}

export default dsUpdateSPOQtyAndSPOSchedAfterDeleteReceivings
