import * as Yup from 'yup'
import {
  vendor,
  qty,
  cpn,
  mpn,
  reqSched,
  cost,
  poNumber,
  date,
} from '../../functions/getValidations'

const formValidationSchema = {
  vendor,
  cpn,
  date,
  poNumber,
  qty,
}

export default formValidationSchema
