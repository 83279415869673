import React from 'react'

const ItemComponent = ({
  values,
  handleBlur,
  index,
  handleChange,
  setFieldValue,
  formStyle,
  item: currentItem,
}) => {

  return <p>item component</p>

  // const prevPoNumber = usePrevious(currentItem.poNumber)
  // const prevReceivingCode = usePrevious(currentItem.receivingCode)

  // const prevHpn = usePrevious(currentItem.hpn)
  // const hpns = [...new Set(values.poOptions.map((x) => x.hpn))]

  // const selection = hpns.map((x) => {
  //   const item = values.poOptions.find((y) => y.hpn === x)
  //   return { mpn: item.mpn, cpn: item.cpn, hpn: item.hpn }
  // })

  // selection.sort((a, b) => (a.cpn > b.cpn ? 1 : -1))

  // // console.log('selection', selection)

  // const itemSelectablePoNumbers = [
  //   ...new Set(
  //     currentItem.itemsSelectablePos.map((x) => x.receivingCode || x.poNumber),
  //   ),
  // ]

  // useEffect(() => {
  //   const func = async () => {
  //     if (currentItem.hpn && currentItem.hpn !== prevHpn) {
  //       let poNumber, receivingCode
  //       const itemsSelectablePos = values.poOptions.filter(
  //         (x) => x.hpn === currentItem.hpn,
  //       )

  //       if (itemsSelectablePos.length === 1) {
  //         receivingCode = itemsSelectablePos[0].receivingCode
  //         poNumber = itemsSelectablePos[0].poNumber
  //       }

  //       // const getProducts = await handleDSOperation('product', 'query', {
  //       //   mpn: { predicate: 'eq', value: currentItem.mpn },
  //       //   version: { predicate: 'eq', value: 0 },
  //       // })

  //       // if (getProducts && getProducts.length > 0) {
  //       //   cpn = getProducts[0].cpn
  //       //   hpn = getProducts[0].hpn
  //       //   unit = getProducts[0].unit
  //       //   costCurrency = getProducts[0].costCurrency
  //       // }

  //       setFieldValue(`items[${index}]`, {
  //         itemsSelectablePos,
  //         // itemSelectablePoNumbers: [...new Set(getSpos.map((x) => x.poNumber))],
  //         mpn: itemsSelectablePos[0].mpn,
  //         poNumber,
  //         receivingCode,
  //         cpn: itemsSelectablePos[0].cpn,
  //         hpn: currentItem.hpn,
  //         unit: itemsSelectablePos[0].unit,
  //         costCurrency: itemsSelectablePos[0].costCurrency,

  //         // alertMessage: {},
  //         // amountExclVat: '',
  //       })
  //     }
  //     if (currentItem.poNumber && currentItem.poNumber !== prevPoNumber) {
  //       const items = currentItem.itemsSelectablePos.filter(
  //         (x) =>
  //           x.receivingCode === currentItem.poNumber ||
  //           x.poNumber === currentItem.poNumber,
  //       )

  //       // console.log('items', items)

  //       setFieldValue(`items[${index}]`, {
  //         ...currentItem,
  //         poNumber: currentItem.poNumber,
  //         qty: items[0].openQty,
  //         poCost: items[0].cost,
  //         costCurrency: items[0].costCurrency,
  //         poAmountExclVat: items[0].amountExclVat,
  //         poQty: items[0].qty,
  //       })
  //     }
  //   }

  //   func()
  // }, [
  //   prevPoNumber,
  //   prevReceivingCode,
  //   index,
  //   setFieldValue,
  //   prevHpn,
  //   values.vendor,
  //   currentItem,
  //   values,
  // ])

  // return (
  //   <Form className={classes.itemForm}>
  //     <TextField
  //       style={{ width: 320 }}
  //       name={`items[${index}].hpn`}
  //       select
  //       onChange={handleChange}
  //       onBlur={handleBlur}
  //       value={currentItem.hpn || ''}
  //       label='型号'>
  //       {selection.map((x, i) => (
  //         <MenuItem key={i} value={x.hpn}>
  //           {x.cpn} - {x.mpn}
  //         </MenuItem>
  //       ))}
  //     </TextField>

  //     <TextField
  //       name={`items[${index}].poNumber`}
  //       select
  //       style={{ width: 140 }}
  //       value={currentItem.poNumber || ''}
  //       label='订单号/入仓号'
  //       onBlur={handleBlur}
  //       onChange={handleChange}>
  //       {itemSelectablePoNumbers.map((x, i) => (
  //         <MenuItem key={i} value={x}>
  //           {x}
  //         </MenuItem>
  //       ))}
  //     </TextField>

  //     <TextField
  //       style={{ width: 80 }}
  //       name={`items[${index}].unit`}
  //       disabled
  //       value={currentItem.unit || ''}
  //       label='单位'
  //     />

  //     <TextField
  //       style={{ width: 100 }}
  //       // color='secondary'
  //       // color={
  //       //   item.alertMessage && item.alertMessage.qty ? 'secondary' : 'primary'
  //       // }
  //       name={`items[${index}].qty`}
  //       type='number'
  //       value={currentItem.qty || ''}
  //       label='数量'
  //       InputProps={{
  //         inputProps: {
  //           min: 0,
  //         },
  //       }}
  //       onBlur={handleBlur}
  //       onChange={handleChange}
  //     />

  //     <TextField
  //       // id='priceExlVat'
  //       name={`items[${index}].amountExclVat`}
  //       style={{ width: 140 }}
  //       // type='number'
  //       value={currentItem.amountExclVat || ''}
  //       label='不含税金额'
  //       InputProps={{
  //         inputProps: {
  //           min: 0,
  //         },
  //       }}
  //       onBlur={handleBlur}
  //       onChange={(e) => {
  //         setFieldValue(`items[${index}]`, {
  //           ...currentItem,
  //           amountExclVat: e.target.value,
  //           paymentAgentAmountExclVat: e.target.value,
  //         })
  //       }}
  //     />
  //     <TextField
  //       name={`items[${index}].priceExclVat`}
  //       disabled
  //       style={{ width: 100 }}
  //       value={
  //         (currentItem.amountExclVat &&
  //           currentItem.qty &&
  //           currentItem.amountExclVat / currentItem.qty) ||
  //         ''
  //       }
  //       label='不含税单价'
  //     />

  //     <TextField
  //       disabled
  //       name={`items[${index}].cost`}
  //       style={{ width: 100 }}
  //       value={
  //         currentItem.poCost ||
  //         (currentItem.poAmountExclVat &&
  //           currentItem.poQty &&
  //           (currentItem.poAmountExclVat / currentItem.poQty).toFixed(3)) ||
  //         ''
  //       }
  //       label='订单价格'
  //     />
  //     {values.usePaymentAgent && (
  //       <>
  //         <TextField
  //           disabled={!groups.includes('admin')}
  //           name={`items[${index}].paymentAgentAmountExclVat`}
  //           style={{ width: 120 }}
  //           value={currentItem.paymentAgentAmountExclVat || ''}
  //           label='EZPAY未税金额'
  //           onBlur={handleBlur}
  //           onChange={handleChange}
  //         />
  //         <TextField
  //           disabled
  //           name={`items[${index}].paymentAgentPriceExclVat`}
  //           style={{ width: 120 }}
  //           value={
  //             (currentItem.paymentAgentAmountExclVat &&
  //               currentItem.qty &&
  //               currentItem.paymentAgentAmountExclVat / currentItem.qty) ||
  //             ''
  //           }
  //           label='EZPAY未税单价'
  //           // onBlur={handleBlur}
  //           // onChange={handleChange}
  //         />
  //       </>
  //     )}
  //   </Form>
  // )
}

export default ItemComponent
