import handleDSOperation from '../handleDSOperation'
import config from '../../parameters'

const dsUpdateForwarderPODeliveryStatusWithReceivingCodeAndHpn = async ({
  receivingCode,
  hpn,
}) => {
  if (!receivingCode || !hpn) {
    alert('9035, argument not defined')
  }
  console.log('receiving code, hpn', receivingCode, hpn)

  // debugger
  // console.log('hpn', hpn)

  const getReceivings = await handleDSOperation('receiving', 'query', {
    receivingCode: {
      predicate: 'eq',
      value: receivingCode,
    },
    hpn: { predicate: 'eq', value: hpn },
  })

  const getForwarderPOs = await handleDSOperation('forwarderPo', 'query', {
    receivingCode: {
      predicate: 'eq',
      value: receivingCode,
    },
    hpn: { predicate: 'eq', value: hpn },
  })

  const receivedQty = getReceivings.reduce((a, v) => a + v.qty * 1, 0)

  const forwarderPoQty = getForwarderPOs.reduce((a, v) => a + v.qty * 1, 0)

  // console.log('getVatInvoices', getReceivings)
  // console.log('getForwarderPo', getForwarderPOs)
  // console.log('receivedQty', receivedQty)
  // console.log('forwarderPoQty', forwarderPoQty)

  // console.log('billedAmount,forwarderPoQty', billedAmount, forwarderPoAmount)

  // // debugger
  let deliveryStatus

  if (receivedQty === 0) {
    deliveryStatus = 'unDelivered'
  } else if (receivedQty < forwarderPoQty) {
    deliveryStatus = 'partial'
  } else if (receivedQty === forwarderPoQty) {
    deliveryStatus = 'delivered'
  } else {
    return alert(
      `${receivingCode}billing amount is greater than forwarder po qty`,
    )
  }

  // console.log('billingStatus', billingStatus)
  await Promise.all(
    getForwarderPOs.map(async (x) => {
      await handleDSOperation(null, 'update', {
        id: x.id,
        deliveryStatus,
      })
    }),
  )
}

export default dsUpdateForwarderPODeliveryStatusWithReceivingCodeAndHpn
