import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import SimpleTable from '../SimpleTable'
import handleDSOperation from '../../../functions/handleDSOperation'

const PoRecord = ({ poType, poNumber, hpn }) => {
  const [rows, setRows] = useState([])
  useEffect(() => {
    ;(async () => {
      let items = await handleDSOperation(poType, 'query', {
        poNumber: { predicate: 'eq', value: poNumber },
        hpn: { predicate: 'eq', value: hpn },
        version: { predicate: 'gt', value: 0 },
      })
      setRows(items)
    })()
  }, [poType, hpn, poNumber])

  return (
    <Box m={3}>
      <SimpleTable
        dataType={poType}
        tbType='collasped'
        rows={rows}
      />
    </Box>
  )
}

export default PoRecord
