import React, { useState } from 'react'
import { IconButton, Typography, Box, CircularProgress } from '@mui/material'

import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material'
import FulfillmentRecord from '../../components/Table/collapse/FulfillmentRecord'
import PoRecord from '../../components/Table/collapse/PoRecord'

const DetailPanel = ({ rowData }) => {
  const [openPoHistory, setOpenPoHistory] = useState(false)
  const [openShipping, setOpenShipping] = useState(false)
  const [openPoHistoryLoading, setOpenPoHistoryLoading] = useState(false)

  const SubItemMenu = () => (
    <div>
      <Typography variant='h6' component='span' gutterBottom>
        <IconButton
          aria-label='expand row'
          size='small'
          onClick={() => {
            setOpenShipping(false)
            setOpenPoHistory(!openPoHistory)
          }}>
          {openPoHistoryLoading ? (
            <CircularProgress size={12} />
          ) : openPoHistory ? (
            <RemoveIcon />
          ) : (
            <AddIcon />
          )}
        </IconButton>
        更改记录
      </Typography>
      <Typography variant='h6' gutterBottom component='span'>
        <IconButton
          aria-label='expand row'
          size='small'
          onClick={() => {
            setOpenPoHistory(false)
            setOpenShipping(!openShipping)
          }}>
          {openShipping ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
        出货记录
      </Typography>
    </div>
  )

  return (
    <Box m={3}>
      <SubItemMenu />
      {openPoHistory && (
        <PoRecord poType='cpo' poNumber={rowData.poNumber} hpn={rowData.hpn} />
      )}
      {openShipping && (
        <FulfillmentRecord
          fulfillmentType='shipping'
          poNumber={rowData.poNumber}
          hpn={rowData.hpn}
        />
      )}
    </Box>
  )
}

export default DetailPanel
