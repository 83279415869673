import { IconButton, Typography } from '@mui/material'
import {RemoveOutlined} from '@mui/icons-material'

export default function FormikRemoveBtnIcon({ items, onDelete }) {
  return (
    <span
      style={{
        display: 'inline-block',
        width: 100,
      }}>
      {items.length > 1 && (
        <IconButton aria-label='delete' onClick={onDelete}>
          <RemoveOutlined color='secondary' fontSize='large' />
          <Typography color='secondary'>删除</Typography>
        </IconButton>
      )}
    </span>
  )
}
