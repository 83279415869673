import handleDSOperation from '../../functions/handleDSOperation'

const handleSubmit = async (values) => {
  

  const type = 'contact'
  let deliveryTerm
  if (values.role && values.role.includes('receiving')) {
    deliveryTerm = values.deliveryTerm
  }

  const input = {
    isMainContact: values.isMainContact,
    type,
    entity: values.entity && values.entity.toUpperCase(),
    name: values.name && values.name.toUpperCase(),
    title: values.title && values.title.toUpperCase(),
    dept: values.dept && values.dept.toUpperCase(),
    role: values.role,
    email: values.email,
    phoneNumber: values.phoneNumber,
    memo: values.memo,
    address: values.address && values.address.toUpperCase(),
    deliveryTerm: deliveryTerm && deliveryTerm.toUpperCase(),
  }
  // console.log('input 888', input)
  await handleDSOperation(type, 'create', input)
}

export default handleSubmit
