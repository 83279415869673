const compareOrderQtyofArrays = (row, inputedArray, fetchedPoItems) => {
  const totalEnteredOrderQty = inputedArray.reduce(
    (a, v) =>
      v.poNumber === row.poNumber &&
      ((v.cpn && v.cpn === row.cpn) ||
        (v.mpn && v.mpn.toLowerCase().includes(row.mpn.toLowerCase())))
        ? a + v.qty
        : a,
    0,
  )

  // console.log('totalEnteredOrderQty', totalEnteredOrderQty)

  // debugger
  const totalOrderQty = fetchedPoItems.reduce(
    (a, v) =>
      (v.cpn && v.cpn === row.cpn) ||
      (v.mpn && v.mpn.toLowerCase().includes(row.mpn.toLowerCase()))
        ? a + v.qty
        : a,
    0,
  )

  if (totalOrderQty !== totalEnteredOrderQty) {
    row.alertMessage &&
      row.alertMessage.push({
        message: `Total entered order qty ${totalEnteredOrderQty} does not match system record ${totalOrderQty}`,
        index: 'qty',
        style: 'alertLevel5',
        level: 4,
      })
  }
}

export default compareOrderQtyofArrays
