import React, { useEffect } from 'react'

import { Typography, Backdrop, CircularProgress } from '@mui/material'

import { Form } from 'formik'
import { FormField } from '../../components/Form/FormField'

import handleDSOperation from '../../functions/handleDSOperation'

const CreateMainComponent = (props) => {
  const {
    values,
    submitForm,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    initialValues,
    setFieldValue,
    setValues,
    isSubmitting,
    formStyle,
  } = props

  const totalQty = values.items.reduce((a, v) => (v.qty ? a + v.qty : a), 0)

  useEffect(() => {
    const func = async () => {
      if (values.productOptions.length === 0) {
        const getProducts = await handleDSOperation('product', 'query', {
          version: { predicate: 'eq', value: 0 },
          isActive: { predicate: 'eq', value: true },
        })
        const productOptions = getProducts
          .map((x) => ({
            coo: x.coo,
            cost: x.cost,
            costCurrency: x.costCurrency,
            cpn: x.cpn,
            mfr: x.mfr,
            mpn: x.mpn,
            hpn: x.hpn,
            unit: x.unit,
            productType: x.productType,
            vendors: x.vendors,
            supplierMOQ: x.supplierMOQ,
            supplierMPQ: x.supplierMPQ,
            leadtime: x.leadtime,
            vendor: x.vendors.length === 1 ? x.vendors[0] : undefined,
          }))
          .sort((a, b) => (a.cpn > b.cpn ? 1 : -1))

        setFieldValue('productOptions', productOptions)
      }
    }
    func()
  }, [values, setFieldValue])

  return (
    <>
      <Form style={formStyle} autoComplete='off'>
        <div>
          <FormField
            fieldName='date'
            value={values.date}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />

          {totalQty > 0 && (
            <span style={{ marginLeft: 50 }}>
              <Typography
                color='secondary'
                component='span'
                variant='h6'
                align='center'>
                数量合计: {totalQty.toFixed(2)}
              </Typography>
            </span>
          )}
        </div>
      </Form>
      <Backdrop
        open={values.productOptions.length === 0}
        onClick={() => {}}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  )
}

export default CreateMainComponent
