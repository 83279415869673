import handleDSOperation from '../../functions/handleDSOperation'
import dsUpdatePaymentStatusOnInvoices from '../../functions/APIOperations/dsUpdatePaymentStatusOnInvoices'

const onRowDelete = async (oldData) => {
  // console.log('olddata', oldData)
  // debugger
  await handleDSOperation(null, 'delete', oldData.id)

  await dsUpdatePaymentStatusOnInvoices({
    invoiceNumber: oldData.invoiceNumber,
    customer: oldData.customer,
  })
}

export default onRowDelete
