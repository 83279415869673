import createItemInitialValue from './createItemInitialValue'

const initialValue = () => ({
  // vendors: [],
  // poOptions: [],
  vendor: '',
  selection: [],
  // mpns: [],
  date: new Date().toISOString().substring(0, 10),
  items: [createItemInitialValue],
})

export default initialValue
