import React, { useState } from 'react'
import {
  IconButton,
  Typography,
  MenuItem,
  Box,
  CircularProgress,
} from '@mui/material'

import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material'
import ProductRecord from '../../components/Table/collapse/ProductRecord'
import InvoiceRecord from '../../components/Table/collapse/InvoiceRecord'
import FulfillmentRecord from '../../components/Table/collapse/FulfillmentRecord'

const DetailPanel = ({ rowData }) => {
  const [openReceiving, setOpenReceiving] = useState(false)
  const [openShipping, setOpenShipping] = useState(false)
  const [openInvoice, setOpenInvoice] = useState(false)
  useState(false)

  const SubItemMenu = () => (
    <div>
      <Typography variant='h6' gutterBottom component='span'>
        <IconButton
          aria-label='expand row'
          size='small'
          onClick={() => {
            setOpenInvoice(false)
            setOpenShipping(false)
            setOpenReceiving(!openReceiving)
          }}>
          {openReceiving ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
        进货记录
      </Typography>

      <Typography variant='h6' gutterBottom component='span'>
        <IconButton
          aria-label='expand row'
          size='small'
          onClick={() => {
            setOpenInvoice(false)
            setOpenReceiving(false)
            setOpenShipping(!openShipping)
          }}>
          {openShipping ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
        出货记录
      </Typography>
      <Typography variant='h6' gutterBottom component='span'>
        <IconButton
          aria-label='expand row'
          size='small'
          onClick={() => {
            setOpenInvoice(!openInvoice)
            setOpenReceiving(false)
            setOpenShipping(false)
          }}>
          {openInvoice ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
        发票记录
      </Typography>
    </div>
  )

  return (
    <Box m={3}>
      <SubItemMenu />

      {openReceiving && (
        <FulfillmentRecord fulfillmentType={'receiving'} hpn={rowData.hpn} />
      )}
      {openShipping && (
        <FulfillmentRecord
          fulfillmentType={'shipping'}
          poNumber={rowData.poNumber}
          hpn={rowData.hpn}
        />
      )}
      {openInvoice && (
        <InvoiceRecord
          invoiceType='outgoingInvoice'
          customer={rowData.customer}
          cpn={rowData.cpn}
          poNumber={rowData.poNumber}
        />
      )}
    </Box>
  )
}

export default DetailPanel
