import React from 'react'

import config from '../../parameters'
import { FormField, ProductAutoComplete } from '../../components/Form/FormField'

// import usePrevious from '../../hooks/usePrevious'
import { Form } from 'formik'

const ItemComponent = ({
  values,
  handleBlur,
  index,
  handleChange,
  setFieldValue,
  formStyle,
  item: currentItem,
  userGroups: groups,
}) => {
  return (
    <Form style={formStyle} autoComplete='off'>
      <ProductAutoComplete
        id={values.items.length}
        index={index}
        options={values.productOptions}
        value={
          currentItem.hpn
            ? {
                // hpn: currentItem.hpn,
                cpn: currentItem.cpn,
                mfr: currentItem.mfr,
                mpn: currentItem.mpn,
                // price: currentItem.price * 1,
                // unit: currentItem.unit,
                // priceCurrency: currentItem.priceCurrency,
                // customerMOQ: currentItem.customerMOQ,
                // customerMPQ: currentItem.customerMPQ,
                // customerLeadtime: currentItem.customerLeadtime,
              }
            : null
        }
        currentItem={currentItem}
        setFieldValue={setFieldValue}
      />
      <FormField
        fieldName='mfr'
        index={index}
        value={currentItem.mfr}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />
      <FormField
        fieldName='mpn'
        index={index}
        value={currentItem.mpn}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />
      <FormField
        fieldName='cpn'
        index={index}
        value={currentItem.cpn}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />
      <FormField
        fieldName='price'
        index={index}
        value={currentItem.price}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />
      <FormField
        fieldName='priceCurrency'
        select
        index={index}
        value={currentItem.priceCurrency}
        handleBlur={handleBlur}
        handleChange={handleChange}
        options={config.currencies.map((x) => x.value)}
      />
      <FormField
        fieldName='unit'
        select
        index={index}
        value={currentItem.unit}
        handleBlur={handleBlur}
        handleChange={handleChange}
        options={config.units}
      />
      <FormField
        fieldName='customerMPQ'
        index={index}
        value={currentItem.customerMPQ}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />

      <FormField
        fieldName='customerMOQ'
        index={index}
        value={currentItem.customerMOQ}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />

      <FormField
        fieldName='customerLeadtime'
        index={index}
        value={currentItem.customerLeadtime}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />
    </Form>
  )
}

export default ItemComponent
