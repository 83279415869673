import React from 'react'

import { FormField } from '../../components/Form/FormField'

import { Form } from 'formik'

const EditComponent = (props) => {
  const {
    values,
    submitForm,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    setFieldValue,
    setValues,
    isSubmitting,
    formStyle,
    userGroups: groups,
  } = props

  const cost =
    values.amountExclVat && values.qty && values.amountExclVat / values.qty

  return (
    <Form style={formStyle} autoComplete='off'>
      <div>
        <FormField
          disabled={!groups.includes('admin')}
          fieldName='date'
          value={values.date}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />

        <FormField
          disabled={!groups.includes('admin')}
          fieldName='receivingCode'
          value={values.receivingCode}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />

        <FormField
          disabled={!groups.includes('admin')}
          fieldName='vendor'
          value={values.vendor}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />

        <FormField
          disabled={!groups.includes('admin')}
          fieldName='poNumber'
          value={values.poNumber}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
      </div>
      <div>
        <FormField
          disabled={!groups.includes('admin')}
          select
          fieldName='costCurrency'
          value={values.costCurrency}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          disabled={!groups.includes('admin')}
          fieldName='exchangeRate'
          value={values.exchangeRate}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          disabled={!groups.includes('admin')}
          fieldName='cpn'
          value={values.cpn}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          disabled={!groups.includes('admin')}
          fieldName='mpn'
          value={values.mpn}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />

        <FormField
          disabled={!groups.includes('admin')}
          fieldName='qty'
          value={values.qty}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          disabled={!groups.includes('admin')}
          fieldName='openQty'
          value={values.openQty}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          disabled={!groups.includes('admin')}
          fieldName='amountExclVat'
          value={values.amountExclVat}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
      </div>
      <div>
        <FormField disabled fieldName='cost' value={cost} />
        <FormField
          disabled
          fieldName='vat'
          value={values.vat.toFixed(2)}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          disabled
          fieldName='handlingFee'
          value={values.handlingFee.toFixed(2)}
        />
        <FormField
          disabled
          fieldName='extraCharge'
          value={values.extraCharge.toFixed(2)}
        />
      </div>
      <div>
        {/* <TextField
            id='vendorPaymentPaid'
            type='date'
            style={{ width: 150 }}
            InputLabelProps={{ shrink: true }}
            value={values.vendorPaymentPaid || ''}
            onBlur={handleBlur}
            onChange={handleChange}
            label='供应商货款付款日'
          /> */}
        {/* <TextField
            diabled
            id='forwarderTaxAndHandlingDue'
            type='date'
            InputLabelProps={{ shrink: true }}
            style={{ width: 150 }}
            value={values.forwarderTaxAndHandlingDue || ''}
            onBlur={handleBlur}
            onChange={handleChange}
            label='税代到期'
          /> */}
        {/* <TextField
            diabled
            id='forwarderTaxAndHandlingPaid'
            InputLabelProps={{ shrink: true }}
            type='date'
            style={{ width: 150 }}
            value={values.forwarderTaxAndHandlingPaid || ''}
            onBlur={handleBlur}
            onChange={handleChange}
            label='税代支付日期'
          /> */}
        {/* <TextField
            id='forwarderPaymentDue'
            InputLabelProps={{ shrink: true }}
            type='date'
            style={{ width: 150 }}
            value={values.forwarderPaymentDue || ''}
            onBlur={handleBlur}
            onChange={handleChange}
            label='货代货款到期'
          /> */}
        {/* <TextField
            id='forwarderPaymentPaid'
            type='date'
            InputLabelProps={{ shrink: true }}
            style={{ width: 150 }}
            value={values.forwarderPaymentPaid || ''}
            onBlur={handleBlur}
            onChange={handleChange}
            label='货代货款支付日期'
          /> */}
      </div>
      <div>
        <FormField
          disabled={!groups.includes('admin')}
          select
          fieldName='paymentStatus'
          value={values.paymentStatus}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />

        <FormField
          disabled={!groups.includes('admin')}
          select
          fieldName='deliveryStatus'
          value={values.deliveryStatus}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          disabled={!groups.includes('admin')}
          select
          fieldName='billingStatus'
          value={values.billingStatus}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
      </div>
      <div>
        <FormField
          fieldName='memo'
          multiline
          value={values.memo}
          handleBlur={handleBlur}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
        />
      </div>
    </Form>
  )
}

export default EditComponent
