import spoCreateItemInitialValue from './createItemInitialValue'

const initialValue = () => ({
  date: new Date().toISOString().substring(0, 10),
  poNumber: '',
  customer: '',
  // customerOptions:,
  deliveryTerms: [],
  productOptions: [],
  items: [spoCreateItemInitialValue],
})

export default initialValue
