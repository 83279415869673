import React from 'react'
import { FormField } from '../../../components/Form/FormField'
import config from '../../../parameters'

const ShippingFee = ({ values, handleBlur, handleChange }) => {
  return (
    <>
      <FormField
        fieldName='invoiceNumber'
        value={values.invoiceNumber}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />
      <FormField
        select
        fieldName='carrier'
        value={values.carrier}
        handleBlur={handleBlur}
        handleChange={handleChange}
        options={config.carrier}
      />
      <FormField
        fieldName='desc'
        value={values.desc}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />
      <FormField
        fieldName='amount'
        value={values.amount}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />
    </>
  )
}

export default ShippingFee
