import handleDSOperation from '../handleDSOperation'
import mergeArrayAndCombineQty from '../helpFunc/mergeArrayAndCombineQty'

const dsCreateShipments = async (items) => {
  const mergedArr = mergeArrayAndCombineQty(
    items,
    ['hpn', 'poNumber', 'price', 'cartonNumber'],
    ['qty'],
  )
  // console.log('mergedArr', mergedArr)
  await Promise.all(
    mergedArr.map(async (x) => {
      await handleDSOperation('shipping', 'create', x)
    }),
  )
}

export default dsCreateShipments
