import handleDSOperation from '../../functions/handleDSOperation'

const onRowUpdate = async (newData) => {
  const updateObj = {
    id: newData.id,
    date: newData.date,
    customer: newData.customer,
    cpn: newData.cpn,
    poNumber: newData.poNumber,
    invoiceType: newData.invoiceType,
    invoiceNumber: newData.invoiceNumber,
    qty: newData.qty && newData.qty * 1,
    price: newData.price && newData.price * 1,
    priceCurrency: newData.priceCurrency,
    customerPaymentDue: newData.customerPaymentDue,
    customerPaymentPaid: newData.customerPaymentPaid,
    paymentStatus: newData.paymentStatus,
    memo: newData.memo,
    unit:newData.unit,
  }
  // console.log('updateObj', updateObj)

  // debugger

  await handleDSOperation(null, 'update', updateObj)
}

export default onRowUpdate
