import handleDSOperation from '../../functions/handleDSOperation'

const onSelectedDelete = async (selectedRows) => {
  await Promise.all(
    selectedRows.map(async (x) => {
      await handleDSOperation(null, 'delete', x.id)
    }),
  )
}

export default onSelectedDelete
