const mapping = (type) => {
  switch (type) {
    case 'newAndExistingBSLCpos':
      return [
        { index: 0, attr: 'reqSched' },
        { index: 1, attr: 'cpn' },
        { index: 4, attr: 'poPrefix' },
        { index: 5, attr: 'poNumber' },
        { index: 6, attr: 'date' },
        { index: 10, attr: 'qty' },
        { index: 11, attr: 'openQty' },
        { index: 13, attr: 'enteredPrice' },
      ]
    case 'newAndExistingOralCpos':
      return [
        { index: 1, attr: 'cpn' },
        { index: 4, attr: 'poPrefix' },
        { index: 5, attr: 'poNumber' },
        { index: 6, attr: 'date' },
        { index: 10, attr: 'reqSched' },
        { index: 11, attr: 'qty' },
        { index: 13, attr: 'openQty' },
        { index: 15, attr: 'enteredPrice' },
      ]
    case 'newAndExistingGeneralCpos':
      return [
        { index: 1, attr: 'reqSched' },
        { index: 2, attr: 'cpn' },
        { index: 3, attr: 'poPrefix' },
        { index: 4, attr: 'poNumber' },
        { index: 5, attr: 'date' },
        { index: 6, attr: 'qty' },
        { index: 7, attr: 'openQty' },
        { index: 8, attr: 'price' },
        { index: 9, attr: 'priceCurrency' },
        { index: 10, attr: 'latestVersion' },
      ]

    case 'existingSpo':
      return [
        { index: 0, attr: 'poNumber' },
        // { index: 1, attr: 'pid' },
        { index: 1, attr: 'cpn' },
        { index: 2, attr: 'mpn' },
        { index: 3, attr: 'qty' },
        { index: 4, attr: 'openQty' },
        // { index: 4, attr: 'reqSched' },
        { index: 5, attr: 'estSched' },
        // { index: 8, attr: 'orderDate' },
      ]
    case 'newSpo':
      return [
        { index: 0, attr: 'date' },
        { index: 1, attr: 'poNumber' },
        { index: 2, attr: 'cpn' },
        { index: 3, attr: 'qty' },
        { index: 4, attr: 'openQty' },
        { index: 5, attr: 'reqSched' },
        { index: 6, attr: 'sendEmail' },
      ]

    case 'newShipping':
      return [
        { index: 0, attr: 'poPrefix' },
        { index: 1, attr: 'poNumber' },
        { index: 2, attr: 'cpn' },
        { index: 3, attr: 'qty' },
        { index: 4, attr: 'price' },
        { index: 5, attr: 'cartonNumber' },
        { index: 6, attr: 'gw' },
        { index: 7, attr: 'dimensions' },
        { index: 8, attr: 'deliveryTerm' },
        { index: 9, attr: 'coo' },
        { index: 10, attr: 'fulfillmentNumber' },
      ]

    case 'newReceiving':
      return [
        { index: 0, attr: 'poNumber' },
        { index: 1, attr: 'pid' },
        { index: 2, attr: 'cpn' },
        { index: 3, attr: 'qty' },
        { index: 4, attr: 'date' },
      ]
    case 'newVendor':
      return [
        { index: 0, attr: 'entity' },
        { index: 1, attr: 'number' },
        { index: 2, attr: 'paymentTerm' },
        { index: 3, attr: 'deliveryTerms' },
        { index: 4, attr: 'mainContactName' },
        { index: 5, attr: 'mainContactRole' },
        { index: 6, attr: 'mainContactPhoneNumber' },
        { index: 7, attr: 'mainContactEmail' },
        { index: 8, attr: 'type' },
        { index: 9, attr: 'hankenoEntity' },
        { index: 10, attr: 'entityType' },
      ]
    case 'newCustomer':
      return [
        { index: 0, attr: 'entity' },
        { index: 1, attr: 'number' },
        { index: 2, attr: 'paymentTerm' },
        { index: 3, attr: 'deliveryTerms' },
        { index: 4, attr: 'mainContactName' },
        { index: 5, attr: 'mainContactRole' },
        { index: 6, attr: 'mainContactPhoneNumber' },
        { index: 7, attr: 'mainContactEmail' },
        { index: 8, attr: 'type' },
        { index: 9, attr: 'hankenoEntity' },
        { index: 10, attr: 'billingInfo' },
      ]
    case 'newContact':
      return [
        { index: 1, attr: 'entity' },
        { index: 2, attr: 'name' },
        { index: 3, attr: 'isMainContact' },
        { index: 4, attr: 'dept' },
        { index: 5, attr: 'role' },
        { index: 6, attr: 'title' },
        { index: 7, attr: 'email' },
        { index: 8, attr: 'address' },
        { index: 9, attr: 'phoneNumber' },
      ]
    case 'newProduct':
      return [
        { index: 1, attr: 'hpn' },
        { index: 2, attr: 'mfr' },
        { index: 3, attr: 'mpn' },
        { index: 4, attr: 'productType' },
        { index: 5, attr: 'coo' },
        { index: 6, attr: 'unit' },
        { index: 7, attr: 'vendor' },
        { index: 8, attr: 'supplierMOQ' },
        { index: 9, attr: 'supplierMPQ' },
        { index: 10, attr: 'leadtime' },
        { index: 11, attr: 'transitDays' },
        { index: 12, attr: 'cost' },
        { index: 13, attr: 'costCurrency' },
        { index: 14, attr: 'cpn' },
        { index: 15, attr: 'price' },
        { index: 16, attr: 'priceCurrency' },
        { index: 17, attr: 'customerMOQ' },
        { index: 18, attr: 'customerMPQ' },
        { index: 19, attr: 'customerLeadtime' },
        { index: 20, attr: 'initialInventory' },
        { index: 21, attr: 'safetyInventory' },
        { index: 22, attr: 'currentInventory' },
        { index: 23, attr: 'spec' },
      ]
    case 'newIncomingInvoice':
      return [
        { index: 0, attr: 'invoiceType' },
        { index: 1, attr: 'date' },
        { index: 2, attr: 'invoiceNumber' },
        { index: 3, attr: 'vendor' },
        { index: 4, attr: 'poNumberOrReceivingNumber' },
        { index: 5, attr: 'cpn' },
        { index: 6, attr: 'qty' },
        { index: 7, attr: 'amountExclVat' },
      ]
    case 'newOutgoingVatInvoice':
      return [
        { index: 0, attr: 'poPrefix' },
        { index: 1, attr: 'poNumber' },
        { index: 2, attr: 'cpn' },
        { index: 3, attr: 'qty' },
        { index: 4, attr: 'price' },
        { index: 9, attr: 'invoiceNumber' },
      ]
    default:
      return []
  }
}

export default mapping
