const FormikErrorMessage = ({ errors, touched, index }) => {
  // console.log('errors', errors)
  // console.log('touched', touched)
  // console.log('index', index)

  if (index === undefined) {
    const arrOfErr = Object.entries(errors).filter((x) => x[0] !== 'items')

    return arrOfErr.map(([attr, errorMsg], i) => {
      return touched[attr] ? (
        <div key={i} style={{ color: 'red' }}>
          {errorMsg}
        </div>
      ) : null
    })
  } else if (errors.items?.[index] && touched.item?.[index]) {
    // console.log('touched', touched)
    // console.log('errors', errors)

    const arr = Object.entries(errors.items[index])
    // console.log('arr', arr)

    return (
      <>
        {arr.map(([attr, errorMsg], i) => {
          // console.log('errorMsg', errorMsg)
          return touched.items[index][attr] ? (
            <div key={i} style={{ color: 'red' }}>
              {errorMsg}
            </div>
          ) : null
        })}
      </>
    )
  }
  return null
}

export default FormikErrorMessage
