const poItemGetValue = (row, item) => {


  row.cpn = row.cpn || item.cpn
  row.date = row.date || item.date
  row.reqSched = row.reqSched || item.reqSched
  row.price = row.price || item.price
  row.priceCurrency = row.priceCurrency || item.priceCurrency
  row.cost = row.cost || item.cost
  row.costCurrency = row.costCurrency || item.costCurrency
  row.mpn = item.mpn
  row.hpn = row.hpn || item.hpn
  row.mfr = row.mfr || item.mfr
  row.unit = row.unit || item.unit
  row.customer = row.customer || item.customer
  row.customerId = row.customerId || item.customerId
  row.vendor = row.vendor || item.vendor
  row.vendorId = row.vendorId || item.vendorId
  // row.memo = row.memo || item.memo
}

export default poItemGetValue
