import getRowValue from './getRowValue'
import filterOutPropertyForInvalidRows from '../rowValidator/filterOutPropertyForInvalidRows'
import isPoExistingAndGetLatestVersionAndRecord from '../rowValidator/isPoExistingAndGetLatestVersionAndRecord'

const arrToObjForReceiving = async (rows) => {
  const dataType = 'receiving'
  const inputType = 'newReceiving'

  rows = getRowValue(dataType, inputType, rows)
  // console.log('rows3333333333', rows)

  rows = await isPoExistingAndGetLatestVersionAndRecord(rows)

  // console.log('rows444444444', rows)

  return filterOutPropertyForInvalidRows(rows)
}

export default arrToObjForReceiving
