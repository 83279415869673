import { firstBy } from 'thenby'
import handleDSOperation from '../handleDSOperation'
import mergeArrayAndCombineQty from '../helpFunc/mergeArrayAndCombineQty'
import groupArrayofObjectsByKey from '../helpFunc/groupArrayofObjectsByKey'
import getDeductedItems from '../helpFunc/getDeductedItems'
import dsUpdateShipmentBillingStatusWithPoNumberAndCpnAndCustomer from './dsUpdateShipmentBillingStatusWithPoNumberAndCpnAndCustomer'

const dsUpdateShipmentsBillingStatusWithPoNumberAndCpnAndCustomer = async (
  items,
  addOrMinus,
) => {
  const arrByCustomer = Object.entries(
    groupArrayofObjectsByKey(items, 'customer'),
  )

  await Promise.all(
    arrByCustomer.map(async ([customer, itemsByCustomer]) => {
      // console.log('customer', customer)

      const arrByPoNumber = Object.entries(
        groupArrayofObjectsByKey(itemsByCustomer, 'poNumber'),
      )
      await Promise.all(
        arrByPoNumber.map(async ([poNumber, itemsByPoNumber]) => {
          // console.log('poNumber', poNumber)
          const arrByPoCpn = Object.entries(
            groupArrayofObjectsByKey(itemsByPoNumber, 'cpn'),
          )
          await Promise.all(
            arrByPoCpn.map(async ([cpn, itemsByCpn]) => {
              // console.log('cpn', cpn)
              // console.log('itemsByCpn', itemsByCpn)
              await dsUpdateShipmentBillingStatusWithPoNumberAndCpnAndCustomer({
                customer,
                poNumber,
                cpn,
                itemsByCpn,
                addOrMinus,
              })
            }),
          )
        }),
      )
    }),
  )
}

export default dsUpdateShipmentsBillingStatusWithPoNumberAndCpnAndCustomer
