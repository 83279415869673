import handleDSOperation from '../handleDSOperation'

const dsCreatePoItem = async ({ id, ...item }, latestVersion) => {
  if (item.openQty === undefined) {
    alert('9998, open qty is undefined')
  }
  const createPoItemInput = {
    date: item.date,
    type: item.type,
    poNumber: item.poNumber && item.poNumber.trim().toUpperCase(),
    hpn: item.hpn && item.hpn.trim().toUpperCase(),
    cpn: item.cpn && item.cpn.trim().toUpperCase(),
    mpn: item.mpn && item.mpn.trim().toUpperCase(),
    mfr: item.mfr && item.mfr.trim().toUpperCase(),
    unit: item.unit,
    cost: item.cost && item.cost * 1,
    costCurrency: item.costCurrency && item.costCurrency.trim().toUpperCase(),
    price: item.price && item.price * 1,
    priceCurrency:
      item.priceCurrency && item.priceCurrency.trim().toUpperCase(),
    qty: item.qty * 1,
    openQty: item.openQty * 1,
    reqSched: item.reqSched,
    estSched: item.estSched,
    customer: item.customer && item.customer.trim().toUpperCase(),
    customerId: item.customerId,
    vendor: item.vendor && item.vendor.trim().toUpperCase(),
    vendorId: item.vendorId,
    memo: item.memo && item.memo.trim(),
    deliveryTerm: item.deliveryTerm && item.deliveryTerm.trim().toUpperCase(),
  }

  // console.log('createPoItemInput', createPoItemInput)
  // debugger
  const createLatestVersionObj = {
    ...createPoItemInput,
    version: latestVersion + 1,
  }

  // console.log('createObj', createObj)
  await handleDSOperation(item.type, 'create', createLatestVersionObj)

  const updateOrCreateVersionZeroObj = {
    ...createPoItemInput,
    version: 0,
    latestVersion: latestVersion + 1,
  }
  
  if (id) {
    // console.log('update888id', id)
    // console.log('updateOrCreateVersionZeroObj')

    // debugger
    await handleDSOperation(null, 'update', {
      id,
      ...updateOrCreateVersionZeroObj,
    })
  } else {
    // console.log('create')
    await handleDSOperation(item.type, 'create', updateOrCreateVersionZeroObj)
  }
}

export default dsCreatePoItem
