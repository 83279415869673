import getProductInfoForNewRowItems from '../APIOperations/getProductInfoForNewRowItems'
import generateSPONumberAndPIDForRowItems from '../APIOperations/generateSPONumberAndPIDForRowItems'
import getRowValue from './getRowValue'
import filterOutPropertyForInvalidRows from '../rowValidator/filterOutPropertyForInvalidRows'
import onePoOnePnCheck from '../rowValidator/onePoOnePnCheck'
const arrToObjForNewSpoInput = async (rows) => {
  const dataType = 'spo'
  const inputType = 'newSpo'

  rows = getRowValue(dataType, inputType, rows)

  const poNumber = Date.now().toString().slice(1, 9)
  // console.log('rows:', rows)
  // debugger
  rows.forEach((row, i) => {
    row.isNew = 'new'
    row.openQty = row.openQty === undefined ? row.qty : row.openQty
    row.date = row.date || new Date().toISOString().substring(0, 10)
    row.poNumber = (row.poNumber || poNumber * 1 + i).toString()
  })

  onePoOnePnCheck(rows)

  rows = await getProductInfoForNewRowItems(rows)

  rows = generateSPONumberAndPIDForRowItems(rows)

  // console.log('spo rows', rows)


  return filterOutPropertyForInvalidRows(rows)
}

export default arrToObjForNewSpoInput
