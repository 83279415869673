import React, { useState, useEffect, useRef, Fragment } from 'react'
import usePrevious from '../../hooks/usePrevious'
import { Typography } from '@mui/material'
import { FormField } from '../../components/Form/FormField'
import { Form } from 'formik'
import config from '../../parameters'
import groupArrayofObjectsByKey from '../../functions/helpFunc/groupArrayofObjectsByKey'
import handleDSOperation from '../../functions/handleDSOperation'

const CreateMainComponent = (props) => {
  const {
    values,
    submitForm,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    initialValues,
    setFieldValue,
    setValues,
    isSubmitting,
    formStyle,
  } = props

  const taxTotal = values.items && values.items.reduce((a, v) => a + v.vat, 0)

  const dutyTotal = values.items && values.items.reduce((a, v) => a + v.duty, 0)

  const handlingFeeTotal =
    values.items && values.items.reduce((a, v) => a + v.handlingFee, 0)

  const cnyValueTotal =
    values.items &&
    values.items.reduce(
      (a, v) =>
        (v.qty && v.cost && a + v.qty * v.cost * values.exchangeRate) || a,
      0,
    )

  const prevVendor = usePrevious(values.vendor)
  const prevInvoiceNumber = usePrevious(values.invoiceNumber)
  const prevExchangeRate = usePrevious(values.exchangeRate)

  useEffect(() => {
    const func = async () => {
      if (!values.allInvoices) {
        const getInvoices = await handleDSOperation(
          'incomingInvoice',
          'query',
          {
            invoiceType: { predicate: 'eq', value: 'PI' },
            usePaymentAgent: { predicate: 'ne', value: true },
            receivingCode: { predicate: 'eq', value: '' },
          },
        )
        if (getInvoices.length === 0) {
          return alert('请先输入PI')
        }

        // console.log('getInvoices', getInvoices)
        setFieldValue('allInvoices', getInvoices)
      }

      if (values.vendor && values.vendor !== prevVendor) {
        const getInvoices = values.allInvoices.filter(
          (x) => x.vendor === values.vendor,
        )
        getInvoices.sort((a, b) => (a.date > b.date ? -1 : 1))

        const invoiceNumber = getInvoices[0].invoiceNumber

        setValues({
          ...values,
          invoiceNumber,
          invoiceOptions: getInvoices,
        })
      }
      if (values.invoiceNumber && values.invoiceNumber !== prevInvoiceNumber) {
        const invoiceItems = values.invoiceOptions
          .filter((x) => x.invoiceNumber === values.invoiceNumber)
          .map((y) => ({
            invoiceID: y.id,
            poNumber: y.poNumber,
            cpn: y.cpn,
            hpn: y.hpn,
            mpn: y.mpn,
            mfr: y.mfr,
            amountExclVat: y.amountExclVat,
            // cost: y.cost,
            costCurrency: y.costCurrency,
            unit: y.unit,
            qty: y.qty,
            vendorPaymentDue: y.vendorPaymentDue,
          }))

        const arrOfinvoiceItemsByHpn = Object.entries(
          groupArrayofObjectsByKey(invoiceItems, 'hpn'),
        )
        await Promise.all(
          arrOfinvoiceItemsByHpn.map(async ([hpn, arrByHpn]) => {
            const getProd = await handleDSOperation('product', 'query', {
              version: { predicate: 'eq', value: 0 },
              hpn: { predicate: 'eq', value: hpn },
            })

            // console.log('hpn', hpn)

            // console.log('getProd', getProd)

            const productType = getProd[0].productType
            const getImportDuty = config.productTypeAndImportDuty.find(
              (x) => x.type === productType,
            )
            const exchangeRate = values.exchangeRate || 0
            const importDuty =
              (getImportDuty && getImportDuty.importDuty * 1) || 0

            arrByHpn.forEach((x) => {
              x.importDuty = importDuty
              x.cnyValue = exchangeRate * x.amountExclVat || 0
              x.cost =
                (x.amountExclVat && x.qty && x.amountExclVat / x.qty) || 0
              x.duty = x.cnyValue * x.importDuty || 0
              x.handlingFee =
                (x.cnyValue + x.duty) * config.forwarder.handlingFeeRate
              x.vat = (x.cnyValue + x.duty + x.handlingFee) * config.vatRate
              x.contractAmount = x.cnyValue + x.duty + x.handlingFee + x.vat
            })
          }),
        )

        const handlingFeeTotal = invoiceItems.reduce(
          (a, v) => a + v.handlingFee,
          0,
        )

        let extraCharge
        if (handlingFeeTotal < config.forwarder.mininumServiceFee) {
          extraCharge =
            (config.forwarder.mininumServiceFee - handlingFeeTotal) *
            (1 + config.vatRate)
        } else {
          extraCharge = 0
        }
        setValues({
          ...values,
          items: invoiceItems,
          extraCharge,
        })
      }

      if (
        values.exchangeRate &&
        values.exchangeRate !== prevExchangeRate &&
        values.vendor
      ) {
        const invoiceItems = [...values.items]
        invoiceItems.forEach((x) => {
          x.cnyValue =
            values.exchangeRate && values.exchangeRate * x.amountExclVat
          x.duty = x.cnyValue * x.importDuty
          x.handlingFee =
            (x.cnyValue + x.duty) * config.forwarder.handlingFeeRate
          x.vat = (x.cnyValue + x.duty + x.handlingFee) * config.vatRate
          x.contractAmount = x.cnyValue + x.duty + x.handlingFee + x.vat

          // console.log('x.contractAmount', x.contractAmount)
        })

        const handlingFeeTotal = invoiceItems.reduce(
          (a, v) => a + v.handlingFee,
          0,
        )

        let extraCharge
        if (handlingFeeTotal < config.forwarder.mininumServiceFee) {
          extraCharge =
            (config.forwarder.mininumServiceFee - handlingFeeTotal) *
            (1 + config.vatRate)
        } else {
          extraCharge = 0
        }
        setValues({
          ...values,
          items: invoiceItems,
          extraCharge,
        })
      }
    }
    func()
  }, [
    prevVendor,
    prevInvoiceNumber,
    prevExchangeRate,
    setFieldValue,
    values,
    setValues,
  ])

  const vendorOptions = [
    ...new Set((values.allInvoices || []).map((x) => x.vendor)),
  ]
  // console.log('vendorOptions', vendorOptions)

  return (
    <>
      <Form style={formStyle} autoComplete='off'>
        <FormField
          fieldName='date'
          value={values.date}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          fieldName='receivingCode'
          value={values.receivingCode}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          fieldName='exchangeRate'
          value={values.exchangeRate}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          fieldName='vendor'
          select
          value={values.vendor}
          handleBlur={handleBlur}
          handleChange={handleChange}
          options={vendorOptions}
        />
        <FormField
          fieldName='invoiceNumber'
          select
          value={values.invoiceNumber}
          handleBlur={handleBlur}
          handleChange={handleChange}
          options={[
            ...new Set(values.invoiceOptions.map((x) => x.invoiceNumber)),
          ]}
        />

        <div style={{ height: 50 }}>
          {taxTotal > 0 && (
            <>
              <Typography color='textSecondary' variant='h6' align='center'>
                税款合计: {taxTotal.toFixed(2)}
                {'  '}
                {'  '}
                关税合计: {dutyTotal.toFixed(2)}
                {'  '}
                {'  '}
                代理费合计: {handlingFeeTotal.toFixed(2)}
                {'  '}
                {'  '}
                附加: {values.extraCharge.toFixed(2)}
              </Typography>
              <Typography color='primary' variant='h6' align='center'>
                税代合计:
                {(
                  taxTotal +
                  dutyTotal +
                  handlingFeeTotal +
                  values.extraCharge
                ).toFixed(2)}
                总计:
                {(
                  cnyValueTotal +
                  taxTotal +
                  dutyTotal +
                  handlingFeeTotal +
                  values.extraCharge
                ).toFixed(2)}
              </Typography>
            </>
          )}
        </div>
      </Form>
    </>
  )
}

export default CreateMainComponent
