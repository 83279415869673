import getRowValue from './getRowValue'
import filterOutPropertyForInvalidRows from '../rowValidator/filterOutPropertyForInvalidRows'
import groupArrayofObjectsByKey from '../helpFunc/groupArrayofObjectsByKey'
import handleDSOperation from '../handleDSOperation'
import getPaymentDue from '../helpFunc/getPaymentDue'

import config from '../../parameters'

const arrToObjForIncomingInvoiceInput = async (rows) => {
  const dataType = 'incomingInvoice'
  const inputType = 'newIncomingInvoice'

  let items = getRowValue(dataType, inputType, rows)

  items.forEach((x) => {
    x.invoiceCostExclVat = (x.amountExclVat / x.qty).toFixed(4) * 1
    x.costCurrency = 'CNY'
  })

  // GET VENDOR INFO (NAME AND PAYMENT DUE)
  items = Object.entries(groupArrayofObjectsByKey(items, 'vendor'))
  items = await Promise.all(
    items.map(async ([vendor, itemsByPoVendor]) => {
      // console.log('vendor', vendor)
      const getVendor = await handleDSOperation('vendor', 'query', {
        entity: { predicate: 'contains', value: vendor },
      })
      if (getVendor.length === 0) {
        itemsByPoVendor.forEach((x) => {
          x.alertMessage.push({
            message: 'vendor is not in system',
            index: 'vendor',
            style: 'alertLevel5',
            level: 4,
          })
        })
        return itemsByPoVendor
      }
      const isForwarder = getVendor[0].entityType === 'FORWARDER' ? true : false
      const paymentDue = getPaymentDue(getVendor[0].paymentTerm)
      const entity = getVendor[0].entity
      itemsByPoVendor.forEach((x) => {
        x.vendor = entity
        x.vendorPaymentDue = paymentDue
        x.isForwarder = isForwarder
        isForwarder
          ? (x.receivingCode = x.poNumberOrReceivingNumber)
          : (x.poNumber = x.poNumberOrReceivingNumber)
      })
      return itemsByPoVendor
    }),
  )
  items = [].concat.apply([], items)

  //*******************************************************************/

  // GET RECEIVING INFO

  items = Object.entries(
    groupArrayofObjectsByKey(items, 'poNumberOrReceivingNumber'),
  )

  items = await Promise.all(
    items.map(async ([poNumberOrReceivingNumber, itemsByNumber]) => {
      const isForwarder = itemsByNumber[0].isForwarder

      const attr = isForwarder ? 'receivingCode' : 'poNumber'

      const getReceivingsPerNumber = await handleDSOperation(
        'receiving',
        'query',
        {
          [attr]: {
            predicate: 'eq',
            value: poNumberOrReceivingNumber,
          },
        },
      )

      let itemsByCpns = Object.entries(
        groupArrayofObjectsByKey(itemsByNumber, 'cpn'),
      )

      itemsByCpns = itemsByCpns.map(([cpn, itemsByCpn]) => {
        const getReceivingsPerCpn = getReceivingsPerNumber.filter(
          (x) => x.cpn === cpn,
        )

        const { mpn, mfr, hpn, cost, unit } = getReceivingsPerCpn[0]

        itemsByCpn.forEach((x) => {
          x.mpn = mpn
          x.mfr = mfr
          x.unit = unit
          x.hpn = hpn
          x.cost = cost
          x.totalReceivedQty = getReceivingsPerCpn.reduce(
            (a, v) => a + v.qty,
            0,
          )
        })
        return itemsByCpn
      })

      itemsByCpns = [].concat.apply([], itemsByCpns)
      return itemsByCpns
    }),
  )

  items = [].concat.apply([], items)

  //*******************************************************************/

  // RUN VALIDATOR

  items = Object.entries(
    groupArrayofObjectsByKey(items, 'poNumberOrReceivingNumber'),
  )

  items = await Promise.all(
    items.map(async ([poNumberOrReceivingNumber, itemsByNumber]) => {
      const isForwarder = itemsByNumber[0].isForwarder

      const attr = isForwarder ? 'receivingCode' : 'poNumber'

      const getInvoicesPerNumber = await handleDSOperation(
        'incomingInvoice',
        'query',
        {
          [attr]: {
            predicate: 'eq',
            value: poNumberOrReceivingNumber,
          },
          invoiceType: { predicate: 'eq', value: 'VAT' },
        },
      )
      console.log('getInvoicesPerNumber', getInvoicesPerNumber)

      let itemsByCpns = Object.entries(
        groupArrayofObjectsByKey(itemsByNumber, 'cpn'),
      )

      itemsByCpns = itemsByCpns.map(([cpn, itemsByCpn]) => {
        const totalReceivedQty = itemsByCpn[0].totalReceivedQty

        const getInvoicesPerCpn = getInvoicesPerNumber.filter(
          (x) => x.cpn === cpn,
        )
        const totalReceivedInvoiceQty = getInvoicesPerCpn.reduce(
          (a, v) => a + v.qty,
          0,
        )

        const newBilledQty = itemsByCpn.reduce((a, v) => a + v.qty * 1, 0)

        // console.log('totalReceivedQty', totalReceivedQty)
        // console.log('totalReceivedInvoiceQty', totalReceivedInvoiceQty)
        // console.log('newBilledQty', newBilledQty)

        if (totalReceivedInvoiceQty + newBilledQty > totalReceivedQty) {
          itemsByCpn.forEach((x) => {
            x.alertMessage.push({
              message: '发票数量总计大于收货数量总计',
              index: 'qty',
              style: 'alertLevel5',
              level: 5,
            })
          })
        }

        return itemsByCpn
      })

      itemsByCpns = [].concat.apply([], itemsByCpns)
      return itemsByCpns
    }),
  )
  items = [].concat.apply([], items)

  //*******************************************************************/

  // console.log('rows1', items)

  return filterOutPropertyForInvalidRows(items)
}

export default arrToObjForIncomingInvoiceInput
