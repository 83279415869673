import handleDSOperation from '../../../functions/handleDSOperation'
import dsUpdateForwarderPOPaymentStatusWithIDAndAmount from '../../../functions/APIOperations/dsUpdateForwarderPOPaymentStatusWithIDAndAmount'
import config from '../../../parameters'

const handleForwarderPayment = async ({ date, paymentType, items }) => {
  // console.log('datepaymentType,items', date, paymentType, items)
  // debugger
  await Promise.all(
    items.map(async (forwarderPaymentsPerReceivingCode) => {
      await Promise.all(
        forwarderPaymentsPerReceivingCode.map(async (x) => {
          if (x.handlingAndTaxChecked && x.handlingAndTax) {
            const obj = {
              date: date,
              paymentType: 'handlingAndTax',
              invoiceType: 'forwarderPo',
              amountExclVat: x.handlingAndTax * 1,
              vendor: config.forwarder.name,
              costCurrency: 'CNY',
              invoiceNumber: x.receivingCode,
              forwarderPOID: x.id,
              invoiceID: x.invoiceID,
              cpn: x.cpn,
              desc: x.mpn,
              mfr: x.mfr,
              hpn: x.hpn,
              qty: x.qty,
            }
            // console.log('obj1', obj)
            // debugger

            await handleDSOperation('outgoingPayment', 'create', obj)
          }

          if (x.cnyValueChecked && x.cnyValue) {
            const obj = {
              date: date,
              paymentType: 'cnyValue',
              invoiceType: 'forwarderPo',
              amountExclVat: x.cnyValue * 1,
              vendor: config.forwarder.name,
              costCurrency: 'CNY',
              invoiceNumber: x.receivingCode,
              forwarderPOID: x.id,
              invoiceID: x.invoiceID,
              cpn: x.cpn,
              desc: x.mpn,
              mfr: x.mfr,
              hpn: x.hpn,
              qty: x.qty,
            }
            console.log('obj2', obj)

            await handleDSOperation('outgoingPayment', 'create', obj)

            if (!x.invoiceID) return alert('invoice ID not defined')
            await handleDSOperation(null, 'update', {
              id: x.invoiceID,
              paymentStatus: 'paid',
            })
          }

          if (x.cnyValueChecked || x.handlingAndTaxChecked) {
            await dsUpdateForwarderPOPaymentStatusWithIDAndAmount({
              forwarderPOID: x.id,
            })
          }
        }),
      )
    }),
  )
}

export default handleForwarderPayment
