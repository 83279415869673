import getRowValue from './getRowValue'

const arrToObjForCustomerInput = (rows) => {
  const dataType = 'customer'
  const inputType = 'newCustomer'

  rows = getRowValue(dataType, inputType, rows)

  rows.forEach((x) => {
    x.entity = x.entity.toUpperCase()
    x.mainContactName = x.mainContactName && x.mainContactName.toUpperCase()

    x.deliveryTerms = x.deliveryTerms && x.deliveryTerms.split(',')
    x.contactPerson = {
      name: x.mainContactName && x.mainContactName.toUpperCase(),
      role: x.mainContactRole,
      phoneNumber: x.mainContactPhoneNumber,
      email: x.mainContactEmail,
    }
  })
  return rows
}

export default arrToObjForCustomerInput
