import forwarderPoCreateItemInitialValue from './createItemInitialValue'

const initialValue = () => ({
  date: new Date().toISOString().substring(0, 10),
  receivingCode: '',
  exchangeRate: '',
  vendor: '',
  invoiceNumber: '',
  vendorOptions: [],
  invoiceOptions: [],
  items: [forwarderPoCreateItemInitialValue],
})

export default initialValue
