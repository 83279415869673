import dsDeleteFulfillments from '../../functions/APIOperations/dsDeleteFulfillments'
import dsUpdateInventory from '../../functions/APIOperations/dsUpdateInventory'
import dsUpdateCPOQtyAfterDeleteShipments from '../../functions/APIOperations/dsUpdateCPOQtyAfterDeleteShipments'

const onSelectedDelete = async (selected) => {

  await dsDeleteFulfillments(selected)

  await dsUpdateCPOQtyAfterDeleteShipments(selected)

  await dsUpdateInventory(selected, 'add')
}

export default onSelectedDelete
