import * as Yup from 'yup'
import {
  vendor,
  qty,
  cpn,
  mpn,
  reqSched,
  cost,
  poNumber,
  date,
} from '../../functions/getValidations'

const formValidationSchema = {
  vendor,
  date,
  items: Yup.array().of(
    Yup.object({
      cpn,
      // poNumber,
      qty,
    }),
  ),
}

export default formValidationSchema
