import handleDSOperation from '../../functions/handleDSOperation'
import dsUpdateForwarderPOsBillingStatusWithReceivingCode from '../../functions/APIOperations/dsUpdateForwarderPOsBillingStatusWithReceivingCode'
import dsUpdateSPOBillingStatus from '../../functions/APIOperations/dsUpdateSPOBillingStatus'

const onSelectedDelete = async (selectedRows) => {
  const itemsWithReceivingCode = selectedRows.filter((x) => x.receivingCode)

  const itemsWithoutReceivingCode = selectedRows.filter((x) => !x.receivingCode)

  await dsUpdateForwarderPOsBillingStatusWithReceivingCode(
    itemsWithReceivingCode,
    'minus',
  )

  await dsUpdateSPOBillingStatus(itemsWithoutReceivingCode, 'minus')

  await Promise.all(
    selectedRows.map(async (x) => {
      await handleDSOperation(null, 'delete', x.id)
    }),
  )
}

export default onSelectedDelete
