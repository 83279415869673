import dsCreateOutgoingInvoice from '../../functions/APIOperations/dsCreateOutgoingInvoice'
import dsUpdateShipmentsBillingStatusWithPoNumberAndCpnAndCustomer from '../../functions/APIOperations/dsUpdateShipmentsBillingStatusWithPoNumberAndCpnAndCustomer'

const handleSubmit = async (values) => {
  let { date, customer, customerPaymentDue, invoiceNumber, items } = values

  items = items.map((x) => ({
    date,
    invoiceNumber,
    customerPaymentDue,
    customer,
    poNumber: x.poNumber,
    hpn: x.hpn,
    cpn: x.cpn,
    price: x.price,
    priceCurrency: x.priceCurrency,
    qty: x.qty,
    mpn: x.mpn,
    mfr: x.mfr,
    unit: x.unit,
    invoiceType: x.priceCurrency !== 'CNY' ? 'PI' : 'VAT',
  }))

  // console.log('items', items)
  await dsUpdateShipmentsBillingStatusWithPoNumberAndCpnAndCustomer(items,'add')

  await dsCreateOutgoingInvoice(items)

  // debugger
}

export default handleSubmit
