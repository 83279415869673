import React, { useState, useEffect } from 'react'
import { Alert, Dialog } from '@mui/material'
import _ from 'lodash'
import { Hub } from 'aws-amplify'
import FormikForm from '../Form/FormikForm'
import DraggablePaperComp from '../../components/DraggablePaperComp'

const RootForm = () => {
  const [formAttr, setFormAttr] = useState({})

  useEffect(() => {
    let ignore = false

    Hub.listen('FormMonitor', ({ payload }) => {
      const { data, event, message } = payload

      if (!ignore && event === 'formEdit') setFormAttr(data)
    })

    return () => {
      ignore = true
      Hub.remove('FormMonitor')
    }
  }, [])

  // console.log('formAttr', formAttr)

  const {
    dataType,
    formType,
    data,
    allowAddItem,
    showDeleteItem,
    allowDuplicateItem,
    allowDuplicateItemFunc,
  } = !_.isEmpty(formAttr) ? formAttr : {}

  const onClose = () => {
    setFormAttr({})
  }

  return !dataType || !formType ? null : (
    <Dialog
      fullWidth
      maxWidth='xl'
      PaperComponent={DraggablePaperComp}
      open={!_.isEmpty(formAttr)}
      onClose={onClose}
      aria-labelledby='draggable-dialog-title'>
      <>
        <div
          style={{ cursor: 'move', backgroundColor: 'pink', height: 30 }}
          id='draggable-dialog-title'
        />
        <FormikForm
          onCloseForm={onClose}
          allowAddItem={allowAddItem}
          showDeleteItem={showDeleteItem}
          allowDuplicateItem={allowDuplicateItem}
          allowDuplicateItemFunc={allowDuplicateItemFunc}
          formType={formType}
          dataType={dataType}
          rowData={formType === 'edit' ? data : {}}
        />
      </>
    </Dialog>
  )
}

export default RootForm
