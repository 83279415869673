import handleDSOperation from '../../functions/handleDSOperation'
import mergeArrayAndCombineQty from '../../functions/helpFunc/mergeArrayAndCombineQty'
import dsUpdateForwarderPOsBillingStatusWithReceivingCode from '../../functions/APIOperations/dsUpdateForwarderPOsBillingStatusWithReceivingCode'
import getPaymentDue from '../../functions/helpFunc/getPaymentDue'
import dsCreateIncomingInvoices from '../../functions/APIOperations/dsCreateIncomingInvoices'
import dsUpdateSPOBillingStatus from '../../functions/APIOperations/dsUpdateSPOBillingStatus'

const handleSubmit = async ({
  date,
  invoiceNumber,
  vendor,
  items,
  invoiceType,
  vendorPaymentDue,
  usePaymentAgent,
}) => {
  if (items[0].receivingCode) {
    await dsUpdateForwarderPOsBillingStatusWithReceivingCode(items, 'add')
  } else {
    await dsUpdateSPOBillingStatus(items,'add')
  }

  let mergedItems = mergeArrayAndCombineQty(
    items,
    ['poNumber', 'receivingCode', 'hpn', 'price'],
    ['qty', 'amountExclVat'],
  )

  let currency
  if (invoiceType === 'VAT' || invoiceType === 'RECEIPT') {
    currency = 'CNY'
  }

  mergedItems = mergedItems.map((x) => ({
    type: 'incomingInvoice',
    paymentStatus: 'unpaid',
    date,
    invoiceNumber: invoiceNumber.toUpperCase(),
    invoiceType,
    vendor,
    vendorPaymentDue,
    poNumber: x.poNumber,
    receivingCode: x.receivingCode || '',
    qty: x.qty * 1,
    unit: x.unit,
    amountExclVat: x.amountExclVat * 1,
    costCurrency: currency || x.costCurrency,
    cpn: x.cpn,
    hpn: x.hpn,
    mpn: x.mpn,
    mfr: x.mfr,
    usePaymentAgent,
    paymentAgentAmountExclVat: x.paymentAgentAmountExclVat,
  }))

  await dsCreateIncomingInvoices(mergedItems)

  if (usePaymentAgent && invoiceType === 'PI') {
    const getPaymentAgent = await handleDSOperation('vendor', 'query', {
      entityType: { predicate: 'eq', value: 'PAYMENT AGENT' },
    })
    const vendor = getPaymentAgent[0].entity
    const paymentTerm = getPaymentAgent[0].paymentTerm
    const vendorPaymentDue = getPaymentDue(paymentTerm)
    mergedItems = mergedItems.map((x) => ({
      ...x,
      vendor,
      vendorPaymentDue,
      usePaymentAgent: false,
      amountExclVat:
        x.paymentAgentAmountExclVat && x.paymentAgentAmountExclVat * 1,
    }))
    await dsCreateIncomingInvoices(mergedItems)
  }
}

export default handleSubmit
