import React, { useState } from 'react'
import { IconButton, Typography, Box } from '@mui/material'
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material'
import PoRecord from '../../components/Table/collapse/PoRecord'
import PaymentRecord from '../../components/Table/collapse/PaymentRecord'
import FulfillmentRecord from '../../components/Table/collapse/FulfillmentRecord'

const DetailPanel = ({ rowData }) => {
  const [openShipping, setOpenShipping] = useState(false)
  const [openPaymentRecord, setOpenPaymentRecord] = useState(false)

  const SubItemMenu = () => (
    <div>
      <Typography
        variant='h6'
        gutterBottom
        component='span'>
        <IconButton
          aria-label='expand row'
          size='small'
          onClick={async () => {
            setOpenPaymentRecord(false)
            setOpenShipping(!openShipping)
          }}>
          {openShipping ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
        出货记录
      </Typography>
      <Typography
        variant='h6'
        gutterBottom
        component='span'>
        <IconButton
          aria-label='expand row'
          size='small'
          onClick={async () => {
            setOpenShipping(false)
            setOpenPaymentRecord(!openPaymentRecord)
          }}>
          {openPaymentRecord ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
        收款记录
      </Typography>
    </div>
  )

  return (
    <Box m={3}>
      <SubItemMenu />
      {openShipping && (
        <FulfillmentRecord
          fulfillmentType='shipping'
          poNumber={rowData.poNumber}
          fulfillmentNumber={rowData.fulfillmentNumber}
          hpn={rowData.hpn}
          cpn={rowData.cpn}
        />
      )}
      {openPaymentRecord && (
        <PaymentRecord
          paymentType='incomingPayment'
          invoiceNumber={rowData.invoiceNumber}
          isForwarderPO={false}
          customer={rowData.customer}
        />
      )}
    </Box>
  )
}

export default DetailPanel
