import React, { useEffect } from 'react'
import usePrevious from '../../hooks/usePrevious'
import { FormField, RadioField } from '../../components/Form/FormField'

import { Form } from 'formik'

import GoodsPayment from './subMainComponents/GoodsPayment'
import ForwarderPayment from './subMainComponents/ForwarderPayment'

import ShippingFee from './subMainComponents/ShippingFee'
import MiscFee from './subMainComponents/MiscFee'

const CreateMainComponent = (props) => {
  const {
    setAllowAdd,
    setAllowDelete,
    values,
    submitForm,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    initialValues,
    setFieldValue,
    setValues,
    isSubmitting,
    formStyle,
    userGroups: groups,
  } = props

  const prevPaymentType = usePrevious(values.paymentType)
  console.log('outgoing payment values', values)

  useEffect(() => {
    if (values.paymentType && values.paymentType !== prevPaymentType) {
      setValues({ ...initialValues, paymentType: values.paymentType})
    }
  }, [setFieldValue, initialValues, values, setValues, prevPaymentType])

  let SubComponent = () => null

  switch (values.paymentType) {
    case 'goodsPayment':
      SubComponent = GoodsPayment
      break
    case 'miscFee':
      SubComponent = MiscFee
      break
    case 'forwarderPayment':
      SubComponent = ForwarderPayment
      break
    case 'shippingFee':
      SubComponent = ShippingFee
      break
    default:
      break
  }

  return (
    <>
      <Form style={formStyle} autoComplete='off'>
        <div style={{ marginBottom: 20 }}>
          <RadioField
            fieldName='paymentType'
            value={values.paymentType || ''}
            handleChange={handleChange}
            groups={[
              { value: 'goodsPayment', label: '货款' },
              { value: 'forwarderPayment', label: '货代' },
              { value: 'miscFee', label: '杂费' },
              { value: 'shippingFee', label: '运费' },
              // { value: 'others', label: '其他' },
            ]}
          />

          <FormField
            fieldName='date'
            value={values.date}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
        </div>

        <SubComponent {...props} />
      </Form>
    </>
  )
}

export default CreateMainComponent
