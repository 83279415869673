import React, { useState } from 'react'
import { Button, Stack } from '@mui/material'
import { Hub } from 'aws-amplify'
import MTSumField from '../../components/Table/toolbar/MTSumField'
import handleToolbarSendVAT from '../../functions/tableFunctions/toolbar/handleToolbarSendVAT'

const Toolbar = ({ selectedRows, setSelectionModel, dataType }) => {
  const [sendVATloading, setSendVATloading] = useState(false)

  return selectedRows && selectedRows.length > 0 ? (
    <Stack direction='row' spacing={3}>
      <Button
        disabled={
          (selectedRows[0] && selectedRows[0].invoiceType !== 'VAT') ||
          sendVATloading
        }
        variant='contained'
        color='primary'
        size='small'
        onClick={async () => {
          try {
            setSendVATloading(true)
            const preview = true
            await handleToolbarSendVAT(selectedRows, preview)
            setSendVATloading(false)
            alert('VAT sent successfully')
            setSelectionModel([])
          } catch (err) {
            console.log('error 7789', err)
            alert('sending VAT failed')
          }
        }}>
        send VAT
      </Button>
      <MTSumField qtyAttr='qty' selectedRows={selectedRows} />
      <MTSumField qtyAttr='amountExclVat' selectedRows={selectedRows} />
      {selectedRows[0] && selectedRows[0].invoiceType === 'VAT' && (
        <MTSumField qtyAttr='amountInclVat' selectedRows={selectedRows} />
      )}
    </Stack>
  ) : (
    <Stack direction='row' spacing={3}>
      <Button
        variant='contained'
        color='secondary'
        size='small'
        onClick={() =>
          Hub.dispatch('FormMonitor', {
            event: 'formEdit',
            data: {
              dataType,
              formType: 'create',
              allowAddItem: true,
              showDeleteItem: true,
            },
            message: '',
          })
        }>
        新建销项发票
      </Button>
    </Stack>
  )
}

export default Toolbar
