import handleDSOperation from '../../../functions/handleDSOperation'
import config from '../../../parameters'

const handleGoodsPayment = async ({ date, paymentType, items, vendor }) => {
  await Promise.all(
    items.map(async (x) => {
      const getInvoices = await handleDSOperation('incomingInvoice', 'query', {
        invoiceNumber: {
          predicate: 'eq',
          value: x.invoiceNumber,
        },
        vendor: {
          predicate: 'eq',
          value: vendor,
        },
      })

      const totalBilled = getInvoices.reduce(
        (a, v) => a + v.amountExclVat * 1,
        0,
      )
      const getPayments = await handleDSOperation('outgoingPayment', 'query', {
        invoiceNumber: { predicate: 'eq', value: x.invoiceNumber },
      })
      const totalPaid = getPayments.reduce((a, v) => a + v.amountExclVat * 1, 0)

      const amountPaidWithoutVat =
        x.invoiceType === 'VAT'
          ? (x.amountPaid / (1 + config.vatRate)).toFixed(2) * 1
          : x.amountPaid

      let paymentStatus = 'paid'

      if (totalPaid + amountPaidWithoutVat < totalBilled) {
        paymentStatus = 'partial'
      } else if (totalPaid + amountPaidWithoutVat - totalBilled > 1) {
        alert(`${x.invoiceNumber} 总付款金额大于发票金额`)
      }

      // console.log('y', y)

      const obj = {
        date: date,
        paymentType: paymentType,
        invoiceType: x.invoiceType,
        vendor: vendor,
        invoiceNumber: x.invoiceNumber,
        costCurrency: x.costCurrency,
        amountExclVat: amountPaidWithoutVat,
        memo: x.memo,
      }
      // console.log('obj', obj)
      // debugger
      await handleDSOperation('outgoingPayment', 'create', obj)
      await Promise.all(
        getInvoices.map(async (x) => {
          await handleDSOperation(null, 'update', {
            id: x.id,
            paymentStatus,
          })
        }),
      )
    }),
  )
}

export default handleGoodsPayment