const getInitialValue = (rowData) => ({
  id: rowData.id,
  type: rowData.type,
  date: rowData.date,
  invoiceType: rowData.invoiceType,
  invoiceNumber: rowData.invoiceNumber,
  amountExclVat: rowData.amountExclVat,
  paymentType: rowData.paymentType,
  costCurrency: rowData.costCurrency,
  vendor: rowData.vendor,
  memo: rowData.memo,
})

export default getInitialValue
