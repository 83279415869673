import getRowValue from './getRowValue'
import filterOutPropertyForInvalidRows from '../rowValidator/filterOutPropertyForInvalidRows'
import groupArrayofObjectsByKey from '../helpFunc/groupArrayofObjectsByKey'
import handleDSOperation from '../handleDSOperation'
import getPaymentDue from '../helpFunc/getPaymentDue'
import generateVATInvoiceNumber from './generateVATInvoiceNumber'

const arrToObjForOutgoingVatInvoiceInput = async (rows) => {
  const dataType = 'outgoingInvoice'
  const inputType = 'newOutgoingVatInvoice'

  rows = getRowValue(dataType, inputType, rows)

  const firstNumber = rows[0].invoiceNumber * 1

  if (!firstNumber) {
    return alert('please input the first VAT number')
  }

  rows = Object.entries(groupArrayofObjectsByKey(rows, 'poNumber'))

  rows = await Promise.all(
    rows.map(async ([poNumber, itemsByPoNumber]) => {
      // console.log('poNumber', poNumber)
      // console.log('itemsByPoNumber', itemsByPoNumber)
      return await Promise.all(
        itemsByPoNumber.map(async (row) => {
          const poNumber = row.poPrefix
            ? `${row.poPrefix}-${row.poNumber}`
            : row.poNumber
          // console.log('poNumber', poNumber)

          const getSPOs = await handleDSOperation('cpo', 'query', {
            poNumber: { predicate: 'eq', value: poNumber },
            version: { predicate: 'eq', value: 0 },
          })
          // console.log('getSPOs', getSPOs)
          return {
            ...row,
            customer: getSPOs.length > 0 && getSPOs[0].customer,
            poNumber,
            date: row.date || new Date().toISOString().substring(0, 10),
            // amountExclVat: (row.price * row.qty).toFixed(2),
            priceCurrency: 'CNY',
            invoiceType: 'VAT',
          }
        }),
      )
    }),
  )

  rows = [].concat.apply([], rows)

  rows = Object.entries(groupArrayofObjectsByKey(rows, 'customer'))

  rows = await Promise.all(
    rows.map(async ([customer, items]) => {
      const getCustomer = await handleDSOperation('customer', 'query', {
        entity: { predicate: 'eq', value: customer },
      })

      const customerPaymentDue = getPaymentDue(getCustomer[0].paymentTerm)
      return items.map((item) => ({
        ...item,
        customerPaymentDue,
      }))
    }),
  )
  rows = [].concat.apply([], rows)

  // rows = await isPoExistingAndGetLatestVersionAndRecord(rows)

  rows = filterOutPropertyForInvalidRows(rows)

  //ATTENTION GENERATE VAT NUMBERS

  // rows = generateVATInvoiceNumber(firstNumber, rows)

  return rows
}

export default arrToObjForOutgoingVatInvoiceInput
