import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import SimpleTable from '../SimpleTable'
import handleDSOperation from '../../../functions/handleDSOperation'

const ScheduleRecord = ({ poNumber }) => {
  const [rows, setRows] = useState([])

  useEffect(() => {
    ;(async () => {
      let items = await handleDSOperation('spoSched', 'query', {
        poNumber: { predicate: 'eq', value: poNumber },
        version: { predicate: 'gt', value: 0 },
      })
      setRows(items)
    })()
  }, [poNumber])
  console.log('rowssss', rows)

  return (
    <Box m={3}>
      <SimpleTable
        dataType={'spo'}
        rows={rows}
        tbType='collasped'
      />
    </Box>
  )
}

export default ScheduleRecord
