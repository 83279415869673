import {
  customer,
  qty,
  openQty,
  price,
  poNumber,
} from '../../functions/getValidations'

const formValidationSchema = {
  customer,
  qty,
  openQty,
  price,
  poNumber,
}

export default formValidationSchema
