import restAPICalls from '../../restAPICalls'
import config from '../../../parameters'

import mergeArrayAndCombineQty from '../../helpFunc/mergeArrayAndCombineQty'
import handleDSOperation from '../../handleDSOperation'

const handleToolbarSendQuote = async (
  itemsSelected,
  isPreview,
  currentUser,
) => {
  try {
    if (!currentUser.email) return alert('currentuser email is not defined')
    const sales = 'Steven Lu'
    const salesEmail = currentUser.email
    const number = itemsSelected[0].number
    const customer = itemsSelected[0].customer
    const date = itemsSelected[0].date
    const dept = itemsSelected[0].dept
    const buyer = itemsSelected[0].name
    const buyerEmail = itemsSelected[0].email
    const paymentTerm = itemsSelected[0].paymentTerm
    const deliveryTerm = itemsSelected[0].deliveryTerm
    const memo = itemsSelected[0].memo
    const hankenoEntityTag = itemsSelected[0].hankenoEntity
    const validDays = 3
    const expireBy = new Date(
      new Date(date).getTime() + validDays * 24 * 60 * 60 * 1000,
    )
      .toISOString()
      .substring(0, 10)

    const hankenoEntity = config.hankenoEntities.find(
      (x) => x.tag === hankenoEntityTag,
    )

    const getQuotes = await handleDSOperation('outgoingQuote', 'query', {
      number: { predicate: 'eq', value: number },
    })

    if (getQuotes.length === 0) return alert(`quote ${number} is not found`)
    const quoteItems = getQuotes.map((x) => ({
      mfr: x.mfr,
      mpn: x.mpn,
      unit: x.unit,
      cpn: x.cpn,
      price: x.price,
      priceCurrency: x.priceCurrency,
      customerMOQ: x.customerMOQ,
      customerMPQ: x.customerMPQ,
      customerLeadtime: x.customerLeadtime,
    }))

    const obj = {
      number,
      date,
      expireBy,
      hankenoEntityName: hankenoEntity.name,
      hankenoEntityPhoneNumber: hankenoEntity.phoneNumber,
      hankenoEntityAdd: hankenoEntity.add,
      sales,
      salesEmail,
      customer,
      buyer,
      buyerEmail,
      dept,
      quoteItems,
      paymentTerm,
      deliveryTerm,
      memo,
    }

    // console.log('obj', obj)
    // console.log('isPreview', isPreview)
    // debugger

    await restAPICalls('post', null, {
      type: 'quote',
      obj,
      isPreview,
    })
  } catch (err) {
    alert('failed to send the quote', err)
  }
}

export default handleToolbarSendQuote
