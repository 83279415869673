import React, { useEffect } from 'react'
import {
  FormField,
} from '../../../components/Form/FormField'

import config from '../../../parameters'

const GoodsPayment = ({
  initialValues,
  setAllowAdd,
  setAllowDelete,
  values,
  handleBlur,
  handleChange,
  setFieldValue,
  setValues,
  index,
  item: currentItem,
}) => {
  useEffect(() => {
    if (currentItem.amountPaid === undefined && currentItem.amountExclVat) {
      setFieldValue(`items[${index}]`, {
        ...currentItem,
        amountPaid:
          currentItem.invoiceType === 'VAT'
            ? (currentItem.amountExclVat * (1 + config.vatRate)).toFixed(2) * 1
            : currentItem.amountExclVat,
      })
    }
  }, [currentItem, setFieldValue, index, values])

  return (
    <>
      <div>
        <FormField
          disabled
          fieldName='invoiceDate'
          value={currentItem.invoiceDate}
        />
        <FormField
          disabled
          fieldName='invoiceNumber'
          value={currentItem.invoiceNumber}
        />
        <FormField
          disabled
          fieldName='invoiceType'
          value={currentItem.invoiceType}
        />
        <FormField disabled fieldName='qty' value={currentItem.qty} />

        <FormField
          disabled
          fieldName='costCurrency'
          value={currentItem.costCurrency}
        />
        <FormField
          disabled
          fieldName='amountExclVat'
          value={currentItem.amountExclVat}
        />
        {currentItem.invoiceType === 'VAT' && (
          <FormField
            disabled
            fieldName='amountInclVat'
            value={
              (currentItem.amountExclVat * (1 + config.vatRate)).toFixed(2) *
                1 || ''
            }
          />
        )}
        <FormField
          fieldName='amountPaid'
          index={index}
          value={currentItem.amountPaid || ''}
          handleBlur={handleBlur}
          handleChange={handleChange}
          validate={(value) => {
            const paidAmountExclVat =
              currentItem.invoiceType === 'VAT'
                ? value / (1 + config.vatRate)
                : value

            let msg

            if (!value) {
              msg = '请输入收款金额'
            } else if (paidAmountExclVat - currentItem.amountExclVat > 1) {
              msg = '付款金额大于应付金额'
            }
            return msg
          }}
        />
      </div>
      <div>
        <FormField
          multiline
          fieldName='memo'
          index={index}
          value={currentItem.memo || ''}
          handleBlur={handleBlur}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          currentItem={currentItem}
        />
      </div>
    </>
  )
}

export default GoodsPayment
