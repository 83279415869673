import getRowValue from './getRowValue'
import filterOutPropertyForInvalidRows from '../rowValidator/filterOutPropertyForInvalidRows'
import isPoExistingAndGetLatestVersionAndRecord from '../rowValidator/isPoExistingAndGetLatestVersionAndRecord'
import shippingQtyValidator from '../rowValidator/shippingQtyValidator'
import mergeArrayAndCombineQty from '../helpFunc/mergeArrayAndCombineQty'

const arrToObjForShippingInput = async (rows) => {
  const dataType = 'shipping'
  const inputType = 'newShipping'

  rows = getRowValue(dataType, inputType, rows)

  rows.forEach((row) => {
    row.poType = 'cpo'
    row.cartonNumber = row.cartonNumber && row.cartonNumber * 1
    row.date = row.fulfillmentNumber
      ? `${row.fulfillmentNumber.substring(
          4,
          8,
        )}-${row.fulfillmentNumber.substring(
          8,
          10,
        )}-${row.fulfillmentNumber.substring(10, 12)}`
      : new Date().toISOString().substring(0, 10)
    row.poNumber = `${row.poPrefix}-${row.poNumber}`
  })

  // console.log('rows111111', rows)
  // debugger
  rows = mergeArrayAndCombineQty(
    rows,
    ['poNumber', 'cpn', 'cartonNumber', 'price'],
    ['qty'],
  )

  rows = await isPoExistingAndGetLatestVersionAndRecord(rows)
  // console.log('rows888888888888', rows)
  // debugger
  // await shippingQtyValidator(rows)

  if ([...new Set(rows.map((x) => x.customer))].length > 1) {
    alert('multiple customers in one shipment')
  }

  const arrayOfCartonNumber = [...new Set(rows.map((x) => x.cartonNumber))]

  console.log('arrayOfCartonNumber', arrayOfCartonNumber)

  const differenceArr = arrayOfCartonNumber
    .slice(1)
    .map((x, i) => x - arrayOfCartonNumber[i])

  // console.log('differenceArr', differenceArr)

  if (differenceArr.length > 0) {
    const isDifference = differenceArr.every((value) => value === 1)

    if (!isDifference || Math.min(...arrayOfCartonNumber) !== 1) {
      console.log('differenceArr', differenceArr)
      console.log('isDifference', isDifference)
      alert('箱号不连续或错误')
    }
  }

  return filterOutPropertyForInvalidRows(rows)
}

export default arrToObjForShippingInput
