import mergeArrayAndCombineQty from '../../functions/helpFunc/mergeArrayAndCombineQty'
import handleDSOperation from '../../functions/handleDSOperation'
// import dsUpdateForwarderPOsBillingStatusWithReceivingCode from '../../functions/APIOperations/dsUpdateForwarderPOsBillingStatusWithReceivingCode'

const handleSubmit = async (values) => {
  const receivingCode =
    values.receivingCode && values.receivingCode.trim().toUpperCase()

  //UPDATE RECEIVING CODE
  const getInvoices = await handleDSOperation('incomingInvoice', 'query', {
    invoiceNumber: { predicate: 'eq', value: values.invoiceNumber },
  })

  await Promise.all(
    getInvoices.map(async (x) => {
      // console.log('x', x.id)
      await handleDSOperation(null, 'update', {
        id: x.id,
        receivingCode,
      })
    }),
  )
  /////////////////////

  let items = mergeArrayAndCombineQty(
    values.items,
    ['poNumber', 'hpn', 'cost'],
    ['qty', 'amountExclVat', 'duty', 'vat', 'handlingFee'],
  )

  items = await Promise.all(
    items.map(async (x, i) => {
      const extraCharge = i === 0 ? values.extraCharge * 1 : 0
      const deliveryStatus = 'unDelivered'
      const billingStatus = receivingCode.startsWith('FC')
        ? 'billed'
        : 'unbilled'
      const paymentStatus = receivingCode.startsWith('FC') ? 'paid' : 'unpaid'

      const obj = {
        date: values.date,
        receivingCode,
        hpn: x.hpn,
        cpn: x.cpn,
        mpn: x.mpn,
        mfr: x.mfr,
        unit: x.unit,
        invoiceNumber: values.invoiceNumber,
        qty: x.qty * 1,
        openQty: x.qty * 1,
        amountExclVat: x.amountExclVat,
        costCurrency: x.costCurrency,
        vendor: values.vendor,
        poNumber: x.poNumber,
        vendorPaymentDue: x.vendorPaymentDue,
        exchangeRate: values.exchangeRate * 1,
        importDuty: x.importDuty * 1,
        duty: x.duty * 1,
        handlingFee: x.handlingFee * 1,
        vat: x.vat * 1,
        extraCharge,
        deliveryStatus,
        billingStatus,
        paymentStatus,
        invoiceID: x.invoiceID,
      }

      return await handleDSOperation('forwarderPo', 'create', obj)
    }),
  )

  // await dsUpdateForwarderPOsBillingStatusWithReceivingCode(items)
}

export default handleSubmit
