import dsCreateProduct from '../../functions/APIOperations/dsCreateProduct'

const handleSubmit = async (values) => {
  // console.log('value', values)
  // debugger
  const version = 0
  await dsCreateProduct(values, version)
}

export default handleSubmit
