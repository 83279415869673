import React from 'react'
import {
  AmplifyAuthenticator,
  AmplifySignUp,
  AmplifySignIn,
  AmplifySignOut,
  AmplifySelectMfaType,
} from '@aws-amplify/ui-react'
import { Box, Container, Typography } from '@mui/material'

export default function AuthUI() {
  return (
    <Container
      maxWidth={false}
      style={{ backgroundColor: '#cfe8fc', height: '100vh' }}>
      <Typography variant='h6' color='gray'>
        HANKENO CLOUD
      </Typography>
      <AmplifyAuthenticator usernameAlias='email'>
        {/* <AmplifySelectMfaType MFATypes={MFATypeOptions}></AmplifySelectMfaType> */}
        <AmplifySignUp
          slot='sign-up'
          usernameAlias='email'
          formFields={[
            // { type: 'username' },
            { type: 'email' },
            { type: 'password' },
            // { type: 'confirmPassword' },

            // {
            //   type: 'email',
            //   label: 'Email',
            //   placeholder: 'Please enter your email address',
            //   required: true,
            // },
            // {
            //   type: 'password',
            //   label: 'Password',
            //   placeholder: 'Please enter your password',
            //   required: true,
            // },
          ]}
        />
      </AmplifyAuthenticator>
    </Container>
  )
}
