import handleDSOperation from '../handleDSOperation'

const dsUpdateBillingStatusWithPoNumberAndCpnAndCustomer = async ({
  poNumber,
  customer,
  cpn,
  itemsByCpn,
  addOrMinus,
}) => {
  if (!poNumber || !customer || !cpn) {
    alert('9033, argument not defined')
  } else if (!addOrMinus) {
    alert('9034, addOrMinus not defined')
  }

  // console.log('poNumber', poNumber)
  // console.log('customer', customer)
  // console.log('cpn', cpn)
  // console.log('itemsByCpn', itemsByCpn)
  // console.log('addOrMinus', addOrMinus)

  // debugger

  const getInvoices = await handleDSOperation('outgoingInvoice', 'query', {
    customer: {
      predicate: 'eq',
      value: customer,
    },
    poNumber: { predicate: 'eq', value: poNumber },
    cpn: { predicate: 'eq', value: cpn },
  })

  const billedQty = getInvoices.reduce((a, v) => a + v.qty * 1, 0)

  const getShppings = await handleDSOperation('shipping', 'query', {
    // fulfillmentNumber: { predicate: 'eq', value: fulfillmentNumber },
    cpn: { predicate: 'eq', value: cpn },
    poNumber: { predicate: 'eq', value: poNumber },
    customer: { predicate: 'eq', value: customer },
  })
  const shippedQty = getShppings.reduce((a, v) => a + v.qty * 1, 0)
  console.log('billedQty,shippedQty', billedQty, shippedQty)

  let sessionQty = itemsByCpn.reduce((a, v) => a + v.qty * 1, 0)

  // debugger
  let billingStatus
  if (addOrMinus === 'minus') {
    sessionQty = 0 - sessionQty
  }
  if (billedQty + sessionQty === 0) {
    billingStatus = 'unbilled'
  } else if (billedQty + sessionQty < shippedQty) {
    billingStatus = 'partial'
  } else if (billedQty + sessionQty === shippedQty) {
    billingStatus = 'billed'
  } else {
    billingStatus = 'billed'
    alert(`PO ${poNumber}billing qty is greater than shipping qty`)
  }

  console.log('billingStatus', billingStatus)
  await Promise.all(
    getShppings.map(async (x) => {
      await handleDSOperation(null, 'update', {
        id: x.id,
        billingStatus,
      })
    }),
  )
}

export default dsUpdateBillingStatusWithPoNumberAndCpnAndCustomer
