import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import SimpleTable from '../SimpleTable'
import handleDSOperation from '../../../functions/handleDSOperation'

const ProductRecord = ({ hpn }) => {
  const [rows, setRows] = useState([])
  useEffect(() => {
    ;(async () => {
      const res = await handleDSOperation('product', 'query', {
        hpn: { predicate: 'eq', value: hpn },
        version: { predicate: 'gt', value: 0 },
      })
      setRows(res)
    })()
  }, [hpn])

  return (
    <Box m={3}>
      <SimpleTable
        dataType='product'
        tbType='collasped'
        rows={rows}
      />
    </Box>
  )
}

export default ProductRecord
