const cpoInputPriceValidator = (row) => {
  // console.log('row2222', row)
  if (
    row.enteredPrice &&
    row.price &&
    (row.enteredPrice * 1) / 1000 !== row.price * 1 &&
    row.enteredPrice * 1 !== row.price * 1
  ) {
    row.alertMessage &&
      row.alertMessage.push({
        message: 'Price does not match',
        index: 'enteredPrice',
        style: 'alertLevel5',
        level: 4,
      })
  }
}

export default cpoInputPriceValidator
