const getInitialValue = (rowData) => ({
  id: rowData.id,
  type: rowData.type,
  invoiceType: rowData.invoiceType,
  fulfillmentNumber: rowData.fulfillmentNumber,
  customer: rowData.customer,
  date: rowData.date,
  poNumber: rowData.poNumber,
  qty: rowData.qty,
  unit: rowData.unit,
  invoiceNumber: rowData.invoiceNumber,
  cpn: rowData.cpn,
  price: rowData.price,
  priceCurrency: rowData.priceCurrency,
  customerPaymentDue: rowData.customerPaymentDue,
  customerPaymentPaid: rowData.customerPaymentPaid,
  paymentStatus: rowData.paymentStatus,
  memo: rowData.memo,
})

export default getInitialValue
