import itemInitialValue from './createItemInitialValue'

const initialValue = () => ({
  date: new Date().toISOString().substring(0, 10),
  number: '',
  customer: '',
  customerOptions: [],
  deliveryTerms: [],
  contacts: [],
  productOptions: [],
  items: [itemInitialValue],
})

export default initialValue
