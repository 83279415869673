import createItemIntialValue from './createItemInitialValue'

const initialValue = () => ({
  // itemsByVendor: { vendor: '', receivingsOrSPOsByVendor: [] },
  retrivedPos: [],
  invoiceNumber: '',
  isForwarder: false,
  invoiceType: '',
  date: new Date().toISOString().substring(0, 10),
  vendorPaymentDue: undefined,
  usePaymentAgent: false,
  items: [createItemIntialValue],
})

export default initialValue
