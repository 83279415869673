import * as Yup from 'yup'
import { qty, estSched, poNumber } from '../../functions/getValidations'

export const itemValidationShape = {
  qty,
  estSched,
}

const formValidationSchema = {
  poNumber,
  items: Yup.array().of(Yup.object(itemValidationShape)),
}

export default formValidationSchema
