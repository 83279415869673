import { Typography } from '@mui/material'
import config from '../../../parameters'

const MTSumField = ({ qtyAttr, selectedRows }) => {
  if (!selectedRows || selectedRows.length === 0 || !selectedRows[0])
    return null
  // console.log('qtyAttr', qtyAttr)
  // console.log('selectedRows', selectedRows)

  let total

  if (qtyAttr === 'amount') {
    const priceAttr =
      selectedRows[0].type === 'cpo' ||
      selectedRows[0].type === 'shipping' ||
      selectedRows[0].type === 'outgoingInvoice'
        ? 'price'
        : 'cost'
    // console.log('selectedRows', selectedRows)
    // console.log('priceAttr', priceAttr)

    total = selectedRows.reduce(
      (a, v) => v.qty && v[priceAttr] && v.qty * v[priceAttr] + a,
      // (a, v) => v.qty && v.price && v.price * v.qty + a,

      0,
    )
    // console.log('total', total)
  } else if (qtyAttr === 'amountExclVat') {
    total = selectedRows.reduce(
      (a, v) =>
        ((v && v.qty && v.price && v.qty * v.price) || v.amountExclVat) + a,
      0,
    )
  } else if (qtyAttr === 'amountInclVat') {
    total = selectedRows.reduce(
      (a, v) =>
        ((v && v.qty && v.price && v.qty * v.price * (1 + config.vatRate)) ||
          v.amountExclVat * (1 + config.vatRate)) + a,
      0,
    )
  } else {
    // console.log(999)
    total = selectedRows.reduce(
      (a, v) => v && v[qtyAttr] && v[qtyAttr] * 1 + a,
      0,
    )
  }
  // console.log('total', total)
  // debugger
  const attrText = () => {
    switch (qtyAttr) {
      case 'qty':
        return 'Quantity'
      case 'openQty':
        return 'Open Quantity'
      case 'amount':
        return 'Amount'
      case 'amountExclVat':
        return '不含税金额'
      case 'amountInclVat':
        return '含税金额'
      default:
        return null
    }
  }

  return (
    <Typography color='primary'>
      Total {attrText()}: {total && total.toFixed(2)}
    </Typography>
  )
}

export default MTSumField
