import dsDeleteFulfillments from '../../functions/APIOperations/dsDeleteFulfillments'
import dsUpdateInventory from '../../functions/APIOperations/dsUpdateInventory'
import dsUpdateSPOQtyAndSPOSchedAfterDeleteReceivings from '../../functions/APIOperations/dsUpdateSPOQtyAndSPOSchedAfterDeleteReceivings'
import dsUpdateForwarderPOQtyAfterDeleteReceivings from '../../functions/APIOperations/dsUpdateForwarderPOQtyAfterDeleteReceivings'
import dsUpdateForwarderPOsDeliveryStatusWithReceivingCodeAndHpn from '../../functions/APIOperations/dsUpdateForwarderPOsDeliveryStatusWithReceivingCodeAndHpn'

const onSelectedDelete = async (selected) => {
  await dsDeleteFulfillments(selected)

  await dsUpdateSPOQtyAndSPOSchedAfterDeleteReceivings(selected)

  await dsUpdateInventory(selected, 'minus')

  await dsUpdateForwarderPOQtyAfterDeleteReceivings(selected)

  // const itemsWithReceivingCode = selected.filter((x) => x.receivingCode)

  // await dsUpdateForwarderPOsDeliveryStatusWithReceivingCodeAndHpn(
  //   itemsWithReceivingCode,
  // )
}

export default onSelectedDelete
