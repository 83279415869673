import handleDSOperation from '../../functions/handleDSOperation'
import dsUpdatePaymentStatusOnInvoices from '../../functions/APIOperations/dsUpdatePaymentStatusOnInvoices'

const onSelectedDelete = async (selectedRows) => {
  await Promise.all(
    selectedRows.map(async (x) => {
      // console.log('x', x)
      // debugger
      await handleDSOperation(null, 'delete', x.id)

      await dsUpdatePaymentStatusOnInvoices({
        invoiceNumber: x.invoiceNumber,
        customer: x.customer,
      })
    }),
  )
}

export default onSelectedDelete
