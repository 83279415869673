import React, { useState } from 'react'
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
  gridClasses,
  useGridSlotComponentProps,
} from '@mui/x-data-grid'
import { createTheme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import { TextField, IconButton, Button, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Clear, Search } from '@mui/icons-material'

const defaultTheme = createTheme()
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
      textField: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    }),
  { defaultTheme },
)

const ToolbarComponent = ({
  Tb,
  onSelectedDelete,
  onSearchChange,
  searchText,
  clearSearch,
  dataType,
  currentUser,
}) => {
  const classes = useStyles()

  const [loading, setLoading] = useState(false)

  const gridProps = useGridSlotComponentProps()

  // console.log('gridProps in Toolbar', gridProps)

  const selection = gridProps.state.selection
  const rows = gridProps.rows
  const setSelectionModel = gridProps.apiRef.current.setSelectionModel
  const selectedRows = selection.map((x) => rows.find((y) => y.id === x))

  return (
    <div className={classes.root}>
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarFilterButton />
        <GridToolbarExport />
        {selection.length > 0 && (
          <>
            <Typography variant='h6' color='secondary'>
              Selected {selection.length}
            </Typography>
            <LoadingButton
              loading={loading}
              style={{ marginLeft: 10, marginRight: 10 }}
              variant='contained'
              color='secondary'
              onClick={async () => {
                if (window.confirm('delete all?')) {
                  setLoading(true)
                  await onSelectedDelete(selectedRows)
                  setLoading(false)
                }
              }}
              size='small'>
              Delete Selected
            </LoadingButton>
            {/* <Button
              variant='contained'
              color='secondary'
              size='small'
              onClick={() => {
                console.log('selected')
                setSelectionModel([])
              }}>
              props
            </Button> */}
          </>
        )}

        <Tb
          setSelectionModel={setSelectionModel}
          selectedRows={selectedRows}
          dataType={dataType}
          currentUser={currentUser}
        />
      </GridToolbarContainer>

      <TextField
        variant='standard'
        value={searchText}
        onChange={onSearchChange}
        placeholder='Search…'
        className={classes.textField}
        InputProps={{
          startAdornment: <Search fontSize='small' />,
          endAdornment: (
            <IconButton
              title='Clear'
              aria-label='Clear'
              size='small'
              style={{ visibility: searchText ? 'visible' : 'hidden' }}
              onClick={clearSearch}>
              <Clear fontSize='small' />
            </IconButton>
          ),
        }}
      />
    </div>
  )
}

export default ToolbarComponent
