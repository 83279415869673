const mapping = {
  enhanced: [
    'actions',
    'memo',
    'customer',
    'poNumber',
    'date',
    'line',
    'cpn',
    'mpn',
    'qty',
    'openQty',
    'amount',
    'price',
    'reqSched',
    'estSched',
    // 'customerLeadtime',
    // 'scheduleBasedOnPdt',
    // 'pdtDifference',

    '_lastChangedAt',
  ],
  simple: [
    'isNew',
    'date',
    'poNumber',
    'line',
    'cpn',
    'mpn',
    'qty',
    'openQty',
    'totalOpenQty',
    'enteredPrice',
    'price',
    'reqSched',
  ],
  collasped: [
    'poNumber',
    'line',
    'cpn',
    'mpn',
    'price',
    'qty',
    'openQty',
    'reqSched',
    '_lastChangedAt',
    'version',
  ],
}

export default mapping
