const getInitialValue = (data) => ({
  id: data.id,
  type: data.type,
  entityType: data.entityType,
  billingInfo: data.billingInfo,
  memo: data.memo,
  number: data.number,
  paymentTerm: data.paymentTerm,
  deliveryTerms: data.deliveryTerms,
  entity: data.entity,
  hankenoEntity: data.hankenoEntity,
  vendorOrCtmCode: data.vendorOrCtmCode,
  isActive: data.isActive || false,
})

export default getInitialValue
