import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import { DataStore } from 'aws-amplify'
import { DataGrid } from '@mui/x-data-grid'
import AuthContext from 'context/AuthContext'
import { HDC } from 'models/index'
import _ from 'lodash'
import getTableMapping from 'functions/getTableMapping'
import handleDSOperation from 'functions/handleDSOperation'
import RootForm from '../Form/RootForm'
import DetailPanelDialog from './DetailPanelDialog'
import Toolbar from './ToolbarComponent'
import NoRowsOverlay from './NoRowsOverlay'
import LoadingOverlay from './LoadingOverlay'
import sort from 'functions/tableFunctions/sort'

export default function MaterialTableLoader({ dataType, tbType }) {
  const currentUser = useContext(AuthContext)
  // const apiRef = useGridApiRef()
  const [data, setData] = useState([])
  const [rows, setRows] = useState()
  const [searchText, setSearchText] = useState('')

  function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
  }
  const requestSearch = useCallback(
    (searchValue, ignore) => {
      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i')
      const filteredRows = data.filter((row) => {
        return Object.keys(row).some((field) => {
          return searchRegex.test(row[field] && row[field].toString())
        })
      })

      if (!ignore) setRows(filteredRows)
    },
    [data],
  )

  const Tb = require(`resources/${dataType}/Toolbar`).default
  const onSelectedDelete =
    require(`resources/${dataType}/onSelectedDelete`).default
  const filter = require(`resources/${dataType}/filter`).default
  const columns = getTableMapping(dataType, tbType, currentUser)

  const funcFecthData = useCallback(
    async (ignore) => {
      console.log('fetching data...')
      let queryObj
      switch (dataType) {
        case 'cpo':
        case 'spo':
        case 'product':
          queryObj = {
            version: { predicate: 'eq', value: 0 },
          }
          break
        case 'forwarderPo':
        case 'receiving':
        case 'shipping':
        case 'customer':
        case 'vendor':
        case 'contact':
        case 'incomingInvoice':
        case 'outgoingInvoice':
        case 'incomingPayment':
        case 'outgoingPayment':
        case 'outgoingQuote':
          queryObj = {}
          break

        default:
          break
      }

      let fetchedData = await handleDSOperation(dataType, 'query', queryObj)
      fetchedData = sort(dataType, tbType, fetchedData)
      if (!ignore) setData(fetchedData)
    },
    [dataType, tbType],
  )

  React.useEffect(() => {
    let ignore = false

    if (data.length > 0) {
      console.log('Setting Data...')
      requestSearch(searchText, ignore)
    }
    return () => {
      ignore = true
    }
  }, [data, requestSearch, searchText])

  useEffect(() => {
    let ignore = false
    funcFecthData(ignore)
    return () => {
      ignore = true
    }
  }, [funcFecthData])

  useEffect(() => {
    let ignore = false
    const debouncedFetchData = _.debounce(({ opType, element, ...props }) => {
      console.log('debounced!')
      console.log('opType in debounced!', opType)
      console.log('element in debounced!', element)
      console.log('props in debounced!', props)
      console.log('dataType in debounced!', dataType)
      funcFecthData(ignore)
    }, 2000)

    const sub = DataStore.observe(HDC).subscribe(
      ({ opType, element, ...props }) => {
        if (dataType === element.type) {
          debouncedFetchData({ opType, element, ...props })

          //           if (opType === 'UPDATE' || opType === 'INSERT') {
          //             if (element.version === null || element.version === 0) {
          //               console.log('UPDATE!!!!!!!!!!!', element)
          // // ATTENTION 直接插入的rows没有排序
          //               apiRef.current.updateRows([element])
          //             }
          //           } else if (opType === 'DELETE') {
          //             apiRef.current.updateRows([{ id: element.id, _action: 'delete' }])
          //           } else {
          //             alert(`func not defined,type:${opType}`)
          //           }
        }
      },
    )

    return () => {
      ignore = true
      sub.unsubscribe()
    }
  }, [dataType, funcFecthData])

  

  return (
    <div style={{ flexGrow: 1 }}>
      <DataGrid
        loading={!rows}
        // apiRef={apiRef}
        rows={rows || []}
        columns={columns || []}
        checkboxSelection
        disableSelectionOnClick
        // filterModel={filter}
        disableColumnSelector
        disableColumnMenu
        throttleRowsMs={2000}
        density='comfortable'
        pagination
        pageSize={100}
        components={{
          Toolbar: useMemo(() => Toolbar, []),
          NoRowsOverlay,
          LoadingOverlay,
        }}
        componentsProps={{
          toolbar: {
            Tb,
            onSelectedDelete,
            dataType,
            currentUser,
            searchText,
            onSearchChange: (e) => setSearchText(e.target.value),
            clearSearch: () => setSearchText(''),
          },
        }}
        onRowDoubleClick={({ row }) => {
          console.log('row data:', row)
        }}
      />
      {dataType && (
        <>
          <RootForm />
          <DetailPanelDialog />
        </>
      )}
    </div>
  )
}
