import handleDSOperation from '../handleDSOperation'
import groupArrayofObjectsByKey from '../helpFunc/groupArrayofObjectsByKey'

export default async function dsUpdateSPOBillingStatus(items, addOrMinus) {
  const invoiceByPoNumber = Object.entries(
    groupArrayofObjectsByKey(items, 'poNumber'),
  )

  await Promise.all(
    invoiceByPoNumber.map(async ([poNumber, itemsByPo]) => {
      const invoiceByHpn = Object.entries(
        groupArrayofObjectsByKey(itemsByPo, 'hpn'),
      )
      await Promise.all(
        invoiceByHpn.map(async ([hpn, itemsByHPN]) => {
          let sessionQty = itemsByHPN.reduce((a, v) => a + v.qty * 1, 0)
          let poQty = itemsByHPN[0].poQty
          let totalBilled = itemsByHPN[0].totalBilled
          let poIDs = itemsByHPN[0].poIDs

          if (!poQty) {
            const getSPOs = await handleDSOperation('spo', 'query', {
              version: { predicate: 'eq', value: 0 },
              poNumber: { predicate: 'eq', value: poNumber },
              hpn: { predicate: 'eq', value: hpn },
            })

            poQty = getSPOs.reduce((a, v) => a + v.qty * 1, 0)
            poIDs = getSPOs.map((x) => x.id)
          }

          if (!totalBilled) {
            const getInvoices = await handleDSOperation(
              'incomingInvoice',
              'query',
              {
                poNumber: { predicate: 'eq', value: poNumber },
                hpn: { predicate: 'eq', value: hpn },
              },
            )
            totalBilled = getInvoices.reduce((a, v) => a + v.qty * 1, 0)
          }
          let billingStatus
          sessionQty = addOrMinus === 'add' ? sessionQty : 0 - sessionQty

          console.log('sessionQty', sessionQty)
          console.log('totalBilled', totalBilled)
          console.log('poQty', poQty)

          if (sessionQty + totalBilled === 0) {
            billingStatus = 'unbilled'
          } else if (sessionQty + totalBilled < poQty) {
            billingStatus = 'partial'
          } else if (sessionQty + totalBilled === poQty) {
            billingStatus = 'billed'
          } else {
            billingStatus = 'billed'
            alert(`PO ${poNumber} billing qty is great than po quantity`)
          }

          await Promise.all(
            poIDs.map(async (id) => {
              await handleDSOperation(null, 'update', {
                id,
                billingStatus,
              })
            }),
          )
        }),
      )
    }),
  )
}
