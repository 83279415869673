// import _ from 'lodash'
import { firstBy } from 'thenby'
import { confirmAlert } from 'react-confirm-alert'
import restAPICalls from '../../functions/restAPICalls'
import dsGenerateSPOItemsPerVendor from '../../functions/APIOperations/dsGenerateSPOItemsPerVendor'
import handleDSOperation from '../../functions/handleDSOperation'
import groupArrayofObjectsByKey from '../../functions/helpFunc/groupArrayofObjectsByKey'
import SPOsPreview from '../../components/Table/toolbar/SPOsPreview'

const handleSubmit = async (values, setSubmitting) => {
  const poNumber = Date.now().toString().slice(1, 9)
  const newOrders = values.items.sort(firstBy('cpn').thenBy('reqSched'))
  newOrders.forEach((x, i) => {
    x.poNumber = (poNumber * 1 + i).toString()
    x.openQty = x.qty && x.qty * 1
  })

  const arrByVendor = Object.entries(
    groupArrayofObjectsByKey(newOrders, 'vendor'),
  )

  let obj = await Promise.all(
    arrByVendor.map(async ([vendor, poByVendor]) => {
      const existingOrders = await handleDSOperation('spo', 'query', {
        vendor: { predicate: 'eq', value: vendor },
        version: { predicate: 'eq', value: 0 },
        openQty: { predicate: 'gt', value: 0 },
      })

      const poObj = await dsGenerateSPOItemsPerVendor(
        vendor,
        values.date,
        poByVendor,
        existingOrders,
      )
      return { ...poObj, sendEmail: true }
    }),
  )

  await new Promise((resolve, reject) => {
    const options = {
      title: 'Title',
      message: 'Message',
      // buttons: [
      //   {
      //     label: 'Yes',
      //     onClick: () => alert('Click Yes'),
      //   },
      //   {
      //     label: 'No',
      //     onClick: () => alert('Click No'),
      //   },
      // ],
      // childrenElement: () => <div />,
      customUI: ({ onClose }) => (
        <SPOsPreview
          spos={obj}
          onClose={onClose}
          setSubmitting={setSubmitting}
          resolve={resolve}
        />
      ),
      closeOnEscape: true,
      closeOnClickOutside: false,
      // willUnmount: () => {},
      // afterClose: () => {},
      // onClickOutside: () => {},
      // onKeypressEscape: () => {},
      // overlayClassName: 'overlay-custom-class-name',
    }

    confirmAlert(options)
  })
}

export default handleSubmit
