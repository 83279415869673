import mapping from './mapping'
import getAttrValue from './getAttrValue'

const getRowValue = (dataType, inputType, rows) => {
  const aggregatedItems = []

  rows.forEach((row) => {
    let isRowEmpty = true

    for (let x of rows) {
      if (x.length > 0) {
        isRowEmpty = false
        break
      }
    }

    if (isRowEmpty) return

    const rowObj = { type: dataType, alertMessage: [] }

    mapping(inputType).forEach((x) => {

      if (row[x.index]) {
        rowObj[x.attr] = getAttrValue(inputType, x.attr, row)
      }
    })
    aggregatedItems.push(rowObj)
  })
  // console.log('aggregatedItems', aggregatedItems)

  // debugger

  return aggregatedItems
}

export default getRowValue
