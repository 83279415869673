import handlePO from './handlePO'
import dsCreateSpoScheds from '../APIOperations/dsCreateSpoScheds'
import dsCreateContact from '../APIOperations/dsCreateContact'
import dsCreateBp from '../APIOperations/dsCreateBp'
import dsCreateProduct from '../APIOperations/dsCreateProduct'
import dsCreatePoItem from '../APIOperations/dsCreatePoItem'
import dsCreateIncomingInvoices from '../APIOperations/dsCreateIncomingInvoices'

import dsCreateOutgoingInvoice from '../APIOperations/dsCreateOutgoingInvoice'
import dsGenerateSPOItemsPerVendor from '../APIOperations/dsGenerateSPOItemsPerVendor'
import dsCreateShipments from '../APIOperations/dsCreateShipments'
import dsUpdateCPOQtyAfterCreateShipments from '../APIOperations/dsUpdateCPOQtyAfterCreateShipments'
import dsUpdateInventory from '../APIOperations/dsUpdateInventory'
import dsUpdateForwarderPOQtyAfterCreateReceivings from '../APIOperations/dsUpdateForwarderPOQtyAfterCreateReceivings'
import dsCreateReceivings from '../APIOperations/dsCreateReceivings'
import dsUpdateSPOQtyAndSPOSchedAfterCreateReceivings from '../APIOperations/dsUpdateSPOQtyAndSPOSchedAfterCreateReceivings'
import dsUpdateShipmentsBillingStatusWithPoNumberAndCpnAndCustomer from '../APIOperations/dsUpdateShipmentsBillingStatusWithPoNumberAndCpnAndCustomer'
import getValidRows from '../rowValidator/getValidRows'
import getPaymentDue from '../helpFunc/getPaymentDue'
import handleDSOperation from '../handleDSOperation'
import groupArrayofObjectsByKey from '../helpFunc/groupArrayofObjectsByKey'
import restAPICalls from '../restAPICalls'

const batchOperation = async (dataType, operationType, rows) => {
  // console.log('operationType', operationType)
  // debugger
  rows = getValidRows(rows)
  if (rows.length === 0) return

  switch (dataType) {
    case 'cpo':
    case 'spo':
      if (operationType === 'updateSched') {
        await dsCreateSpoScheds(rows)
        break
      }
      if (operationType === 'createdNewSpo') {
        const poItemsByVendor = Object.entries(
          groupArrayofObjectsByKey(rows, 'vendor'),
        )

        const arrOfPoObj = await Promise.all(
          poItemsByVendor.map(async ([vendor, poItems]) => {
            await poItems.map(async (item) => await dsCreatePoItem(item, 0))
            const existingOrders = await handleDSOperation('spo', 'query', {
              vendor: { predicate: 'eq', value: vendor },
              version: { predicate: 'eq', value: 0 },
              openQty: { predicate: 'gt', value: 0 },
            })

            const currentDate = new Date().toISOString().substring(0, 10)

            const poObj = await dsGenerateSPOItemsPerVendor(
              vendor,
              currentDate,
              poItems,
              existingOrders,
            )

            return poObj
          }),
        )
        const sendEmail = rows[0].sendEmail
        if (sendEmail && sendEmail.toLowerCase() === 'n') return

        await restAPICalls('post', '', {
          type: 'spo',
          items: arrOfPoObj,
          isPreview: false,
        })

        break
      }
      // console.log(333)

      await handlePO(rows)
      break
    case 'shipping':
      if ([...new Set(rows.map((x) => x.customer))].length > 1) {
        return alert('multiple customers in one shipment')
      }
      //VALIDATOR CARTON NUMBER
      const arrayOfCartonNumber = [...new Set(rows.map((x) => x.cartonNumber))]

      const differenceArr = arrayOfCartonNumber
        .slice(1)
        .map((x, i) => x - arrayOfCartonNumber[i])

      // console.log('differenceArr', differenceArr)

      if (differenceArr.length > 0) {
        const isDifference = differenceArr.every((value) => value === 1)
        console.log('isDifference', isDifference)

        if (!isDifference || Math.min(...arrayOfCartonNumber) !== 1) {
          return alert('箱号不连续或错误')
        }
      }

      // END OF VALIDATE CARTON NUMBER

      const PIfulfillmentNumber = Date.now().toString().slice(1, 9)

      const invoiceType = rows[0].priceCurrency === 'CNY' ? 'VAT' : 'PI'

      const shippingItems = rows.map((x) => ({
        type: 'shipping',
        date: x.date,
        invoiceType,
        customer: x.customer,
        customerPaymentDue: x.customerPaymentDue,
        fulfillmentNumber:
          x.customer === '宝洁' || x.customer === '博朗(上海)有限公司'
            ? x.fulfillmentNumber
            : PIfulfillmentNumber,
        deliveryTerm: x.deliveryTerm,
        invoiceNumber: x.invoiceNumber,
        cartonNumber: x.cartonNumber && x.cartonNumber * 1,
        cartonNw: x.nw || (x.gw && x.gw * 0.92),
        cartonGw: x.gw && x.gw * 1,
        cartonDimensions: x.dimensions,
        cpn: x.cpn,
        hpn: x.hpn,
        mfr: x.mfr,
        mpn: x.mpn,
        poNumber: x.poNumber && x.poNumber.trim(),
        qty: x.qty && x.qty * 1,
        unit: x.unit,
        price: x.price && x.price * 1,
        priceCurrency: x.priceCurrency,
        coo: x.coo && x.coo.split(','),
        billingStatus: invoiceType === ' VAT' ? 'unbilled' : 'billed',
        receivingCode: x.receivingCode || '',
      }))

      // console.log('shippingItems', shippingItems)
      // debugger
      if (invoiceType === 'PI') {
        const getCustomer = await handleDSOperation('customer', 'query', {
          entity: { predicate: 'eq', value: rows[0].customer },
        })

        if (getCustomer.length === 0 || !getCustomer[0].deliveryTerms) {
          return alert('shipping term not defined')
        }

        // const deliveryTerms = getCustomer[0].deliveryTerms
        const hankenoEntity = getCustomer[0].hankenoEntity

        const customerPaymentDue = getPaymentDue(getCustomer[0].paymentTerm)

        let invoiceNumber

        if (hankenoEntity === 'HKNUSA') {
          // GENERATE INVOICE NUMBER
          const getInvoices = await handleDSOperation(
            'outgoingInvoice',
            'query',
            {
              invoiceType: { predicate: 'eq', value: 'PI' },
            },
          )

          if (getInvoices.length > 0) {
            getInvoices.sort((a, b) =>
              a.invoiceNumber > b.invoiceNumber ? -1 : 1,
            )
            const lastNumber = getInvoices[0].invoiceNumber.slice(2, 8)
            const lastDate = getInvoices[0].date
            const dateDiff =
              (new Date().getTime() - new Date(lastDate).getTime()) /
              (24 * 60 * 60 * 1000)
            const dailyDiff = Math.random() * 100 + 200
            const invoiceDiff = Math.floor(dateDiff * dailyDiff)
            invoiceNumber = `EN${lastNumber * 1 + invoiceDiff * 1}`
          } else {
            invoiceNumber = 'Temp123'
          }
        }

        // console.log('invoiceNumber', invoiceNumber)
        // console.log('customerPaymentDue', customerPaymentDue)
        shippingItems.forEach((x) => {
          x.invoiceNumber = invoiceNumber
          x.customerPaymentDue = customerPaymentDue
        })

        await dsCreateOutgoingInvoice(shippingItems)
      }

      await dsCreateShipments(shippingItems)
      await dsUpdateCPOQtyAfterCreateShipments(shippingItems)
      await dsUpdateInventory(shippingItems, 'minus')

      break
    case 'receiving':
      let receivingItems = rows.map((x) => ({
        date: x.date,
        vendor: x.vendor,
        type: 'receiving',
        cpn: x.cpn,
        hpn: x.hpn,
        mpn: x.mpn,
        poNumber: x.poNumber,
        invoiceNumber: x.invoiceNumber,
        receivingCode: x.receivingCode,
        cost: x.cost,
        costCurrency: x.costCurrency,
        qty: x.qty,
        unit: x.unit,
      }))

      if (receivingItems[0].receivingCode) {
        receivingItems = await dsUpdateForwarderPOQtyAfterCreateReceivings(
          receivingItems,
        )
      }
      await dsCreateReceivings(receivingItems)
      await dsUpdateInventory(receivingItems, 'add')
      await dsUpdateSPOQtyAndSPOSchedAfterCreateReceivings(receivingItems)

      break
    case 'customer':
    case 'vendor':
      await dsCreateBp(rows)
      break
    case 'contact':
      await dsCreateContact(rows)
      break
    case 'product':
      await Promise.all(
        rows.map(async (x) => {
          // the 2nd arg is version number
          await dsCreateProduct(x, 0)
        }),
      )
      break
    case 'outgoingInvoice':
      await dsUpdateShipmentsBillingStatusWithPoNumberAndCpnAndCustomer(
        rows,
        'add',
      )

      await dsCreateOutgoingInvoice(rows)
      break
    case 'incomingInvoice':
      await dsCreateIncomingInvoices(rows)
      // await dsCreateOutgoingInvoice(rows)
      // await dsUpdateShipmentsBillingStatusWithPoNumberAndCpnAndCustomer(rows)
      break

    default:
      return
  }
}

export default batchOperation
