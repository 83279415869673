import handleDSOperation from '../../functions/handleDSOperation'

const onRowUpdate = async (newData) => {
  if (!Array.isArray(newData.role)) {
    newData.role = newData.role.split(',').map((x) => x.trim())
  }
  const input = {
    id: newData.id,
    entity: newData.entity && newData.entity.toUpperCase(),
    isMainContact: newData.isMainContact,
    name: newData.name,
    title: newData.title,
    dept: newData.dept && newData.dept.toUpperCase(),
    role: newData.role,
    email: newData.email,
    phoneNumber: newData.phoneNumber,
    memo: newData.memo,
    address: newData.address,
    deliveryTerm: newData.deliveryTerm,
  }

  await handleDSOperation(null, 'update', input)
}

export default onRowUpdate
