const getInitialValue = (data) => {
  const initialValues = {
    id: data.id,
    type: data.type,
    vendor: data.vendor,
    hpn: data.hpn,
    unit: data.unit,
    poNumber: data.poNumber,
    date: data.date,
    qty: data.qty,
    openQty: data.openQty,
    mfr: data.mfr,
    mpn: data.mpn,
    cpn: data.cpn,
    cost: data.cost,
    costCurrency: data.costCurrency,
    reqSched: data.reqSched,
    memo: data.memo,
    latestVersion: data.latestVersion,
    billingStatus: data.billingStatus,
  }

  return {
    ...initialValues,
    initialValues,
  }
}
export default getInitialValue
