import React, { useEffect, useMemo } from 'react'
import _ from 'lodash'
import {
  FormField,
  ProductAutoComplete,
  AutoCompleteField,
} from '../../components/Form/FormField'

import usePrevious from '../../hooks/usePrevious'
import handleDSOperation from '../../functions/handleDSOperation'
import { Form } from 'formik'

const ItemComponent = ({
  values,
  handleBlur,
  index,
  handleChange,
  setFieldValue,
  formStyle,
  item: currentItem,
  userGroups: groups,
}) => {
  const poAttr = values.isForwarder ? 'receivingCode' : 'poNumber'
  const prevPoAttr = usePrevious(currentItem[poAttr])
  const prevAmountExclVat = usePrevious(currentItem.amountExclVat)
  const sessionQty = values.items.reduce(
    (a, v) =>
      a +
      (v[poAttr] === currentItem[poAttr] && v.hpn === currentItem.hpn
        ? v.qty * 1 || 0
        : 0),
    0,
  )

  const billable =
    (currentItem?.poQty || 0) - (currentItem?.totalBilled || 0) - sessionQty * 1

  useEffect(() => {
    console.log('in useEffect111')
    if (currentItem.hpn) {
      let poNumber, receivingCode
      const itemsSelectablePos = values.retrivedPos.filter(
        (x) => x.hpn === currentItem.hpn,
      )

      if (itemsSelectablePos.length === 1) {
        receivingCode = itemsSelectablePos[0].receivingCode
        poNumber = itemsSelectablePos[0].poNumber
      }
      setFieldValue(`items[${index}]`, {
        itemsSelectablePos,
        mpn: itemsSelectablePos[0].mpn,
        poNumber,
        receivingCode,
        cpn: itemsSelectablePos[0].cpn,
        hpn: currentItem.hpn,
        unit: itemsSelectablePos[0].unit,
        costCurrency: itemsSelectablePos[0].costCurrency,
        mfr: itemsSelectablePos[0].mfr,

        // alertMessage: {},
        // amountExclVat: '',
      })
    }
  }, [currentItem.hpn, values.retrivedPos, index, setFieldValue])

  useEffect(() => {
    const func = async () => {
      if (
        // currentItem[poAttr] &&
        // &&!_.isEqual(values, prevValues)
        currentItem[poAttr] !== prevPoAttr
      ) {
        const poItems = currentItem.itemsSelectablePos.filter(
          (x) => x[poAttr] === currentItem[poAttr],
        )

        const poItemsTotalQty = poItems.reduce((a, v) => a + v.qty * 1, 0)

        const getReceivings = await handleDSOperation('receiving', 'query', {
          // vendor: { predicate: 'eq', value: values.vendor },
          hpn: { predicate: 'eq', value: currentItem.hpn },
          [poAttr]: { predicate: 'eq', value: currentItem[poAttr] },
        })

        const totalReceivings = getReceivings.reduce((a, v) => a + v.qty * 1, 0)

        const getInvoices = await handleDSOperation(
          'incomingInvoice',
          'query',
          {
            vendor: { predicate: 'eq', value: values.vendor },
            hpn: { predicate: 'eq', value: currentItem.hpn },
            [poAttr]: { predicate: 'eq', value: currentItem[poAttr] },
            invoiceType: { predicate: 'eq', value: values.invoiceType },
          },
        )

        const totalBilled = getInvoices.reduce((a, v) => a + v.qty * 1, 0)

        // console.log('poItemsTotalQty', poItemsTotalQty)
        // console.log('totalBilled', totalBilled)
        // console.log('sessionQty', sessionQty)

        setFieldValue(`items[${index}]`, {
          ...currentItem,
          [poAttr]: currentItem[poAttr],
          poIDs: poItems.map((x) => x.id),
          poQty: poItemsTotalQty,
          totalReceivings,
          totalBilled,
          qty: poItemsTotalQty - totalBilled - sessionQty,
          poCost: poItems[0]?.cost,
          costCurrency: poItems[0]?.costCurrency,
          poAmountExclVat: poItems[0]?.amountExclVat,
        })
      }
    }
    func()
  }, [
    poAttr,
    currentItem,
    setFieldValue,
    index,
    prevPoAttr,
    values,
    sessionQty,
  ])

  // useEffect(() => {
  //   if (
  //     currentItem.receivingCode &&
  //     currentItem.receivingCode !== prevReceivingCode
  //   ) {
  //     const items = currentItem.itemsSelectablePos.filter(
  //       (x) => x.receivingCode === currentItem.receivingCode,
  //     )
  //     setFieldValue(`items[${index}]`, {
  //       ...currentItem,
  //       receivingCode: currentItem.receivingCode,
  //       qty: items[0].openQty || items[0].qty,
  //       poCost: items[0].cost,
  //       costCurrency: items[0].costCurrency,
  //       poAmountExclVat: items[0].amountExclVat,
  //       poQty: items[0].qty,
  //     })
  //   }
  // }, [currentItem, setFieldValue, index, prevReceivingCode])

  useEffect(() => {
    if (
      currentItem.amountExclVat &&
      currentItem.amountExclVat !== prevAmountExclVat &&
      values.usePaymentAgent
    ) {
      setFieldValue(`items[${index}]`, {
        ...currentItem,
        paymentAgentAmountExclVat: currentItem.amountExclVat,
      })
    }
  }, [
    prevAmountExclVat,
    index,
    setFieldValue,
    currentItem,
    values.usePaymentAgent,
  ])

  const selection = useMemo(
    () =>
      [...new Set(values.retrivedPos.map((x) => x.hpn))]
        .map((y) => {
          const item = values.retrivedPos.find((z) => z.hpn === y)
          return {
            mpn: item.mpn,
            cpn: item.cpn,
            hpn: item.hpn,
            mfr: item.mfr,
          }
        })
        .sort((a, b) => (a.cpn > b.cpn ? 1 : -1)),
    [values.retrivedPos],
  )

  // console.log('currentItem', currentItem)
  return (
    <Form style={formStyle} autoComplete='off'>
      <ProductAutoComplete
        id={`${values.vendor}${values.items.length}`}
        options={selection}
        value={
          currentItem.hpn
            ? {
                // hpn: currentItem.hpn,
                cpn: currentItem.cpn,
                mfr: currentItem.mfr,
                mpn: currentItem.mpn,
              }
            : null
        }
        index={index}
        currentItem={currentItem}
        setFieldValue={setFieldValue}
      />

      <AutoCompleteField
        id={currentItem.hpn}
        fieldName={poAttr}
        attr={poAttr}
        index={index}
        currentItem={currentItem}
        setFieldValue={setFieldValue}
        value={
          currentItem && currentItem[poAttr]
            ? { [poAttr]: currentItem[poAttr] }
            : null
        }
        options={[
          ...new Set(currentItem.itemsSelectablePos.map((x) => x[poAttr])),
        ]
          .sort((a, b) => (a > b ? 1 : -1))
          .map((x) => ({ [poAttr]: x }))}
        handleChange={(e, newValue) => {
          setFieldValue(`items[${index}]`, {
            ...currentItem,
            [poAttr]: newValue?.[poAttr],
            qty: 0,
          })
        }}
      />

      <FormField
        disabled
        fieldName='unit'
        index={index}
        value={currentItem.unit}
      />
      <FormField
        disabled
        fieldName='poQty'
        index={index}
        value={currentItem.poQty}
      />
      <FormField
        disabled
        fieldName='totalReceivings'
        index={index}
        value={currentItem.totalReceivings}
      />

      <FormField
        disabled
        fieldName='totalBilled'
        index={index}
        value={currentItem.totalBilled}
      />

      <FormField
        disabled
        fieldName='sessionQty'
        index={index}
        value={sessionQty}
      />

      {/* <FormField disabled fieldName='billable' index={index} value={billable} /> */}

      <FormField
        disabled={!currentItem.poNumber && !currentItem.receivingCode}
        fieldName='qty'
        index={index}
        value={currentItem.qty}
        handleBlur={handleBlur}
        handleChange={handleChange}
        validate={(value) => {
          let msg

          if (value === undefined || value === null) {
            msg = '请输入数量'
          } else if (sessionQty + currentItem.totalBilled > currentItem.poQty) {
            msg = '输入数量大于订单未开票数量'
          }
          return msg
        }}
      />

      <FormField
        disabled={!currentItem.poNumber && !currentItem.receivingCode}
        fieldName='amountExclVat'
        index={index}
        value={currentItem.amountExclVat}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />

      <FormField
        disabled
        fieldName='priceExclVat'
        index={index}
        value={
          currentItem.amountExclVat &&
          currentItem.qty &&
          currentItem.amountExclVat / currentItem.qty
        }
      />
      <FormField
        disabled
        fieldName='cost'
        index={index}
        value={
          currentItem.poCost ||
          (currentItem.poAmountExclVat &&
            currentItem.poQty &&
            (currentItem.poAmountExclVat / currentItem.poQty).toFixed(3))
        }
      />
      {values.usePaymentAgent && (
        <>
          <FormField
            disabled
            fieldName='paymentAgentAmountExclVat'
            index={index}
            value={currentItem.paymentAgentAmountExclVat}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <FormField
            disabled
            fieldName='paymentAgentPriceExclVat'
            index={index}
            value={
              currentItem.paymentAgentAmountExclVat &&
              currentItem.qty &&
              currentItem.paymentAgentAmountExclVat / currentItem.qty
            }
          />
        </>
      )}
    </Form>
  )
}

export default ItemComponent
