import handleDSOperation from '../../functions/handleDSOperation'

const handleSubmit = async (values) => {
  await Promise.all(
    values.items.map(async (x) => {
      const obj = {
        customer: values.customer,
        date: values.date,
        number: values.number,
        // sales,
        // salesEmail,
        hankenoEntity: values.hankenoEntity,
        paymentTerm: values.paymentTerm,
        deliveryTerm: values.deliveryTerm,
        email:
          values.contact &&
          values.contact.email &&
          values.contact.email.toLowerCase(),
        dept:
          (values.contact &&
            values.contact.dept &&
            values.contact.dept.toUpperCase()) ||
          (values.customer && values.customer.toUpperCase()),
        phoneNumber: values.contact && values.contact.phoneNumber,
        name: values.name,
        memo: values.memo,
        mpn: x.mpn,
        mfr: x.mfr,
        cpn: x.cpn,
        hpn: x.hpn,
        price: x.price && x.price * 1,
        unit: x.unit,
        priceCurrency: x.priceCurrency,
        customerMOQ: x.customerMOQ && x.customerMOQ * 1,
        customerMPQ: x.customerMPQ && x.customerMPQ * 1,
        customerLeadtime: x.customerLeadtime && x.customerLeadtime * 1,
      }

      // console.log('obj555', obj)
      // debugger

      await handleDSOperation('outgoingQuote', 'create', obj)
    }),
  )
}

export default handleSubmit
