import React, { useState, useContext } from 'react'
import { BrowserRouter as Route, useHistory, Link } from 'react-router-dom'
import { API, DataStore, graphqlOperation } from 'aws-amplify'
import {
  Stack,
  TextField,
  Button,
  Box,
  CircularProgress,
  Backdrop,
} from '@mui/material'
import batchOperation from '../functions/handleBatchOperation/batchOperation'
import getTypeAndTableData from '../functions/arrToObjConversion/getTypeAndTableData'
import handleDSOperation from '../functions/handleDSOperation'
import SimpleTable from '../components/Table/SimpleTable'
import MTSumField from '../components/Table/toolbar/MTSumField'
import AuthContext from '../context/AuthContext'
import groupArrayofObjectsByKey from '../functions/helpFunc/groupArrayofObjectsByKey'

export default function Batch() {
  const auth = useContext(AuthContext)
  const { groups } = auth

  const [textarea, setTextarea] = useState('')
  const [dataType, setDataType] = useState(null)
  const [operationType, setOperationType] = useState(null)
  const [pastedData, setPastedData] = useState([])
  // const [snackbarAttr, setSnackbarAttr] = useState(false)
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  // console.log('pastedData', pastedData)

  let history = useHistory()

  // const setSnackbar = (status, message, duration) => {
  //   setSnackbarAttr({
  //     isOpen: true,
  //     status,
  //     message,
  //     duration: duration || 4000,
  //     onClose: () => {
  //       setSnackbarAttr({ isOpen: false })
  //     },
  //   })
  // }

  const handleTextAreaChange = async (e) => {
    setLoading(true)
    setTextarea(e.target.value)

    const [dataType, optType, rows] = await getTypeAndTableData(e.target.value)

    setDataType(dataType)
    setOperationType(optType)

    if (rows && Array.isArray(rows) && rows[0] !== undefined) {
      setPastedData(rows)
    }
    setLoading(false)
  }

  const handleOnClick = async () => {
    setSubmitting(true)
    await batchOperation(dataType, operationType, pastedData)
    const failedItems = pastedData.filter(
      (x) => x.alertMessage && x.alertMessage.map((y) => y.level).includes(5),
    )
    if (failedItems.length > 0) {
      alert(
        `The following items failed,
      ${failedItems.map((x) => 'CPN ' + x.cpn + ' on PO ' + x.poNumber)}`,
      )
    }
    setSubmitting(false)
    setPastedData([])
    setDataType(null)

    setTextarea('')
    // setSnackbar('success', 'data created successfully')
    history.push(dataType)
  }

  // const updatePoNumber = async () => {
  //   const cpos = await handleDSOperation('cpo', 'query', {})
  //   await Promise.all(
  //     cpos.map(async (x) => {
  //       let poNumber

  //       if (x.poNumber.startsWith('A6P') || x.poNumber.startsWith('F6P')) {
  //         console.log('poNumber', x.poNumber)
  //       } else {
  //         const obj = { id: x.id, poNumber: `A6P-${x.poNumber}` }
  //         await handleDSOperation('cpo', 'update', obj)

  //         return null
  //       }
  //       return poNumber
  //     }),
  //   )
  // }

  const devFunc = async () => {
    try {
      // console.log(123)
      // const items = await API.graphql({
      //   query: listHDCs,
      //   variables: {
      //     filter: { date: { contains: '2021-07-27' } },
      //   },
      // })
      // const items = await DataStore.query(HDC)
      // console.log('items', items)
      // const items = await handleDSOperation('cpo', 'query', {
      //   date: { predicate: 'gt', value: '2021-08-27' },
      //   // _deleted: { predicate: 'eq', value: 'true' },
      //   // version: { predicate: 'eq', value: 0 },
      // })
      // console.log('items.length', items)
      // const itemsByPoNumber = Object.entries(
      //   groupArrayofObjectsByKey(items, 'poNumber'),
      // )
      // await Promise.all(
      //   itemsByPoNumber.map(async ([poNumber, itemsByPoNumber]) => {
      //     const orderedItems = itemsByPoNumber.sort((a, b) =>
      //       a.version > b.version ? 1 : -1,
      //     )
      //     if (
      //       itemsByPoNumber.length > 1 &&
      //       itemsByPoNumber[0].version !== 0
      //     ) {
      //       console.log('poNumber', poNumber)
      //       console.log('orderedItems', orderedItems)
      //       const {
      //         id,
      //         version,
      //         _deleted,
      //         _lastChangedAt,
      //         _version,
      //         ...attrs
      //       } = itemsByPoNumber[0]
      //       const newObj = { ...attrs, version: 0, latestVersion: version }
      //       console.log('newObj', newObj)
      //     }
      //   }),
      // )
    } catch (err) {
      console.log('error', err)
    }
  }
  // console.log('dataType', dataType)
  return (
    <>
      <Stack spacing={2} direction='row'>
        <Button
          // disabled={!groups.includes('admin')}
          variant='contained'
          color='secondary'
          onClick={async () => {
            await DataStore.clear()
            console.log('DS cleared')

            DataStore.start()
            console.log('DS restarted')
          }}>
          Clear Datastore
        </Button>
        <Button
          disabled={!groups.includes('admin')}
          variant='contained'
          color='primary'
          onClick={devFunc}>
          Dev Func
        </Button>
      </Stack>
      <div style={{ margin: 10 }}>
        <TextField
          onChange={handleTextAreaChange}
          id='outlined-textarea'
          value={textarea}
          // label='Please paste the form date here'
          placeholder='Please paste the form date here'
          multiline
          variant='outlined'
          inputProps={{
            style: {
              margin: 10,
              width: 800,
              minHeight: 100,
              maxHeight: 200,
              resize: 'both',
            },
          }}
        />

        <Stack spacing={2} direction='row'>
          {dataType && (
            // <div className={classes.wrapper}>

            <Button
              variant='contained'
              color='secondary'
              disabled={submitting}
              onClick={handleOnClick}>
              {/* {mapping[dataType].batchOperation.btnText} */}
              {`批量生成/更新${dataType}`}
            </Button>
            // {submitting && (
            //   <CircularProgress size={24} className={classes.buttonProgress} />
            // )}
            // </div>
          )}

          {textarea.length > 0 && (
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                // setButtonText(null)
                setTextarea('')
                setDataType(null)
                setPastedData([])
              }}>
              CLEAR
            </Button>
          )}
        </Stack>

        {dataType && pastedData.length > 0 && (
          <Box p={5}>
            <MTSumField qtyAttr='qty' selectedRows={pastedData} />
            <MTSumField qtyAttr='amountExclVat' selectedRows={pastedData} />
            <SimpleTable
              dataType={dataType}
              rows={pastedData}
              tbType='simple'
            />
          </Box>
        )}
        <Backdrop
          style={{
            // zIndex: theme.zIndex.drawer + 1,
            zIndex: 999,
            color: '#fff',
          }}
          open={loading}
          // onClick={handleClose}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
        {/* <Snackbar attr={snackbarAttr} /> */}
      </div>
    </>
  )
}
