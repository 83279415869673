import React, { useState } from 'react'
import {
  IconButton,
  Typography,
  MenuItem,
  Box,
  CircularProgress,
} from '@mui/material'
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material'
import ProductRecord from '../../components/Table/collapse/ProductRecord'
import FulfillmentRecord from '../../components/Table/collapse/FulfillmentRecord'

const DetailPanel = ({ rowData }) => {
  const [openProductHistory, setOpenProductHistory] = useState(false)
  const [openReceiving, setOpenReceiving] = useState(false)
  const [openShipping, setOpenShipping] = useState(false)

  const [openProductHistoryLoading, setOpenProductHistoryLoading] =
    useState(false)

  const SubItemMenu = () => (
    <div>
      <Typography variant='h6' component='span' gutterBottom>
        <IconButton
          aria-label='expand row'
          size='small'
          onClick={() => {
            setOpenReceiving(false)
            setOpenShipping(false)
            setOpenProductHistory(!openProductHistory)
          }}>
          {openProductHistoryLoading ? (
            <CircularProgress size={12} />
          ) : openProductHistory ? (
            <RemoveIcon />
          ) : (
            <AddIcon />
          )}
        </IconButton>
        更改记录
      </Typography>

      <Typography
        variant='h6'
        gutterBottom
        component='span'>
        <IconButton
          aria-label='expand row'
          size='small'
          onClick={() => {
            setOpenProductHistory(false)
            setOpenShipping(false)
            setOpenReceiving(!openReceiving)
          }}>
          {openReceiving ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
        进货记录
      </Typography>

      <Typography
        variant='h6'
        gutterBottom
        component='span'>
        <IconButton
          aria-label='expand row'
          size='small'
          onClick={() => {
            setOpenProductHistory(false)
            setOpenReceiving(false)
            setOpenShipping(!openShipping)
          }}>
          {openShipping ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
        出货记录
      </Typography>
    </div>
  )

  return (
    <Box m={3}>
      <SubItemMenu />
      {openProductHistory && (
        <ProductRecord hpn={rowData.hpn}  />
      )}
      {openReceiving && (
        <FulfillmentRecord
          fulfillmentType={'receiving'}
          hpn={rowData.hpn}
        />
      )}
      {openShipping && (
        <FulfillmentRecord
          fulfillmentType={'shipping'}
          hpn={rowData.hpn}
        />
      )}
    </Box>
  )
}

export default DetailPanel
