import handleDSOperation from '../handleDSOperation'

import groupArrayofObjectsByKey from '../helpFunc/groupArrayofObjectsByKey'
import poItemGetValue from '../rowValidator/poItemGetValue'
import cpoInputPriceValidator from '../rowValidator/cpoInputPriceValidator'
import getValidRows from '../rowValidator/getValidRows'

const getProductInfoForNewRowItems = async (originalRows) => {
  if (!originalRows || originalRows.length === 0) return

  let rows = JSON.parse(JSON.stringify(originalRows))

  const validRows = getValidRows(rows)

  const newPoItems = validRows.filter((x) => x.isNew === 'new')

  const groupedPoItemsByCpn = Object.entries(
    groupArrayofObjectsByKey(newPoItems, 'cpn'),
  )

  await Promise.all(
    groupedPoItemsByCpn.map(async ([cpn, itemsGroupedByCpn]) => {
      const items = await handleDSOperation('product', 'query', {
        cpn: { predicate: 'eq', value: cpn },
        version: { predicate: 'eq', value: 0 },
      })

      // console.log('items9999', items)

      // debugger

      itemsGroupedByCpn.forEach((x) => {
        // if (!isValidRow(x)) return
        if (!items || items.length === 0) {
          x.alertMessage &&
            x.alertMessage.push({
              message: `cpn ${x.cpn} can't be found in system`,
              index: 'cpn',
              style: 'alertLevel5',
              level: 5,
            })
        } else {
          poItemGetValue(x, items[0])
          cpoInputPriceValidator(x)
        }
      })
    }),
  )
  return rows
}
export default getProductInfoForNewRowItems
