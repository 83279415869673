import createItemInitialValue from './createItemInitialValue'

const initialValue = () => ({
  // customerOptions: [],
  deliveryTerms: [],
  poOptions: [],
  cartons: {},
  customer: '',
  // mpns: [],
  date: new Date().toISOString().substring(0, 10),
  items: [createItemInitialValue],
})

export default initialValue
