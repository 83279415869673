const mapping = {
  enhanced: [
    'actions',
    'memo',
    'entity',
    'name',
    'isMainContact',
    'dept',
    'title',
    'email',
    'role',
    'phoneNumber',
    'address',
  ],
  simple: [
    'entity',
    'name',
    'isMainContact',
    'dept',
    'title',
    'email',
    'role',
    'phoneNumber',
    'address',
  ],
  collasped: ['entity', 'name', 'role', 'phoneNumber', 'email'],
}

export default mapping
