import React, { useEffect } from 'react'
import {
  FormField,
  SwitchField,
  AutoCompleteField,
  RadioField,
} from '../../components/Form/FormField'
import usePrevious from '../../hooks/usePrevious'
import {
  Typography,
  Backdrop,
  CircularProgress,
  Checkbox,
  ListItemText,
} from '@mui/material'
import { Form } from 'formik'
import handleDSOperation from '../../functions/handleDSOperation'
import getPaymentDue from '../../functions/helpFunc/getPaymentDue'
import groupArrayofObjectsByKey from '../../functions/helpFunc/groupArrayofObjectsByKey'
import config from '../../parameters'

const CreateMainComponent = (props) => {
  const {
    values,
    submitForm,
    errors,
    touched,
    initialValues,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    setFieldValue,
    setValues,
    isSubmitting,
    formStyle,
  } = props

  const prevVendor = usePrevious(values.vendor)

  useEffect(() => {
    ;(async () => {
      if (!values.invoiceType) return
      console.log('444')

      const spos = await handleDSOperation('spo', 'query', {
        version: { predicate: 'eq', value: 0 },
        billingStatus: { predicate: 'ne', value: 'billed' },
        ...(values.invoiceType === 'PI'
          ? {
              costCurrency: { predicate: 'ne', value: 'CNY' },
            }
          : {
              costCurrency: { predicate: 'eq', value: 'CNY' },
            }),
      })

      let vendors = [...new Set(spos.map((x) => x.vendor))]

      if (values.invoiceType === 'VAT') {
        const getForwarder = await handleDSOperation('vendor', 'query', {
          entityType: { predicate: 'eq', value: 'FORWARDER' },
        })
        const forwarder = getForwarder && getForwarder[0].entity
        vendors = [forwarder, ...vendors]
      }

      setFieldValue(
        'vendors',
        vendors.map((x) => ({ vendor: x })),
      )
    })()
  }, [values.invoiceType, setFieldValue])

  useEffect(() => {
    const func = async () => {

      if (values.vendor && values.vendor !== prevVendor) {
        let isForwarder = false
        let retrivedPos
        let vendorPaymentDue
        let usePaymentAgent

        const getVendor = await handleDSOperation('vendor', 'query', {
          entity: { predicate: 'eq', value: values.vendor },
        })

        if (getVendor.length > 0) {
          if (getVendor[0].entityType === 'FORWARDER') {
            isForwarder = true
          }
          usePaymentAgent = getVendor[0].usePaymentAgent || false
          const paymentTerm = getVendor[0].paymentTerm
          vendorPaymentDue = getPaymentDue(paymentTerm)
        }

        if (isForwarder) {
          retrivedPos = await handleDSOperation('forwarderPo', 'query', {
            billingStatus: { predicate: 'ne', value: 'billed' },
          })
        } else {
          retrivedPos = await handleDSOperation('spo', 'query', {
            vendor: { predicate: 'eq', value: values.vendor },
            version: { predicate: 'eq', value: 0 },
            billingStatus: { predicate: 'ne', value: 'billed' },
          })
        }
        setValues({
          ...values,
          usePaymentAgent,
          vendorPaymentDue: vendorPaymentDue,
          isForwarder,
          items: [{ itemsSelectablePos: [] }],
          retrivedPos: retrivedPos.map((x) => ({
            id: x.id,
            hpn: x.hpn,
            cpn: x.cpn,
            mpn: x.mpn,
            mfr: x.mfr,
            poNumber: x.poNumber,
            fulfillmentNumber: x.fulfillmentNumber,
            qty: x.qty,
            openQty: x.openQty,
            receivingCode: x.receivingCode,
            vendor: x.vendor,
            unit: x.unit,
            cost: x.cost,
            costCurrency: x.costCurrency,
            amountExclVat: x.amountExclVat,
          })),
        })
      }
    }
    func()
  }, [values, prevVendor, setValues, initialValues, setFieldValue])

  const totalQty = values.items.reduce((a, v) => (v.qty ? a + v.qty : a), 0)
  const totalAmountExclVat = values.items.reduce(
    (a, v) => (v.amountExclVat ? a + v.amountExclVat * 1 : a),
    0,
  )

  let totalAmountInclVat

  if (values.invoiceType !== 'PI') {
    totalAmountInclVat =
      totalAmountExclVat && totalAmountExclVat * (1 + config.vatRate)
  }

  // console.log('isForwarder', values.isForwarder)

  console.log('incoming inv values', values)

  return (
    <>
      <Form style={formStyle} autoComplete='off'>
        <div>
          <RadioField
            fieldName='invoiceType'
            value={values.invoiceType}
            handleChange={handleChange}
            groups={[
              { value: 'VAT', label: '增值税发票' },
              { value: 'RECEIPT', label: '收据' },
              { value: 'PI', label: '形式发票' },
            ]}
          />
          {values.invoiceType === 'PI' && (
            <SwitchField
              fieldName='usePaymentAgent'
              handleChange={handleChange}
              checked={values.usePaymentAgent}
              labelPlacement='start'
            />
          )}
        </div>
        <div>
          <AutoCompleteField
            id={values.invoiceType}
            fieldName='vendor'
            options={values.vendors}
            attr='vendor'
            setFieldValue={setFieldValue}
          />
          <FormField
            fieldName='date'
            value={values.date}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />

          <FormField
            fieldName='invoiceNumber'
            value={values.invoiceNumber}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
        </div>
        <div style={{ height: 30 }}>
          {totalQty > 0 && (
            <span style={{ marginLeft: 50 }}>
              <Typography
                color='secondary'
                component='span'
                variant='h6'
                align='center'>
                数量: {totalQty.toFixed(2)}
              </Typography>
            </span>
          )}
          {totalAmountExclVat > 0 && (
            <span style={{ marginLeft: 50 }}>
              <Typography
                color='secondary'
                component='span'
                variant='h6'
                align='center'>
                未税金额: {totalAmountExclVat.toFixed(2)}
              </Typography>
            </span>
          )}
          {totalAmountInclVat > 0 && (
            <span style={{ marginLeft: 50 }}>
              <Typography
                color='secondary'
                component='span'
                variant='h6'
                align='center'>
                含税金额: {totalAmountInclVat.toFixed(2)}
              </Typography>
            </span>
          )}
        </div>
      </Form>
      <Backdrop
        sx={{
          zIndex: 3000,
          // zIndex: 'zIndex.modal' + 1,
          color: '#fff',
        }}
        open={Boolean(!values.vendors && values.invoiceType)}
        onClick={() => {}}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  )
}

export default CreateMainComponent
