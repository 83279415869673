import React, { useEffect } from 'react'
import usePrevious from '../../hooks/usePrevious'
import { FormField, AutoCompleteField } from '../../components/Form/FormField'
import {
  Typography,
} from '@mui/material'
import { Form } from 'formik'
import handleDSOperation from '../../functions/handleDSOperation'
import config from '../../parameters'

const CreateMainComponent = (props) => {
  const {
    values,
    submitForm,
    errors,
    touched,
    handleChange,
    initialValues,
    handleSubmit,
    handleBlur,
    resetForm,
    setFieldValue,
    setValues,
    isSubmitting,
    formStyle,
  } = props

  const prevVendor = usePrevious(values.vendor)

  const totalQty = values.items.reduce((a, v) => (v.qty ? a + v.qty : a), 0)

  useEffect(() => {
    const func = async () => {
      if (!values.vendors) {
        const spos = await handleDSOperation('spo', 'query', {
          version: { predicate: 'eq', value: 0 },
          openQty: { predicate: 'gt', value: 0 },
        })

        const allVendorsFromSpos = [...new Set(spos.map((x) => x.vendor))]
        const vendorsUseForwarder = await handleDSOperation('vendor', 'query', {
          deliveryTerms: {
            predicate: 'contains',
            value: config.forwarder.deliveryTerm,
          },
        })
        const mergedVendors = allVendorsFromSpos.reduce((a, v) => {
          if (vendorsUseForwarder.map((x) => x.entity).includes(v)) {
            if (a.includes(config.forwarder.name)) {
              return a
            } else {
              return a.concat(config.forwarder.name)
            }
          } else {
            return a.concat(v)
          }
        }, [])


        setFieldValue(
          'vendors',
          mergedVendors.map((x) => ({ vendor: x })),
        )
      }
    }
    func()

  }, [values.vendors, setFieldValue])

  useEffect(() => {
    const func = async () => {
      if (values.vendor && values.vendor !== prevVendor) {
        let pos = []

        if (values.vendor === config.forwarder.name) {
          pos = await handleDSOperation('forwarderPo', 'query', {
            openQty: { predicate: 'gt', value: 0 },
          })
        } else {
          pos = await handleDSOperation('spo', 'query', {
            version: { predicate: 'eq', value: 0 },
            openQty: { predicate: 'gt', value: 0 },
            vendor: { predicate: 'eq', value: values.vendor },
          })
          if (pos.length === 0) {
            alert('no record found')
          }
        }

        const poOptions = pos.map((x) => ({
          id: x.id,
          date: x.date,
          amountExclVat: x.amountExclVat,
          cost: x.cost,
          costCurrency: x.costCurrency,
          cpn: x.cpn,
          exchangeRate: x.exchangeRate,
          duty: x.duty,
          extraCharge: x.extraCharge,
          vat: x.vat,
          hpn: x.hpn,
          handlingFee: x.handlingFee,
          invoiceNumber: x.invoiceNumber,
          memo: x.memo,
          mfr: x.mfr,
          mpn: x.mpn,
          poNumber: x.poNumber,
          qty: x.qty,
          openQty: x.openQty,
          unit: x.unit,
          receivingCode: x.receivingCode,
          vendor: x.vendor,
          vendorPaymentDue: x.vendorPaymentDue,
        }))

        const hpns = [...new Set(poOptions.map((x) => x.hpn))]

        const selection = hpns.map((x) => {
          const item = poOptions.find((y) => y.hpn === x)
          return {
            mpn: item.mpn,
            cpn: item.cpn,
            hpn: item.hpn,
            mfr: item.mfr,
          }
        })

        selection.sort((a, b) => (a.cpn > b.cpn ? 1 : -1))

        setValues({
          ...initialValues,
          vendors: values.vendors,
          vendor: values.vendor,
          poOptions,
          selection,
        })
      }
    }
    func()
  }, [setFieldValue, setValues, initialValues, values, prevVendor])

  return (
    <>
      <Form style={formStyle} autoComplete='off'>
        <AutoCompleteField
          fieldName='vendor'
          attr='vendor'
          options={values.vendors}
          setFieldValue={setFieldValue}
        />
        <FormField
          fieldName='date'
          value={values.date}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />

        {totalQty > 0 && (
          <span style={{ marginLeft: 50 }}>
            <Typography
              color='secondary'
              component='span'
              variant='h6'
              align='center'>
              数量合计: {totalQty.toFixed(2)}
            </Typography>
          </span>
        )}
      </Form>
    </>
  )
}

export default CreateMainComponent
