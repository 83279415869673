import handleDSOperation from '../../../functions/handleDSOperation'

const handleShippingFee = async ({
  date,
  paymentType,
  amount,
  desc,
  carrier,
  invoiceNumber,
}) => {
  if (!amount || !desc) {
    return alert('9800, incomplete parameters')
  }
  const shippingFeeObj = {
    date,
    paymentType,
    amountExclVat: amount * 1,
    costCurrency: 'CNY',
    desc: carrier ? `${carrier} ${desc}` : desc,
    invoiceNumber: invoiceNumber,
    vendor: carrier,
    invoiceType: 'SHIPPING FEE',
  }

  await handleDSOperation('outgoingPayment', 'create', shippingFeeObj)
}

export default handleShippingFee
