const mapping = {
  enhanced: [
    'actions',
    'memo',
    'paymentType',
    'invoiceType',
    'vendor',
    'cpn',
    'desc',
    'qty',
    'date',
    'invoiceNumber',
    'costCurrency',
    'amountExclVat',
    'amountInclVat',
  ],
  simple: [
    'date',
    'invoiceNumber',
    'poNumber',
    'cpn',
    'qty',
    'price',
    'amountExclVat',
    'customerPaymentDue',
  ],
  collasped: [
    'date',
    'vendor',
    'paymentType',
    'cpn',
    'mpn',
    'qty',
    'invoiceNumber',
    'amountExclVat',
    'amountInclVat',
  ],
}

export default mapping
