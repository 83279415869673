import groupArrayofObjectsByKey from '../helpFunc/groupArrayofObjectsByKey'

const onePoOnePnCheck = (originalRows) => {
  const arrOfItemsByPoNumber = Object.entries(
    groupArrayofObjectsByKey(originalRows, 'poNumber'),
  )

  arrOfItemsByPoNumber.forEach(([poNumber, poItemsByPoNumber]) => {
    const cpns = [...new Set(poItemsByPoNumber.map((x) => x.cpn))]

    if (cpns.length > 1) {
      poItemsByPoNumber.forEach((x) => {
        // console.log('xxxxxx', x)
        x.alertMessage.push({
          message: 'Only one cpn allowed per po',
          index: 'cpn',
          style: 'alertLevel5',
          level: 5,
        })
      })
    }
  })



  // row.alertMessage &&
  //   row.alertMessage.push({
  //     message: `Total entered open qty ${totalEnteredOpenQty} does not match system record ${totalOpenQty}`,
  //     index: 'openQty',
  //     style: 'alertLevel5',
  //     level: 4,
  //   })

}

export default onePoOnePnCheck
