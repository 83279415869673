import React, { useState, useEffect } from 'react'
import {
  FormField,
  AutoCompleteField,
  RadioField,
} from '../../../components/Form/FormField'

import { Typography, Checkbox, ListItemText } from '@mui/material'

import handleDSOperation from '../../../functions/handleDSOperation'

import config from '../../../parameters'

import usePrevious from '../../../hooks/usePrevious'

const GoodsPayment = ({
  initialValues,
  setAllowAdd,
  setAllowDelete,
  values,
  handleBlur,
  handleChange,
  setFieldValue,
  setValues,
}) => {
  const [invoices, setInvoices] = useState()
  const [vendorinvoicesOptions, setVendorInvoiceOptions] = useState()

  const prevVendor = usePrevious(values.vendor)
  const prevSelectedInvoices = usePrevious(values.selectedInvoices)

  useEffect(() => {
    const func = async () => {
      if (!invoices) {
        console.log('fetch cny spos')

        const getInvoices = await handleDSOperation(
          'incomingInvoice',
          'query',
          {
            receivingCode: { predicate: 'eq', value: '' },
            paymentStatus: { predicate: 'ne', value: 'paid' },
          },
        )

        //ATTENTION GET PAYMENGAGENT INVOICE
        const getVendorsPaymentAgent = await handleDSOperation(
          'vendor',
          'query',
          {
            usePaymentAgent: { predicate: 'eq', value: true },
          },
        )
        // console.log('getVendorsPaymentAgent', getVendorsPaymentAgent)
        const getPaymentAgentInvoice = await Promise.all(
          getVendorsPaymentAgent.map(
            async (x) =>
              await handleDSOperation('incomingInvoice', 'query', {
                vendor: { predicate: 'eq', value: x.entity },
                paymentStatus: { predicate: 'ne', value: 'paid' },
              }),
          ),
        )
        const paymentAgentInvoices = [].concat.apply([], getPaymentAgentInvoice)
        //********************************************************** */
        //ATTENTION GET FORWARDER PO FC INVOICE
        const forwarderPoFCInvoice = await handleDSOperation(
          'incomingInvoice',
          'query',
          {
            receivingCode: { predicate: 'beginsWith', value: 'FC' },
            paymentStatus: { predicate: 'ne', value: 'paid' },
          },
        )
        //********************************************************** */

        setInvoices([
          ...getInvoices,
          ...paymentAgentInvoices,
          ...forwarderPoFCInvoice,
        ])
      }

      if (invoices && values.vendor && values.vendor !== prevVendor) {
        const vendorInvoices = invoices.filter(
          (x) => x.vendor === values.vendor,
        )
        // console.log('vendorInvoices777', vendorInvoices)

        const invoiceNumbers = [
          ...new Set(vendorInvoices.map((x) => x.invoiceNumber)),
        ]
        const getInvoicesOptions = invoiceNumbers.map((x) => {
          const sameNumberInvoices = vendorInvoices.filter(
            (y) => y.invoiceNumber === x,
          )
          const qty = sameNumberInvoices.reduce((a, v) => a + v.qty, 0)
          const amountExclVat = sameNumberInvoices.reduce(
            (a, v) =>
              v.amountExclVat ? a + v.amountExclVat : a + v.qty * v.cost,
            0,
          )
          // let amountInclVat
          const invoiceType = sameNumberInvoices[0].invoiceType
          const invoiceDate = sameNumberInvoices[0].date
          const receivingCode = sameNumberInvoices[0].receivingCode

          const costCurrency = sameNumberInvoices[0].costCurrency
          // const isVat = invoiceType === 'VAT'
          // if (isVat) {
          //   amountInclVat = amountExclVat * (1 + config.vatRate)
          // }
          return {
            invoiceDate,
            invoiceNumber: x,
            invoiceType,
            qty,
            amountExclVat: amountExclVat.toFixed(2) * 1,
            // amountInclVat: amountInclVat && amountInclVat.toFixed(2) * 1,
            costCurrency,
            receivingCode,
          }
        })

        setVendorInvoiceOptions(getInvoicesOptions)
        setValues({ ...values, items: null })
      }

      if (
        values.selectedInvoices &&
        values.selectedInvoices !== prevSelectedInvoices
      ) {
        setValues({ ...values, items: values.selectedInvoices })
      }
    }

    func()
  }, [
    invoices,
    setInvoices,
    prevVendor,
    prevSelectedInvoices,
    values,
    setValues,
  ])

  return (
    <>
      {invoices && (
        <AutoCompleteField
          fieldName='vendor'
          options={[...new Set(invoices.map((x) => x.vendor))].map((x) => ({
            vendor: x,
          }))}
          attr='vendor'
          setFieldValue={setFieldValue}
        />
      )}
      {vendorinvoicesOptions && (
        <div>
          <AutoCompleteField
            id={values.vendor}
            multiple
            disableCloseOnSelect
            fieldName='selectedInvoices'
            options={vendorinvoicesOptions}
            getOptionLabel={(x) => {
              const { label } = config.currencies.find(
                (y) => y.value === x.costCurrency,
              )
              const amount =
                x.invoiceType === 'VAT'
                  ? (x.amountExclVat * (1 + config.vatRate)).toFixed(2) * 1
                  : x.amountExclVat

              return (
                x &&
                `${x.invoiceNumber} - ${label}${amount}  ${x.receivingCode}`
              )
            }}
            // attr='invoiceNumber'
            renderOption={(props, option, { selected }) => {
              // return <p>label</p>
              const { label } = config.currencies.find(
                (x) => x.value === option.costCurrency,
              )

              const amountType =
                option.invoiceType === 'VAT' ? 'amountInclVat' : 'amountExclVat'

              const amount =
                option.invoiceType === 'VAT'
                  ? (option.amountExclVat * (1 + config.vatRate)).toFixed(2) * 1
                  : option.amountExclVat

              return (
                <li {...props}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  <ListItemText
                    primary={`INV#:${option.invoiceNumber}`}
                    secondary={`Date:${option.invoiceDate} ${amountType}: ${label}${amount}  ${option.receivingCode}`}
                  />
                </li>
              )
            }}
            setFieldValue={setFieldValue}
          />
        </div>
      )}

      <Typography>
        付款合计：{' '}
        {values.items && values.items.reduce((a, v) => a + v.amountPaid * 1, 0)}
      </Typography>
    </>
  )
}

export default GoodsPayment
