import handleDSOperation from '../../functions/handleDSOperation'

const handleSubmit = async ({ deductStatus, items }) => {
  await Promise.all(
    items.map(async ({ selectedInvoices, vendor }) => {
      await Promise.all(
        selectedInvoices.map(async ({ invoiceNumber }) => {
          console.log('invoiceNumber', invoiceNumber)

          const getInvoices = await handleDSOperation(
            'incomingInvoice',
            'query',
            {
              vendor: { predicate: 'eq', value: vendor },
              invoiceType: { predicate: 'eq', value: 'VAT' },
              invoiceNumber: { predicate: 'eq', value: invoiceNumber },
            },
          )

          await Promise.all(
            getInvoices.map(async (x) => {
              await handleDSOperation(null, 'update', {
                id: x.id,
                deductStatus,
              })
            }),
          )
        }),
      )
    }),
  )
}

export default handleSubmit
