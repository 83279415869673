import { firstBy } from 'thenby'
import handleDSOperation from '../handleDSOperation'
import mergeArrayAndCombineQty from '../helpFunc/mergeArrayAndCombineQty'
import getDeductedItems from '../helpFunc/getDeductedItems'

const dsUpdateCPOQtyAfterCreateShipments = async (items) => {
  const mergedArr = mergeArrayAndCombineQty(items, ['hpn', 'poNumber'], ['qty'])
  // console.log('mergedArr', mergedArr)

  const arrOfarrUpdatedPo = await Promise.all(
    mergedArr.map(async (shippingItem) => {
      const poItems = await handleDSOperation('cpo', 'query', {
        version: { predicate: 'eq', value: 0 },
        hpn: { predicate: 'eq', value: shippingItem.hpn },
        poNumber: { predicate: 'eq', value: shippingItem.poNumber },
        openQty: { predicate: 'gt', value: 0 },
      })

      poItems.sort(firstBy('reqSched').thenBy('openQty'))

      // console.log('shippingItem', shippingItem)

      // console.log('poItems', poItems)

      return getDeductedItems(shippingItem, poItems, 'qty', 'openQty')
    }),
  )
  const arrOfUpdatedPo = [].concat.apply([], arrOfarrUpdatedPo)

  // console.log('arrOfUpdatedPo', arrOfUpdatedPo)

  await Promise.all(
    arrOfUpdatedPo.map(async (x) => {
      await handleDSOperation(null, 'update', {
        id: x.id,
        openQty: x.openQty - x.changedQty,
      })
    }),
  )
}

export default dsUpdateCPOQtyAfterCreateShipments
