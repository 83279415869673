import React from 'react'
import { Hub } from 'aws-amplify'
import { Button, Stack } from '@mui/material'

const Toolbar = ({ selectedRows, dataType }) => {
  return selectedRows && selectedRows.length > 0 ? (
    <Stack direction='row' spacing={3}>
      <Button
        variant='contained'
        color='primary'
        size='small'
        onClick={async () => {}}>
        button
      </Button>
    </Stack>
  ) : (
    <Stack direction='row' spacing={3}>
      <Button
        variant='contained'
        color='secondary'
        size='small'
        onClick={() =>
          Hub.dispatch('FormMonitor', {
            event: 'formEdit',
            data: {
              dataType,
              formType: 'create',
              allowAddItem: false,
              showDeleteItem: false,
            },
            message: '',
          })
        }>
        输入新产品
      </Button>
    </Stack>
  )
}
export default Toolbar
