import handleDSOperation from '../handleDSOperation'

const dsDeleteFulfillments = async (items) => {
  await Promise.all(
    items.map(async (fulfillmentItem) => {
      await handleDSOperation(null, 'delete', fulfillmentItem.id)
    }),
  )
}

export default dsDeleteFulfillments
