import React, { useEffect } from 'react'
import { Checkbox, ListItemText, Typography, Stack } from '@mui/material'
import usePrevious from '../../hooks/usePrevious'
import { Form } from 'formik'
import { FormField, AutoCompleteField } from '../../components/Form/FormField'
import handleDSOperation from '../../functions/handleDSOperation'
import config from '../../parameters'

const ItemComponent = (props) => {
  const {
    values,
    index,
    submitForm,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    setFieldValue,
    setValues,
    isSubmitting,
    formStyle,
    item: currentItem,
  } = props

  const prevVendor = usePrevious(currentItem.vendor)

  useEffect(() => {
    if (values.allInvoices && currentItem.vendor !== prevVendor) {
      setFieldValue(`items[${index}]`, {
        ...currentItem,
        vendorInvoices: values.allInvoices.filter(
          (x) => x.vendor === currentItem.vendor,
        ),
        selectedInvoices: [],
      })
    }
    // else if (
    //   values.allInvoices &&
    //   !currentItem.vendor &&
    //   currentItem.vendor !== prevVendor
    // ) {
    //   setFieldValue(`items[${index}]`, {
    //     ...currentItem,
    //     vendorInvoices: undefined,
    //     selectedInvoices: [],
    //   })
    // }
  }, [currentItem, setFieldValue, index, values, prevVendor])

  // console.log('values', values)

  const subTotalExclVat =
    currentItem.selectedInvoices
      .reduce((a, v) => a + v.amountExclVat, 0)
      .toFixed(2) * 1

  return (
    <Form style={formStyle} autoComplete='off'>
      <AutoCompleteField
        id={values.items.length}
        // id={(currentItem.selectedInvoices[0] || {}).invoiceNumber || 'default'}
        index={index}
        fieldName='vendor'
        value={currentItem.vendor ? { vendor: currentItem.vendor } : null}
        currentItem={currentItem}
        attr='vendor'
        options={[
          ...new Set((values.allInvoices || []).map((x) => x.vendor)),
        ].map((y) => ({ vendor: y }))}
        setFieldValue={setFieldValue}
      />
      {currentItem.selectedInvoices && subTotalExclVat !== 0 && (
        // <Stack component='span' direction='row' spacing={3}>
        <>
          <Typography component='span' variant='h6' color='primary'>
            未税金额小计: {subTotalExclVat}元
          </Typography>{' '}
          <Typography component='span' variant='h6' color='primary'>
            含税金额小计:{' '}
            {(subTotalExclVat * (1 + config.vatRate)).toFixed(2) * 1}元
          </Typography>
        </>
      )}
      <AutoCompleteField
        index={index}
        id={currentItem.vendor}
        fieldName='selectedInvoices'
        currentItem={currentItem}
        value={currentItem.selectedInvoices || null}
        multiple
        disableCloseOnSelect
        options={[
          ...new Set(
            (currentItem.vendorInvoices || []).map((x) => x.invoiceNumber),
          ),
        ]
          .sort()
          .map((y) => {
            const sameNumberInvoices = currentItem.vendorInvoices.filter(
              (z) => z.invoiceNumber === y,
            )

            return {
              invoiceNumber: y,
              vendor: sameNumberInvoices[0].vendor,
              date: sameNumberInvoices[0].date,
              costCurrency: sameNumberInvoices[0].costCurrency,
              amountExclVat: sameNumberInvoices.reduce(
                (a, v) => a + v.amountExclVat * 1,
                0,
              ),
            }
          })}
        setFieldValue={setFieldValue}
        getOptionLabel={(x) => {
          const { label } = config.currencies.find(
            (y) => y.value === x.costCurrency,
          )
          return (
            x &&
            `${x.invoiceNumber} - ${label}${(
              x.amountExclVat *
              (1 + config.vatRate)
            ).toFixed(2)}`
          )
        }}
        renderOption={(props, option, { selected }) => {
          const { label } = config.currencies.find(
            (x) => x.value === option.costCurrency,
          )

          return (
            <li {...props}>
              <Checkbox style={{ marginRight: 8 }} checked={selected} />
              <ListItemText
                primary={`INV#:${option.invoiceNumber}`}
                secondary={`开票日期:${option.date} 未税金额: ${label}${
                  option.amountExclVat
                } 含税金额: ${label}${
                  (option.amountExclVat * (1 + config.vatRate)).toFixed(2) * 1
                }`}
              />
            </li>
          )
        }}
      />
    </Form>
  )
}

export default ItemComponent
