const compareOpenQtyofArrays = (row, inputedArray, fetchedPoItems) => {
  console.log('row', row)
  console.log('inputedArray', inputedArray)
  console.log('fetchedPoItems', fetchedPoItems)

  const totalEnteredOpenQty = inputedArray.reduce(
    (a, v) =>
      v.poNumber === row.poNumber &&
      ((v.cpn && v.cpn === row.cpn) ||
        (v.mpn && v.mpn.toLowerCase().includes(row.mpn.toLowerCase())))
        ? a + v.openQty
        : a,
    0,
  )
  const totalOpenQty = fetchedPoItems.reduce(
    (a, v) =>
      (v.cpn && v.cpn === row.cpn) ||
      (v.mpn && v.mpn.toLowerCase().includes(row.mpn.toLowerCase()))
        ? a + v.openQty
        : a,
    0,
  )
  if (totalEnteredOpenQty > totalOpenQty) {
    row.alertMessage &&
      row.alertMessage.push({
        message: `Total entered open qty ${totalEnteredOpenQty} is greater than open quantity in system ${totalOpenQty}`,
        index: 'openQty',
        style: 'alertLevel5',
        level: 5,
      })
  } else if (totalEnteredOpenQty < totalOpenQty) {
    row.alertMessage &&
      row.alertMessage.push({
        message: `Total entered open qty ${totalEnteredOpenQty} does not match system record ${totalOpenQty}`,
        index: 'openQty',
        style: 'alertLevel5',
        level: 4,
      })
  }
}

export default compareOpenQtyofArrays
