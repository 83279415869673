import dsCreateSpoScheds from '../../functions/APIOperations/dsCreateSpoScheds'

const handleSubmit = async (values) => {
  const items = values.items.map((x) => ({
    poNumber: values.poNumber,
    cpn: values.cpn,
    mpn: values.mpn,
    hpn: values.hpn,
    date: values.date,
    vendor: values.vendor,
    unit: values.unit,
    qty: x.qty,
    openQty: x.qty,
    estSched: x.estSched,
    memo: x.memo,
  }))

  // console.log('items', items)
  // debugger
  await dsCreateSpoScheds(items)
}

export default handleSubmit
