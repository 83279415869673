import React, { useEffect } from 'react'
import { Form } from 'formik'
import { FormField } from '../../components/Form/FormField'

const EditComponent = (props) => {
  const {
    values,
    initialValues,
    submitForm,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    setFieldValue,
    setValues,
    isSubmitting,
    formStyle,
    userGroups: groups,
  } = props

  return (
    <>
      <Form style={formStyle} autoComplete='off'>
        <div>
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='poNumber'
            value={values.poNumber}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='cpn'
            value={values.cpn}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='mfr'
            value={values.mfr}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='mpn'
            value={values.mpn}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='date'
            value={values.date}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </div>
        <div>
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='qty'
            value={values.qty}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='openQty'
            value={values.openQty}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <FormField
            fieldName='reqSched'
            value={values.reqSched}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <FormField
            fieldName='cost'
            value={values.cost}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <FormField
            fieldName='costCurrency'
            select
            value={values.costCurrency}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </div>

        <div>
          <FormField
            disabled={!groups.includes('admin')}
            select
            fieldName='billingStatus'
            value={values.billingStatus}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
        </div>
        <FormField
          fieldName='memo'
          multiline
          value={values.memo}
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
        />
      </Form>
      {/* <TextField
        disabled={!groups.includes('admin')}
        id='memo'
        value={values.memo || ''}
        label='备注'
        onBlur={handleBlur}
        onChange={handleChange}
        multiline
        variant='outlined'
        inputProps={{ className: classes.textarea }}
      /> */}
    </>
  )
}

export default EditComponent
