import getRowValue from './getRowValue'

const arrToObjForProductInput = (rows) => {
  const dataType = 'product'
  const inputType = 'newProduct'

  rows = getRowValue(dataType, inputType, rows)

  rows.forEach((x) => {
    x.vendors = x.vendor && [x.vendor.toUpperCase()]
    x.mpn = x.mpn.toUpperCase()
    x.mfr = x.mfr.toUpperCase()
    x.coo = x.coo && x.coo.split(',')
  })

  return rows
}

export default arrToObjForProductInput
