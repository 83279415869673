import React, { useEffect } from 'react'
import { FormField, AutoCompleteField } from '../../components/Form/FormField'

import { Form } from 'formik'
import handleDSOperation from '../../functions/handleDSOperation'
import usePrevious from '../../hooks/usePrevious'

const CreateMainComponent = (props) => {
  const {
    values,
    submitForm,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    initialValues,
    setFieldValue,
    setValues,
    isSubmitting,
    formStyle,
    userGroups: groups,
  } = props

  const prevCustomer = usePrevious(values.customer)
  const prevName = usePrevious(values.name)

  useEffect(() => {
    const func = async () => {
      if (!values.number) {
        let number = '999'

        const getQuotes = await handleDSOperation('outgoingQuote', 'query')

        if (getQuotes.length > 0) {
          getQuotes.sort((a, b) => (a.number > b.number ? -1 : 1))

          const lastNumber = getQuotes[0].number.slice(1, 8)

          const lastDate = getQuotes[0].date

          const dateDiff =
            (new Date().getTime() - new Date(lastDate).getTime()) /
            (24 * 60 * 60 * 1000)
          const dailyDiff = Math.random() * 100 + 200
          const invoiceDiff = Math.floor(dateDiff * dailyDiff)

          number = `A${lastNumber * 1 + invoiceDiff * 1}`
        }
        // debugger

        const getCustomers = await handleDSOperation('customer', 'query', {
          isActive: { predicate: 'eq', value: true },
        })

        const customerOptions = getCustomers.map((x) => ({
          entity: x.entity,
          hankenoEntity: x.hankenoEntity,
          vendorOrCtmCode: x.vendorOrCtmCode,
        }))

        const getProducts = await handleDSOperation('product', 'query', {
          version: { predicate: 'eq', value: 0 },
        })

        const productOptions = getProducts.map((x) => ({
          mpn: x.mpn,
          mfr: x.mfr,
          cpn: x.cpn,
          hpn: x.hpn,
          price: x.price && x.price * 1,
          unit: x.unit,
          priceCurrency: x.priceCurrency,
          customerMOQ: x.customerMOQ && x.customerMOQ * 1,
          customerMPQ: x.customerMPQ && x.customerMPQ * 1,
          customerLeadtime: x.customerLeadtime && x.customerLeadtime * 1,
        }))

        productOptions.sort((a, b) => (a.mpn > b.mpn ? 1 : -1))

        setValues({ ...initialValues, number, customerOptions, productOptions })
      }

      if (values.customer && values.customer !== prevCustomer) {
        let name
        const selectedCustomer = values.customerOptions.find(
          (x) => x.entity === values.customer,
        )

        const getCustomer = await handleDSOperation('customer', 'query', {
          entity: { predicate: 'eq', value: values.customer },
        })

        const getContacts = await handleDSOperation('contact', 'query', {
          entity: { predicate: 'eq', value: values.customer },
          role: { predicate: 'contains', value: 'purchasing' },
          name: { predicate: 'ne', value: null },
        })

        if (getCustomer.length === 0 || !getCustomer[0].deliveryTerms) {
          return alert('shipping term not defined')
        }

        const deliveryTerms = getCustomer[0].deliveryTerms
        const paymentTerm = getCustomer[0].paymentTerm
        const hankenoEntity = getCustomer[0].hankenoEntity

        // console.log('getContacts',getContacts)

        const contacts = getContacts.map((x) => ({
          name: x.name,
          email: x.email,
          phoneNumber: x.phoneNumber,
          dept: x.dept,
        }))

        if (contacts.length === 1) {
          name = contacts[0].name
        }
        setValues({
          ...values,
          contacts,
          selectedCustomer,
          name,
          deliveryTerms,
          deliveryTerm: deliveryTerms[0],
          paymentTerm,
          hankenoEntity,
        })
      }

      if (values.name && values.name !== prevName) {
        const contact = values.contacts.find((x) => x.name === values.name)
        setFieldValue('contact', contact)
      }
    }

    func()
  }, [prevCustomer, initialValues, prevName, setValues, setFieldValue, values])

  console.log('values', values)

  return (
    <>
      <Form style={formStyle} autoComplete='off'>
        <div>
          <AutoCompleteField
            fieldName='customer'
            attr='entity'
            options={values.customerOptions || []}
            setFieldValue={setFieldValue}
          />
        </div>
        <FormField
          fieldName='date'
          value={values.date}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          fieldName='number'
          value={values.number}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          select
          fieldName='name'
          value={values.name}
          handleBlur={handleBlur}
          handleChange={handleChange}
          options={values.contacts.map((x) => x.name)}
        />

        <FormField
          select
          fieldName='deliveryTerm'
          value={values.deliveryTerm}
          handleBlur={handleBlur}
          handleChange={handleChange}
          options={values.deliveryTerms}
        />

        <div>
          <FormField
            multiline
            fieldName='memo'
            value={values.memo}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
          />
        </div>
      </Form>
    </>
  )
}

export default CreateMainComponent
