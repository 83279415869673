import React, { useState } from 'react'
import {
  IconButton,
  Typography,
  Box,
  CircularProgress,
  Stack,
} from '@mui/material'
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material'
import PoRecord from '../../components/Table/collapse/PoRecord'
import FulfillmentRecord from '../../components/Table/collapse/FulfillmentRecord'
import ScheduleRecord from '../../components/Table/collapse/ScheduleRecord'

const DetailPanel = ({ rowData }) => {
  const [openPoHistory, setOpenPoHistory] = useState(false)
  const [openReceiving, setOpenReceiving] = useState(false)
  const [openScheduleHistory, setOpenScheduleHistory] = useState(false)
  const [openPoHistoryLoading, setOpenPoHistoryLoading] = useState(false)

  const SubItemMenu = () => (
    <Stack direction='row'>
      <IconButton
        aria-label='expand row'
        color='primary'
        size='small'
        onClick={() => {
          setOpenReceiving(false)
          setOpenScheduleHistory(false)
          setOpenPoHistory(!openPoHistory)
        }}>
        {openPoHistoryLoading ? (
          <CircularProgress size={12} />
        ) : openPoHistory ? (
          <RemoveIcon />
        ) : (
          <AddIcon />
        )}
      </IconButton>
      <Typography variant='h6' gutterBottom component='span'>
        订单更改记录
      </Typography>
      <IconButton
        aria-label='expand row'
        color='secondary'
        size='small'
        onClick={() => {
          setOpenReceiving(false)
          setOpenPoHistory(false)
          setOpenScheduleHistory(!openScheduleHistory)
        }}>
        {openScheduleHistory ? <RemoveIcon /> : <AddIcon />}
      </IconButton>
      <Typography variant='h6' gutterBottom component='span'>
        交期记录
      </Typography>
      <IconButton
        aria-label='expand row'
        size='small'
        onClick={async () => {
          setOpenScheduleHistory(false)
          setOpenPoHistory(false)
          setOpenReceiving(!openReceiving)
        }}>
        {openReceiving ? <RemoveIcon /> : <AddIcon />}
      </IconButton>
      <Typography variant='h6' gutterBottom component='span'>
        收货记录
      </Typography>
    </Stack>
  )

  return (
    <Box m={3}>
      <SubItemMenu />
      {openPoHistory && (
        <PoRecord poType='spo' poNumber={rowData.poNumber} hpn={rowData.hpn} />
      )}
      {openScheduleHistory && (
        <ScheduleRecord poNumber={rowData.poNumber} hpn={rowData.hpn} />
      )}
      {openReceiving && (
        <FulfillmentRecord
          fulfillmentType='receiving'
          hpn={rowData.hpn}
          poNumber={rowData.poNumber}
        />
      )}
    </Box>
  )
}

export default DetailPanel
