import React, { useEffect } from 'react'

import {
  FormField,
  AutoCompleteField,
  SwitchField,
} from '../../components/Form/FormField'

import { TextField } from '@mui/material'
import { Form } from 'formik'
import config from '../../parameters'
import handleDSOperation from '../../functions/handleDSOperation'

const EditComponent = (props) => {
  const {
    initialValues,
    values,
    submitForm,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    setFieldValue,
    setValues,
    isSubmitting,
    formStyle,
    userGroups: groups,
  } = props

  const isAdmin = groups.map((x) => x.toLowerCase()).includes('admin')

  useEffect(() => {
    ;(async () => {
      if (values.vendorOptions.length === 0) {
        const getVendors = await handleDSOperation('vendor', 'query')
        setFieldValue(
          'vendorOptions',
          getVendors.map((x) => ({ vendor: x.entity })),
        )
      }
    })()
  }, [setFieldValue, values])

  useEffect(() => {
    if (!values.initialValues) {
      const { vendorOptions, ...initialvaluesObj } = initialValues
      setFieldValue('initialValues', initialvaluesObj)
    }
  }, [setFieldValue, values, initialValues])


  const IsJsonString = (str) => {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  // console.log('values', values)

  return (
    <>
      <Form style={formStyle} autoComplete='off'>
        <div>
          <SwitchField
            fieldName='isActive'
            checked={values.isActive}
            handleChange={handleChange}
          />
        </div>
        <div>
          <FormField
            fieldName='cpn'
            value={values.cpn}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <FormField
            fieldName='mfr'
            value={values.mfr}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />

          <FormField
            fieldName='mpn'
            value={values.mpn}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <FormField
            fieldName='hpn'
            value={values.hpn}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <FormField
            select
            fieldName='productType'
            value={values.productType}
            handleBlur={handleBlur}
            handleChange={handleChange}
            options={config.productTypeAndImportDuty.map((x) => x.type)}
          />
          <FormField
            select
            fieldName='unit'
            value={values.unit}
            handleBlur={handleBlur}
            handleChange={handleChange}
            options={config.units}
          />
        </div>
        <div>
          <AutoCompleteField
            value={values.vendors.map((x) => ({ vendor: x }))}
            multiple
            disableCloseOnSelect
            attr='vendor'
            fieldName='vendors'
            options={values.vendorOptions}
            setFieldValue={setFieldValue}
            width={1200}
          />

          <div>
            <AutoCompleteField
              value={values.coo.map((x) => ({ coo: x }))}
              multiple
              disableCloseOnSelect
              fieldName='coo'
              attr='coo'
              options={config.coo.map((x) => ({ coo: x }))}
              setFieldValue={setFieldValue}
            />
          </div>
        </div>

        <div>
          <FormField
            fieldName='cost'
            value={values.cost}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <FormField
            select
            fieldName='costCurrency'
            value={values.costCurrency}
            handleBlur={handleBlur}
            handleChange={handleChange}
            options={config.currencies.map((x) => x.value)}
          />
          <FormField
            fieldName='supplierMOQ'
            value={values.supplierMOQ}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <FormField
            fieldName='supplierMPQ'
            value={values.supplierMPQ}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <FormField
            fieldName='leadtime'
            value={values.leadtime}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <FormField
            fieldName='transitDays'
            value={values.transitDays}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
        </div>
        <div>
          {groups.includes('admin') && (
            <>
              <FormField
                fieldName='price'
                value={values.price}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
              <FormField
                select
                fieldName='priceCurrency'
                value={values.priceCurrency}
                handleBlur={handleBlur}
                handleChange={handleChange}
                options={config.currencies.map((x) => x.value)}
              />
            </>
          )}
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='customerMOQ'
            value={values.customerMOQ}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='customerMPQ'
            value={values.customerMPQ}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='customerLeadtime'
            value={values.customerLeadtime}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
        </div>
        <div>
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='initialInventory'
            value={values.initialInventory}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='safetyInventory'
            value={values.safetyInventory}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <FormField
            disabled={!groups.includes('admin')}
            fieldName='currentInventory'
            value={values.currentInventory}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <div>
            <FormField
              multiline
              fieldName='memo'
              value={values.memo}
              handleBlur={handleBlur}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
          </div>

          {isAdmin && (
            <TextField
              id='spec'
              style={{ width: '100%' }}
              value={(values.spec && JSON.stringify(values.spec)) || ''}
              label='spec'
              onChange={(e) => {
                if (IsJsonString(e.target.value))
                  setFieldValue('spec', JSON.parse(e.target.value))
              }}
            />
          )}
        </div>
      </Form>
    </>
  )
}

export default EditComponent
