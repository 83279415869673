import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import SimpleTable from '../SimpleTable'
import handleDSOperation from '../../../functions/handleDSOperation'
import config from '../../../parameters'

const VATInvoiceRecord = ({ receivingCode, poNumber, hpn }) => {
  const [rows, setRows] = useState([])

  useEffect(() => {
    const attr = receivingCode ? 'receivingCode' : 'poNumber'
    const value = receivingCode ? receivingCode : poNumber
    const func = async () => {
      let items = await handleDSOperation('incomingInvoice', 'query', {
        [attr]: { predicate: 'eq', value },
        invoiceType: { predicate: 'eq', value: 'VAT' },
        hpn: { predicate: 'eq', value: hpn },

        //  version: { predicate: 'gt', value: 0 },
      })
      // console.log('items: ', items)
      setRows(items)
    }
    func()
  }, [poNumber, receivingCode, hpn])

  const qty = rows.reduce((a, v) => a + v.qty * 1, 0)
  const amountExclVat = rows.reduce((a, v) => a + v.amountExclVat * 1, 0)
  const amountInclVat = (amountExclVat * (1 + config.vatRate)).toFixed(2)

  return (
    <Box m={3}>
      <Typography> Total:{qty} </Typography>
      <Typography> 含税金额:{amountInclVat} </Typography>

      <SimpleTable
        dataType='incomingInvoice'
        tbType='collasped'
        rows={rows}
      />
    </Box>
  )
}

export default VATInvoiceRecord
