import React from 'react'
import { Button, Stack } from '@mui/material'
import { Hub } from 'aws-amplify'
import MTSumField from '../../components/Table/toolbar/MTSumField'

const Toolbar = ({
  setSelectionModel,
  selectedRows,
  dataType,
  currentUser,
}) => {
  return selectedRows && selectedRows.length > 0 ? (
    <Stack direction='row' spacing={3}>
      <Button
        // disabled
        variant='contained'
        color='primary'
        size='small'
        onClick={async () => {
          setSelectionModel([])
          // console.log(122)
          // await handleToolbarResetSelectedCpoSched(itemsSelected)
        }}>
        Calculate Sched(Selected)
      </Button>

      <Button
        disabled
        variant='contained'
        color='secondary'
        size='small'
        onClick={async () => {
          // await handleToolbarResetAllCpoSched()
          setSelectionModel([])
        }}>
        Calculate Sched(All)
      </Button>

      <MTSumField qtyAttr='qty' selectedRows={selectedRows} />
      <MTSumField qtyAttr='openQty' selectedRows={selectedRows} />
      <MTSumField qtyAttr='amount' selectedRows={selectedRows} />
    </Stack>
  ) : (
    <Stack direction='row' spacing={3}>
      <Button
        color='primary'
        variant='contained'
        size='small'
        onClick={() => {
          // setFormType('create')
          Hub.dispatch('FormMonitor', {
            event: 'formEdit',
            data: {
              dataType,
              formType: 'create',
              allowAddItem: true,
              showDeleteItem: true,
            },
            message: '',
          })
        }}>
        新建订单
      </Button>

      <Button
        disabled
        variant='contained'
        color='primary'
        size='small'
        onClick={async () => {
          setSelectionModel([])
        }}>
        Calculate Sched for ALL orders
      </Button>
    </Stack>
  )
}

export default Toolbar
