import React, { useState } from 'react'
import { Hub } from 'aws-amplify'
import { Button, Stack } from '@mui/material'
import MTSumField from '../../components/Table/toolbar/MTSumField'
import mergeArrayAndCombineQty from '../../functions/helpFunc/mergeArrayAndCombineQty'
import handleToolbarSendShippingList from '../../functions/tableFunctions/toolbar/handleToolbarSendShippingList'
import handleToolbarSendCOA from '../../functions/tableFunctions/toolbar/handleToolbarSendCOA'

const Toolbar = ({ selectedRows, dataType, currentUser }) => {
  const { groups } = currentUser
  const [sendShippingPreviewLoading, setSendShippingPreviewLoading] =
    useState(false)
  const [sendShippingLoading, setSendShippingLoading] = useState(false)
  const [sendCOAPreviewLoading, setSendCOAPreviewLoading] = useState(false)
  const [sendCOALoading, setSendCOALoading] = useState(false)

  selectedRows = mergeArrayAndCombineQty(
    selectedRows,
    ['poNumber', 'cpn'],
    ['qty'],
  )

  const handleSendShippingList = async (isPreview) => {
    try {
      if (isPreview) {
        setSendShippingPreviewLoading(true)
        await handleToolbarSendShippingList(selectedRows, isPreview)
        setSendShippingPreviewLoading(false)
      } else {
        setSendShippingLoading(true)
        if (window.confirm('Send shipping list to customer?')) {
          await handleToolbarSendShippingList(selectedRows, isPreview)
        }
        setSendShippingLoading(false)
      }
      alert('documents sent successfully')
    } catch (e) {
      alert(e)
      console.log('error8973', e)
    }
  }

  const handleSendCOA = async (isPreview) => {
    try {
      if (isPreview) {
        setSendCOAPreviewLoading(true)
        await handleToolbarSendCOA(selectedRows, isPreview)
        setSendCOAPreviewLoading(false)
      } else {
        setSendCOALoading(true)
        if (window.confirm('Send COA to customer?')) {
          await handleToolbarSendCOA(selectedRows, isPreview)
        }
        setSendCOALoading(false)
      }
      alert('documents sent successfully')
    } catch (e) {
      alert(e)
      console.log('error8974', e)
    }
  }

  const allowDuplicateItemFunc = ({ values, push, setFieldValue }) => {
    console.log('values', values)
    const item = JSON.parse(
      JSON.stringify(values.items[values.items.length - 1]),
    )
    if (item.cartonNumber) {
      const cartons = {
        ...values.cartons,
        [item.cartonNumber + 1]: {
          dimensions: values.cartons[item.cartonNumber].dimensions,
        },
      }

      item.cartonNumber = item.cartonNumber * 1 + 1

      setFieldValue('cartons', cartons)
    }

    push(item)
  }

  return selectedRows && selectedRows.length > 0 ? (
    <Stack direction='row' spacing={3}>
      <MTSumField qtyAttr='qty' selectedRows={selectedRows} />
      <Button
        variant='contained'
        disabled={sendCOAPreviewLoading}
        color='primary'
        size='small'
        onClick={async () => {
          await handleSendCOA(true)
        }}>
        COA Preview
      </Button>

      <Button
        variant='contained'
        disabled={sendShippingPreviewLoading}
        color='primary'
        size='small'
        onClick={async () => {
          await handleSendShippingList(true)
        }}>
        Shipping List Preview
      </Button>

      <Button
        variant='contained'
        disabled={sendCOALoading || !groups.includes('admin')}
        color='secondary'
        size='small'
        onClick={async () => {
          await handleSendCOA(false)
        }}>
        send COA
      </Button>

      <Button
        variant='contained'
        disabled={sendShippingLoading || !groups.includes('admin')}
        color='secondary'
        size='small'
        onClick={async () => {
          await handleSendShippingList(false)
        }}>
        send Shipping List
      </Button>
    </Stack>
  ) : (
    <Stack direction='row' spacing={3}>
      <Button
        variant='contained'
        color='secondary'
        size='small'
        onClick={() => {
          Hub.dispatch('FormMonitor', {
            event: 'formEdit',
            data: {
              dataType,
              formType: 'create',
              allowAddItem: true,
              showDeleteItem: true,
              allowDuplicateItem: true,
              allowDuplicateItemFunc,
            },
            message: '',
          })
        }}>
        出货
      </Button>
    </Stack>
  )
}

export default Toolbar
