import React from 'react'
import { FormField, DatePickerField } from '../../components/Form/FormField'

import { Form } from 'formik'
import config from '../../parameters'

const EditComponent = (props) => {
  const {
    values,
    submitForm,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    setFieldValue,
    setValues,
    isSubmitting,
    formStyle,
    userGroups: groups,
  } = props

  const isAdmin = groups.map((x) => x.toLowerCase()).includes('admin')

  return (
    <Form style={formStyle} autoComplete='off'>
      <div>
        <FormField disabled fieldName='vendor' value={values.vendor} />

        <FormField
          disabled={!groups.includes('admin')}
          fieldName='date'
          value={values.date}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />

        <FormField
          disabled={!groups.includes('admin')}
          fieldName='invoiceNumber'
          value={values.invoiceNumber}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          disabled={!groups.includes('admin')}
          fieldName='receivingCode'
          value={values.receivingCode}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />

        {/*
          {displayedTotalAmount > 0 && (
            <Typography
              // className={classes.headerElement}
              color='secondary'
              variant='h6'
              component='span'>
              含税总金额
              {displayedTotalAmount}
            </Typography>
          )} */}
      </div>
      <div>
        <FormField
          disabled={!groups.includes('admin')}
          fieldName='poNumber'
          value={values.poNumber}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          disabled={!groups.includes('admin')}
          fieldName='mpn'
          value={values.mpn}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          disabled={!groups.includes('admin')}
          fieldName='cpn'
          value={values.cpn}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          disabled={!groups.includes('admin')}
          fieldName='qty'
          value={values.qty}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          disabled
          fieldName='unit'
          value={values.unit}
          // handleBlur={handleBlur}
          // handleChange={handleChange}
        />
        <FormField
          select
          disabled={!groups.includes('admin')}
          fieldName='costCurrency'
          value={values.costCurrency}
          handleBlur={handleBlur}
          handleChange={handleChange}
          options={config.currencies.map((x) => x.value)}
        />
      </div>
      <div>
        <FormField
          disabled
          fieldName='cost'
          value={values.amountExclVat / values.qty}
        />
        <FormField
          disabled={!groups.includes('admin')}
          fieldName='amountExclVat'
          value={values.amountExclVat}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
      </div>
      <div>
        <FormField
          disabled={!groups.includes('admin')}
          fieldName='vendorPaymentDue'
          value={values.vendorPaymentDue}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <FormField
          disabled={!groups.includes('admin')}
          fieldName='vendorPaymentPaid'
          value={values.vendorPaymentPaid}
          handleBlur={handleBlur}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
        />

        <FormField
          select
          disabled={!groups.includes('admin')}
          fieldName='paymentStatus'
          value={values.paymentStatus}
          handleBlur={handleBlur}
          handleChange={handleChange}
          options={config.paymentStatus}
          S
        />

        {values.invoiceType === 'VAT' && (
          <DatePickerField
            fieldName='deductStatus'
            minDate={new Date().toISOString().substring(0, 10)}
            maxDate={new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 60)
              .toISOString()
              .substring(0, 10)}
            value={values.deductStatus}
            setFieldValue={setFieldValue}
          />
        )}
      </div>
      <div>
        <FormField
          multiline
          fieldName='memo'
          value={values.memo}
          handleBlur={handleBlur}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
        />
      </div>
    </Form>
  )
}

export default EditComponent
