import groupArrayofObjectsByKey from '../../helpFunc/groupArrayofObjectsByKey'
import restAPICalls from '../../restAPICalls'
import handleDSOperation from '../../handleDSOperation'
import config from '../../../parameters'

const handleToolbarSendVat = async (itemsSelected, isPreview) => {
  const customer = itemsSelected[0].customer
  const date = itemsSelected[0].date

  const getInvoices = await handleDSOperation('outgoingInvoice', 'query', {
    customer: { predicate: 'eq', value: customer },
    date: { predicate: 'eq', value: date },
  })

  const invoiceItems = getInvoices.map((x) => ({
    cpn: x.cpn,
    mpn: x.mpn,
    mfr: x.mfr,
    hpn: x.hpn,
    invoiceNumber: x.invoiceNumber,
    poNumber: x.poNumber,
    qty: x.qty,
    unit: x.unit,
    price: x.price,
    priceCurrency: x.priceCurrency,
  }))

  const getCustomer = await handleDSOperation('customer', 'query', {
    entity: { predicate: 'eq', value: customer },
  })

  const getBillingContact = await handleDSOperation('contact', 'query', {
    entity: { predicate: 'eq', value: customer },
    role: { predicate: 'contains', value: 'billing' },
  })
  const { hankenoEntity, vendorOrCtmCode } = getCustomer[0]
  const hankenoEty = config.hankenoEntities.find((x) => x.tag === hankenoEntity)

  const billingAddress = getBillingContact && getBillingContact[0].address
  const billingDept = getBillingContact && getBillingContact[0].dept
  const billingName = getBillingContact && getBillingContact[0].name
  const billingPhoneNumber =
    getBillingContact && getBillingContact[0].phoneNumber

  const vatObj = {
    date,
    vendorCode: vendorOrCtmCode,
    hankenoEntityName: hankenoEty.name,
    hankenoEntityContact: '',
    hankenoEntityAdd: hankenoEty.add,
    hankenoEntityPhoneNumber: hankenoEty.phoneNumber,
    customer,
    billingAddress,
    billingDept,
    billingName,
    billingPhoneNumber,
    invoiceItems,
  }

  await restAPICalls('post', null, {
    type: 'invoice',
    items: [vatObj],
    isPreview,
  })
}

export default handleToolbarSendVat
