import React from 'react'

import { Form } from 'formik'

import { FormField, ProductAutoComplete } from '../../components/Form/FormField'

const CreateItemComponent = ({
  values,
  handleBlur,
  index,
  handleChange,
  setFieldValue,
  formStyle,
  item: currentItem,
  userGroups: groups,
}) => {
  return (
    <Form style={formStyle} autoComplete='off'>
      <FormField disabled fieldName='line' index={index} value={values.line} />

      <ProductAutoComplete
        id={values.items.length}
        options={values.productOptions}
        value={
          currentItem.hpn
            ? {
                // coo: currentItem.coo,
                // cost: currentItem.cost,
                // costCurrency: currentItem.costCurrency,
                cpn: currentItem.cpn,
                mfr: currentItem.mfr,
                mpn: currentItem.mpn,
                // hpn: currentItem.hpn,
                // unit: currentItem.unit,
                // productType: currentItem.productType,
                // vendors: currentItem.vendors,
                // supplierMOQ: currentItem.supplierMOQ,
                // supplierMPQ: currentItem.supplierMPQ,
                // leadtime: currentItem.leadtime,
                // vendor: currentItem.vendor,
              }
            : null
        }
        index={index}
        currentItem={currentItem}
        setFieldValue={setFieldValue}
      />

      <FormField
        fieldName='vendor'
        disabled={
          (currentItem.vendors && currentItem.vendors.length === 1) ||
          !currentItem.hpn
        }
        select={currentItem.vendors && currentItem.vendors.length > 1}
        index={index}
        value={currentItem.vendor}
        options={currentItem.vendors}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />
      <FormField
        fieldName='mpn'
        disabled={!groups.includes('admin') || !currentItem.hpn}
        index={index}
        value={currentItem.mpn}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />
      <FormField
        fieldName='cost'
        disabled={!groups.includes('admin') || !currentItem.hpn}
        index={index}
        value={currentItem.cost}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />
      <FormField
        fieldName='costCurrency'
        disabled={!groups.includes('admin') || !currentItem.hpn}
        select
        index={index}
        value={currentItem.costCurrency}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />
      <FormField
        disabled
        fieldName='supplierMOQ'
        index={index}
        value={currentItem.supplierMOQ}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />

      <FormField
        disabled
        fieldName='supplierMPQ'
        index={index}
        value={currentItem.supplierMPQ}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />

      <FormField
        disabled={!currentItem.hpn}
        fieldName='qty'
        index={index}
        value={currentItem.qty}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />

      <FormField
        disabled={!currentItem.hpn}
        fieldName='reqSched'
        index={index}
        value={currentItem.reqSched}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />

      <FormField
        disabled
        fieldName='salesAmount'
        index={index}
        value={
          (currentItem.qty &&
            currentItem.cost &&
            (currentItem.qty * currentItem.cost).toFixed(2)) ||
          ''
        }
        handleBlur={handleBlur}
        handleChange={handleChange}
      />
    </Form>
  )
}

export default CreateItemComponent
