import React from 'react'
import { Form } from 'formik'
import { FormField } from '../../components/Form/FormField'

const EditComponent = (props) => {
  const {
    values,
    submitForm,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    setFieldValue,
    setValues,
    isSubmitting,
    formStyle,
    userGroups: groups,
  } = props

  const isAdmin = groups.map((x) => x.toLowerCase()).includes('admin')

  return (
    <Form style={formStyle} autoComplete='off'>
      <div>
        <FormField disabled fieldName='custoemr' value={values.customer} />
        <FormField
          disabled={!groups.includes('admin')}
          fieldName='date'
          value={values.date}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />

        <FormField
          disabled={!groups.includes('admin')}
          fieldName='invoiceNumber'
          value={values.invoiceNumber}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
      </div>
      <div>
        <FormField
          disabled
          fieldName='invoiceType'
          value={values.invoiceType}
        />
        <FormField
          disabled
          fieldName='priceCurrency'
          value={values.priceCurrency}
        />
        <FormField
          disabled={!groups.includes('admin')}
          fieldName='amountExclVat'
          value={values.amountExclVat}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
      </div>

      <div>
        <FormField
          multiline
          setFieldValue={setFieldValue}
          fieldName='memo'
          value={values.memo}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
      </div>
    </Form>
  )
}

export default EditComponent
