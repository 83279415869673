import { firstBy } from 'thenby'
import handleDSOperation from '../handleDSOperation'
import mergeArrayAndCombineQty from '../helpFunc/mergeArrayAndCombineQty'
import getDeductedItems from '../helpFunc/getDeductedItems'

const dsUpdateCPOQtyAfterDeleteShipments = async (items) => {
  const mergedArr = mergeArrayAndCombineQty(items, ['hpn', 'poNumber'], ['qty'])
  console.log('mergedArr', mergedArr)

  const arrOfarrUpdatedPo = await Promise.all(
    mergedArr.map(async (shippingItem) => {
      let poItems = await handleDSOperation('cpo', 'query', {
        version: { predicate: 'eq', value: 0 },
        hpn: { predicate: 'eq', value: shippingItem.hpn },
        poNumber: { predicate: 'eq', value: shippingItem.poNumber },
      })

      poItems = poItems.filter((x) => x.qty !== x.openQty)

      poItems.sort(firstBy('reqSched').thenBy('openQty'))

      return getDeductedItems(shippingItem, poItems, 'qty', 'openQty')
    }),
  )
  const arrOfUpdatedPo = [].concat.apply([], arrOfarrUpdatedPo)

  console.log('arrOfUpdatedPo', arrOfUpdatedPo)

  await Promise.all(
    arrOfUpdatedPo.map(async (x) => {
      await handleDSOperation(null, 'update', {
        id: x.id,
        openQty: x.openQty + x.changedQty,
      })
    }),
  )
}

export default dsUpdateCPOQtyAfterDeleteShipments
