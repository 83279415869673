import config from '../../parameters'
import handleDSOperation from '../handleDSOperation'
import { firstBy } from 'thenby'

const getOrderBy = (hankenoEntity) =>
  config.hankenoEntities.find((x) => x.tag === hankenoEntity)

const getShippingAddress = (deliveryTerm) => {
  // console.log('deliveryTerm', deliveryTerm)
  switch (deliveryTerm) {
    case 'DAP SHANGHAI':
      return config.shippingAddresses.Shanghai
    case 'DAP GERMANY':
      return config.shippingAddresses.Germany

    default:
      return config.shippingAddresses.Hongkong
  }
}
const dsGenerateSPOItemsPerVendor = async (
  vendorName,
  date,
  newOrders,
  existingOrders,
) => {
  const getVendor = await handleDSOperation('vendor', 'query', {
    entity: { predicate: 'eq', value: vendorName },
  })
  const vendor = getVendor[0]

  const getContacts = await handleDSOperation('contact', 'query', {
    entity: { predicate: 'eq', value: vendorName },
  })

  const contacts = getContacts.filter((x) =>
    x.role.some((r) => config.vendorRolesToAcceptPO.includes(r)),
  )
  const emails = contacts.map((x) => x.email).join(', ')
  const mainContact = getContacts.find((x) => x.isMainContact)
  const orderBy = getOrderBy(vendor.hankenoEntity)
  const deliveryTerm = vendor.deliveryTerms && vendor.deliveryTerms[0]
  const shippingAddress = getShippingAddress(deliveryTerm)

  // console.log('getOpenOrders', getOpenOrders)
  const getAttrObj = (x, isNew) => ({
    cost: x.cost,
    costCurrency: x.costCurrency,
    cpn: x.cpn,
    hpn: x.hpn,
    mfr: x.mfr,
    orderDate: x.date || date,
    line: (x.line && x.line.split('/')[0] * 1) || 1,
    mpn: x.mpn,
    qty: x.qty * 1,
    openQty: x.openQty * 1,
    reqSched: x.reqSched,
    poNumber: x.poNumber,
    unit: x.unit,
    isNew,
  })

  existingOrders = existingOrders.map((z) => getAttrObj(z, false))

  // console.log('existingOrders', existingOrders)

  newOrders = newOrders.map((x) => getAttrObj(x, true))

  // console.log('newOrders', newOrders)

  const poItems = [...existingOrders, ...newOrders].sort(
    firstBy('orderDate', -1)
      // firstBy('poNumber', 1)
      .thenBy('poNumber', -1)
      .thenBy('cpn')
      .thenBy('reqSched')
      .thenBy('estSched')
      .thenBy('qty'),
  )
  const obj = {
    orderDate: date,
    entity: vendorName,
    vendorNumber: vendor.number,
    mainContactName: mainContact.name,
    mainContactPhoneNumber: mainContact.phoneNumber,
    emails,
    deliveryTerm,
    paymentTerm: vendor.paymentTerm,
    orderBy,
    shippingAddress,
    poItems,
  }

  // console.log('obj', obj)

  // debugger
  return obj
}

export default dsGenerateSPOItemsPerVendor
