import handleDSOperation from '../../functions/handleDSOperation'
import _ from 'lodash'

const onRowUpdate = async (newData) => {
  // console.log('newData', newData)

  const updateObj = {
    id: newData.id,
    type: newData.type,
    amountExclVat: newData.amountExclVat && newData.amountExclVat * 1,
    costCurrency: newData.costCurrency,
    cpn: newData.cpn,
    hpn: newData.hpn,
    invoiceNumber: newData.invoiceNumber,
    invoiceType: newData.invoiceType,
    paymentStatus: newData.paymentStatus,
    deductStatus: newData.deductStatus,
    date: newData.date,
    mpn: newData.mpn,
    poNumber: newData.poNumber,
    qty: newData.qty && newData.qty * 1,
    receivingCode: newData.receivingCode,
    unit: newData.unit,
    usePaymentAgent: newData.usePaymentAgent,
    vendor: newData.vendor,
    vendorPaymentDue: newData.vendorPaymentDue,
    vendorPaymentPaid: newData.vendorPaymentPaid,
    memo: newData.memo,
  }
  // console.log('updateObj', updateObj)
  // console.log('deductstatus', updateObj.deductStatus)

  // debugger
  await handleDSOperation(null, 'update', updateObj)
}

export default onRowUpdate
