import React, { useEffect } from 'react'
import _ from 'lodash'
import {
  FormField,
  RadioField,
  AutoCompleteField,
} from '../../components/Form/FormField'
import usePrevious from '../../hooks/usePrevious'
import { Typography, Checkbox, ListItemText } from '@mui/material'
import { Form } from 'formik'
import config from '../../parameters'
import handleDSOperation from '../../functions/handleDSOperation'

const CreateMainComponent = (props) => {
  const {
    values,
    submitForm,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    initialValues,
    setFieldValue,
    setValues,
    isSubmitting,
    formStyle,
  } = props

  const prevCustomer = usePrevious(values.customer)
  const prevPaymentType = usePrevious(values.paymentType)
  const prevSelectedInvoices = usePrevious(values.selectedInvoices)

  useEffect(() => {
    const func = async () => {
      if (values.paymentType && values.paymentType !== prevPaymentType) {
        setValues({
          ...initialValues,
          paymentType: values.paymentType,
        })
      }

      if (
        (values.paymentType === 'goodsPayment' ||
          values.paymentType === 'goodsPrePayment') &&
        values.customerOptions.length === 0
      ) {
        const getInvoices = await handleDSOperation(
          'outgoingInvoice',
          'query',
          {
            paymentStatus: { predicate: 'ne', value: 'paid' },
          },
        )
        const customerOptions = [...new Set(getInvoices.map((x) => x.customer))]

        setValues({
          ...initialValues,
          paymentType: values.paymentType,
          invoices: getInvoices,
          customerOptions: customerOptions,
        })
      }

      if (values.customer && values.customer !== prevCustomer) {
        const getInvoices = values.invoices.filter(
          (x) => x.customer === values.customer,
        )

        const invoiceNumbers = [
          ...new Set(getInvoices.map((x) => x.invoiceNumber)),
        ].sort()

        const customerinvoicesOptions = invoiceNumbers.map((x) => {
          const sameNumberInvoices = getInvoices.filter(
            (y) => y.invoiceNumber === x,
          )
          const qty = sameNumberInvoices.reduce((a, v) => a + v.qty * 1, 0)
          const amountExclVat = sameNumberInvoices.reduce(
            (a, v) =>
              v.amountExclVat ? a + v.amountExclVat : a + v.qty * v.price,
            0,
          )
          let amountInclVat
          const invoiceType = sameNumberInvoices[0].invoiceType
          const invoiceDate = sameNumberInvoices[0].date

          const priceCurrency = sameNumberInvoices[0].priceCurrency
          const isVat = invoiceType === 'VAT'
          if (isVat) {
            amountInclVat = amountExclVat * (1 + config.vatRate)
          }
          return {
            invoiceDate,
            invoiceNumber: x,
            invoiceType,
            qty,
            amountExclVat: amountExclVat.toFixed(2) * 1,
            amountInclVat: amountInclVat && amountInclVat.toFixed(2) * 1,
            priceCurrency,
          }
        })

        setValues({
          ...values,
          customerinvoicesOptions,
          items: initialValues.items,
        })
      }

      if (
        values.selectedInvoices &&
        !_.isEqual(values.selectedInvoices, prevSelectedInvoices)
      ) {
        setValues({
          ...values,
          items: values.selectedInvoices,
        })
      }
    }

    func()
  }, [
    setFieldValue,
    initialValues,
    values,
    setValues,
    prevCustomer,
    prevSelectedInvoices,
    prevPaymentType,
  ])

  return (
    <>
      <Form style={formStyle} autoComplete='off'>
        <div style={{ marginBottom: 20 }}>
          <FormField
            fieldName='date'
            value={values.date}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
        </div>

        <div>
          <RadioField
            fieldName='paymentType'
            value={values.paymentType || ''}
            handleChange={handleChange}
            groups={[
              { value: 'goodsPayment', label: '货款' },
              // { value: 'goodsPrePayment', label: '预付货款' },
              { value: 'others', label: '其他' },
            ]}
          />
        </div>

        <div>
          {values.paymentType === 'others' && (
            <FormField
              fieldName='paymentDetails'
              value={values.paymentDetails}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
          )}
        </div>
        <div>
          {(values.paymentType === 'goodsPayment' ||
            values.paymentType === 'goodsPrePayment') &&
            values.invoices.length > 0 && (
              <>
                <div>
                  <AutoCompleteField
                    fieldName='customer'
                    options={values.customerOptions.map((x) => ({
                      customer: x,
                    }))}
                    attr='customer'
                    setFieldValue={setFieldValue}
                  />
                </div>
                <div>
                  <AutoCompleteField
                    id={values.customer}
                    multiple
                    disableCloseOnSelect
                    fieldName='selectedInvoices'
                    options={values.customerinvoicesOptions}
                    // attr='invoiceNumber'
                    setFieldValue={setFieldValue}
                    getOptionLabel={(x) => {
                      const { label } = config.currencies.find(
                        (y) => y.value === x.priceCurrency,
                      )
                      return (
                        x && `${x.invoiceNumber} - ${label}${x.amountExclVat}`
                      )
                    }}
                    renderOption={(props, option, { selected }) => {
                      const { label } = config.currencies.find(
                        (x) => x.value === option.priceCurrency,
                      )

                      return (
                        <li {...props}>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          <ListItemText
                            primary={`INV#:${option.invoiceNumber}`}
                            secondary={`Date:${option.invoiceDate} AmountExclVat: ${label}${option.amountExclVat}`}
                          />
                        </li>
                      )
                    }}
                  />
                </div>
              </>
            )}
        </div>
        <div>
          <Typography>
            收款合计：{' '}
            {(values.items &&
              values.items
                .reduce((a, v) => a + v.amountReceived || 0, 0)
                .toFixed(2) * 1) ||
              ''}
          </Typography>
        </div>
      </Form>
    </>
  )
}

export default CreateMainComponent
