const getInitialValue = (data) => ({
  id: data.id,
  type: data.type,
  email: data.email,
  entity: data.entity,
  isMainContact: data.isMainContact,
  name: data.name,
  phoneNumber: data.phoneNumber,
  role: data.role,
  title: data.title,
  dept: data.dept,
  address: data.address,
  deliveryTerm: data.deliveryTerm,
  deliveryTerms: [],
  memo: data.memo,
  entityOptions: [],
})

export default getInitialValue
