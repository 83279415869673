import React, { useState, useEffect } from 'react'
import {
  FormField,
  AutoCompleteField,
  RadioField,
} from '../../../components/Form/FormField'

import { Typography, Checkbox, ListItemText } from '@mui/material'

import handleDSOperation from '../../../functions/handleDSOperation'

import usePrevious from '../../../hooks/usePrevious'

const ForwarderPayment = ({
  initialValues,
  setAllowAdd,
  setAllowDelete,
  values,
  handleBlur,
  handleChange,
  setFieldValue,
  setValues,
}) => {
  const [forwarderPOs, setForwarderPOs] = useState()

  const prevFsorwarderPOsbyReceivingCode = usePrevious(
    values.forwarderPOsbyReceivingCode,
  )

  useEffect(() => {
    const func = async () => {
      if (!forwarderPOs) {
        const getForwarderPOs = await handleDSOperation(
          'forwarderPo',
          'query',
          {
            paymentStatus: { predicate: 'ne', value: 'paid' },
          },
        )

        setForwarderPOs(
          getForwarderPOs.map((x) => ({
            id: x.id,
            invoiceID: x.invoiceID,
            receivingCode: x.receivingCode,
            amountExclVat: x.amountExclVat,
            cost: x.cost,
            costCurrency: x.costCurrency,
            cpn: x.cpn,
            duty: x.duty,
            exchangeRate: x.exchangeRate,
            extraCharge: x.extraCharge,
            handlingFee: x.handlingFee,
            hpn: x.hpn,
            mpn: x.mpn,
            qty: x.qty,
            vat: x.vat,
            unit: x.unit,
            vendor: x.vendor,
          })),
        )
      }
      if (
        values.forwarderPOsbyReceivingCode &&
        values.forwarderPOsbyReceivingCode !== prevFsorwarderPOsbyReceivingCode
      ) {
        const itemsByReceivingCode = await Promise.all(
          values.forwarderPOsbyReceivingCode.map(async (x) => {
            // console.log('xxxxx', x)

            return await Promise.all(
              x.forwaorderPOItems.map(async (y) => {
                const getPayments = await handleDSOperation(
                  'outgoingPayment',
                  'query',
                  {
                    forwarderPOID: { predicate: 'eq', value: y.id },
                  },
                )

                const handlingAndTaxPaid = getPayments.reduce(
                  (a, v) =>
                    a +
                    (v.paymentType === 'handlingAndTax' ? v.amountExclVat : 0),
                  0,
                )
                const cnyValuePaid = getPayments.reduce(
                  (a, v) =>
                    a + (v.paymentType === 'cnyValue' ? v.amountExclVat : 0),
                  0,
                )

                const handlingAndTaxPayable =
                  y.duty + y.handlingFee + y.vat + y.extraCharge

                const cnyValuePayable =
                  (y.amountExclVat || y.cost * y.qty) * y.exchangeRate

                // console.log(9999999999, getPayments)
                // console.log('handlingAndTaxPayable', handlingAndTaxPayable)
                // console.log('cnyValuePayable', cnyValuePayable)

                // console.log('handlingAndTaxPaid', handlingAndTaxPaid)
                // console.log('cnyValuePaid', cnyValuePaid)
                const handlingAndTax =
                  handlingAndTaxPayable - handlingAndTaxPaid

                const cnyValue = cnyValuePayable - cnyValuePaid
                // debugger

                return {
                  ...y,
                  handlingAndTax: handlingAndTax.toFixed(2) * 1,
                  cnyValue: cnyValue.toFixed(2) * 1,
                  handlingAndTaxChecked: true,
                  cnyValueChecked: true,
                }
              }),
            )
          }),
        )

        setValues({ ...values, items: itemsByReceivingCode })
      }
    }

    func()
  }, [
    forwarderPOs,
    setForwarderPOs,
    values,
    setValues,
    prevFsorwarderPOsbyReceivingCode,
  ])

  console.log('outgoing payment values', values)

  const arrOfItemValue =
    values.items &&
    values.items.map((x) =>
      x.reduce(
        (a, v) =>
          a +
          (v.handlingAndTaxChecked ? v.handlingAndTax : 0) +
          (v.cnyValueChecked ? v.cnyValue : 0),
        0,
      ),
    )

  const itemsTotal =
    arrOfItemValue && arrOfItemValue.reduce((a, v) => a + v, 0).toFixed(2) * 1

  return (
    <>
      {forwarderPOs && (
        <>
          <AutoCompleteField
            multiple
            id={values.paymentType}
            setFieldValue={setFieldValue}
            getOptionLabel={(x) => x.receivingCode}
            // attr='receivingCode'
            renderOption={(props, option, { selected }) => {
              return (
                <li {...props}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  <ListItemText primary={option.receivingCode} />
                </li>
              )
            }}
            disableCloseOnSelect
            fieldName='forwarderPOsbyReceivingCode'
            options={[...new Set(forwarderPOs.map((x) => x.receivingCode))]
              .sort()
              .map((y) => ({
                receivingCode: y,
                forwaorderPOItems: forwarderPOs.filter(
                  (z) => z.receivingCode === y,
                ),
              }))}
          />
        </>
      )}

      <Typography color='secondary' variant='h6'>
        付款合计：
        {itemsTotal}
      </Typography>
    </>
  )
}

export default ForwarderPayment
