import { firstBy } from 'thenby'
import getLineForRow from '../helpFunc/getLineForRow'

const sort = (dataType, tbType, rows) => {
  rows = [...rows]
  // console.log('9999999999999', tbType)
  // console.log('88888888888888', dataType)

  switch (dataType) {
    case 'product':
      if (tbType.startsWith('collasped')) {
        rows.sort((a, b) => (a.version < b.version ? 1 : -1))
      } else {
        rows.sort((a, b) => (a.cpn > b.cpn ? 1 : -1))
      }
      break

    case 'cpo':
      if (tbType === 'enhanced' || tbType === 'simple') {
        rows.sort(
          firstBy('date', -1)
            .thenBy('poNumber', -1)
            .thenBy('cpn')
            .thenBy('reqSched')
            .thenBy('qty'),
        )
        getLineForRow(rows, 'poNumber')
      } else if (tbType.startsWith('collasped')) {
        rows.sort(
          firstBy('version', -1)
            .thenBy('poNumber', -1)
            .thenBy('cpn')
            .thenBy('reqSched'),
        )
        getLineForRow(rows, 'version')
      }
      break
    case 'spo':
      if (tbType === 'enhanced' || tbType === 'simple') {
        rows.sort(
          firstBy('date', -1)
            // firstBy('poNumber', 1)
            .thenBy('poNumber', -1)
            .thenBy('cpn')
            .thenBy('reqSched')
            .thenBy('estSched')
            .thenBy('qty'),
        )
        getLineForRow(rows, 'poNumber')
      } else if (tbType.startsWith('collasped')) {
        rows.sort(
          firstBy('version', -1)
            .thenBy('poNumber', -1)
            .thenBy('cpn')
            .thenBy('reqSched'),
        )
        getLineForRow(rows, 'version')
      }
      break
    case 'forwarderPo':
      rows.sort(
        firstBy('date', -1)
          .thenBy('receivingCode', -1)
          .thenBy('cpn')
          .thenBy('poNumber'),
      )
      getLineForRow(rows, 'receivingCode')
      break
    case 'shipping':
      rows.sort(
        firstBy('date', -1)
          .thenBy('fulfillmentNumber', -1)
          .thenBy('cartonNumber')
          .thenBy('cpn')
          .thenBy('mpn')
          .thenBy('poNumber'),
      )
      getLineForRow(rows, 'fulfillmentNumber')

      break
    case 'receiving':
      rows.sort(firstBy('date', -1).thenBy('cpn').thenBy('poNumber'))
      // getLineForRow(rows, 'number')
      break
    case 'incomingInvoice':
      rows.sort(
        firstBy('date', -1)
          .thenBy('vendor')
          .thenBy('invoiceNumber', -1)
          .thenBy('cpn'),
      )
      getLineForRow(rows, 'invoiceNumber')
      break
    case 'incomingPayment':
      rows.sort(firstBy('date', -1).thenBy('invoiceNumber', -1))
      // getLineForRow(rows, 'invoiceNumber')
      break
    case 'outgoingInvoice':
      rows.sort(
        firstBy('date', -1)
          .thenBy('invoiceType')
          .thenBy('invoiceNumber', -1)
          .thenBy('cpn'),
      )
      getLineForRow(rows, 'invoiceNumber')
      break
    case 'outgoingPayment':
      rows.sort(
        firstBy('date', -1).thenBy('vendor').thenBy('invoiceNumber', -1),
      )
      // getLineForRow(rows, 'invoiceNumber')
      break
    case 'outgoingQuote':
      rows.sort(firstBy('date', -1).thenBy('number', -1))
      // getLineForRow(rows, 'invoiceNumber')
      break
    case 'contact':
      rows.sort(firstBy('entity').thenBy('isMainContact').thenBy('role'))
      break
    case 'spoSched':
      rows.sort(
        firstBy('version', -1)
          .thenBy('poNumber', -1)
          .thenBy('cpn')
          .thenBy('estSched'),
      )
      getLineForRow(rows, 'version')
      break

    default:
      break
  }
  return rows
}

export default sort
