import {
  Link,
  TextField,
  Tooltip,
  Typography,
  Alert,
  Button,
  IconButton,
} from '@mui/material'
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Security as SecurityIcon,
  Announcement as AnnouncementIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material'

import { Hub } from 'aws-amplify'
import { withStyles } from '@mui/styles'
import config from '../parameters'

const AlertTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(22),
    border: '1px solid #dadde9',
  },
}))(Tooltip)

const getTableMapping = (dataType, tbType, currentUser) => {
  const userGroups = currentUser.groups

  // console.log('userGroups888', userGroups)

  const tbProperties = {
    actions: {
      type: 'actions',
      index: 'actions',
      minWidth: 110,
      head: 'Action',
      disableExport: true,
      restriction: ['Admin', 'Sales', 'Accounting'],
      renderCell: ({ row, ...props }) => (
        <>
          <IconButton
            label='Edit'
            onClick={() => {
              Hub.dispatch('FormMonitor', {
                event: 'formEdit',
                data: {
                  dataType,
                  formType: 'edit',
                  data: row,
                  allowAddItem: false,
                  showDeleteItem: false,
                },
                message: '',
              })
            }}>
            <EditIcon />
          </IconButton>
          <IconButton
            label='Detail'
            onClick={() => {
              Hub.dispatch('FormMonitor', {
                event: 'showDetailPanel',
                data: {
                  dataType,
                  row,
                },
                message: '',
              })
            }}>
            <KeyboardArrowDownIcon />
          </IconButton>
        </>
      ),
    },

    collapse: {
      index: 'collapse',
      head: '',
      restriction: ['Admin', 'Sales', 'Accounting'],
    },
    isNew: {
      type: 'boolean',
      index: 'isNew',
      head: 'New/Existing',
      restriction: ['Admin', 'Sales', 'Accounting'],
    },
    name: {
      index: 'name',
      head: 'Name',
      restriction: ['Admin', 'Sales', 'Accounting'],
      sortable: false,
    },
    memo: {
      index: 'memo',
      width: 80,
      head: 'Memo',
      filterable: false,
      restriction: ['Admin', 'Sales', 'Accounting'],
      searchable: false,
      grouping: false,
      disableExport: true,
      sortable: false,
      renderCell: ({ value }) =>
        value ? (
          <AlertTooltip placement='top' title={value}>
            <AnnouncementIcon color='secondary' fontSize='large' />
          </AlertTooltip>
        ) : null,
    },
    isActive: {
      renderCell: ({ value }) => {
        return value ? (
          <Typography
            variant='h6'
            style={{ fontStyle: 'italic', fontWeight: 600, color: 'green' }}>
            Active
          </Typography>
        ) : (
          <Typography
            variant='h6'
            style={{ fontStyle: 'italic', fontWeight: 600, color: 'red' }}>
            Idle
          </Typography>
        )
      },
      type: 'boolean',
      index: 'isActive',
      head: 'Status',
      restriction: ['Admin', 'Sales', 'Accounting'],
    },
    entity: {
      index: 'entity',
      head: 'Company',
      restriction: ['Admin', 'Sales', 'Accounting'],
      width: 280,
      sortable: false,
    },
    deductStatus: {
      type: 'date',
      index: 'deductStatus',
      head: '抵扣月份',
      restriction: ['Admin', 'Sales', 'Accounting'],
    },
    customer: {
      index: 'customer',
      head: 'CTM',
      restriction: ['Admin', 'Sales', 'Accounting'],
      sortable: false,
      minWidth: 160,
    },
    customerPaymentDue: {
      type: 'date',
      index: 'customerPaymentDue',
      head: '货款到期',
      restriction: ['Admin', 'Accounting'],
      filterable: false,
      searchable: false,
      grouping: false,
    },
    customerPaymentPaid: {
      type: 'date',
      index: 'customerPaymentPaid',
      head: '付款日期',
      restriction: ['Admin', 'Accounting'],
      filterable: false,
      searchable: false,
      grouping: false,
    },
    date: {
      type: 'date',
      index: 'date',
      head: 'DATE',
      restriction: ['Admin', 'Sales', 'Accounting'],
      width: 120,
    },

    fulfillmentNumber: {
      index: 'fulfillmentNumber',
      head: 'Shipment#',
      restriction: ['Admin', 'Sales', 'Accounting'],
      minWidth: 150,
    },
    cartonDimensions: {
      index: 'dimensions',
      head: 'Dimensions',
      restriction: ['Admin', 'Sales', 'Accounting'],
      filterable: false,
      searchable: false,
      grouping: false,
      sortable: false,
    },
    cartonGw: {
      index: 'gw',
      head: 'Gw',
      restriction: ['Admin', 'Sales', 'Accounting'],
      filterable: false,
      searchable: false,
      grouping: false,
    },
    poNumber: {
      index: 'poNumber',
      head: 'PO',
      restriction: ['Admin', 'Sales', 'Accounting'],
      minWidth: 150,
    },
    hpn: {
      index: 'hpn',
      head: 'HPN',
      restriction: ['Admin', 'Sales', 'Accounting'],
      sortable: false,
    },

    exchangeRate: {
      type: 'number',
      index: 'exchangeRate',
      head: '汇率',
      restriction: ['Admin', 'Sales', 'Accounting'],
      sortable: false,
    },
    duty: {
      type: 'number',
      index: 'duty',
      head: '关税',
      restriction: ['Admin', 'Sales', 'Accounting'],
      valueGetter: ({ row }) => row.duty && row.duty.toFixed(2),
      filterable: false,
      searchable: false,
      grouping: false,
      sortable: false,
    },
    handlingFee: {
      type: 'number',
      index: 'handlingFee',
      head: '代理费',
      restriction: ['Admin', 'Sales', 'Accounting'],
      valueGetter: ({ row }) => row.handlingFee && row.handlingFee.toFixed(2),
      filterable: false,
      searchable: false,
      grouping: false,
      sortable: false,
    },
    vat: {
      index: 'vat',
      type: 'number',
      head: '增值税',
      restriction: ['Admin', 'Sales', 'Accounting'],
      valueGetter: ({ value }) => value && value.toFixed(2),
      filterable: false,
      searchable: false,
      grouping: false,
      sortable: false,
    },
    extraCharge: {
      index: 'extraCharge',
      type: 'number',
      head: '附加',
      restriction: ['Admin', 'Sales', 'Accounting'],
      valueGetter: ({ value }) => value && value.toFixed(2),
      filterable: false,
      searchable: false,
      grouping: false,
      sortable: false,
    },
    forwarderTaxAndHandlingDue: {
      type: 'date',
      index: 'forwarderTaxAndHandlingDue',
      head: '税代到期',
      restriction: ['Admin', 'Sales', 'Accounting'],
      filterable: false,
      searchable: false,
      grouping: false,
      sortable: false,
    },
    forwarderTaxAndHandlingPaid: {
      type: 'date',
      index: 'forwarderTaxAndHandlingPaid',
      head: '税代支付日期',
      restriction: ['Admin', 'Sales', 'Accounting'],
      filterable: false,
      searchable: false,
      grouping: false,
      sortable: false,
    },

    paymentType: {
      index: 'paymentType',
      head: '付款类型',
      restriction: ['Admin', 'Sales', 'Accounting'],
      minWidth: 150,
    },
    paymentStatus: {
      type: 'singleSelect',
      valueOptions: config.paymentStatus,
      index: 'paymentStatus',
      head: '付款状态',
      restriction: ['Admin', 'Sales', 'Accounting'],
    },

    invoiceType: {
      index: 'invoiceType',
      head: '发票类型',
      restriction: ['Admin', 'Sales', 'Accounting'],
    },

    vendorPaymentDue: {
      type: 'date',
      index: 'vendorPaymentDue',
      head: '供应商款应付日期',
      restriction: ['Admin', 'Sales', 'Accounting'],
      filterable: false,
      searchable: false,
      grouping: false,
    },
    vendorPaymentPaid: {
      type: 'date',
      index: 'vendorPaymentPaid',
      head: '供应商款付款日期',
      restriction: ['Admin', 'Sales', 'Accounting'],
      filterable: false,
      searchable: false,
      grouping: false,
    },
    forwarderPaymentDue: {
      type: 'date',
      index: 'forwarderPaymentDue',
      head: '货代货款应付日期',
      restriction: ['Admin', 'Sales', 'Accounting'],
      filterable: false,
      searchable: false,
      grouping: false,
    },
    forwarderPaymentPaid: {
      type: 'date',
      index: 'forwarderPaymentPaid',
      head: '货代货款付款日期',
      restriction: ['Admin', 'Sales', 'Accounting'],
      filterable: false,
      searchable: false,
      grouping: false,
    },
    piAmount: {
      type: 'number',
      index: 'piAmount',
      filterable: false,
      head: 'PI Amount',
      restriction: ['Admin', 'Sales', 'Accounting'],
      valueGetter: ({ row }) =>
        (row.amountExclVat && row.amountExclVat.toFixed(2)) ||
        (row.cost && row.qty && row.cost * row.qty).toFixed(2),
    },
    // billedAmount: {
    //   index: 'billedAmount',
    //   head: '开票金额',
    //   restriction: ['Admin', 'Sales', 'Accounting'],
    // },
    billedDate: {
      type: 'date',
      index: 'billedDate',
      head: '开票日期',
      restriction: ['Admin', 'Sales', 'Accounting'],
    },
    goodsReceivedDate: {
      type: 'date',
      index: 'goodsReceivedDate',
      head: '收货日期',
      restriction: ['Admin', 'Sales', 'Accounting'],
    },
    productType: {
      index: 'productType',
      head: 'Product Type',
      restriction: ['Admin', 'Sales', 'Accounting'],
    },

    vendor: {
      index: 'vendor',
      head: 'Vendor',
      restriction: ['Admin', 'Sales', 'Accounting'],

      sortable: false,
      minWidth: 150,
      flex: 1,
    },
    vendors: {
      index: 'vendors',
      head: 'Vendors',
      restriction: ['Admin', 'Sales', 'Accounting'],
      valueGetter: ({ row }) => (row.vendors ? row.vendors.join(',') : ''),
      sortable: false,
      minWidth: 200,
    },

    isMainContact: {
      type: 'boolean',
      index: 'isMainContact',
      head: 'Main',
      restriction: ['Admin', 'Sales', 'Accounting'],
    },
    dept: {
      index: 'dept',
      head: 'Dept',
      restriction: ['Admin', 'Sales', 'Accounting'],
      sortable: false,
      minWidth: 240,
    },
    title: {
      index: 'title',
      head: 'Title',
      restriction: ['Admin', 'Sales', 'Accounting'],
      sortable: false,
    },
    address: {
      index: 'address',
      head: 'Address',
      restriction: ['Admin', 'Sales', 'Accounting'],
      sortable: false,
      minWidth: 300,
      flex: 1,
    },
    billingInfo: {
      index: 'billingInfo',
      head: 'billingInfo',
      restriction: ['Admin', 'Sales', 'Accounting'],
      sortable: false,
      minWidth: 300,
      flex: 2,
    },

    role: {
      index: 'role',
      head: 'Role',
      restriction: ['Admin', 'Sales', 'Accounting'],
      valueGetter: ({ row }) => row.role.join(', '),
      sortable: false,
      minWidth: 120,
    },
    cpn: {
      index: 'cpn',
      head: 'CPN',
      restriction: ['Admin', 'Sales', 'Accounting'],
      defaultGroupOrder: -1,
      defaultGroupSort: 'asc',
      // lookup: true,
    },
    line: {
      index: 'line',
      head: 'LINE',
      valueFormatter: ({ value }) => `'${value}`,
      restriction: ['Admin', 'Sales', 'Accounting'],
      width: 80,
      searchable: false,
      grouping: false,
      sorting: false,
      filterable: false,
      sortable: false,
    },

    qty: {
      index: 'qty',
      head: 'QTY',
      restriction: ['Admin', 'Sales', 'Accounting'],
      type: 'number',
      searchable: false,
      grouping: false,
      filterable: false,
      width: 100,
    },
    unit: {
      index: 'unit',
      head: 'Unit',
      restriction: ['Admin', 'Sales', 'Accounting'],
      searchable: false,
      grouping: false,
    },
    priceExclVat: {
      type: 'number',
      index: 'priceExclVat',
      head: '未税售价',
      restriction: ['Accounting', 'Admin'],
      searchable: false,
      grouping: false,
    },

    invoiceCostExclVat: {
      type: 'number',
      index: 'invoiceCostExclVat',
      head: '发票单价',
      restriction: ['Accounting', 'Admin', 'Sales'],
      searchable: false,
      grouping: false,
    },
    vatRate: {
      type: 'number',
      index: 'vatRate',
      head: '税率',
      restriction: ['Admin', 'Accounting', 'Sales'],
      searchable: false,
      grouping: false,
    },
    contractAmount: {
      type: 'number',
      index: 'contractAmount',
      head: '合同金额',
      restriction: ['Admin', 'Accounting', 'Sales'],
      valueGetter: ({ row }) =>
        (
          (row.cost * row.qty * row.exchangeRate ||
            row.amountExclVat * row.exchangeRate) +
          row.duty +
          row.vat +
          row.handlingFee +
          row.extraCharge
        ).toFixed(2),
      filterable: false,
      searchable: false,
      grouping: false,
    },
    amountExclVat: {
      type: 'number',
      index: 'amountExclVat',
      head: '未税金额',
      restriction: ['Admin', 'Accounting'],
      searchable: false,
      grouping: false,
      filterable: false,
      valueGetter: ({ row }) =>
        (row.amountExclVat && row.amountExclVat.toFixed(2)) ||
        (row.price && row.qty && (row.price * row.qty).toFixed(2)),
    },
    amountInclVat: {
      index: 'amountInclVat',
      head: '含税金额',
      restriction: ['Admin', 'Accounting'],
      searchable: false,
      grouping: false,
      filterable: false,
      valueGetter: ({ row }) =>
        (row.invoiceType === 'VAT' &&
          (row.amountInclVat ||
            (row.price &&
              row.qty &&
              (row.price * row.qty * (1 + config.vatRate)).toFixed(2)) ||
            (row.amountExclVat * (1 + config.vatRate)).toFixed(2))) ||
        null,
    },
    openQty: {
      type: 'number',
      index: 'openQty',
      head: 'Open Qty',
      restriction: ['Admin', 'Sales', 'Accounting'],
      searchable: false,
      grouping: false,
      width: 100,

      // lookup: { 0: '已完成' },

      // filterComponent: ({ filterComponent, onFilterChanged, columnDef }) => {
      //   // console.log('filterComponent', filterComponent)
      //   return (
      //     <TextField
      //       type='number'
      //       variant='standard'
      //       onChange={(e) => {
      //         onFilterChanged(columnDef.tableData.id, e.target.value)
      //       }}
      //     />
      //   )
      // },
      customFilterAndSearch: (term, rowData) => rowData.openQty >= term * 1,

      // return term === rowData.name.length
    },
    totalOpenQty: {
      type: 'number',
      index: 'totalOpenQty',
      head: 'System Total Open Qty',
      restriction: ['Admin', 'Sales', 'Accounting'],
      width: 100,
    },
    mfr: {
      index: 'mfr',
      head: 'MFR',
      restriction: ['Admin', 'Sales', 'Accounting'],

      sortable: false,
    },
    mpn: {
      index: 'mpn',
      head: 'MPN',
      restriction: ['Admin', 'Sales', 'Accounting'],
      sorting: false,
      sortable: false,
      minWidth: 200,
      // flex: 1,
    },
    receivingCode: {
      index: 'receivingCode',
      head: '入仓号',
      restriction: ['Admin', 'Sales', 'Accounting'],
      minWidth: 120,
    },
    bpNumber: {
      index: 'bpNumber',
      head: 'Number',
      restriction: ['Admin', 'Sales', 'Accounting'],
      sortable: false,
    },
    invoiceNumber: {
      index: 'invoiceNumber',
      head: 'Invoice',
      restriction: ['Admin', 'Sales', 'Accounting'],
      minWidth: 120,
    },
    paymentDue: {
      type: 'date',
      index: 'paymentDue',
      head: 'Payment Due',
      restriction: ['Admin', 'Sales', 'Accounting'],
    },
    paymentMade: {
      type: 'date',
      index: 'paymentMade',
      head: 'Payment Made',
      restriction: ['Admin', 'Sales', 'Accounting'],
    },

    paymentTerm: {
      index: 'paymentTerm',
      head: 'Payment Term',
      restriction: ['Admin', 'Sales', 'Accounting'],
      flex: 0.5,
      minWidth: 100,
      sortable: false,
    },
    deliveryTerm: {
      index: 'deliveryTerm',
      head: 'Shipping Term',
      restriction: ['Admin', 'Sales', 'Accounting'],
    },
    deliveryTerms: {
      flex: 1,
      minWidth: 150,
      index: 'deliveryTerms',
      head: 'Shipping Terms',
      restriction: ['Admin', 'Sales', 'Accounting'],
      valueGetter: ({ row }) =>
        row.deliveryTerms && row.deliveryTerms.join(', '),
    },
    mainContactName: {
      index: 'mainContactName',
      head: 'Contact',
      restriction: ['Admin', 'Sales', 'Accounting'],
      valueGetter: ({ row }) => row.contactPerson && row.contactPerson.name,
    },
    mainContactRole: {
      index: 'mainContactRole',
      head: 'Role',
      restriction: ['Admin', 'Sales', 'Accounting'],
      valueGetter: ({ row }) =>
        row.contactPerson && row.contactPerson && row.contactPerson.role.join(),
    },
    mainContactPhoneNumber: {
      index: 'mainContactPhoneNumber',
      head: 'Phone',
      restriction: ['Admin', 'Sales', 'Accounting'],
      valueGetter: ({ row }) =>
        row.contactPerson && row.contactPerson.phoneNumber,
      sortable: false,
      minWidth: 200,
    },
    mainContactEmail: {
      index: 'mainContactEmail',
      head: 'Email',
      restriction: ['Admin', 'Sales', 'Accounting'],
      valueGetter: ({ row }) => row.contactPerson && row.contactPerson.email,
      sortable: false,
      width: 300,
    },
    supplierMOQ: {
      type: 'number',
      index: 'supplierMOQ',
      head: 'vMOQ',
      restriction: ['Admin', 'Sales', 'Accounting'],
      valueFormatter: ({ value }) => Number(value).toLocaleString(),
      sortable: false,
    },
    supplierMPQ: {
      type: 'number',
      index: 'supplierMPQ',
      head: 'vMPQ',
      restriction: ['Admin', 'Sales', 'Accounting'],
      valueFormatter: ({ value }) => Number(value).toLocaleString(),
      sortable: false,
    },
    cost: {
      index: 'cost',
      head: 'Cost',
      restriction: ['Admin', 'Sales', 'Accounting'],
      valueGetter: ({ row }) => {
        return (
          (row.cost && row.cost.toFixed(4)) * 1 ||
          (row.amountExclVat &&
            row.qty &&
            (row.amountExclVat / row.qty).toFixed(4)) * 1
        )
      },
      filterable: false,
      searchable: false,
      grouping: false,
      width: 80,
    },
    costCurrency: {
      index: 'costCurrency',
      head: 'cCur',
      restriction: ['Admin', 'Sales', 'Accounting'],
      width: 70,
      type: 'singleSelect',
      valueOptions: config.currencyOptions,
    },
    currency: {
      index: 'currency',
      head: 'Cur',
      restriction: ['Admin', 'Sales', 'Accounting'],
    },

    customerMOQ: {
      type: 'number',
      index: 'customerMOQ',
      head: 'cMOQ',
      restriction: ['Admin', 'Sales', 'Accounting'],
    },
    customerMPQ: {
      type: 'number',
      index: 'customerMPQ',
      head: 'cMPQ',
      restriction: ['Admin', 'Sales', 'Accounting'],
    },
    enteredPrice: {
      type: 'number',
      index: 'enteredPrice',
      head: 'Entered Price',
      restriction: ['Admin', 'Sales', 'Accounting'],
      width: 80,
    },
    price: {
      type: 'number',
      index: 'price',
      head: 'Price',
      restriction: ['Admin'],
      valueFormatter: ({ value }) => value.toFixed(4) * 1,
      filterable: false,
      searchable: false,
      grouping: false,
      width: 80,
    },
    priceCurrency: {
      index: 'priceCurrency',
      head: 'pCur',
      restriction: ['Accounting', 'Admin'],
      grouping: false,
    },

    initialInventory: {
      type: 'number',
      index: 'initialInventory',
      head: 'iniInv',
      restriction: ['Admin', 'Sales', 'Accounting'],
    },
    safetyInventory: {
      type: 'number',
      index: 'safetyInventory',
      head: 'safInv',
      restriction: ['Admin', 'Sales', 'Accounting'],
    },
    currentInventory: {
      type: 'number',
      index: 'currentInventory',
      head: 'curInv',
      restriction: ['Admin', 'Sales', 'Accounting'],
      valueGetter: ({ value }) => value && value.toFixed(2),
    },

    orderDate: {
      type: 'date',
      index: 'orderDate',
      head: 'Order Date',
      restriction: ['Admin', 'Sales', 'Accounting'],
    },

    reqSched: {
      type: 'date',
      index: 'reqSched',
      head: 'Req Sched',
      restriction: ['Admin', 'Sales', 'Accounting'],
      filterable: false,
      searchable: false,
      grouping: false,
    },
    enteredEstSched: {
      type: 'date',
      index: 'enteredEstSched',
      head: 'Entered Est Sched',
      restriction: ['Admin', 'Sales', 'Accounting'],
    },
    estSched: {
      type: 'date',
      index: 'estSched',
      head: 'Est Sched',
      restriction: ['Admin', 'Sales', 'Accounting'],
      filterable: false,
      searchable: false,
      grouping: false,
    },

    actualLt: {
      type: 'number',
      index: 'actualLt',
      head: 'Actual LT',
      restriction: ['Admin', 'Sales', 'Accounting'],
    },

    amount: {
      type: 'number',
      index: 'amount',
      head: 'Amount',
      restriction: ['Admin'],
      grouping: false,
      disableExport: true,
      filterable: false,
      sorting: false,
      searchable: false,
      valueGetter: ({ row }) => {
        let value
        if (row.type === 'cpo') {
          value = row.price * row.qty.toFixed(2)
        } else if (row.type === 'spo') {
          value = row.cost * row.qty.toFixed(2)
        }
        return value
      },
    },
    customerLeadtime: {
      type: 'number',
      index: 'customerLeadtime',
      head: 'PDT',
      restriction: ['Admin', 'Sales', 'Accounting'],
      width: 80,
    },
    scheduleBasedOnPdt: {
      type: 'number',
      index: 'scheduleBasedOnPdt',
      head: 'PDT based Schedule',
      restriction: ['Admin', 'Sales', 'Accounting'],
      width: 80,
    },
    pdtDifference: {
      type: 'number',
      index: 'pdtDifference',
      head: 'PDT Difference',
      restriction: ['Admin', 'Sales', 'Accounting'],
      width: 80,
    },
    leadtime: {
      type: 'number',
      index: 'leadtime',
      head: 'L/T',
      restriction: ['Admin', 'Sales', 'Accounting'],
      width: 80,
    },
    transitDays: {
      type: 'number',
      index: 'transitDays',
      head: 'Transit',
      restriction: ['Admin', 'Sales', 'Accounting'],
      width: 80,
    },
    scheduleBasedOnLeadtime: {
      type: 'number',
      index: 'scheduleBasedOnLeadtime',
      head: 'LT based Schedule',
      restriction: ['Admin', 'Sales', 'Accounting'],
    },
    leadtimeDifference: {
      type: 'number',
      index: 'leadtimeDifference',
      head: 'LT Difference',
      restriction: ['Admin', 'Sales', 'Accounting'],
    },
    _lastChangedAt: {
      index: '_lastChangedAt',
      head: 'Last Update',
      restriction: ['Admin', 'Sales', 'Accounting'],
      valueFormatter: ({ value }) =>
        value ? new Date(value).toISOString().substring(0, 10) : null,
      disableExport: true,
      filterable: false,
      searchable: false,
      grouping: false,
      sorting: false,
    },
    poVersion: {
      index: 'poVersion',
      head: 'PO Version',
      restriction: ['Admin', 'Sales', 'Accounting'],
    },
    version: {
      index: 'version',
      head: 'Version',
      restriction: ['Admin', 'Sales', 'Accounting'],
    },
    phoneNumber: {
      index: 'phoneNumber',
      head: 'Phone Number',
      restriction: ['Admin', 'Sales', 'Accounting'],
      sortable: false,
      minWidth: 200,
    },
    email: {
      index: 'email',
      head: 'Email',
      restriction: ['Admin', 'Sales', 'Accounting'],
      sortable: false,
      minWidth: 240,
    },
    number: {
      index: 'number',
      head: 'Number',
      restriction: ['Admin', 'Sales', 'Accounting'],
      sortable: false,
    },
    desc: {
      index: 'desc',
      head: 'Desc',
      restriction: ['Admin', 'Sales', 'Accounting'],
      sortable: false,
    },
    hankenoEntity: {
      index: 'hankenoEntity',
      head: 'HankenoEntity',
      restriction: ['Admin', 'Sales', 'Accounting'],
      type: 'singleSelect',
      valueOptions: config.hankenoEntities.map((x) => x.tag),
    },
    entityType: {
      index: 'entityType',
      head: 'Type',
      restriction: ['Admin', 'Sales', 'Accounting'],
      type: 'singleSelect',
      valueOptions: config.entityType,
    },
    cartonNumber: {
      type: 'number',
      index: 'cartonNumber',
      head: 'Carton',
      restriction: ['Admin', 'Sales', 'Accounting'],
      filterable: false,
      searchable: false,
      grouping: false,
      sortable: false,
      width: 80,
    },
    vendorOrCtmCode: {
      index: 'vendorOrCtmCode',
      head: 'Code',
      restriction: ['Admin', 'Sales', 'Accounting'],
    },
    coo: {
      index: 'coo',
      head: 'coo',
      restriction: ['Admin', 'Sales', 'Accounting'],
    },
    trackingNumber: {
      index: 'trackingNumber',
      head: 'Tracking',
      restriction: ['Admin', 'Sales', 'Accounting'],
      renderCell: ({ row }) => {
        let href
        if (row.trackingNumber) {
          switch (row.carrier) {
            case 'DHL':
              href = `https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=${row.trackingNumber}`
              break
            default:
              break
          }

          return (
            <Link
              href={href}
              target='_blank'
              // to={href}
              // onClick={() => {
              //   console.log('href clicked')
              // }}
            >
              {row.trackingNumber}
            </Link>
          )
        }
      },
    },
    carrier: {
      index: 'carrier',
      head: 'Carrier',
      restriction: ['Admin', 'Sales', 'Accounting'],
      width: 80,
    },
    billingStatus: {
      type: 'singleSelect',
      valueOptions: config.billingStatus,
      index: 'billingStatus',
      head: '发票状态',
      restriction: ['Admin', 'Sales', 'Accounting'],
      width: 80,
    },
    deliveryStatus: {
      type: 'singleSelect',
      valueOptions: config.deliveryStatus,
      index: 'deliveryStatus',
      head: '送货状态',
      restriction: ['Admin', 'Sales', 'Accounting'],
    },
  }

  if (!dataType || !tbType) return

  const mapping = require(`resources/${dataType}/mapping`).default
  const tbMapping = mapping && mapping[tbType]

  // const rowProperties = []
  if (!tbMapping) return alert('TBMapping does not exist')

  const rowProperties = tbMapping.map((x) => {
    if (!tbProperties[x]) {
      alert(`property does not exist, ${x}`)
    }
    return tbProperties[x]
  })

  // tbMapping.forEach((x) => {
  //   if (!tbProperties[x]) {
  //     console.log('property does not exist', x)
  //     return
  //   }

  //   if (
  //     tbProperties[x].restriction.some((r) =>
  //       userGroups.map((x) => x.toLowerCase()).includes(r.toLowerCase()),
  //     )
  //   ) {
  //     rowProperties.push(tbProperties[x])
  //   }
  // })

  if (tbType === 'enhanced') {
    return rowProperties.map((a) => {
      const { index, head, restriction, ...props } = a

      return {
        ...props,
        field: a.index,
        title: a.head,
        headerName: a.head,
        // width: a.width || 120,
        hide: !restriction.some((role) =>
          userGroups.map((x) => x.toLowerCase()).includes(role.toLowerCase()),
        ),
      }
    })
  } else {
    return rowProperties.map((x) => ({
      index: x.index,
      head: x.head,
    }))
  }
}

export default getTableMapping
