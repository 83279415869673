import React, { useState } from 'react'
import { Hub } from 'aws-amplify'
import { Button, Stack } from '@mui/material'
import MTSumField from '../../components/Table/toolbar/MTSumField'

const Toolbar = ({ selectedRows, dataType }) => {
  return selectedRows && selectedRows.length > 0 ? (
    <Stack direction='row' spacing={3}>
      <MTSumField qtyAttr='qty' selectedRows={selectedRows} />
    </Stack>
  ) : (
    <Stack direction='row' spacing={3}>
      <Button
        variant='contained'
        color='secondary'
        size='small'
        onClick={() => {
          Hub.dispatch('FormMonitor', {
            event: 'formEdit',
            data: {
              dataType,
              formType: 'create',
              allowAddItem: true,
              showDeleteItem: true,
            },
            message: '',
          })
        }}>
        收货
      </Button>
    </Stack>
  )
}

export default Toolbar
