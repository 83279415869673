import * as Yup from 'yup'

export const itemValidationShape = {
  // poNumber: Yup.string().required('PO Number is required'),
  cpn: Yup.string().required('CPN is required'),
  mpn: Yup.string().required('MPN is required'),
  qty: Yup.string().required('Qty is required'),
  amountExclVat: Yup.string().required('请输入不含税金额'),
}

const formValidationSchema = {
  invoiceType: Yup.string().required('请选择发票类型'),
  // vendor: Yup.string().required('Vendor is required'),
  invoiceNumber: Yup.string().test(
    'len',
    'Invoice number must have at least three characters',
    (val) => {
      if (val) {
        return val.length > 2
      }
    },
  ),
  items: Yup.array().of(Yup.object(itemValidationShape)),
}

export default formValidationSchema
