import getProductInfoForNewRowItems from '../APIOperations/getProductInfoForNewRowItems'
import isPoFieldNull from '../rowValidator/isPoFieldNull'
import isPoExistingAndGetLatestVersionAndRecord from '../rowValidator/isPoExistingAndGetLatestVersionAndRecord'
import getRowValue from './getRowValue'
import filterOutPropertyForInvalidRows from '../rowValidator/filterOutPropertyForInvalidRows'
import config from '../../parameters'

const arrToObjForBSLCpoInput = async (rows) => {
  const dataType = 'cpo'
  const inputType = 'newAndExistingGeneralCpos'

  rows = getRowValue(dataType, inputType, rows)

  rows.forEach((row) => {
    row.poNumber = `${row.poPrefix}-${row.poNumber}`

    const customer = config.pgEntities.find((x) => x.poPrefix === row.poPrefix)
    if (customer) {
      row.customer = customer.name.toUpperCase()
    }
  })

  rows = isPoFieldNull(rows)

  rows = await isPoExistingAndGetLatestVersionAndRecord(rows)

  rows = await getProductInfoForNewRowItems(rows)

  return filterOutPropertyForInvalidRows(rows)
}
export default arrToObjForBSLCpoInput
