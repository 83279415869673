import handleDSOperation from '../../functions/handleDSOperation'

const onRowUpdate = async (rowData) => {
  const updateObj = {
    id: rowData.id,
    // type: rowData.type,
    customer: rowData.customer,
    email: rowData.email,
    name: rowData.name,
    dept: rowData.dept,
    date: rowData.date,
    unit: rowData.unit,
    mfr: rowData.mfr,
    mpn: rowData.mpn,
    number: rowData.number,
    cpn: rowData.cpn,
    price: rowData.price && rowData.price * 1,
    priceCurrency: rowData.priceCurrency,
    customerMOQ: rowData.customerMOQ && rowData.customerMOQ * 1,
    customerMPQ: rowData.customerMPQ && rowData.customerMPQ * 1,
    customerLeadtime: rowData.customerLeadtime && rowData.customerLeadtime * 1,
    paymentTerm: rowData.paymentTerm,
    deliveryTerm: rowData.deliveryTerm,
    hankenoEntity: rowData.hankenoEntity,
    memo: rowData.memo,
  }
  // console.log('updateObj', updateObj)

  // debugger

  await handleDSOperation(null, 'update', updateObj)
}

export default onRowUpdate
