const initialValue = {
  cpn: '',
  mpn: '',
  price: '',
  priceCurrency: '',
  supplierMOQ: '',
  supplierMPQ: '',
  reqSched: '',
  qty: '',
}

export default initialValue
