const initialValue = {
  
  itemsSelectablePos: [],
  poNumber: '',
  cpn: '',
  mpn: '',
  amountExclVat: '',
  qty: '',
  paymentAgentPriceExclVat: '',
}

export default initialValue
