import * as Yup from 'yup'

const formValidationSchema = {
  cpn: Yup.string().required('请输入客户型号，如无，请输入原厂型号'),
  mfr: Yup.string().required('请输入原厂'),
  mpn: Yup.string().required('请输入原厂型号'),
  hpn: Yup.string().required('请输入HKN型号'),
  productType: Yup.string().required('请输入产品种类'),
  vendors: Yup.array().min(1, '请选择供应商'),
  coo: Yup.array().min(1, '请选择原产国'),
  cost: Yup.string().required('请输入未税进价'),
  costCurrency: Yup.string().required('请输入进价货币'),
  price: Yup.string().required('请输入未税售价'),
  priceCurrency: Yup.string().required('请输入售价货币'),
  supplierMOQ: Yup.string().required('请输入原厂最小订货量'),
  supplierMPQ: Yup.string().required('请输入原厂最小包装量'),
  customerMOQ: Yup.string().required('请输入客户最小订货量'),
  customerMPQ: Yup.string().required('请输入客户最小包装量'),
  leadtime: Yup.string().required('请输入交期'),
  transitDays: Yup.string().required('请输入物流天数'),
  customerLeadtime: Yup.string().required('请输入PDT'),
}

export default formValidationSchema
