import handleDSOperation from '../../functions/handleDSOperation'
import config from '../../parameters'
import dsUpdatePaymentStatusOnInvoices from '../../functions/APIOperations/dsUpdatePaymentStatusOnInvoices'

const handleSubmit = async (values) => {
  try {
    await Promise.all(
      values.items.map(async (x) => {
        let inputAmountReceived = x.amountReceived

        if (inputAmountReceived && x.invoiceType === 'VAT') {
          inputAmountReceived =
            (inputAmountReceived / (1 + config.vatRate)).toFixed(2) * 1
          // debugger
        }
        const obj = {
          date: values.date,
          paymentType: values.paymentType,
          invoiceType: x.invoiceType,
          customer: values.customer,
          invoiceNumber: x.invoiceNumber,
          priceCurrency: x.priceCurrency,
          amountExclVat: inputAmountReceived,
        }

        // console.log('obj', obj)
        // debugger
        await handleDSOperation('incomingPayment', 'create', obj)
        await dsUpdatePaymentStatusOnInvoices({
          invoiceNumber: x.invoiceNumber,
          customer: values.customer,
        })
      }),
    )
  } catch (err) {
    console.log('err 8893', err)
  }
}

export default handleSubmit
