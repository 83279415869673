import React, { useEffect } from 'react'
import { Typography, Backdrop, CircularProgress } from '@mui/material'

import { FormField, AutoCompleteField } from '../../components/Form/FormField'

import usePrevious from '../../hooks/usePrevious'
import { Form } from 'formik'
import handleDSOperation from '../../functions/handleDSOperation'

const CreateMainComponent = (props) => {
  const {
    values,
    submitForm,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    initialValues,
    setFieldValue,
    setValues,
    isSubmitting,
    formStyle,
  } = props
  // console.log('values', values)
  const prevCustomer = usePrevious(values.customer)
  const totalQty = values.items.reduce((a, v) => (v.qty ? a + v.qty : a), 0)
  const totalAmount = values.items.reduce(
    (a, v) => (v.qty && v.price ? a + v.qty * v.price : a),
    0,
  )

  useEffect(() => {
    const func = async () => {
      const currentCustomer = values.customer

      if (!values.customerOptions) {
        // console.log('getCustomers')
        const getCustomer = await handleDSOperation('customer', 'query', {
          isActive: { predicate: 'eq', value: true },
        })
        const customerOptions = getCustomer.map((x) => ({
          customer: x.entity,
          paymentTerm: x.paymentTerm,
          deliveryTerms: x.deliveryTerms,
          number: x.number,
          hankenoEntity: x.hankenoEntity,
          entityType: x.entityType,
        }))
        const getProducts = await handleDSOperation('product', 'query', {
          version: { predicate: 'eq', value: 0 },
          isActive: { predicate: 'eq', value: true },
        })
        const productOptions = getProducts
          .map((x) => ({
            hpn: x.hpn,
            cpn: x.cpn,
            mpn: x.mpn,
            mfr: x.mfr,
            unit: x.unit,
            price: x.price,
            priceCurrency: x.priceCurrency,
            supplierMOQ: x.supplierMOQ,
            supplierMPQ: x.supplierMPQ,
          }))
          .sort((a, b) => (a.cpn > b.cpn ? 1 : -1))

        setValues({
          ...initialValues,
          customerOptions,
          productOptions,
        })
      }

      if (currentCustomer && prevCustomer !== currentCustomer) {
        const customer = values.customerOptions.find(
          (x) => x.customer === currentCustomer,
        )

        setValues({
          ...values,
          customer: currentCustomer,
          paymentTerm: customer.paymentTerm,
          deliveryTerms: customer.deliveryTerms || [],
          deliveryTerm: customer.deliveryTerms && customer.deliveryTerms[0],
          hankenoEntity: customer.hankenoEntity,
          customerNumber: customer.number,
        })
      }
    }
    func()
  }, [prevCustomer, values, setFieldValue, setValues, initialValues])

  return (
    <>
      <Form style={formStyle} autoComplete='off'>
        <div>
          <AutoCompleteField
            fieldName='customer'
            attr='customer'
            options={values.customerOptions || []}
            setFieldValue={setFieldValue}
          />

          <FormField
            fieldName='poNumber'
            value={values.poNumber}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <FormField
            fieldName='date'
            value={values.date}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <FormField
            disabled
            fieldName='customerNumber'
            value={values.customerNumber}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <FormField
            disabled
            fieldName='paymentTerm'
            value={values.paymentTerm}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
          <FormField
            select
            fieldName='deliveryTerm'
            value={values.deliveryTerm}
            handleBlur={handleBlur}
            handleChange={handleChange}
            options={values.deliveryTerms}
          />
        </div>
        <div style={{ height: 50 }}>
          {totalQty > 0 && (
            <div style={{ marginLeft: 50 }}>
              <Typography
                color='secondary'
                component='span'
                variant='h6'
                align='center'>
                数量合计: {totalQty.toFixed(2)}
              </Typography>
              {'  '}
              <Typography
                color='secondary'
                component='span'
                variant='h6'
                align='center'>
                金额合计: {totalAmount.toFixed(2)}
              </Typography>
            </div>
          )}
        </div>
      </Form>
      <Backdrop
        sx={{
          zIndex: 3000,
          // zIndex: 'zIndex.modal' + 1,
          color: '#fff',
        }}
        open={!values.customerOptions}
        onClick={() => {}}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  )
}

export default CreateMainComponent
