import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import SimpleTable from '../SimpleTable'
import handleDSOperation from '../../../functions/handleDSOperation'

import config from '../../../parameters'

const PaymentRecord = ({
  paymentType,
  invoiceNumber,
  receivingCode,
  isForwarderPO,
  vendor,
  customer,
}) => {
  const [rows, setRows] = useState([])

  useEffect(() => {
    ;(async () => {
      let queryObj
      let entityObj
      if (vendor) {
        entityObj = { vendor: { predicate: 'eq', value: vendor } }
      } else if (customer) {
        entityObj = {
          customer: { predicate: 'eq', value: customer },
        }
      }

      if (isForwarderPO) {
        queryObj = {
          invoiceType: { predicate: 'eq', value: 'forwarderPo' },
          invoiceNumber: {
            predicate: 'eq',
            value: receivingCode,
          },
        }
      } else {
        queryObj = {
          invoiceNumber: {
            predicate: 'eq',
            value: invoiceNumber,
          },
        }
      }

      console.log('paymentType', paymentType)

      let items = await handleDSOperation(paymentType, 'query', {
        ...queryObj,
        ...entityObj,
      })
      setRows(items)
    })()
  }, [
    paymentType,
    invoiceNumber,
    receivingCode,
    isForwarderPO,
    vendor,
    customer,
  ])

  let amount =
    rows.length > 0 &&
    rows.reduce((a, v) => a + v.amountExclVat * 1, 0).toFixed(2)
  if (rows.length > 0 && rows[0].invoiceType === 'VAT') {
    amount = (amount * (1 + config.vatRate)).toFixed(2)
  }

  return (
    <Box m={3}>
      <Typography> Amount:{amount} </Typography>
      {/* <MTSumField qtyAttr='amount' selectedRows={rows} /> */}
      <SimpleTable
        dataType={paymentType}
        tbType='collasped'
        rows={rows}
      />
    </Box>
  )
}

export default PaymentRecord
