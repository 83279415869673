import React from 'react'
import { FormGroup, Typography } from '@mui/material'
import { FormField, SwitchField } from '../../../components/Form/FormField'

const ForwarderPayment = ({
  initialValues,
  setAllowAdd,
  setAllowDelete,
  values,
  handleBlur,
  handleChange,
  setFieldValue,
  setValues,
  index,
  item: currentItem,
}) => {
  // useEffect(() => {
  //   if (currentItem.amountPaid === undefined && currentItem.amountExclVat) {
  //     setFieldValue(`items[${index}]`, {
  //       ...currentItem,
  //       amountPaid:
  //         currentItem.invoiceType === 'VAT'
  //           ? (currentItem.amountExclVat * (1 + config.vatRate)).toFixed(2) * 1
  //           : currentItem.amountExclVat,
  //     })
  //   }
  // }, [currentItem, setFieldValue, index, values])

  return (
    <>
      <Typography variant='h6'>
        小计：{' '}
        {currentItem
          .reduce(
            (a, v) =>
              a +
              (v.handlingAndTaxChecked ? v.handlingAndTax : 0) +
              (v.cnyValueChecked ? v.cnyValue : 0),
            0,
          )
          .toFixed(2) * 1}
      </Typography>

      {currentItem &&
        Array.isArray(currentItem) &&
        currentItem.map((x, i) => (
          <FormGroup row key={x.id}>
            <FormField disabled fieldName='cpn' value={x.cpn} />
            <FormField disabled fieldName='mpn' value={x.mpn} />
            <FormField
              disabled
              fieldName='cost'
              value={x.cost || x.amountExclVat / x.qty}
            />
            <FormField disabled fieldName='qty' value={x.qty} />
            <FormField
              disabled
              fieldName='amount'
              value={(x.qty * (x.cost || x.amountExclVat / x.qty)).toFixed(2)}
            />

            <SwitchField
              fieldName='handlingAndTaxChecked'
              disabled={x.handlingAndTax * 1 === 0}
              index={index}
              subIndex={i}
              handleChange={handleChange}
              checked={x.handlingAndTaxChecked}
            />
            <FormField
              fieldName='handlingAndTax'
              disabled={!x.handlingAndTaxChecked}
              index={index}
              subIndex={i}
              value={x.handlingAndTax}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <SwitchField
              fieldName='cnyValueChecked'
              disabled={x.cnyValue * 1 === 0}
              index={index}
              subIndex={i}
              handleChange={handleChange}
              checked={x.cnyValueChecked}
            />
            <FormField
              fieldName='cnyValue'
              disabled={!x.cnyValueChecked}
              index={index}
              subIndex={i}
              value={x.cnyValue}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </FormGroup>
        ))}
    </>
  )
}
export default ForwarderPayment
