import React, { useEffect } from 'react'

import {
  FormField,
  ProductAutoComplete,
  DimensionAutoComplete,
} from '../../components/Form/FormField'

import handleDSOperation from '../../functions/handleDSOperation'
import usePrevious from '../../hooks/usePrevious'
import { Form, Field } from 'formik'
import config from '../../parameters'

const ItemComponent = ({
  values,
  handleBlur,
  index,
  handleChange,
  setFieldValue,
  formStyle,
  item: currentItem,
  errors,
  touched,
}) => {
  // const currentItem = values.items[index]
  const prevPoNumber = usePrevious(currentItem.poNumber)
  const prevReceivingCode = usePrevious(currentItem.receivingCode)
  const prevHpn = usePrevious(currentItem.hpn)
  const prevDimensions = usePrevious(currentItem.dimensions)

  const hpns = [...new Set(values.poOptions.map((x) => x.hpn))]

  const selection = hpns.map((x) => {
    const item = values.poOptions.find((y) => y.hpn === x)
    return { mpn: item.mpn, cpn: item.cpn, hpn: item.hpn, mfr: item.mfr }
  })

  selection.sort((a, b) => (a.cpn > b.cpn ? 1 : -1))

  // console.log('values', values)

  useEffect(() => {
    const func = async () => {
      let coos, coo, cpn, unit, mfr, mpn, poNumber
      let currentInventory = 0
      let openQty = 0

      if (currentItem.hpn && currentItem.hpn !== prevHpn) {
        const getProd = await handleDSOperation('product', 'query', {
          hpn: {
            predicate: 'eq',
            value: currentItem.hpn,
          },
          version: { predicate: 'eq', value: 0 },
        })

        if (getProd.length > 0) {
          const prod = getProd[0]

          // console.log('prod', prod)
          cpn = prod.cpn
          unit = prod.unit
          currentInventory = prod.currentInventory
          coos = prod.coo && prod.coo.length > 0 ? prod.coo : config.coo
          coo = coos[0]
          mfr = prod.mfr
          mpn = prod.mpn
        }

        const itemSelectablePos = values.poOptions.filter(
          (x) => x.hpn === currentItem.hpn,
        )

        if (
          [...new Set(itemSelectablePos.map((x) => x.poNumber))].length === 1
        ) {
          poNumber = itemSelectablePos[0].poNumber
        }

        setFieldValue(`items[${index}]`, {
          ...currentItem,
          itemSelectablePos,
          hpn: currentItem.hpn,
          currentInventory,
          poNumber,
          mfr,
          mpn,
          cpn,
          coo,
          coos,
          prices: [],
          unit,
          price: '',
          priceCurrency: '',
        })
      }

      if (
        currentItem.hpn &&
        currentItem.poNumber &&
        currentItem.poNumber !== prevPoNumber
      ) {
        const getCpos = currentItem.itemSelectablePos.filter(
          (x) => x.poNumber === currentItem.poNumber,
        )
        // console.log('getCpos', getCpos)

        const prices = [...new Set(getCpos.map((x) => x.price))]
        const price = prices[0]

        if (getCpos.length > 0) {
          openQty = getCpos.reduce((a, v) => a + v.openQty, 0)

          // console.log('eeeeee', currentInventory, openQty)

          setFieldValue(`items[${index}]`, {
            ...currentItem,
            openQty,
            price,
            priceCurrency: getCpos[0].priceCurrency,
            prices,
            qty:
              currentItem.qty ||
              Math.min(Math.max(currentItem.currentInventory, 0), openQty),
          })
        }
      }
    }

    func()
  }, [
    prevPoNumber,
    prevReceivingCode,
    prevDimensions,
    index,
    setFieldValue,
    prevHpn,
    values,
    currentItem,
  ])

  // console.log('values.customer', values.customer)
  return (
    <Form style={formStyle} autoComplete='off'>
      <ProductAutoComplete
        id={`${values.customer}${values.items.length}`}
        options={selection}
        currentItem={currentItem}
        value={
          currentItem.cpn
            ? {
                cpn: currentItem.cpn,
                mfr: currentItem.mfr,
                mpn: currentItem.mpn,
                hpn: currentItem.hpn,
              }
            : null
        }
        index={index}
        setFieldValue={setFieldValue}
      />

      <FormField
        disabled={!currentItem.hpn}
        fieldName='poNumber'
        select
        index={index}
        value={(currentItem.hpn && currentItem.poNumber) || ''}
        options={[
          ...new Set(currentItem.itemSelectablePos.map((x) => x.poNumber)),
        ]}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
      <FormField
        disabled={!currentItem.hpn}
        fieldName='mfr'
        index={index}
        value={currentItem.mfr}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
      <FormField
        disabled={!currentItem.hpn}
        fieldName='mpn'
        index={index}
        value={currentItem.mpn}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />

      <FormField
        disabled={!currentItem.hpn}
        fieldName='price'
        select
        index={index}
        value={currentItem.price}
        options={currentItem.prices}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
      <FormField
        disabled={!currentItem.hpn}
        fieldName='coo'
        select={currentItem.coos && currentItem.coos.length > 1}
        index={index}
        value={currentItem.coo}
        options={currentItem.coos}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
      <FormField
        fieldName='openQty'
        disabled
        value={(currentItem.hpn && currentItem.openQty) || ''}
      />
      <FormField
        fieldName='currentInventory'
        disabled
        value={(currentItem.hpn && currentItem.currentInventory) || ''}
      />
      <FormField
        disabled={!currentItem.hpn}
        fieldName='cartonNumber'
        index={index}
        handleChange={handleChange}
        handleBlur={handleBlur}
        value={currentItem.cartonNumber}
      />
      <FormField
        disabled={!currentItem.hpn}
        fieldName='qty'
        index={index}
        handleChange={handleChange}
        handleBlur={handleBlur}
        value={currentItem.qty}
        // validate={(value) => {
        //   return value * 1 >
        //     Math.min(currentItem.openQty, currentItem.currentInventory)
        //     ? 'Shipping qty is greater than inventory or order open qty'
        //     : null
        // }}
      />
      <FormField
        disabled={!currentItem.hpn}
        fieldName='gw'
        index={index}
        handleChange={handleChange}
        handleBlur={handleBlur}
        value={currentItem.gw}
      />
      <FormField
        disabled={!currentItem.hpn}
        fieldName='nw'
        index={index}
        handleChange={handleChange}
        handleBlur={handleBlur}
        value={
          currentItem.nw ||
          (currentItem.gw && (currentItem.gw * 0.92).toFixed(2)) ||
          ''
        }
      />
      <DimensionAutoComplete
        disabled={!currentItem.hpn}
        values={values}
        currentItem={currentItem}
        index={index}
        setFieldValue={setFieldValue}
        options={config.packageDimensions}
        value={
          (values.cartons &&
            values.cartons[currentItem.cartonNumber] &&
            values.cartons[currentItem.cartonNumber].dimensions) ||
          ''
        }
      />
      {/* <FormField
        fieldName='dimensions'
        disabled={!currentItem.cartonNumber}
        select={
          !currentCarton ||
          currentCarton.dimensions === undefined ||
          (currentCarton.dimensions !== '自定义' &&
            config.packageDimensions.includes(currentCarton.dimensions))
        }
        index={index}
        handleChange={handleChange}
        handleBlur={handleBlur}
        value={(currentCarton && currentCarton.dimensions) || ''}
        options={config.packageDimensions}
      /> */}
    </Form>
  )
}

export default ItemComponent
