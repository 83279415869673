import getRowValue from './getRowValue'

const arrToObjForVendorInput = (rows) => {
  const dataType = 'vendor'
  const inputType = 'newVendor'

  rows = getRowValue(dataType, inputType, rows)

  rows.forEach((x) => {
    x.entity = x.entity.toUpperCase()
    x.mainContactName = x.mainContactName && x.mainContactName.toUpperCase()
    x.deliveryTerms = x.deliveryTerms && x.deliveryTerms.split(',')
    x.contactPerson = {
      name: x.mainContactName && x.mainContactName.toUpperCase(),
      role: x.mainContactRole,
      phoneNumber: x.mainContactPhoneNumber,
      email: x.mainContactEmail,
    }
  })
  // console.log('rows', rows)
  // debugger
  return rows
}

export default arrToObjForVendorInput
