import firstBy from 'thenby'
import handleDSOperation from '../handleDSOperation'
import mergeArrayAndCombineQty from '../helpFunc/mergeArrayAndCombineQty'

const dsCreateOutgoingInvoice = async (rows) => {
  // console.log('rows', rows)
  if (rows[0].invoiceType !== 'VAT') {
    rows = mergeArrayAndCombineQty(
      rows,
      ['poNumber', 'fulfillmentNumber', 'hpn', 'price'],
      ['qty'],
    )
  }

  // alert(333)
  // return

  rows.sort(
    firstBy('cpn')
      .thenBy('fulfillmentNumber')
      .thenBy('poNumber')
      .thenBy('price')
      .thenBy('qty'),
  )

  // console.log('rows', rows)
  // debugger
  await Promise.all(
    rows.map(async (x) => {
      const input = {
        date: x.date,
        invoiceNumber: x.invoiceNumber,
        fulfillmentNumber: x.fulfillmentNumber,
        customer: x.customer,
        poNumber: x.poNumber,
        cpn: x.cpn,
        hpn: x.hpn,
        qty: x.qty,
        mpn: x.mpn,
        mfr: x.mfr,
        unit: x.unit,
        price: x.price,
        priceCurrency: x.priceCurrency,
        invoiceType: x.invoiceType,
        customerPaymentDue: x.customerPaymentDue,
        paymentStatus: 'unpaid',
      }

      await handleDSOperation('outgoingInvoice', 'create', input)
    }),
  )
}

export default dsCreateOutgoingInvoice
