const mapping = {
  enhanced: [
    'actions',
    'memo',
    'date',
    'poNumber',
    'cpn',
    'qty',
    'mpn',
    'vendor',
    'invoiceNumber',
    'receivingCode',
    'billingStatus',
    // 'vendorPaymentDue',
    // 'vendorPaymentPaid',
  ],
  simple: ['date', 'poNumber', 'cpn', 'qty', 'mpn', 'vendor'],
  collasped: ['date', 'cpn', 'qty'],
  collaspedFulfillment: ['date', 'vendor', 'poNumber', 'cpn', 'mpn', 'qty'],
}

export default mapping
