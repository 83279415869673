import handleDSOperation from '../../functions/handleDSOperation'

const onRowUpdate = async (newData) => {
  const item = {
    id: newData.id,
    date: newData.date,
    mpn: newData.mpn,
    cpn: newData.cpn,
    qty: newData.qty * 1,
    receivingCode: newData.receivingCode,
    amountExclVat: newData.amountExclVat && newData.amountExclVat * 1,
    costCurrency: newData.costCurrency,
    openQty: newData.openQty * 1,
    handlingFee: newData.handlingFee,
    extraCharge: newData.extraCharge,
    forwarderTaxAndHandlingDue: newData.forwarderTaxAndHandlingDue,
    forwarderTaxAndHandlingPaid: newData.forwarderTaxAndHandlingPaid,
    vendorPaymentDue: newData.vendorPaymentDue,
    vendorPaymentPaid: newData.vendorPaymentPaid,
    forwarderPaymentDue: newData.forwarderPaymentDue,
    forwarderPaymentPaid: newData.forwarderPaymentPaid,
    memo: newData.memo,
    paymentStatus: newData.paymentStatus,
    deliveryStatus: newData.deliveryStatus,
    billingStatus: newData.billingStatus,
  }
  // console.log('update forwarder po')
  // console.log('dataType', dataType)
  // console.log('newData', newData)

  // console.log('item', item)
  // debugger
  await handleDSOperation(null, 'update', item)
}

export default onRowUpdate
