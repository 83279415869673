import React from 'react'
import { FormField } from '../../components/Form/FormField'
import { Form } from 'formik'

const SpoUpdateSchedItemComponent = (props) => {
  const {
    values,
    index,
    submitForm,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    setFieldValue,
    setValues,
    isSubmitting,
    formStyle,
    item: currentItem,
  } = props

  return (
    <Form style={formStyle} autoComplete='off'>
      <div>
        <FormField
          fieldName='qty'
          index={index}
          value={currentItem.qty}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />

        <FormField
          fieldName='estSched'
          index={index}
          value={currentItem.estSched}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
      </div>
    </Form>
  )
}

export default SpoUpdateSchedItemComponent
