import handleDSOperation from '../../functions/handleDSOperation'

const onRowUpdate = async (newData) => {
  const updateObj = {
    id: newData.id,
    date: newData.date,
    invoiceNumber: newData.invoiceNumber,
    invoiceType: newData.invoiceType,
    amountExclVat: newData.amountExclVat,
    paymentType: newData.paymentType,
    priceCurrency: newData.priceCurrency,
    vendor: newData.vendor,
    memo: newData.memo,
  }
  console.log('updateObj', updateObj)

  // debugger

  await handleDSOperation(null, 'update', updateObj)
}

export default onRowUpdate
