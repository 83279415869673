import dsDeleteFulfillments from '../../functions/APIOperations/dsDeleteFulfillments'
import dsUpdateInventory from '../../functions/APIOperations/dsUpdateInventory'
import dsUpdateSPOQtyAndSPOSchedAfterDeleteReceivings from '../../functions/APIOperations/dsUpdateSPOQtyAndSPOSchedAfterDeleteReceivings'
import dsUpdateForwarderPOQtyAfterDeleteReceivings from '../../functions/APIOperations/dsUpdateForwarderPOQtyAfterDeleteReceivings'
import dsUpdateForwarderPODeliveryStatusWithReceivingCodeAndHpn from '../../functions/APIOperations/dsUpdateForwarderPODeliveryStatusWithReceivingCodeAndHpn'

const onRowDelete = async (oldData) => {
  await dsDeleteFulfillments([oldData])

  await dsUpdateSPOQtyAndSPOSchedAfterDeleteReceivings([oldData])

  await dsUpdateInventory([oldData], 'minus')

  await dsUpdateForwarderPOQtyAfterDeleteReceivings([oldData])

  // if (oldData.receivingCode) {
  //   await dsUpdateForwarderPODeliveryStatusWithReceivingCodeAndHpn({
  //     receivingCode: oldData.receivingCode,
  //     hpn: oldData.hpn,
  //   })
  // }
}

export default onRowDelete
