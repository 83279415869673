import outgoingPaymentCreateItemInitialValue from './createItemInitialValue'

const initialValue = () => ({
  date: new Date().toISOString().substring(0, 10),
 

  // vendorOptions: [],
  // items: [outgoingPaymentCreateItemInitialValue],
  // month: '',
  // amount: '',
})

export default initialValue
